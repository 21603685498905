.email-preview-modal {
  .modal-dialog {
    max-width: 56.25rem;
  }
  .modal-content {
    // min-height: calc(90vh - 10rem);

    .modal-header {
      max-height: 88px;
      padding: 1.25rem 1.5rem;
      border-bottom-color: $gray-13;

      .modal-title {
        h1 {
          margin: 0;
          font-weight: $font-weight-semibold;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $blue-15;
        }
        p {
          margin: 0;
          font-weight: $font-weight-medium;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $gray-15;
          margin-top: 0.25rem;
        }
      }
      .close {
        margin: 0;
        padding: 0;
        font-size: 1.5rem !important;
      }
    }

    .modal-body {
      max-height: calc(90vh - 5.6rem);
      .email-sender-details {
        margin-left: 0.75rem;
        h3 {
          margin: 0;
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          line-height: 1.25rem;
          color: $blue-15;
          margin-bottom: 2px;

          span {
            margin-left: 0.25rem;
            font-weight: $font-weight-normal;
          }
        }
        p {
          margin: 0;
          font-weight: $font-weight-medium;
          font-size: 0.75rem;
          line-height: 1rem;
          color: $gray-15;
        }
      }
      .email-sent-time {
        margin: 0;
        font-weight: $font-weight-medium;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $gray-15;
      }
      .email-preview-content {
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        font-size: 0.8125rem;
        line-height: 1.25rem;
        color: $blue-15;
      }
      .email-preview-attachments {
        gap: 0.625rem;
      }
    }
  }
}

.email-preview-tooltip {
  .tooltip-inner {
    max-width: 500px !important;
  }
}

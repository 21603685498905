$icomoon-font-family: 'SalesHandy' !default;
$icomoon-font-path: '../fonts' !default;

$sh-shield-check: '\ec1b';
$sh-right-arrow: '\ec1a';
$sh-alert-circle: '\ec19';
$sh-pause-circle: '\ec18';
$sh-rotate: '\ec17';
$sh-pencil: '\ec16';
$sh-click: '\ec14';
$sh-layout-lists: '\ec15';
$sh-send: '\ec12';
$sh-round-corner-arrow-back: '\ec13';
$sh-help: '\ec11';
$sh-paperclip: '\ec0f';
$sh-rotate-clockwise: '\ec10';
$sh-circle-check: '\ec0e';
$sh-file-invoice: '\ea82';
$sh-wand: '\ec0d';
$sh-bell: '\e904';
$sh-bell-off: '\eb18';
$sh-chrome: '\eb77';
$sh-download: '\ec07';
$sh-gift: '\ec08';
$sh-headset: '\ec09';
$sh-mailbox: '\ec0a';
$sh-refresh: '\ec0b';
$sh-trash: '\ec0c';
$sh-prospects: '\ec06';
$sh-curly-braces: '\ebff';
$sh-warning-hexagone: '\ebf4';
$sh-warning: '\ebf5';
$sh-alarm: '\e900';
$sh-battery: '\e901';
$sh-battery-empty: '\e902';
$sh-battery-full: '\e903';
$sh-bolt: '\e905';
$sh-bulb: '\e906';
$sh-calendar: '\e907';
$sh-calendar-dates: '\e908';
$sh-calendar-due: '\e909';
$sh-calendar-next: '\e90a';
$sh-calendar-today: '\e90b';
$sh-calendar-two: '\e90c';
$sh-danger: '\e90d';
$sh-info: '\e90e';
$sh-phone: '\e90f';
$sh-caret-up: '\ebf6';
$sh-caret-down: '\ebf7';
$sh-caret-right: '\ebf8';
$sh-caret-left: '\ebf9';
$sh-arrow-align-h: '\e910';
$sh-arrow-align-v: '\e911';
$sh-arrow-bottom-left: '\e912';
$sh-arrow-bottom-left-o: '\e913';
$sh-arrow-bottom-left-r: '\e914';
$sh-arrow-bottom-right: '\e915';
$sh-arrow-bottom-right-o: '\e916';
$sh-arrow-bottom-right-r: '\e917';
$sh-arrow-down: '\e918';
$sh-arrow-down-o: '\e919';
$sh-arrow-down-r: '\e91a';
$sh-arrow-left: '\e91b';
$sh-arrow-left-o: '\e91c';
$sh-arrow-left-r: '\e91d';
$sh-arrow-long-down: '\e91e';
$sh-arrow-long-down-c: '\e91f';
$sh-arrow-long-down-e: '\e920';
$sh-arrow-long-down-l: '\e921';
$sh-arrow-long-down-r: '\e922';
$sh-arrow-long-left: '\e923';
$sh-arrow-long-left-c: '\e924';
$sh-arrow-long-left-e: '\e925';
$sh-arrow-long-left-l: '\e926';
$sh-arrow-long-left-r: '\e927';
$sh-arrow-long-right: '\e928';
$sh-arrow-long-right-c: '\e929';
$sh-arrow-long-right-e: '\e92a';
$sh-arrow-long-right-l: '\e92b';
$sh-arrow-long-right-r: '\e92c';
$sh-arrow-long-up: '\e92d';
$sh-arrow-long-up-c: '\e92e';
$sh-arrow-long-up-e: '\e92f';
$sh-arrow-long-up-l: '\e930';
$sh-arrow-long-up-r: '\e931';
$sh-arrow-right: '\e932';
$sh-arrow-right-o: '\e933';
$sh-arrow-right-r: '\e934';
$sh-arrows-breake-h: '\e935';
$sh-arrows-breake-v: '\e936';
$sh-arrows-exchange: '\e937';
$sh-arrows-exchange-alt: '\e938';
$sh-arrows-exchange-alt-v: '\e939';
$sh-arrows-exchange-v: '\e93a';
$sh-arrows-expand-down-left: '\e93b';
$sh-arrows-expand-down-right: '\e93c';
$sh-arrows-expand-left: '\e93d';
$sh-arrows-expand-left-alt: '\e93e';
$sh-arrows-expand-right: '\e93f';
$sh-arrows-expand-right-alt: '\e940';
$sh-arrows-expand-up-left: '\e941';
$sh-arrows-expand-up-right: '\e942';
$sh-arrows-h: '\e943';
$sh-arrows-h-alt: '\e944';
$sh-arrows-merge-alt-h: '\e945';
$sh-arrows-merge-alt-v: '\e946';
$sh-arrows-scroll-h: '\e947';
$sh-arrows-scroll-v: '\e948';
$sh-arrows-shrink-h: '\e949';
$sh-arrows-shrink-v: '\e94a';
$sh-arrows-v: '\e94b';
$sh-arrows-v-alt: '\e94c';
$sh-arrow-top-left: '\e94d';
$sh-arrow-top-left-o: '\e94e';
$sh-arrow-top-left-r: '\e94f';
$sh-arrow-top-right: '\e950';
$sh-arrow-top-right-o: '\e951';
$sh-arrow-top-right-r: '\e952';
$sh-arrow-up: '\e953';
$sh-arrow-up-o: '\e954';
$sh-arrow-up-r: '\e955';
$sh-check: '\e956';
$sh-check-o: '\e957';
$sh-check-r: '\e958';
$sh-compress: '\e959';
$sh-compress-left: '\e95a';
$sh-compress-right: '\e95b';
$sh-compress-v: '\e95c';
$sh-corner-double-down-left: '\e95d';
$sh-corner-double-down-right: '\e95e';
$sh-corner-double-left-down: '\e95f';
$sh-corner-double-left-up: '\e960';
$sh-corner-double-right-down: '\e961';
$sh-corner-double-right-up: '\e962';
$sh-corner-double-up-left: '\e963';
$sh-corner-double-up-right: '\e964';
$sh-corner-down-left: '\e965';
$sh-corner-down-right: '\e966';
$sh-corner-left-down: '\e967';
$sh-corner-left-up: '\e968';
$sh-corner-right-down: '\e969';
$sh-corner-right-up: '\e96a';
$sh-corner-up-left: '\e96b';
$sh-corner-up-right: '\e96c';
$sh-enter: '\e96d';
$sh-expand: '\e96e';
$sh-export: '\e96f';
$sh-import: '\e970';
$sh-internal: '\e971';
$sh-merge-horizontal: '\e972';
$sh-merge-vertical: '\e973';
$sh-minimize: '\e974';
$sh-minimize-alt: '\e975';
$sh-move-down: '\e976';
$sh-move-left: '\e977';
$sh-move-right: '\e978';
$sh-move-task: '\e979';
$sh-move-up: '\e97a';
$sh-push-down: '\e97b';
$sh-push-left: '\e97c';
$sh-push-right: '\e97d';
$sh-push-up: '\e97e';
$sh-redo: '\e97f';
$sh-repeat: '\e980';
$sh-scroll-h: '\e981';
$sh-scroll-v: '\e982';
$sh-select: '\e983';
$sh-select-o: '\e984';
$sh-select-r: '\e985';
$sh-swap: '\e986';
$sh-swap-vertical: '\e987';
$sh-sync: '\e988';
$sh-trending: '\e989';
$sh-trending-down: '\e98a';
$sh-undo: '\e98b';
$sh-abstract: '\e98c';
$sh-adidas: '\e98d';
$sh-atlasian-path1: '\e98e';
$sh-atlasian-path2: '\e98f';
$sh-bmw: '\e990';
$sh-brackets: '\e991';
$sh-chanel: '\e992';
$sh-circleci: '\e993';
$sh-code-climate: '\e994';
$sh-comedy-central: '\e995';
$sh-crowdfire: '\e996';
$sh-designmodo: '\e997';
$sh-digitalocean: '\e998';
$sh-dolby: '\e999';
$sh-dribbble: '\e99a';
$sh-eventbrite: '\e99b';
$sh-facebook: '\e99c';
$sh-figma: '\e99d';
$sh-framer-path1: '\e99e';
$sh-framer-path2: '\e99f';
$sh-ghost: '\e9a0';
$sh-gitter: '\e9a1';
$sh-google: '\e9a2';
$sh-google-tasks: '\e9a3';
$sh-hello: '\e9a4';
$sh-if-design: '\e9a5';
$sh-indie-hackers: '\e9a6';
$sh-instagram: '\e9a7';
$sh-lastpass: '\e9a8';
$sh-linear: '\e9a9';
$sh-maze: '\e9aa';
$sh-microbit: '\e9ab';
$sh-microsoft: '\e9ac';
$sh-monday: '\e9ad';
$sh-npm: '\e9ae';
$sh-oculus: '\e9af';
$sh-overflow-path1: '\e9b0';
$sh-overflow-path2: '\e9b1';
$sh-overflow-path3: '\e9b2';
$sh-pacman: '\e9b3';
$sh-patreon-path1: '\e9b4';
$sh-patreon-path2: '\e9b5';
$sh-paypal: '\e9b6';
$sh-pexels: '\e9b7';
$sh-pocket: '\e9b8';
$sh-pokemon: '\e9b9';
$sh-product-hunt: '\e9ba';
$sh-serverless: '\e9bb';
$sh-shutterstock: '\e9bc';
$sh-sketch: '\e9bd';
$sh-slack: '\e9be';
$sh-spectrum: '\e9bf';
$sh-square: '\e9c0';
$sh-stark: '\e9c1';
$sh-studio: '\e9c2';
$sh-sweden: '\e9c3';
$sh-swiss: '\e9c4';
$sh-tally: '\e9c5';
$sh-trello: '\e9c6';
$sh-twilio: '\e9c7';
$sh-twitter: '\e9c8';
$sh-unfold: '\e9c9';
$sh-unsplash: '\e9ca';
$sh-userlane: '\e9cb';
$sh-windows: '\e9cc';
$sh-youtube: '\e9cd';
$sh-zeit: '\e9ce';
$sh-filter: '\ebfa';
$sh-add: '\e9cf';
$sh-add-r: '\e9d0';
$sh-assign: '\e9d1';
$sh-backspace: '\e9d2';
$sh-bookmark: '\e9d3';
$sh-captions: '\e9d4';
$sh-close-o: '\e9d5';
$sh-close-r: '\e9d6';
$sh-feed-path1: '\e9d7';
$sh-feed-path2: '\e9d8';
$sh-feed-path3: '\e9d9';
$sh-feed-path4: '\e9da';
$sh-feed-path5: '\e9db';
$sh-feed-path6: '\e9dc';
$sh-flag: '\e9dd';
$sh-inbox: '\e9de';
$sh-lock: '\e9df';
$sh-lock-unlock: '\e9e0';
$sh-log-in: '\e9e1';
$sh-log-off: '\e9e2';
$sh-log-out: '\e9e3';
$sh-menu: '\e9e4';
$sh-menu-left: '\e9e5';
$sh-menu-right: '\e9e6';
$sh-more: '\e9e7';
$sh-more-alt: '\e9e8';
$sh-more-o: '\e9e9';
$sh-more-r: '\e9ea';
$sh-more-vertical: '\e9eb';
$sh-more-vertical-alt: '\e9ec';
$sh-more-vertical-o: '\e9ed';
$sh-more-vertical-r: '\e9ee';
$sh-play-pause: '\e9ef';
$sh-play-pause-o: '\e9f0';
$sh-play-pause-r: '\e9f1';
$sh-play-stop: '\e9f2';
$sh-play-stop-o: '\e9f3';
$sh-play-stop-r: '\e9f4';
$sh-printer: '\e9f5';
$sh-read: '\e9f6';
$sh-share: '\e9f7';
$sh-shortcut: '\e9f8';
$sh-size: '\e9f9';
$sh-software-download: '\e9fa';
$sh-software-upload: '\e9fb';
$sh-sort-az: '\e9fc';
$sh-sort-za: '\e9fd';
$sh-square1: '\e9fe';
$sh-toggle-off: '\e9ff';
$sh-toggle-on: '\ea00';
$sh-toggle-square: '\ea01';
$sh-toggle-square-off: '\ea02';
$sh-chevron-double-down: '\ea03';
$sh-chevron-double-down-o: '\ea04';
$sh-chevron-double-down-r: '\ea05';
$sh-chevron-double-left: '\ea06';
$sh-chevron-double-left-o: '\ea07';
$sh-chevron-double-left-r: '\ea08';
$sh-chevron-double-right: '\ea09';
$sh-chevron-double-right-o: '\ea0a';
$sh-chevron-double-right-r: '\ea0b';
$sh-chevron-double-up: '\ea0c';
$sh-chevron-double-up-o: '\ea0d';
$sh-chevron-double-up-r: '\ea0e';
$sh-chevron-down: '\ea0f';
$sh-chevron-down-o: '\ea10';
$sh-chevron-down-r: '\ea11';
$sh-chevron-left: '\ea12';
$sh-chevron-left-o: '\ea13';
$sh-chevron-left-r: '\ea14';
$sh-chevron-right: '\ea15';
$sh-chevron-right-o: '\ea16';
$sh-chevron-right-r: '\ea17';
$sh-chevron-up: '\ea18';
$sh-chevron-up-o: '\ea19';
$sh-chevron-up-r: '\ea1a';
$sh-push-chevron-down: '\ea1b';
$sh-push-chevron-down-o: '\ea1c';
$sh-push-chevron-down-r: '\ea1d';
$sh-push-chevron-left: '\ea1e';
$sh-push-chevron-left-o: '\ea1f';
$sh-push-chevron-left-r: '\ea20';
$sh-push-chevron-right: '\ea21';
$sh-push-chevron-right-o: '\ea22';
$sh-push-chevron-right-r: '\ea23';
$sh-push-chevron-up: '\ea24';
$sh-push-chevron-up-o: '\ea25';
$sh-push-chevron-up-r: '\ea26';
$sh-code: '\ea27';
$sh-code-slash: '\ea28';
$sh-c-plus-plus: '\ea29';
$sh-git-branch: '\ea2a';
$sh-git-commit: '\ea2b';
$sh-git-fork: '\ea2c';
$sh-git-pull: '\ea2d';
$sh-hashtag: '\ea2e';
$sh-remote: '\ea2f';
$sh-terminal: '\ea30';
$sh-align-bottom-path1: '\ea31';
$sh-align-bottom-path2: '\ea32';
$sh-align-bottom-path3: '\ea33';
$sh-align-center-path1: '\ea34';
$sh-align-center-path2: '\ea35';
$sh-align-left-path1: '\ea36';
$sh-align-left-path2: '\ea37';
$sh-align-left-path3: '\ea38';
$sh-align-middle-path1: '\ea39';
$sh-align-middle-path2: '\ea3a';
$sh-align-right-path1: '\ea3b';
$sh-align-right-path2: '\ea3c';
$sh-align-right-path3: '\ea3d';
$sh-align-top-path1: '\ea3e';
$sh-align-top-path2: '\ea3f';
$sh-align-top-path3: '\ea40';
$sh-attachment: '\ea41';
$sh-board: '\ea42';
$sh-clipboard: '\ea43';
$sh-copy: '\ea44';
$sh-crop: '\ea45';
$sh-details-less: '\ea46';
$sh-details-more: '\ea47';
$sh-display-flex: '\ea48';
$sh-display-fullwidth: '\ea49';
$sh-display-grid: '\ea4a';
$sh-display-spacing: '\ea4b';
$sh-distribute-horizontal-path1: '\ea4c';
$sh-distribute-horizontal-path2: '\ec00';
$sh-distribute-horizontal-path3: '\ec01';
$sh-duplicate: '\ea4d';
$sh-format-bold: '\ea4e';
$sh-format-center: '\ea4f';
$sh-format-color: '\ea50';
$sh-format-heading: '\ea51';
$sh-format-indent-decrease: '\ea52';
$sh-format-indent-increase: '\ea53';
$sh-format-italic: '\ea54';
$sh-format-justify: '\ea55';
$sh-format-left: '\ea56';
$sh-format-line-height: '\ea57';
$sh-format-right: '\ea58';
$sh-format-separator-path1: '\ea59';
$sh-format-separator-path2: '\ea5a';
$sh-format-separator-path3: '\ea5b';
$sh-format-separator-path4: '\ea5c';
$sh-format-separator-path5: '\ea5d';
$sh-format-slash: '\ea5e';
$sh-format-strike: '\ea5f';
$sh-format-text: '\ea60';
$sh-format-underline: '\ea61';
$sh-format-uppercase: '\ea62';
$sh-layout-grid: '\ea63';
$sh-layout-grid-small: '\ea64';
$sh-layout-list: '\ea65';
$sh-layout-pin: '\ea66';
$sh-link: '\ea67';
$sh-list: '\ea68';
$sh-math-divide: '\ea69';
$sh-math-equal: '\ea6a';
$sh-math-minus: '\ea6b';
$sh-math-percent: '\ea6c';
$sh-math-plus: '\ea6d';
$sh-menu-boxed: '\ea6e';
$sh-menu-left-alt: '\ea6f';
$sh-menu-right-alt: '\ea70';
$sh-menu-round: '\ea71';
$sh-notes: '\ea72';
$sh-pin: '\ea73';
$sh-pin-alt: '\ea74';
$sh-pin-bottom: '\ea75';
$sh-pin-top: '\ea76';
$sh-quote: '\ea77';
$sh-quote-o: '\ea78';
$sh-ruler: '\ea79';
$sh-smile: '\ea7a';
$sh-smile-mouth-open: '\ea7b';
$sh-smile-neutral: '\ea7c';
$sh-smile-no-mouth: '\ea7d';
$sh-smile-none: '\ea7e';
$sh-smile-sad: '\ea7f';
$sh-smile-upside: '\ea80';
$sh-stack: '\ea81';
$sh-trash-empty: '\ea83';
$sh-edit: '\ebfb';
$sh-question: '\ebfc';
$sh-question-circle: '\ebfd';
$sh-arrange-back: '\ea84';
$sh-arrange-front: '\ea85';
$sh-asterisk: '\ea86';
$sh-attribution: '\ea87';
$sh-border-all: '\ea88';
$sh-border-bottom-path1: '\ea89';
$sh-border-bottom-path2: '\ea8a';
$sh-border-left-path1: '\ea8b';
$sh-border-left-path2: '\ea8c';
$sh-border-right-path1: '\ea8d';
$sh-border-right-path2: '\ea8e';
$sh-border-style-dashed: '\ea8f';
$sh-border-style-dotted: '\ea90';
$sh-border-style-solid: '\ea91';
$sh-border-top-path1: '\ea92';
$sh-border-top-path2: '\ea93';
$sh-color-bucket: '\ea94';
$sh-color-picker: '\ea95';
$sh-distribute-vertical-path1: '\ea96';
$sh-distribute-vertical-path2: '\ec02';
$sh-distribute-vertical-path3: '\ec03';
$sh-drop: '\ea97';
$sh-drop-invert: '\ea98';
$sh-drop-opacity: '\ea99';
$sh-edit-black-point: '\ea9a';
$sh-edit-contrast: '\ea9b';
$sh-edit-exposure: '\ea9c';
$sh-edit-fade-path1: '\ea9d';
$sh-edit-fade-path2: '\ec04';
$sh-edit-fade-path3: '\ec05';
$sh-edit-flip-h-path1: '\ea9e';
$sh-edit-flip-h-path2: '\ea9f';
$sh-edit-flip-h-path3: '\eaa0';
$sh-edit-flip-v-path1: '\eaa1';
$sh-edit-flip-v-path2: '\eaa2';
$sh-edit-flip-v-path3: '\eaa3';
$sh-edit-highlight: '\eaa4';
$sh-edit-markup: '\eaa5';
$sh-edit-mask: '\eaa6';
$sh-edit-noise: '\eaa7';
$sh-edit-shadows: '\eaa8';
$sh-edit-straight: '\eaa9';
$sh-edit-unmask: '\eaaa';
$sh-erase: '\eaab';
$sh-font-height: '\eaac';
$sh-font-spacing: '\eaad';
$sh-insert-after: '\eaae';
$sh-insert-after-o: '\eaaf';
$sh-insert-after-r: '\eab0';
$sh-insert-before: '\eab1';
$sh-insert-before-o: '\eab2';
$sh-insert-before-r: '\eab3';
$sh-list-tree: '\eab4';
$sh-path-back: '\eab5';
$sh-path-crop: '\eab6';
$sh-path-divide: '\eab7';
$sh-path-exclude: '\eab8';
$sh-path-front: '\eab9';
$sh-path-intersect: '\eaba';
$sh-path-outline: '\eabb';
$sh-path-trim: '\eabc';
$sh-path-unite: '\eabd';
$sh-pen: '\eabe';
$sh-pentagon-bottom-left: '\eabf';
$sh-pentagon-bottom-right: '\eac0';
$sh-pentagon-down: '\eac1';
$sh-pentagon-left: '\eac2';
$sh-pentagon-right: '\eac3';
$sh-pentagon-top-left: '\eac4';
$sh-pentagon-top-right: '\eac5';
$sh-pentagon-up: '\eac6';
$sh-poll: '\eac7';
$sh-rename: '\eac8';
$sh-row-first-path1: '\eac9';
$sh-row-first-path2: '\eaca';
$sh-row-first-path3: '\eacb';
$sh-row-last-path1: '\eacc';
$sh-row-last-path2: '\eacd';
$sh-row-last-path3: '\eace';
$sh-shape-circle: '\eacf';
$sh-shape-half-circle: '\ead0';
$sh-shape-hexagon: '\ead1';
$sh-shape-rhombus: '\ead2';
$sh-shape-square: '\ead3';
$sh-shape-triangle: '\ead4';
$sh-shape-zigzag: '\ead5';
$sh-stories: '\ead6';
$sh-template: '\ead7';
$sh-tikcode: '\ead8';
$sh-toolbar-bottom: '\ead9';
$sh-toolbar-left: '\eada';
$sh-toolbar-right: '\eadb';
$sh-toolbar-top: '\eadc';
$sh-view-cols: '\eadd';
$sh-view-comfortable: '\eade';
$sh-view-day: '\eadf';
$sh-view-grid: '\eae0';
$sh-view-list: '\eae1';
$sh-view-month: '\eae2';
$sh-view-split: '\eae3';
$sh-website: '\eae4';
$sh-apple-watch: '\eae5';
$sh-bot: '\eae6';
$sh-browser: '\eae7';
$sh-calculator: '\eae8';
$sh-camera: '\eae9';
$sh-cast: '\eaea';
$sh-controller: '\eaeb';
$sh-desktop: '\eaec';
$sh-dialpad: '\eaed';
$sh-drive: '\eaee';
$sh-ereader: '\eaef';
$sh-home-screen: '\eaf0';
$sh-keyboard: '\eaf1';
$sh-laptop: '\eaf2';
$sh-mouse: '\eaf3';
$sh-music-speaker: '\eaf4';
$sh-piano: '\eaf5';
$sh-qr: '\eaf6';
$sh-screen: '\eaf7';
$sh-screen-wide: '\eaf8';
$sh-server: '\eaf9';
$sh-shield: '\eafa';
$sh-signal: '\eafb';
$sh-smartphone: '\eafc';
$sh-smartphone-chip: '\eafd';
$sh-smartphone-ram: '\eafe';
$sh-smartphone-shake: '\eaff';
$sh-stopwatch: '\eb00';
$sh-thermostat: '\eb01';
$sh-touchpad: '\eb02';
$sh-tv: '\eb03';
$sh-webcam: '\eb04';
$sh-box: '\eb05';
$sh-cloud: '\eb06';
$sh-file: '\eb07';
$sh-file-add: '\eb08';
$sh-file-document: '\eb09';
$sh-file-remove: '\eb0a';
$sh-folder: '\eb0b';
$sh-folder-add: '\eb0c';
$sh-folder-remove: '\eb0d';
$sh-toolbox: '\eb0e';
$sh-album: '\eb0f';
$sh-collage: '\eb10';
$sh-comment: '\eb11';
$sh-credit-card: '\eb12';
$sh-data: '\eb13';
$sh-database: '\eb14';
$sh-disc: '\eb15';
$sh-film: '\eb16';
$sh-games: '\eb17';
$sh-image: '\eb19';
$sh-inpicture: '\eb1a';
$sh-live-photo: '\eb1b';
$sh-media-live: '\eb1c';
$sh-media-podcast: '\eb1d';
$sh-mic: '\eb1e';
$sh-music: '\eb1f';
$sh-music-note: '\eb20';
$sh-photoscan: '\eb21';
$sh-play-backwards: '\eb22';
$sh-play-button: '\eb23';
$sh-play-button-o: '\eb24';
$sh-play-button-r: '\eb25';
$sh-play-forwards: '\eb26';
$sh-play-list: '\eb27';
$sh-play-list-add: '\eb28';
$sh-play-list-check: '\eb29';
$sh-play-list-remove: '\eb2a';
$sh-play-list-search: '\eb2b';
$sh-play-track-next: '\eb2c';
$sh-play-track-next-o: '\eb2d';
$sh-play-track-next-r: '\eb2e';
$sh-play-track-prev: '\eb2f';
$sh-play-track-prev-o: '\eb30';
$sh-play-track-prev-r: '\eb31';
$sh-polaroid: '\eb32';
$sh-presentation: '\eb33';
$sh-ratio: '\eb34';
$sh-screen-mirror: '\eb35';
$sh-screen-shot: '\eb36';
$sh-ui-kit: '\eb37';
$sh-usb: '\eb38';
$sh-usb-c: '\eb39';
$sh-voicemail: '\eb3a';
$sh-voicemail-o: '\eb3b';
$sh-voicemail-r: '\eb3c';
$sh-volume: '\eb3d';
$sh-setting: '\ebfe';
$sh-shared-user: '\ebf2';
$sh-single-user: '\ebf3';
$sh-airplane: '\eb3e';
$sh-anchor: '\eb3f';
$sh-awards: '\eb40';
$sh-band-aid: '\eb41';
$sh-bee: '\eb42';
$sh-bitbucket: '\eb43';
$sh-block: '\eb44';
$sh-bowl: '\eb45';
$sh-boy: '\eb46';
$sh-briefcase: '\eb47';
$sh-browse: '\eb48';
$sh-brush: '\eb49';
$sh-calibrate: '\eb4a';
$sh-cap: '\eb4b';
$sh-card-clubs: '\eb4c';
$sh-card-diamonds: '\eb4d';
$sh-card-hearts: '\eb4e';
$sh-card-spades: '\eb4f';
$sh-carousel: '\eb50';
$sh-chart: '\eb51';
$sh-clapper-board: '\eb52';
$sh-close: '\eb53';
$sh-coffee: '\eb54';
$sh-community: '\eb55';
$sh-components: '\eb56';
$sh-copyright: '\eb57';
$sh-cross: '\eb58';
$sh-crown: '\eb59';
$sh-dark-mode: '\eb5a';
$sh-debug: '\eb5b';
$sh-dice-1: '\eb5c';
$sh-dice-2: '\eb5d';
$sh-dice-3: '\eb5e';
$sh-dice-4: '\eb5f';
$sh-dice-5: '\eb60';
$sh-dice-6: '\eb61';
$sh-dock-bottom: '\eb62';
$sh-dock-left: '\eb63';
$sh-dock-right: '\eb64';
$sh-dock-window: '\eb65';
$sh-dollar: '\eb66';
$sh-eject: '\eb67';
$sh-ericsson: '\eb68';
$sh-ethernet: '\eb69';
$sh-euro: '\eb6a';
$sh-extension: '\eb6b';
$sh-extension-add: '\eb6c';
$sh-extension-alt: '\eb6d';
$sh-extension-remove: '\eb6e';
$sh-external: '\eb6f';
$sh-eye: '\eb70';
$sh-eye-alt: '\eb71';
$sh-filters: '\eb72';
$sh-flag-alt: '\eb73';
$sh-gender-female: '\eb74';
$sh-gender-male: '\eb75';
$sh-ghost-character: '\eb76';
$sh-girl: '\eb78';
$sh-glass: '\eb79';
$sh-glass-alt: '\eb7a';
$sh-globe: '\eb7b';
$sh-globe-alt: '\eb7c';
$sh-gym: '\eb7d';
$sh-heart: '\eb7e';
$sh-home: '\eb7f';
$sh-home-alt: '\eb80';
$sh-icecream: '\eb81';
$sh-infinity: '\eb82';
$sh-insights: '\eb83';
$sh-key: '\eb84';
$sh-keyhole: '\eb85';
$sh-loadbar: '\eb86';
$sh-loadbar-alt-path1: '\eb87';
$sh-loadbar-alt-path2: '\eb88';
$sh-loadbar-doc: '\eb89';
$sh-loadbar-sound: '\eb8a';
$sh-loupe: '\eb8b';
$sh-magnet-path1: '\eb8c';
$sh-magnet-path2: '\eb8d';
$sh-magnet-path3: '\eb8e';
$sh-mail: '\eb8f';
$sh-mail-forward: '\eb90';
$sh-mail-open: '\eb91';
$sh-mail-reply: '\eb92';
$sh-maximize: '\eb93';
$sh-maximize-alt: '\eb94';
$sh-menu-cake: '\eb95';
$sh-menu-cheese: '\eb96';
$sh-menu-grid-o: '\eb97';
$sh-menu-grid-r: '\eb98';
$sh-menu-hotdog: '\eb99';
$sh-menu-motion: '\eb9a';
$sh-menu-oreos: '\eb9b';
$sh-mini-player: '\eb9c';
$sh-modem: '\eb9d';
$sh-moon: '\eb9e';
$sh-nametag: '\eb9f';
$sh-notifications: '\eba0';
$sh-open-collective-path1: '\eba1';
$sh-open-collective-path2: '\eba2';
$sh-options: '\eba3';
$sh-organisation: '\eba4';
$sh-password: '\eba5';
$sh-performance: '\eba6';
$sh-pill: '\eba7';
$sh-plug: '\eba8';
$sh-profile: '\eba9';
$sh-pull-clear: '\ebaa';
$sh-radio-check: '\ebab';
$sh-radio-checked: '\ebac';
$sh-readme: '\ebad';
$sh-record: '\ebae';
$sh-remove: '\ebaf';
$sh-remove-r: '\ebb0';
$sh-reorder-path1: '\ebb1';
$sh-reorder-path2: '\ebb2';
$sh-reorder-path3: '\ebb3';
$sh-reorder-path4: '\ebb4';
$sh-reorder-path5: '\ebb5';
$sh-ring: '\ebb6';
$sh-sand-clock: '\ebb7';
$sh-scan: '\ebb8';
$sh-search: '\ebb9';
$sh-search-found: '\ebba';
$sh-search-loading: '\ebbb';
$sh-shopping-bag: '\ebbc';
$sh-shopping-cart: '\ebbd';
$sh-sidebar: '\ebbe';
$sh-sidebar-open: '\ebbf';
$sh-sidebar-right: '\ebc0';
$sh-sleep: '\ebc1';
$sh-smart-home-boiler: '\ebc2';
$sh-smart-home-cooker: '\ebc3';
$sh-smart-home-heat: '\ebc4';
$sh-smart-home-light: '\ebc5';
$sh-smart-home-refrigerator: '\ebc6';
$sh-smart-home-wash-machine: '\ebc7';
$sh-space-between: '\ebc8';
$sh-space-between-v: '\ebc9';
$sh-spinner-path1: '\ebca';
$sh-spinner-path2: '\ebcb';
$sh-spinner-alt: '\ebcc';
$sh-spinner-two-path1: '\ebcd';
$sh-spinner-two-path2: '\ebce';
$sh-spinner-two-path3: '\ebcf';
$sh-spinner-two-alt: '\ebd0';
$sh-style: '\ebd1';
$sh-sun: '\ebd2';
$sh-support: '\ebd3';
$sh-tab: '\ebd4';
$sh-tag: '\ebd5';
$sh-tap-double: '\ebd6';
$sh-tap-single: '\ebd7';
$sh-tennis: '\ebd8';
$sh-terrain: '\ebd9';
$sh-thermometer: '\ebda';
$sh-time: '\ebdb';
$sh-timelapse: '\ebdc';
$sh-timer: '\ebdd';
$sh-today-path1: '\ebde';
$sh-today-path2: '\ebdf';
$sh-today-path3: '\ebe0';
$sh-track: '\ebe1';
$sh-transcript: '\ebe2';
$sh-tree: '\ebe3';
$sh-trees: '\ebe4';
$sh-trophy: '\ebe5';
$sh-umbrella: '\ebe6';
$sh-unavailable: '\ebe7';
$sh-unblock: '\ebe8';
$sh-user: '\ebe9';
$sh-user-add: '\ebea';
$sh-user-list: '\ebeb';
$sh-user-remove: '\ebec';
$sh-vinyl: '\ebed';
$sh-work-alt: '\ebee';
$sh-yinyang: '\ebef';
$sh-zoom-in: '\ebf0';
$sh-zoom-out: '\ebf1';

.shd-checkbox {
  position: relative;

  width: 1rem;
  height: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;

  &__input {
    position: absolute;
    visibility: hidden;

    &:checked + .shd-checkbox__label .shd-checkbox__box {
      background: $blue-11;
      border-color: $blue-11;

      svg {
        stroke-dashoffset: 0;
      }
    }

    &.indeterminate + .shd-checkbox__label .shd-checkbox__box {
      background: rgba($blue-11, 0.06);
      border-color: $blue-11;
      animation: unset;

      svg {
        stroke: $blue-15;
      }
    }
  }

  &__label {
    width: 100%;
    height: 100%;

    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    margin: 0;
    border-radius: 0.25rem;

    overflow: hidden;
    transition: all 0.2s ease;

    &:hover {
      background: #eff6ff;

      .shd-checkbox__box {
        border-color: $blue-11;
      }
    }
  }

  &__box {
    display: inline-block;

    width: 100%;
    height: 100%;

    border: 0.0625rem solid #9ca3af;
    border-radius: 0.25rem;

    position: relative;

    transform: scale(1);
    transition: all 0.2s ease;

    svg {
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;

      fill: none;

      stroke: #ffffff;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 1rem;
      stroke-dashoffset: 1rem;

      transition: all 0.2s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }
  }
}

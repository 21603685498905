.logo {
  &--secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;

    img {
      width: auto;
      max-width: 3rem;
      height: 2.135rem;
    }
  }
}

.input-dropdown-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 1.5rem;

  &.prev-next-btn-visible {
    .input-dropdown {
      width: 19.875rem;
    }
  }

  .input-dropdown {
    display: flex;
    align-items: center;

    width: 24.8125rem;
    height: 2.125rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid #d1d5db;

    transition: all 0.2s ease;

    &:focus,
    &:focus-within {
      border-color: $blue-11;
      .input-dropdown--label {
        border-right-color: $blue-11;
      }
    }

    &.disabled {
      opacity: 0.35;
    }

    &--label {
      width: 3.625rem;
      height: 2rem;
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem 0 0 0.25rem;
      box-sizing: border-box;
      border-right: 0.0625rem solid #d1d5db;
      background: #f9fafb;
      overflow: hidden;
      transition: all 0.2s ease;

      p {
        margin: 0;
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }

    .bs-select-box {
      height: 2rem;
      border: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .fromEmail-option {
        max-width: 18.5087rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .prospect-option {
        max-width: 13.6425rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:focus,
      :hover {
        border: none;
        box-shadow: none;
      }
    }
  }

  .prev-next-btn {
    display: flex;
    align-items: center;

    width: 4.5rem;
    height: 2rem;

    border-radius: 0.25rem;

    background: $white;
    box-sizing: border-box;

    &--cta {
      width: 2.25rem;
      height: 2rem;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: all 0.2s ease;
      overflow: hidden;

      border: 0.0625rem solid #d1d5db;

      svg {
        width: 1rem;
        height: 1rem;
        color: $blue-15;
      }

      &:first-of-type {
        border-right-width: 0.5px;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &:last-of-type {
        border-left-width: 0.5px;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:hover,
      &:focus {
        border-color: $blue-11;
        background: #dbeafe;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.35;

        &:hover,
        &:focus {
          border-color: #d1d5db;
          background: $white;
        }
      }
    }
  }
}

.input-dropdown-select {
  width: 24.8125rem !important;
  padding: 0 !important;
  padding-top: 0.25rem !important;

  &.sm-width {
    width: 19.8125rem !important;
  }

  .transfer-search {
    height: 2rem;
    max-height: 2rem;
    padding: 0.375rem 0.75rem;
    border: 0.0625rem solid #f3f4f6;
    margin-bottom: 0.5rem;
    .transfer-search-input {
      height: 1.25rem;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      align-items: center;
      justify-content: flex-start;
      .input-icons-left {
        width: 1rem;
        height: 1rem;
        margin: 0 !important;
        margin-right: 0.5rem !important;
        .bs-input-prefix {
          font-size: 1rem;
          margin: 0 !important;
        }
      }
      .bs-input {
        border-radius: 0 !important;
      }
    }
  }

  .transfer-list {
    li {
      padding: 0.375rem 0.75rem;
    }
  }

  .loader-wrapper {
    height: 15.625rem;
  }

  .transfer-empty {
    margin: 0 !important;
    height: 15.625rem;

    .input-dropdown-empty-ui {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      width: 7rem;

      &--image {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h3 {
        margin: 0;
        color: $blue-15;
        text-align: center;
        font-size: 0.875rem;
        font-weight: $font-weight-semibold;
        line-height: 1.5rem;
      }
    }
  }

  .from-email-option {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    &--inner {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &--fromName {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;

      max-width: 19.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.prospect {
        max-width: 16.9444rem;
      }
    }
    &--type {
      .smtp-label-container {
        width: 2.8125rem;
        height: 1.125rem;
        border-radius: 1.5rem;
        span {
          color: #6b7280;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1rem;
        }
      }
    }
    &--fromEmail {
      margin: 0;
      color: #6b7280;
      text-align: center;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;

      max-width: 21.8431rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.prospect {
        max-width: 15.6563rem;
      }
    }
    &--status {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      &.connected {
        background-color: #059669;
      }
      &.disconnected {
        background-color: #b91c1c;
      }
    }
  }
}

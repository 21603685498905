.bs-modal-body {
  padding: 2rem 2rem 1.5rem 2rem;

  .bs-modal-content {
    display: flex;

    .bs-modal-icon {
      font-size: $heading-2;
      margin-right: 10px;

      &.icon-warning {
        i {
          color: $gold-6;
        }
      }

      &.icon-basic {
        i {
          color: $blue-6;
        }
      }

      &.icon-error {
        i {
          color: $red-6;
        }
      }

      &.icon-success {
        i {
          color: $green-6;
        }
      }
    }

    .bs-modal-inner-content {
      h3 {
        color: $gray-9;
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
      }

      p {
        font-size: $text-14;
        line-height: $text-style-2-line-height;
        color: $gray-8;
      }

      &__title {
        color: $gray-10;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 1rem;
      }

      &__contents {
        color: $gray-8;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.modal-content {
  border: 0 !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius !important;
  max-height: 90vh;
}

.modal-body {
  max-height: calc(100vh - 124px);
  overflow-y: auto;
}

.modal-title {
  font-size: $text-16;
  line-height: $text-style-3-line-height;
  font-weight: $font-weight-semibold;
  color: $gray-9;
  width: 100%;
}

.modal-header {
  align-items: center;
  border-bottom-color: $gray-4;
  padding: 0 1.5rem;
  margin: 0;
  min-height: 3.5rem;

  .close {
    margin: 0 -1rem 0 0;
    color: $gray-9;

    &:hover {
      background: transparent;
      opacity: 1;
    }

    &:focus {
      outline: 0;
      background: transparent;
    }

    &:active {
      border: 0;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      border: 0;
      background-color: transparent;
      opacity: 1;
      color: $gray-9;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
    }
  }

  .alert {
    &.modal-error {
      margin: 0;
      position: absolute;
      z-index: 9;
      top: 1rem;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 70%;

      .close {
        margin: auto;
        padding: 0.5rem;
      }

      &.alert-dismissible {
        padding: 0.5rem 4rem 0.5rem 1.25rem;
      }

      .modal-error-list {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
  }
}

.modal-footer {
  border-top-color: $gray-4;
  max-height: 4rem;
  min-height: 4rem;
  padding: 0 1.5rem;
}

.confirm-modal-width {
  .modal-dialog {
    width: 28.125rem;
    .bs-modal-icon {
      align-items: flex-start;
    }
  }
  .modal-content {
    .cancel-btn {
      border-width: 0px !important;
    }
    .submit-btn {
      width: 6.25rem !important;
      background-color: $blue-11 !important;
    }
  }
}

.modal-backdrop {
  z-index: 1050 !important;
  backdrop-filter: blur(5px);
  background-color: rgba(31, 41, 55, 0.7);
  opacity: 1 !important;
}

.delete-modal {
  .modal-dialog {
    max-width: 28.12rem;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .modal-footer {
    .btn-solid {
      border: 1px solid $red-12;
      color: $white;
      font-weight: $font-weight-normal;
      background: $red-16;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.empty-list {
  width: 100%;
  height: 100%;
  position: relative;

  &__inner {
    width: 65%;
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__inner-vertical {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__photo {
    width: 17.125rem;
    height: 17.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__photo-vertical {
    height: 4.851875rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 7rem;
    }

    &--withBgAndShadow {
      height: auto;
    }
  }

  &__photo-withBgAndShadow {
    width: 3.375rem;
    height: 3.375rem;
    border-radius: 50%;
    background: $gray-19;

    box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
      8px 16px 56px -24px rgba(156, 157, 161, 0.24);

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__photo-withBgAndShadow {
    width: 3.375rem;
    height: 3.375rem;
    border-radius: 50%;
    background: $yellow-11;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
      8px 16px 56px -24px rgba(156, 157, 161, 0.24);

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__content {
    height: 17.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-left: 1.5rem;
  }

  &__content-vertical {
    height: 14.125rem;

    &--withBgAndShadow {
      height: auto;
      margin-top: 1.5rem;
    }
  }

  &__title {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }

  &__title-vertical {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }

  &__body-vertical {
    width: 16.5625rem;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    button {
      margin-right: 1rem;
      white-space: nowrap;
    }
  }

  &__buttons-vertical {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    button {
      // margin-right: 1rem;
      white-space: nowrap;
    }
  }
}

.log-activity {
  position: relative;
  width: 95%;
  &::after {
    z-index: 0;
    content: '';
    width: 2px;
    height: calc(100% - 2.25rem);
    background-color: #e5e7eb;
    margin-left: 0.6rem;
    position: absolute;
    top: 1.5rem;
    left: 1rem;
  }
  .day-text {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 0.4rem 0.52rem;
    color: #000;
    font-weight: $font-weight-semibold;
    z-index: 3;
  }
}

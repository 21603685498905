.email-account-setup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: $gray-1;
  padding: 1.5rem;

  .get-it-done {
    right: 0rem;
    top: 1.5rem;
  }

  &.email-account-settings {
    border: 0.0625rem solid $gray-13;
    border-radius: 0.25rem;
    padding: 1.875rem 1.5rem;

    .email-account-setup-factor-details {
      margin-top: 1.875rem;
    }
  }

  .email-account-setup-progress {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    &.poor {
      background-color: rgba(254, 242, 242, 0.5);
      svg {
        color: $red-18;
      }
    }
    &.average {
      background-color: rgba(255, 251, 235, 0.5);
      svg {
        color: $yellow-12;
      }
    }
    &.excellent {
      background-color: rgba(236, 253, 245, 0.5);
      svg {
        color: $green-16;
      }
    }
    .email-account-setup-refresh {
      position: absolute;
      right: -6.875rem;
      top: 0;
      cursor: pointer;
    }
  }
  .circular-progressbar-text {
    font-weight: $font-weight-semibold;
    font-size: 1.25rem !important;
    line-height: 1.75rem;
    color: $blue-16;
  }
  .email-account-setup-title {
    font-weight: $font-weight-medium;
    font-size: $text-14;
    line-height: 1.0625rem;
    color: $blue-15;
    margin: 1.5rem 0 0.625rem 0;
  }
  .email-account-setup-content {
    font-size: $text-12;
    line-height: 0.9375rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 1rem;
  }
  .email-account-setup-description {
    font-size: $text-12;
    line-height: 0.9375rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 1rem;
    max-width: 47.5rem;
    text-align: center;
  }
  .email-account-setup-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10.5rem;
    margin-bottom: 1.875rem;
    .health-score-label-wrapper {
      display: flex;
      align-items: center;
      .health-score-label-ellipse {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;
        margin-right: 0.3125rem;
        background-color: $gray-15;
      }
      .health-score-label-text {
        font-size: $text-12;
        line-height: 0.9375rem;
        color: $blue-15;
        margin: 0;
      }
      &.poor {
        .health-score-label-ellipse {
          background-color: $red-18;
        }
      }
      &.average {
        .health-score-label-ellipse {
          background-color: $yellow-12;
        }
      }
      &.excellent {
        .health-score-label-ellipse {
          background-color: $green-16;
        }
      }
    }
  }
  .email-account-setup-help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.9375rem 1.25rem;

    box-sizing: border-box;
    border-radius: 0.25rem;

    background: rgba(239, 246, 255, 0.6);
    border: 0.0625rem solid $blue-17;

    .email-account-setup-help-content {
      .email-account-setup-help-title {
        font-weight: $font-weight-medium;
        font-size: $text-14;
        line-height: 1.25rem;
        color: $blue-15;
        margin: 0;
        margin-bottom: 0.3125rem;
      }
      .email-account-setup-help-text {
        font-size: $text-12;
        line-height: 1.125rem;
        color: $gray-15;
      }
    }
  }
  .email-account-setup-factor-details {
    width: 100%;
    .email-account-setup-factor-details-title {
      font-weight: $font-weight-semibold;
      font-size: $text-14;
      line-height: 1.0625rem;
      color: $blue-15;
      margin: 0;
      margin-bottom: 1.25rem;
    }
  }
}

.email-account-setup-factor-details-accordians {
  .score-factor-details {
    .score-factor-details-title {
      display: flex;
      align-items: center;
      p {
        margin: 0 0.9375rem 0 1.25rem;
        font-weight: $font-weight-semibold;
        font-size: $text-14;
        line-height: 1.25rem;
        color: $blue-15;
      }
    }
    .score-factor-details-content {
      width: 100%;
      .score-factor-details-content-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        p {
          width: 74%;
        }

        button {
          color: $gray-15;
          border-color: $gray-15;
          background-color: transparent;
          background-color: $gray-19;
        }
      }
      .helper-text {
        margin-bottom: 1rem;
      }
    }
  }
}

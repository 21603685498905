.prospect-form-field {
  .bs-input-container {
    .bs-input-label-prefix {
      line-height: 1rem;
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 0.25rem;
    }

    &:nth-child(1) {
      padding-left: 1.0625rem;
      padding-right: 0;
      margin-top: 0 !important;
    }

    &:nth-child(4) {
      padding-right: 0.6875rem;
      padding-left: 1.25rem;
    }

    &:nth-child(5) {
      padding-right: 0.6875rem;
      padding-left: 1.25rem;
    }

    &:nth-child(13),
    &:nth-child(15) {
      padding-left: 1.25rem !important;
    }

    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(17),
    &:nth-child(19),
    &:nth-child(21),
    &:nth-child(23),
    &:nth-child(25),
    &:nth-child(27) {
      padding-right: 0 !important;
    }

    &:nth-child(8),
    &:nth-child(10),
    &:nth-child(18),
    &:nth-child(22),
    &:nth-child(24),
    &:nth-child(26),
    &:nth-child(28) {
      padding-right: 0.9375rem !important;
    }
  }

  .prospect-modal-user-field {
    .col-md-6 {
      &:nth-child(odd) {
        padding-right: 0;
        padding-left: 1.25rem;
      }

      &:nth-child(even) {
        padding-left: 1.25rem;
        padding-right: 0;
      }

      &:nth-child(3),
      &:nth-child(13),
      &:nth-child(15),
      &:nth-child(32),
      &:nth-child(34) {
        padding-right: 0.9375rem;
      }
    }

    .prospect-label-field {
      margin-bottom: 1rem !important;
    }

    .bs-input-container {
      margin-bottom: 1rem;
    }
  }

  .bs-input-number-container {
    .bs-input-number-label-prefix {
      line-height: 1rem;
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      margin-bottom: 0.25rem;
    }
  }

  .col-md-6 {
    &:nth-child(even) {
      padding-left: 1.25rem;
      padding-right: 0;
    }

    &:nth-child(odd) {
      padding-right: 0.9375rem;
      padding-left: 1.25rem;
    }
  }
}
.reward-ev-credits {
  min-height: 23.8666rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 1.5rem;
  padding: 2rem;

  border-radius: 4px;
  border: 1px solid #eaedef;
  background: #fff;
  background-size: cover;
  background-image: url('../../../assets/images/reward-ev-credits-bg.svg');
  background-position: center;
  box-shadow: 0px 2px 4px 0px rgba(234, 237, 239, 0.65);
  background-repeat: no-repeat;

  .reward-ev-credits-title {
    color: #1f2937;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .reward-ev-credits-desc {
    color: #6b7280;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin-bottom: 1.625rem;
  }
  .reward-ev-credits-msg {
    color: #6b7280;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0;
    margin-top: 1rem;
  }
  .reward-ev-credits-steps {
    max-width: 37.125rem;
    margin-bottom: 2rem;

    &--numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;

      .review-step-number {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.8125rem;
        background: #60a5fa;
        margin-bottom: 0;

        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: $white;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.125rem;
        }
      }
      .review-step-divider {
        width: 11.5853rem;
        height: 0.0625rem;
        background-color: #d1d5db;
      }
    }
    &--details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3.125rem;

      .review-step-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &:nth-of-type(1) {
          width: 8.4375rem;
        }
        &:nth-of-type(2) {
          width: 13rem;
        }
        &:nth-of-type(3) {
          width: 9.4375rem;
        }

        .title {
          color: #1f2937;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.25rem;
          margin: 0;
          margin-bottom: 0.5rem;
        }
        .desc {
          p,
          a {
            color: #4b5563;
            text-align: center;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.25rem;
            margin: 0;
          }
          a {
            color: #1d4ed8;
            font-weight: 500;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}

.bs-tabs-small {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      padding: 8px 0;
      font-size: 14px;
    }
  }
}
.bs-tabs-large {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      padding: 16px 0;
      font-size: 16px;
    }
  }
}
.bs-tabs-card.bs-tabs-small {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      padding: 6px 16px;
    }
  }
}
.bs-tabs-card.bs-tabs-large {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      padding: 7px 16px 6px;
    }
  }
}

.bs-tabs-top {
  flex-direction: column;
  > .bs-tabs-nav {
    margin: 0 0 16px 0;
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      border-bottom: 1px solid $gray-13;
      content: '';
      bottom: 0;
    }
    .bs-tabs-ink-bar {
      height: 2px;
      bottom: 0;
    }
    .bs-tabs-ink-bar-animated {
      transition: width 0.3s, left 0.3s, right 0.3s;
    }
    .bs-tabs-nav-wrap {
      &::before {
        top: 0;
        bottom: 0;
        width: 30px;
        left: 0;
      }
      &::after {
        top: 0;
        bottom: 0;
        width: 30px;
        right: 0;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-left {
      &::before {
        opacity: 1;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-right {
      &::after {
        opacity: 1;
      }
    }
  }
  > div {
    > .bs-tabs-nav {
      margin: 0 0 16px 0;
      &::before {
        position: absolute;
        right: 0;
        left: 0;
        border-bottom: 1px solid $gray-5;
        content: '';
        bottom: 0;
      }
      .bs-tabs-ink-bar {
        height: 2px;
        bottom: 0;
      }
      .bs-tabs-ink-bar-animated {
        transition: width 0.3s, left 0.3s, right 0.3s;
      }
      .bs-tabs-nav-wrap {
        &::before {
          top: 0;
          bottom: 0;
          width: 30px;
          left: 0;
        }
        &::after {
          top: 0;
          bottom: 0;
          width: 30px;
          right: 0;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-left {
        &::before {
          opacity: 1;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-right {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
.bs-tabs-bottom {
  flex-direction: column;
  > .bs-tabs-nav {
    margin: 0 0 16px 0;
    order: 1;
    margin-top: 16px;
    margin-bottom: 0;
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      border-bottom: 1px solid $gray-5;
      content: '';
      top: 0;
    }
    .bs-tabs-ink-bar {
      height: 2px;
      top: 0;
    }
    .bs-tabs-ink-bar-animated {
      transition: width 0.3s, left 0.3s, right 0.3s;
    }
    .bs-tabs-nav-wrap {
      &::before {
        top: 0;
        bottom: 0;
        width: 30px;
        left: 0;
      }
      &::after {
        top: 0;
        bottom: 0;
        width: 30px;
        right: 0;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-left {
      &::before {
        opacity: 1;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-right {
      &::after {
        opacity: 1;
      }
    }
  }
  > div {
    > .bs-tabs-nav {
      margin: 0 0 16px 0;
      order: 1;
      margin-top: 16px;
      margin-bottom: 0;
      &::before {
        position: absolute;
        right: 0;
        left: 0;
        border-bottom: 1px solid $gray-5;
        content: '';
        top: 0;
      }
      .bs-tabs-ink-bar {
        height: 2px;
        top: 0;
      }
      .bs-tabs-ink-bar-animated {
        transition: width 0.3s, left 0.3s, right 0.3s;
      }
      .bs-tabs-nav-wrap {
        &::before {
          top: 0;
          bottom: 0;
          width: 30px;
          left: 0;
        }
        &::after {
          top: 0;
          bottom: 0;
          width: 30px;
          right: 0;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-left {
        &::before {
          opacity: 1;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-right {
        &::after {
          opacity: 1;
        }
      }
    }
    > .bs-tabs-content-holder {
      order: 0;
    }
  }
  > .bs-tabs-content-holder {
    order: 0;
  }
}
.bs-tabs-left {
  > .bs-tabs-nav {
    flex-direction: column;
    min-width: 50px;
    .bs-tabs-tab {
      margin: 0 0 16px 0;
      padding: 8px 24px;
      text-align: center;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .bs-tabs-nav-wrap {
      flex-direction: column;
      &::before {
        right: 0;
        left: 0;
        height: 30px;
        top: 0;
      }
      &::after {
        right: 0;
        left: 0;
        height: 30px;
        bottom: 0;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-top {
      &::before {
        opacity: 1;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-bottom {
      &::after {
        opacity: 1;
      }
    }
    .bs-tabs-ink-bar {
      width: 2px;
      right: 0;
    }
    .bs-tabs-ink-bar-animated {
      transition: height 0.3s, top 0.3s;
    }
    .bs-tabs-nav-list {
      flex-direction: column;
    }
    .bs-tabs-nav-operations {
      flex-direction: column;
    }
  }
  > div {
    > .bs-tabs-nav {
      flex-direction: column;
      min-width: 50px;
      .bs-tabs-tab {
        margin: 0 0 16px 0;
        padding: 8px 24px;
        text-align: center;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .bs-tabs-nav-wrap {
        flex-direction: column;
        &::before {
          right: 0;
          left: 0;
          height: 30px;
          top: 0;
        }
        &::after {
          right: 0;
          left: 0;
          height: 30px;
          bottom: 0;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-top {
        &::before {
          opacity: 1;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-bottom {
        &::after {
          opacity: 1;
        }
      }
      .bs-tabs-ink-bar {
        width: 2px;
        right: 0;
      }
      .bs-tabs-ink-bar-animated {
        transition: height 0.3s, top 0.3s;
      }
      .bs-tabs-nav-list {
        flex-direction: column;
      }
      .bs-tabs-nav-operations {
        flex-direction: column;
      }
    }
    > .bs-tabs-content-holder {
      margin-left: -1px;
      border-left: 1px solid $gray-5;
      > .bs-tabs-content {
        > .bs-tabs-tabpane {
          padding-left: 24px;
        }
      }
    }
  }
  > .bs-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid $gray-5;
    > .bs-tabs-content {
      > .bs-tabs-tabpane {
        padding-left: 24px;
      }
    }
  }
}
.bs-tabs-right {
  > .bs-tabs-nav {
    flex-direction: column;
    min-width: 50px;
    order: 1;
    .bs-tabs-tab {
      margin: 0 0 16px 0;
      padding: 8px 24px;
      text-align: center;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .bs-tabs-nav-wrap {
      flex-direction: column;
      &::before {
        right: 0;
        left: 0;
        height: 30px;
        top: 0;
      }
      &::after {
        right: 0;
        left: 0;
        height: 30px;
        bottom: 0;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-top {
      &::before {
        opacity: 1;
      }
    }
    .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-bottom {
      &::after {
        opacity: 1;
      }
    }
    .bs-tabs-ink-bar {
      width: 2px;
      left: 0;
    }
    .bs-tabs-ink-bar-animated {
      transition: height 0.3s, top 0.3s;
    }
    .bs-tabs-nav-list {
      flex-direction: column;
    }
    .bs-tabs-nav-operations {
      flex-direction: column;
    }
  }
  > div {
    > .bs-tabs-nav {
      flex-direction: column;
      min-width: 50px;
      order: 1;
      .bs-tabs-tab {
        margin: 0 0 16px 0;
        padding: 8px 24px;
        text-align: center;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .bs-tabs-nav-wrap {
        flex-direction: column;
        &::before {
          right: 0;
          left: 0;
          height: 30px;
          top: 0;
        }
        &::after {
          right: 0;
          left: 0;
          height: 30px;
          bottom: 0;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-top {
        &::before {
          opacity: 1;
        }
      }
      .bs-tabs-nav-wrap.bs-tabs-nav-wrap-ping-bottom {
        &::after {
          opacity: 1;
        }
      }
      .bs-tabs-ink-bar {
        width: 2px;
        left: 0;
      }
      .bs-tabs-ink-bar-animated {
        transition: height 0.3s, top 0.3s;
      }
      .bs-tabs-nav-list {
        flex-direction: column;
      }
      .bs-tabs-nav-operations {
        flex-direction: column;
      }
    }
    > .bs-tabs-content-holder {
      order: 0;
      margin-right: -1px;
      border-right: 1px solid $gray-5;
      > .bs-tabs-content {
        > .bs-tabs-tabpane {
          padding-right: 24px;
        }
      }
    }
  }
  > .bs-tabs-content-holder {
    order: 0;
    margin-right: -1px;
    border-right: 1px solid $gray-5;
    > .bs-tabs-content {
      > .bs-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
}
.bs-tabs-card {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      margin: 0;
      padding: 8px 16px;
      background: $gray-2;
      border: 1px solid $gray-5;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .bs-tabs-tab-active {
      color: $blue-6;
      background: $gray-1;
    }
    .bs-tabs-ink-bar {
      visibility: hidden;
    }
  }
  > div {
    > .bs-tabs-nav {
      .bs-tabs-tab {
        margin: 0;
        padding: 8px 16px;
        background: $gray-2;
        border: 1px solid $gray-5;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .bs-tabs-tab-active {
        color: $blue-6;
        background: $gray-1;
      }
      .bs-tabs-ink-bar {
        visibility: hidden;
      }
    }
  }
}
.bs-tabs-card.bs-tabs-top {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      &:not(:last-of-type) {
        margin-right: 2px;
      }
      border-radius: $border-radius-top;
    }
    .bs-tabs-tab-active {
      border-bottom-color: $gray-1;
    }
  }
  > div {
    > .bs-tabs-nav {
      .bs-tabs-tab {
        &:not(:last-of-type) {
          margin-right: 2px;
        }
        border-radius: $border-radius-top;
      }
      .bs-tabs-tab-active {
        border-bottom-color: $gray-1;
      }
    }
  }
}
.bs-tabs-card.bs-tabs-bottom {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      &:not(:last-of-type) {
        margin-right: 2px;
      }
      border-radius: $border-radius-bottom;
    }
    .bs-tabs-tab-active {
      border-top-color: $gray-1;
    }
  }
  > div {
    > .bs-tabs-nav {
      .bs-tabs-tab {
        &:not(:last-of-type) {
          margin-right: 2px;
        }
        border-radius: $border-radius-bottom;
      }
      .bs-tabs-tab-active {
        border-top-color: $gray-1;
      }
    }
  }
}
.bs-tabs-card.bs-tabs-left {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      &:not(:last-of-type) {
        margin-bottom: 2px;
      }
      border-radius: $border-radius-left;
    }
    .bs-tabs-tab-active {
      border-right-color: $gray-1;
    }
  }
  > div {
    > .bs-tabs-nav {
      .bs-tabs-tab {
        &:not(:last-of-type) {
          margin-bottom: 2px;
        }
        border-radius: $border-radius-left;
      }
      .bs-tabs-tab-active {
        border-right-color: $gray-1;
      }
    }
  }
}
.bs-tabs-card.bs-tabs-right {
  > .bs-tabs-nav {
    .bs-tabs-tab {
      &:not(:last-of-type) {
        margin-bottom: 2px;
      }
      border-radius: $border-radius-right;
    }
    .bs-tabs-tab-active {
      border-left-color: $gray-1;
    }
  }
  > div {
    > .bs-tabs-nav {
      .bs-tabs-tab {
        &:not(:last-of-type) {
          margin-bottom: 2px;
        }
        border-radius: $border-radius-right;
      }
      .bs-tabs-tab-active {
        border-left-color: $gray-1;
      }
    }
  }
}
.bs-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $gray-8;
  font-size: $text-14;
  line-height: $text-style-1-line-height;
  list-style: none;
  display: flex;
  overflow: hidden;
  > .bs-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    .bs-tabs-nav-wrap {
      position: relative;
      display: flex;
      flex: auto;
      align-self: stretch;
      overflow: hidden;
      white-space: nowrap;
      transform: translate(0);
      &::before {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        content: '';
        pointer-events: none;
      }
      &::after {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        content: '';
        pointer-events: none;
      }
    }
    .bs-tabs-nav-list {
      position: relative;
      display: flex;
      transition: transform 0.3s;
      .bs-tabs-tab {
        &.bs-tabs-tab-active {
          &:hover {
            color: $blue-11;
          }
          &:active {
            color: $blue-11;
          }
        }
      }
    }
    .bs-tabs-nav-operations {
      display: flex;
      align-self: stretch;
    }
    .bs-tabs-nav-operations-hidden {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
    }
    .bs-tabs-nav-add {
      padding: 0 8px;
      background: $gray-2;
      border: 1px solid $gray-5;
      border-radius: $border-radius-top;
      outline: none;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:hover {
        color: $blue-5;
      }
      &:active {
        color: $blue-7;
      }
      &:focus {
        color: $blue-7;
      }
    }
  }
  > div {
    > .bs-tabs-nav {
      position: relative;
      display: flex;
      flex: none;
      align-items: center;
      .bs-tabs-nav-wrap {
        position: relative;
        display: flex;
        flex: auto;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
        transform: translate(0);
        &::before {
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s;
          content: '';
          pointer-events: none;
        }
        &::after {
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s;
          content: '';
          pointer-events: none;
        }
      }
      .bs-tabs-nav-list {
        position: relative;
        display: flex;
        transition: transform 0.3s;
      }
      .bs-tabs-nav-operations {
        display: flex;
        align-self: stretch;
      }
      .bs-tabs-nav-operations-hidden {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
      }
      .bs-tabs-nav-more {
        position: relative;
        padding: 8px 16px;
        background: transparent;
        border: 0;
        &::after {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 5px;
          transform: translateY(100%);
          content: '';
        }
      }
      .bs-tabs-nav-add {
        padding: 0 8px;
        background: $gray-2;
        border: 1px solid $gray-5;
        border-radius: $border-radius-top;
        outline: none;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
          color: $blue-5;
        }
        &:active {
          color: $blue-7;
        }
        &:focus {
          color: $blue-7;
        }
      }
    }
  }
}
.bs-tabs-extra-content {
  flex: none;
}
.bs-tabs-ink-bar {
  position: absolute;
  background: $blue-6;
  pointer-events: none;
}
.bs-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 12px 0;
  font-size: $text-14;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
    margin-left: 0;
  }
  .bs-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    color: $gray-7;
    font-size: $text-12;
    outline: none;
    transition: all 0.3s;
    .anticon {
      margin: 0;
    }
    &:hover {
      color: $gray-7;
    }
    &:focus {
      color: $blue-11;
    }
    &:active {
      color: $blue-11;
    }
  }
  &:focus {
    color: $blue-11;
  }
  &:active {
    color: $blue-11;
  }
  &:hover {
    color: $blue-11;
  }
  .anticon {
    margin-right: 12px;
  }
}
.bs-tabs-tab.bs-tabs-tab-active {
  color: $blue-6;
  font-weight: $font-weight-semibold;
}
.bs-tabs-tab.bs-tabs-tab-disabled {
  color: $gray-6;
  cursor: not-allowed;
}
.bs-tabs-content {
  display: flex;
  width: 100%;
}
.bs-tabs-content-holder {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.bs-tabs-content-animated {
  transition: margin 0.3s;
}
.bs-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.bs-tabs-tab {
  .bs-tabs-tab-remove {
    flex: none;
    margin-right: -6px;
    margin-left: 4px;
    color: $gray-7;
    font-size: $text-18;
    background: 0 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    top: -1px;
    line-height: $text-style-1-line-height;
  }
}

.bs-tabs-tab-btn {
  &:focus {
    outline: 0;
  }
}

.bs-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.bs-tabs-dropdown-hidden {
  display: none;
}
.bs-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.bs-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.bs-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.bs-tabs-dropdown-menu-item-disabled,
.bs-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

.bs-tabs > .bs-tabs-nav .bs-tabs-nav-more,
.bs-tabs > div > .bs-tabs-nav .bs-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
  text-indent: -999px;
  &:focus {
    outline: 0;
  }
}
.bs-tabs > .bs-tabs-nav .bs-tabs-nav-more::before,
.bs-tabs > div > .bs-tabs-nav .bs-tabs-nav-more::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  font-family: 'SalesHandy';
  content: $sh-more-alt;
}

.bs-tabs-nav-operations {
  &:before {
    position: absolute;
    font-family: 'SalesHandy';
    content: $sh-more-alt;
    top: 0;
    right: 0;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2.25rem;
    border: 1px solid $gray-5;
    background: $gray-1;
  }
}

.text-editor-skeleton-overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;

  & .tooltip-spacer {
    width: 100%;
    height: 2rem; // same as .editor .tooltip height
  }
  & .ai-variant-skeleton-content {
    width: 100%;
    flex: 1;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .loading-icon-text-container {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      &__icon {
        max-width: 0.875rem;
        height: auto;
        width: 0.875rem;
      }
      &__text {
        font-size: 0.813rem;
        line-height: 1.25rem;
        font-weight: $font-weight-medium;
      }
    }

    & .skeletons-container {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      &__paragraph {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
    }
  }
}

.text-skeletons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .custom-skeleton {
    width: 0%;
    animation: widthZeroToMax 1.5s ease-in-out forwards;
  }
}

.email-preview-tab {
  & .email-preview-tab-inner {
    & .email-preview-ai-variant-skeleton {
      padding-top: 20%;
      height: calc(
        100% - 4rem
      ); // ** same as .email-preview-tab .email-preview-tab-inner .no-email-content-container
    }
  }
}

.email-preview-ai-variant-skeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  & .ai-3-star-animated-icon {
    width: 54px;
    height: 54px;
    max-width: 54px;
    max-height: 54px;
    overflow: hidden;
  }

  & .ai-variant-loading-text-section {
    display: flex;
    text-align: center;
    flex-direction: column;
    & .text-content {
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      background-image: linear-gradient(
        113.67deg,
        #eb4ce6 -49.13%,
        #9d54db 12.25%,
        #1d4ed8 93.65%
      );
      color: transparent;
      background-clip: text;
    }
  }
}

@keyframes widthZeroToMax {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

.collapsible-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem !important;
  height: 2rem !important;

  padding: 0.3125rem !important;

  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;

  svg {
    display: block;

    width: 1.25rem !important;
    height: 1.25rem !important;

    color: #6b7280;
  }

  &:hover {
    background-color: #dbeafe !important;
    svg {
      color: $blue-11;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.collapsible-search-input {
  position: relative;
  width: 2rem;
  height: 2rem;
  .shd-search-input {
    width: 12.1875rem;

    position: absolute;
    top: 0;
    right: 0;

    input {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      color: $blue-15;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }
}

nav.bs-sidenav-container {
  min-width: 4.5rem;
  background: $blue-10;
  position: fixed;
  top: 0;
  width: 4.5rem;
  height: 100%;
  transition: none;
  left: 0;

  &.sidenav---sidenav---_2tBP {
    &.sidenav---expanded---1KdUL {
      min-width: 14.375rem;
      .sidenav-icon {
        display: none;

        .sidenav---icon-bar---u1f02 {
          display: none;
        }

        &.sidenav---sidenav-toggle---1KRjR {
          position: absolute;
          float: left;
          height: 2rem;
          bottom: 25%;
          left: 50%;
          width: 2rem;
          cursor: pointer;
          transform: translate(-50%);
          z-index: 99;
          align-items: center;
          justify-content: center;
        }

        &::before {
          font-family: 'SalesHandy';
          content: '\e91c';
          color: $gray-1;
          font-size: 1.3rem;
          z-index: 999;
        }
      }

      &:hover {
        .sidenav-icon {
          display: flex;
        }
      }

      .sidenav---sidenav-navitem---uwIJ- {
        > .sidenav---navitem---9uL5T {
          .sidenav---navtext---1AE_f {
            //color: $gray-3;
            color: $gray-5;
          }
        }
        &.sidenav---selected---1EK3y {
          .sidenav---navitem---9uL5T {
            .sidenav---navtext---1AE_f {
              color: $gray-1;
            }
          }
        }
        &:hover {
          .sidenav---navitem---9uL5T {
            .sidenav---navtext---1AE_f {
              color: $gray-1;
            }
          }
        }
      }
    }

    .sidenav---sidenav-navitem---uwIJ- {
      &.sidenav---selected---1EK3y {
        .sidenav---navitem---9uL5T {
          &::after {
            border-left: 6px solid $blue-2;
            opacity: 1;
            position: absolute;
            background: #ffffff26;
            left: 3px;
            width: calc(100% - 3px);
          }

          .sidenav---navicon---3gCRo {
            i {
              color: $gray-1;
            }

            .sidenav---navtext---1AE_f {
              color: $gray-1;
            }
          }
        }
      }
    }

    .sidenav---sidenav-nav---3tvij {
      .sidenav---sidenav-navitem---uwIJ- {
        .sidenav---navitem---9uL5T {
          height: auto;
          line-height: initial;
          display: flex;
          align-content: center;
          align-items: center;
          cursor: pointer;
          margin-bottom: 0.75rem;

          .sidenav---navtext---1AE_f {
            font-size: $text-14;
          }

          .sidenav---navicon---3gCRo {
            width: 72px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            cursor: pointer;
            height: 40px;
            i {
              font-size: 1.5rem;
              color: $gray-3;
            }
          }
        }
      }
    }

    .bs-sidebar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.625rem;

      .sh-logo-text-wrapper {
        margin-left: 0.433rem; // 0.4334605rem
      }
    }
  }

  &.sidenav---collapsed---LQDEv {
    .sidenav-icon {
      display: none;

      .sidenav---icon-bar---u1f02 {
        display: none;
      }

      &.sidenav---sidenav-toggle---1KRjR {
        position: absolute;
        float: left;
        bottom: 25%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%);
        z-index: 99;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
      }

      &::before {
        font-family: 'SalesHandy';
        content: '\e933';
        color: $gray-1;
        font-size: 1.3rem;
        z-index: 999;
      }
    }

    &:hover {
      .sidenav-icon {
        display: flex;
      }
    }
  }
}

nav.bs-sidenav-container.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv
  + .home-container {
  width: calc(100% - 4.5rem);
  left: 4.5rem;
  transform: translate(4.5rem, 0px);
}

nav.bs-sidenav-container.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  + .home-container {
  width: calc(100% - 14.375rem);
  left: 14.375rem;
  position: relative;
  //transform: translate(14.375rem, 0px);
}

.bs-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: $gray-1;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: $gray-6;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.bs-avatar-image {
  background: transparent;
}
.bs-avatar-string {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: 0 center;
}
.bs-avatar.bs-avatar-icon {
  font-size: 18px;
  > .anticon {
    margin: 0;
  }
}
.bs-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.bs-avatar-lg.bs-avatar-icon {
  font-size: 24px;
  > .anticon {
    margin: 0;
  }
}
.bs-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.bs-avatar-md {
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
}
.bs-avatar-sm.bs-avatar-icon {
  font-size: 14px;
  > .anticon {
    margin: 0;
  }
}
.bs-avatar-square {
  border-radius: 2px;
}

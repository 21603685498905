.connect-email-account-options-container {
  margin-left: 32px;
  .connect-email-account-description {
    color: #6b7280;
  }
  .options-container {
    margin-left: 0 !important;
    margin-top: 56px;
    h4 {
      color: #1f2937;
    }
    .options-main {
      display: flex;
      .options-item {
        height: 3.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        margin-right: 1rem;
        position: relative;
        img {
          margin-right: 0.5rem;
        }
        &:hover {
          background: $blue-17;
          color: $blue-11;
        }
      }
    }
    .local-footer {
      margin-top: 64px;
      .btn-later {
        color: #9ca3af;
      }
    }
  }
}

.smtp-modal {
  .modal-content {
    padding: 0.75rem;
  }
}

.admin-setting-section {
    padding: 1.5rem;
    background-color: white;
    margin-bottom: 1rem;
    border: 0.0625rem;
    border-color: #E8EFF5;
    border-radius: 0.25rem;

    &--title {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $blue-15;
        margin-bottom: 1rem;
    }
}
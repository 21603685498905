.accordion {
  border: none;
}

.custom-accordion {
  .custom-accordion-item {
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-13;
    background-color: $gray-1;

    .custom-accordion-heading {
      .custom-accordion-button {
        display: flex;
        align-items: center;
        padding: 1rem 1.25rem;
        background-color: $gray-1;
        border-radius: 0.25rem;
        user-select: none;

        &.custom-icon-start {
          .custom-accordian-title {
            margin-left: 1.25rem;
          }
        }
        &.custom-icon-end {
          justify-content: space-between;
        }

        .custom-accordian-title {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $blue-15;
        }
        &::before {
          display: none;
        }
        &:focus {
          outline: none;
        }
      }
    }

    .custom-accordion-panel {
      border-top: 0.0625rem solid $gray-16;

      padding: 14px 20px 20px 20px;

      font-size: 0.75rem;
      line-height: 1.125rem;
      display: flex;
      align-items: center;
      color: $blue-15;
    }
  }
}

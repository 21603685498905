@import './../../fonts/fontStyle';

.font-icon-detail {
  text-align: center;
  padding: 30px 0 30px;
  border: 1px solid #9a9a9a;
  border-radius: 0.1875rem;
  margin: 15px 0;
  min-height: 154px;
  i {
    color: #000000;
    font-size: 2.5em;
  }
  p {
    color: #9a9a9a !important;
    margin: 20px 0 0 0;
    padding: 0 10px;
    font-size: 0.7142em;
  }
}

.verify-email-modal {
  .modal-dialog {
    width: 40.625rem;
    min-width: 40.625rem;
    max-width: 40.625rem;

    .modal-content,
    .modal-content-container,
    .modal-body {
      width: 100%;
      height: 27.375rem;
      min-height: 27.375rem;
      overflow: hidden;
    }

    .modal-body {
      padding: 3.75rem 2.625rem;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2.5rem;

      position: relative;

      .verify-email-close {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;

        width: 1.5rem;
        height: 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        color: $gray-15;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      h1 {
        margin: 0;
        font-weight: $font-weight-bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $blue-15;
      }

      p {
        margin: 0;

        font-weight: $font-weight-medium;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: $gray-15;

        span {
          color: $blue-15;
          font-weight: $font-weight-semibold;
        }
      }

      .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
      }
    }
  }
}

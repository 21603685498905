.deal-value-prospect {
  overflow: hidden;
  height: 1.875rem;
  padding: 0.125rem;
  width: 8em;
}

.deal-value-input {
  height: 100%;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  border-radius: 0.25rem;
  border: 0.125rem solid transparent;
  background-color: transparent;
}

.deal-value-icon-btn-cls-border:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

.icon-deal-remove {
  display: none;
}

.deal-value-icon-btn-cls {
  border-color: $blue-11;
  outline: none;
}

.deal-icon-wrap {
  display: inline-block;
  transition: background-color 0.25s;
  border-radius: 50%;
}

.deal-icon-wrap:hover {
  background-color: $blue-17;
  cursor: pointer;
}

.bs-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $gray-7;
  font-size: $text-14;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
  .bs-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;
  }
  &:not(.bs-badge-not-a-wrapper) {
    margin-right: 20px;
  }
}
.bs-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: $gray-1;
  font-weight: normal;
  font-size: $text-12;
  line-height: $text-style-1-line-height;
  white-space: nowrap;
  text-align: center;
  background: $red-6;
  border-radius: 10px;
  box-shadow: 0 0 0 1px $gray-1;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
  a {
    color: $gray-1;
    &:hover {
      color: $gray-1;
    }
  }
}
.bs-badge-multiple-words {
  padding: 0 8px;
}
.bs-badge-dot {
  z-index: auto;
  width: 0.5rem;
  height: 0.5rem;
  background: $red-6;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.bs-badge-purple-dot,
.bs-badge-yellow-dot {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $purple-13;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.bs-badge-yellow-dot {
  background: $yellow-12;
}
.bs-badge-gray-dot {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $gray-11;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.bs-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.bs-badge-status-dot {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border-radius: 50%;
}
.bs-badge-status-success {
  background-color: $green-6;
}
.bs-badge-status-processing {
  position: relative;
  background-color: $blue-6;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $blue-6;
    border-radius: 50%;
    content: '';
  }
}
.bs-badge-status-default {
  background-color: $gray-5;
}
.bs-badge-status-error {
  background-color: $red-6;
}
.bs-badge-status-warning {
  background-color: $orange-6;
}
.bs-badge-status-process {
  background: $blue-6;
}
.bs-badge-status-text {
  margin-left: 8px;
  color: $gray-7;
  font-size: $text-14;
}

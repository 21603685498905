/**

Override Bootstrap Components

1. Color Palette
2. Typography
3. Button
   3.1 Solid Button Style
   3.2 Ghost Button Style
4. Icons
5. Border Radius
6. Breadcrumb
7. Dropdown
8. Pagination
9. Stepper
10. Checkbox
11. Radio Buttons
12. Switch
13. Slider
14. Input nuber
15. Input
16. Baddge
17. Avtar
18. Accordions
19. Card
20. Empty
21. Popover
22. Tooltip
23. Tab
25. Alert
26. Modal
27. Message
28. Notification
29. Progress
30. Popconfirm
31. Tags
32. Transfer
33. Select
34. Ribbon
**. Misc
*/

@import './../../../node_modules/bootstrap/scss/bootstrap.scss';
@import './../../../node_modules/@saleshandy/design-system/dist/assets/css/design-system.css';
@import 'custom-variable';
@import './app.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
}

code {
  font-family: 'Inter', sans-serif;
}

// 1. Color Palette start ///////////////////////////////////
@import './scss/colors';
// 1. Color Palette End ///////////////////////////////////

// 2. Custom Typography Start /////////////////////////////////
@import './scss/typography';
// 2. Custom Typography End /////////////////////////////////

// 3. Button Custom Start/////////////////////////////////
@import './scss/buttons';
// 3. Custom Button End /////////////////////////////////

// 4. Icons Start /////////////////////////////////////////
@import './scss/icons';
// 4. Icons End ///////////////////////////////////////////

// 5. Border Radius Start /////////////////////////////////
@import './scss/borderRadius';
// 5. Border Radius End ///////////////////////////////////

// 6. Breadcrumb Start///////////////////////////////
@import './scss/breadcrumb';
// 6. Breadcrumb End/////////////////////////////////

// 7. Dropdown Start/////////////////////////////////
@import './scss/dropdown';
// 7. Dropdown End///////////////////////////////////

// 8. Pagination End/////////////////////////////////
@import './scss/pagination';
// 8. Pagination End/////////////////////////////////

// 9. Stepper Start///////////////////////////////
@import './scss/stepper';
// 9. Stepper End/////////////////////////////////

// 10. Checkbox Start///////////////////////////////
@import './scss/checkbox';
// 10. Checkbox End/////////////////////////////////

// 10. Radio Button Start///////////////////////////////
@import './scss/radioButton';
// 10. Radio Button End/////////////////////////////////

// 12. Switch Start///////////////////////////////
@import './scss/switch';
// 12. Switch End/////////////////////////////////

// 13. Slider Start///////////////////////////////
@import './scss/slider';
// 13. Slider End/////////////////////////////////

// 14. Input Number ///////////////////////////////
@import './scss/inputNumber';
// 14. Input Number /////////////////////////////////

// 15. Input ///////////////////////////////
@import './scss/input';
// 15. Input /////////////////////////////////

// 16. Badge ///////////////////////////////
@import './scss/badges';
// 16. Input /////////////////////////////////

// 17. Avtar ///////////////////////////////
@import './scss/avtar';
// 17. Avtar /////////////////////////////////

// 18. Accordions ///////////////////////////////
@import './scss/accordions';
// 18. Accordions /////////////////////////////////

// 19. Card /////////////////////////////////
@import './scss/card';
// 19. Card /////////////////////////////////

// 20. Empty /////////////////////////////////
@import './scss/empty';
// 20. Empty /////////////////////////////////

// 21. Popover /////////////////////////////////
@import './scss/popover';
// 21. Popover /////////////////////////////////

// 22. Tooltip /////////////////////////////////
@import './scss/tooltip';
// 22. Tooltip /////////////////////////////////

// 23. Table /////////////////////////////////
@import './scss/table';
// 23. Table /////////////////////////////////

// 24. Tab /////////////////////////////////
@import './scss/tab';
// 24. Tab /////////////////////////////////

// 25. Alert /////////////////////////////////
@import './scss/alert';
// 25. Alert /////////////////////////////////

// 26. Modal /////////////////////////////////
@import './scss/modal';
// 26. Modal /////////////////////////////////

// 27. Message /////////////////////////////////
@import './scss/message';
// 27. Message /////////////////////////////////

// 28. Notification /////////////////////////////////
@import './scss/notification';
// 28. Notification /////////////////////////////////

// 29. Progress /////////////////////////////////
@import './scss/progress';
// 29. Progress /////////////////////////////////

// 30. Popconfirm /////////////////////////////////
@import './scss/popConfirm';
// 30. Popconfirm /////////////////////////////////

// 31. Tags /////////////////////////////////
@import './scss/tag';
// 31. Tags /////////////////////////////////

// 32. Transfer /////////////////////////////////
@import './scss/transfer';
// 32. Transfer /////////////////////////////////

// 33. Select /////////////////////////////////
@import './scss/select';
// 33. Select /////////////////////////////////

// 34. Ribbon /////////////////////////////////
@import './scss/ribbon';
// 33. Select /////////////////////////////////

////// Misc
.bs-mr-5 {
  margin-right: $mr-5 !important;
}

.bs-ml-5 {
  margin-left: $mr-5 !important;
}

.bs-mt-2 {
  margin-top: 2px !important;
}
.bs-mt-3 {
  margin-top: 0.1875rem !important;
}

.bs-mt-5 {
  margin-top: $mr-5 !important;
}
.bs-mb-5 {
  margin-bottom: $mr-5 !important;
}

.bs-mt-6 {
  margin-top: 0.375rem !important;
}

.bs-mt-10 {
  margin-top: $mr-10 !important;
}

.bs-mt-12 {
  margin-top: $mr-12 !important;
}

.bs-mr-10 {
  margin-right: $mr-10 !important;
}

.bs-mr-12 {
  margin-right: $mr-12 !important;
}

.bs-ml-10 {
  margin-left: $mr-10 !important;
}

.bs-ml-12 {
  margin-left: $mr-12 !important;
}

.bs-mb-10 {
  margin-bottom: $mr-10 !important;
}

.bs-mb-12 {
  margin-bottom: $mr-12 !important;
}

.bs-mt-15 {
  margin-top: $mr-15 !important;
}
.bs-mt-18 {
  margin-top: 1.125rem !important;
}

.bs-mr-15 {
  margin-right: $mr-15 !important;
}

.bs-ml-15 {
  margin-left: $mr-15 !important;
}

.bs-mb-15 {
  margin-bottom: $mr-15 !important;
}

.bs-mt-20 {
  margin-top: $mr-20 !important;
}
.bs-mr-20 {
  margin-right: $mr-20 !important;
}
.bs-mb-20 {
  margin-bottom: $mr-20 !important;
}
.bs-ml-20 {
  margin-left: $mr-20 !important;
}

.bs-mt-30 {
  margin-top: $mr-30 !important;
}
.bs-mr-30 {
  margin-right: $mr-30 !important;
}
.bs-mb-30 {
  margin-bottom: $mr-30 !important;
}
.bs-ml-30 {
  margin-left: $mr-30 !important;
}
.bs-ml-34 {
  margin-left: $mr-32 !important;
}
.bs-ml-36 {
  margin-left: 2.25rem !important;
}

.bs-mt-32 {
  margin-top: $mr-32 !important;
}
.bs-mr-32 {
  margin-right: $mr-32 !important;
}
.bs-mb-32 {
  margin-bottom: $mr-32 !important;
}
.bs-ml-32 {
  margin-left: $mr-32 !important;
}
.bs-ml-34 {
  margin-left: $mr-32 !important;
}
.bs-ml-36 {
  margin-left: 2.25rem !important;
}
.bs-pt-2 {
  padding-top: 2px !important;
}
.bs-pb-2 {
  padding-bottom: 2px !important;
}

.bs-py-6 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.bs-px-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.bs-border-radius-4 {
  border-radius: $border-4 !important;
}
.bs-border-radius-8 {
  border-radius: $border-8 !important;
}
.bs-border-radius-50 {
  border-radius: $border-50 !important;
}

.bs-no-margin {
  margin: $no-margin !important;
}

.divider {
  height: 1px;
  background: $gray-4;
  display: flex;
  margin: 1rem 0;
  width: 100%;
}

.no-shadow {
  box-shadow: none !important;
}

/*Skeleton loading start*/
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.sequence-header {
  &.header-skeleton {
    h1 {
      background-color: $gray-4;
      border-radius: $border-radius;
      width: 12rem;
      position: relative;
      text-indent: -99999px;
    }
    p {
      background-color: $gray-4;
      border-radius: $border-radius;
      width: 20rem;
      position: relative;
      text-indent: -99999px;
      &:active {
        background-color: $gray-4;
      }
    }
    .btn-solid {
      background-color: $gray-4;
      border-radius: $border-radius;
      width: 11rem;
      position: relative;
      border: 0;
      text-indent: -99999px;
    }
    .new-sequence-title {
      background-color: $gray-4;
      border-radius: $border-radius;
      width: 12rem;
      position: relative;
      text-indent: -99999px;
    }
    .steps-top-action {
      .switchToggle {
        background-color: $gray-4;
        border-radius: $border-radius;
        position: relative;
        width: 6.25rem;
        text-indent: -99999px;
      }
    }
    h1,
    p,
    .btn-solid,
    .new-sequence-title,
    .steps-top-action .switchToggle {
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
      }
    }
  }
}

.step-list-skeleton {
  display: flex;
  flex: 1;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    li {
      padding: 0.875rem 0.8rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      .step-title {
        background-color: $gray-4;
        border-radius: $border-radius;
        width: 22rem;
        position: relative;
      }
      .step-list-inner-skeleton {
        display: flex;
        flex-direction: column;
        padding: 0 2rem 0 4rem;
        margin-top: 1rem;
        .step-sub-list-skeleton {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .step-skeleton-left {
            padding: 0.375rem;
            display: flex;
            align-items: center;
            .box-skeleton {
              width: 2.625rem;
              height: 2.625rem;
              background-color: $gray-4;
              border-radius: $border-radius;
              position: relative;
              margin-right: 0.4375rem;
              overflow: hidden;
            }
            .step-desc-skeleton {
              p {
                background-color: $gray-4;
                border-radius: $border-radius;
                width: 18rem;
                position: relative;
                margin: 0 0 2px 0;
                overflow: hidden;
              }
              span {
                background-color: $gray-4;
                border-radius: $border-radius;
                width: 14rem;
                position: relative;
                display: flex;
                overflow: hidden;
              }
            }
          }
          .step-skeleton-right {
            display: flex;
            align-items: center;
            .box-skeleton {
              width: 2.625rem;
              height: 2.625rem;
              background-color: $gray-4;
              border-radius: $border-radius;
              position: relative;
              margin-right: 0.4375rem;
            }
            .action-skeleton {
              width: 1.5rem;
              height: 1.9375rem;
              background-color: $gray-4;
              border-radius: $border-radius;
              position: relative;
            }
          }
          &.full-skeleton {
            background-color: $gray-4;
            border-radius: $border-radius;
            position: relative;
            min-height: 3.375rem;
          }
        }
      }
    }
  }
  .step-title,
  .box-skeleton,
  .step-desc-skeleton p,
  .step-desc-skeleton span,
  .action-skeleton,
  .full-skeleton {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
}

.table-list tr {
  .hoverable-cell {
    &--actions {
      display: none !important;
    }
  }

  &:hover {
    background: $gray-19 !important;

    .hoverable-cell {
      &--content {
        display: none !important;
      }
      &--actions {
        display: flex !important;
      }
    }
  }
}

.sequence-list {
  .skeleton-row {
    .checkbox-skeleton {
      width: 1rem;
      height: 1rem;
      background-color: $gray-4;
      border-radius: $border-radius;
      position: relative;
    }
    .title-skeleton {
      width: 20rem;
      background-color: $gray-4;
      border-radius: $border-radius;
      margin: 0;
      position: relative;
    }
    .round-skeleton {
      width: 1.5rem;
      height: 1.5rem;
      background-color: $gray-4;
      border-radius: 100%;
      position: relative;
    }
    .action-skeleton {
      width: 1.5rem;
      height: 1.9375rem;
      background-color: $gray-4;
      border-radius: $border-radius;
      position: relative;
    }
    .state-row {
      opacity: 1;
      .box-skeleton {
        width: 3.125rem;
        height: 3.125rem;
        background-color: $gray-4;
        border-radius: $border-radius;
        position: relative;
        margin-left: 0.4375rem;
      }
    }
  }
  .checkbox-skeleton,
  .title-skeleton,
  .round-skeleton,
  .action-skeleton,
  .box-skeleton {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }

  .sequence-table-header.table-organism-header {
    border: 0px solid $gray-16 !important;
    // .sortable-custom div {
    //   display: flex;
    //   align-items: center;
    //   &:hover {
    //     span {
    //       background: transparent;
    //       cursor: default;
    //     }
    //   }
    //   span {
    //     padding: 0;
    //     margin-right: 0.375rem;
    //   }
    // }
  }

  .sequence-body-wrapper > tr {
    border: 1px solid $gray-4 !important;
    padding-left: 2rem !important;

    .sequence-total-text {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        margin-right: -0.5rem;
        bottom: -0.675rem;
        background: $gray-13;
        width: 0.125rem;
        height: 2.6875rem;
      }
    }

    .created-by-wrap {
      height: 1.8rem;
      min-width: 9rem;
      max-width: 9rem;

      .sequence-created-by {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sequence-actions {
      width: 7rem;
      margin-left: -0.5rem;
    }

    .hoverable-cell {
      &--actions {
        display: none;
      }
    }

    &:hover {
      background: $gray-19 !important;

      .hoverable-cell {
        &--content {
          display: none;
        }
        &--actions {
          display: block;
        }
      }
    }

    & > td {
      padding: 1.25rem 0.75rem !important;

      &:first-child {
        padding-left: 2rem !important;
      }
    }
  }

  .sequence-avatar {
    .bs-avatar-string {
      color: $blue-11 !important;
      font-size: 0.8rem;
    }
  }
}
/*Skeleton loading end*/

// header content style according to user plan subscription
.header-justify-content-center {
  justify-content: center;
}
.header-justify-content-end {
  justify-content: flex-end;
}

// Flex Wrap
.flex-flow-wrap {
  flex-flow: wrap;
}

// Link
.link {
  color: $blue-11;
  text-decoration: underline;
  &:hover {
    color: $blue-11;
  }
}

.link-2 {
  color: $blue-11;
  &:hover {
    color: $blue-11;
  }
}

// Font Family
.font-monospace {
  font-family: monospace !important;
}
.font-italic {
  font-style: italic;
}

.underline-txt {
  text-decoration: underline;
}

// Hover Underline
.hover-underline:hover {
  text-decoration: underline;
}

.icon-hover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  &:hover {
    background-color: $blue-17;
  }
}

.empty-avatar {
  background-color: $blue-13;
  padding: 0.5rem;
  border-radius: 50%;
}

// z-index
.z-idx-1 {
  z-index: 1 !important;
}

// User Avatar Styles
.shd--user-avatar {
  width: 2rem;
  height: 2rem;

  border-radius: 50%;
  border: 0.0625rem solid #dbeafe;
  background: #eff6ff;

  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    color: $blue-11;
  }

  &__md {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.375rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}

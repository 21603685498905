.date-filter-select-wrap {
  width: 10.1875rem;
  height: 2rem;
  .date-filter-select {
    width: 191px;
    height: 32px;

    .transfer-container {
      .transfer-body {
        .transfer-content {
          .transfer-list {
            li {
              .separator {
                border-bottom-width: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .render-text {
    border: none;
  }
  .bs-select-box {
    width: 100%;
    height: 100%;
    border: 1px solid $gray-26;
  }
  .report-date-picker {
    .react-datepicker {
      // position: absolute !important;
      // right: 24px;
      z-index: 10;
      margin-top: 0.225rem;
    }
  }
  .date-renderer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 1.125rem;
      height: 1.125rem;
    }

    span {
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: $blue-15;
    }
  }
}

.bs-popover-inner.bs-popover-confirm {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: $border-radius;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  border: 0;
  .popover-body {
    padding: 0;
  }
  .bs-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: #fff;
  }
  .bs-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65);
    p {
      margin: 0;
    }
  }
}
.bs-popover-confirm-desc {
  display: flex;
  .bs-popover-confirm-icon {
    margin-right: 5px;
    &.icon-basic {
      i {
        color: $blue-6;
      }
    }
    &.icon-error {
      i {
        color: $red-6;
      }
    }
    &.icon-success {
      i {
        color: $green-6;
      }
    }
    &.icon-warning {
      i {
        color: $gold-6;
      }
    }
  }
}

.logo-container {
  // gap: 2rem;
  width: 100%;

  .info-image {
    img {
      height: 18.125rem;
    }
  }
}
.whitelabel {
  .bs-tabs-tab {
    margin: 0 1.5rem 0 0;
  }
  .bs-tabs-top > .bs-tabs-nav {
    margin: 0 0 1.625rem 0;
  }
  .bs-tabs-nav .bs-tabs-tab-active {
    color: #1d4ed8;
  }
  .bs-tabs-top > .bs-tabs-nav::before {
    border-bottom: none;
  }
  &__container {
    overflow-y: auto !important;
    height: 77vh;
    width: 100%;
  }
  .card-view {
    width: 100%;
  }
  &__card {
    padding: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-26;
    background-color: $gray-1;
    position: relative;

    &--input-container {
      .bs-input-affix-wrapper {
        width: 20.25rem;
      }
    }

    .action-container--delete > div {
      &:hover {
        background-color: #fee2e2;

        svg {
          color: #b91c1c !important;
        }
      }
    }

    .action-container--edit > div {
      &:hover {
        background-color: $blue-13;

        svg {
          color: $blue-11;
        }
      }
    }

    .action-container {
      div {
        position: absolute;
        top: 1.0625rem;
        right: 1.1875rem;
        padding: 0.375rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border: none;
        border-radius: 0.25rem;

        transition: all 0.2s;
        cursor: pointer;
      }
    }
  }

  .ed-image-container {
    .file-uploader {
      display: none !important;
    }
  }

  .selected-image-section {
    overflow: hidden; /* Hide any overflow */
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .file-uploader {
      opacity: 0;
    }
  }

  &__image-picker-drop {
    border: none !important;
  }
  &__image-picker-dnd {
    height: 11.875rem;
    border-radius: 0.5rem;
    border: 1px dashed $gray-11;
    background-color: $gray-19;
    display: flex;
    justify-content: center;
    align-items: center;
    .pre-select-file-uploader {
      background-color: $gray-1;
    }
    .file-uploader {
      padding: 0.375rem 1rem;
      border: 1px solid $blue-11;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover,
      &:focus {
        background-color: #dbeafe;
      }

      > input {
        display: none;
      }

      .select-file-placeholder {
        padding: 0 0.75rem;
        color: $gray-6;
      }

      .choose-file {
        height: calc(100% + 2px);
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: $blue-11;
        justify-content: center;
        width: 100%;
        font-weight: $font-weight-semibold;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__domain-configuration {
    background-color: $gray-1;
    padding: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #e8eff5;

    .action-container--delete > div {
      &:hover {
        background-color: #fee2e2;

        svg {
          color: #b91c1c !important;
        }
      }
    }

    .action-container--edit > div {
      &:hover {
        background-color: $blue-13;

        svg {
          color: $blue-11;
        }
      }
    }

    .action-container {
      div {
        padding: 0.375rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border: none;
        border-radius: 0.25rem;

        transition: all 0.2s;
        cursor: pointer;
      }
    }

    .brand-domain {
      .bs-input-affix-wrapper {
        width: 20.25rem;
      }
    }

    &-steps {
      .bs-input-affix-wrapper {
        width: 20.25rem;
        margin-top: 0.875rem;
        margin-left: 2.25rem;
      }
      .input-error-container {
        display: flex;
        flex-direction: column;
        label {
          margin-left: 2.25rem;
        }
      }
      .domain-setup-table {
        margin-top: 0.75rem;
        margin-left: 2.25rem;
        .table-list {
          height: fit-content;
          .react-bootstrap-table .table-organism {
            .table-organism-header {
              tr:hover {
                background: $gray-19 !important;
              }
            }
          }
          .table-organism {
            &-header {
              height: 2.25rem;
              background-color: #f3f4f6 !important;
            }
            &-body {
              border-right: 1px solid #e5e7eb;
              td {
                &:first-child {
                  border-left: 1px solid #e5e7eb;
                }
                &:last-child {
                  border-right: 1px solid #e5e7eb;
                }
              }
            }
          }
        }
      }
    }
  }
  &__step-number {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-16;
    border-radius: 50%;
    margin-right: 0.75rem;
  }
}

.tasks {
  &__header {
    padding: 1.125rem 1.5rem;
    display: flex;
    gap: 1.5rem;
    border-bottom: 1px solid $gray-14;
    box-shadow: 0px 2px 4px 0px $gray-13;
    height: 3.5rem;

    &--active {
      position: relative;
      div::before {
        content: '';
        position: absolute;
        top: 2.1563rem;
        width: 100%;
        height: 3px;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        background-color: $blue-11;
      }
    }

    &--item > div {
      &:hover {
        .label {
          color: $blue-11;
        }
      }
      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        border-radius: 1.25rem;
        line-height: 1rem;
      }
    }
  }

  &__content {
    background-color: $gray-19;
    padding-top: 1.25rem;
    .filter-container {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 1.25rem;
      .search-input {
        width: 12.5rem;
        background-color: $gray-1;
        input {
          background-color: inherit;
        }
      }
      .task-filter-icon-container {
        margin-left: 12.8px;
        width: 2rem;
        height: 2rem;
        padding: 0.3125rem !important;

        display: flex;
        justify-content: center;
        align-items: center;

        border: none;
        border-radius: 0.25rem;

        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          background-color: $blue-13 !important;

          svg {
            color: $blue-11;
          }
        }
      }
      .filter-border {
        width: 0.125rem;
        height: 2rem;
        background: $gray-23;
        border-radius: 0.125rem;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
      }
      .bulk-actions {
        display: flex;
        gap: 0.25rem;
        &__action-icon {
          width: 2rem;
          height: 2rem;
          border-radius: 0.25rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gray-15;
          &:hover {
            background-color: $blue-13;
            color: $blue-11;
          }
        }
      }
    }
    .tasks-filter {
      .filter-tab-container {
        margin-bottom: 0px;
      }
    }
    .sm-table {
      .table-list {
        height: calc(100vh - 14.5rem);
      }
    }
    .md-table {
      .table-list {
        height: calc(100vh - 13.5rem);
      }
    }
    .lg-table {
      .table-list {
        height: calc(100vh - 17rem);
      }
    }
    .task-banner {
      background-color: $yellow-11;
      border: 1px solid $orange-18;
      border-radius: 0.25rem;
      height: 2.5rem;
    }
    &-table-list {
      height: max-content;
      .table-list {
        overflow-y: scroll;
        overflow-y: overlay;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        // scrollbar-width: none;

        height: calc(100vh - 11rem);
        .react-bootstrap-table .table-organism {
          .table-organism-header {
            background: $gray-19;
            height: 2.5rem;
            tr > th {
              border-top: 1px solid $gray-13;
              border-bottom: 1px solid $gray-13;
              padding: 0.5rem;
              &:first-of-type {
                border-left: 1px solid $gray-13;
                padding-left: 1.5rem;
                padding-right: 0px;
              }
              &:last-of-type {
                border-right: 1px solid $gray-13;
              }
            }
            tr:hover {
              background: $gray-19 !important;
            }
          }

          .table-organism-body {
            td {
              height: 4rem;
              padding: 0.6875rem 0.5rem !important;

              &:first-of-type {
                border-left: 0.0625rem solid $gray-13;
                padding-left: 1.5rem !important;
              }
              &:last-of-type {
                border-right: 0.0625rem solid $gray-13;
              }
            }
            .tag-field {
              .priority {
                &__urgent {
                  border: 1px solid $red-19;
                }
                &__high {
                  border: 1px solid $orange-18;
                }
                &__normal {
                  border: 1px solid $blue-13;
                  color: $blue-24 !important;
                }
                &__low {
                  border: 1px solid $cyan-11;
                }
              }
            }
          }
        }
        .tasks-body-wrapper > tr {
          background-color: $gray-1;
          &:hover {
            background: $gray-19 !important;
            .hr-blue {
              color: $blue-24;
            }
            .hr-green {
              color: $green-23;
            }
            .hr-purple {
              color: $purple-16;
            }
            .hr-orange {
              color: $orange-20;
            }
          }
        }
        .bs-table-content {
          background-color: $gray-19;
          padding: 0px 1.5rem;
        }
        .owner {
          width: 12rem;
          .action-container {
            display: none;
            margin-left: 1rem;
            width: 6rem;

            div {
              width: 2rem;
              height: 2rem;

              display: flex;
              justify-content: center;
              align-items: center;

              border: none;
              border-radius: 0.25rem;

              transition: all 0.2s;
              cursor: pointer;

              &:hover {
                background-color: $blue-13 !important;

                svg {
                  color: $blue-11;
                }
              }
            }
          }
        }
        .table-organism-body {
          tr:hover {
            .action-container {
              display: flex;
              justify-content: space-between;
            }
            .name-container {
              display: none;
            }
          }
        }
      }
    }
  }
}

.task-type-filter-select {
  .transfer-container {
    min-height: 19.5rem !important;
    .transfer-body > .transfer-content {
      min-height: 17.4375rem !important;
    }
  }
}
.task-type-btn {
  min-width: 10.1875rem !important;
  max-width: 14rem !important;
  width: auto !important;
  padding: 0.5rem 0.75rem;
}

.snooze-modal {
  .modal-dialog {
    max-width: 28.125rem;
  }
  .modal-content {
    min-height: 18.125rem;
    max-height: 22.375rem;
  }
  .modal-header {
    border-bottom: 0px;
    height: 4.5rem;
  }
  .modal-body {
    padding: 0px 1.5rem 1.5rem 1.5rem !important;
    overflow-y: inherit !important;
    .date-picker {
      width: 11.9375rem;
      .react-datepicker__input-container {
        cursor: pointer !important;
        input {
          background: $gray-1 !important;
          cursor: inherit;
        }
        .input-icons-right {
          align-items: center;
        }
      }
      .react-datepicker-popper[data-placement^='bottom'] {
        padding-top: 4px !important;
      }
    }
    .time-picker {
      width: 11.9375rem;
    }
  }
  .modal-footer {
    padding: 0;
    max-height: 4.5rem;
    min-height: 4.5rem;
    margin: 0 1.5rem;
    .bs-modal-footer-action-buttons {
      margin: 0;
    }
    .snooze-cancel-btn {
      min-width: 6.25rem !important;
      height: 2rem !important;
      border: 0.0625rem solid #e5e7eb !important;
      font-weight: 500 !important;
      line-height: 1.25rem !important;
      transition: all 0.2s ease !important;
      color: #6b7280 !important;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        color: #6b7280 !important;
        background-color: #f3f4f6 !important;
      }
    }
    .snooze-submit-btn {
      height: 2rem;
      border: none;
      font-weight: 500;
      line-height: 1.25rem;
      transition: all 0.2s ease;
      background-color: $blue-11;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        background-color: #346aff !important;
        border-color: #346aff !important;
      }
    }
    .btn-solid,
    .btn-ghost {
      height: 2rem;
    }
    .btn-solid-primary:disabled {
      background-color: $blue-11;
    }
  }
}

.empty-task-list {
  background-color: $gray-19;
  height: 85vh !important;
}

.task-confirmation-modal-call {
  .modal-content {
    max-height: 17.3125rem;
  }
}
.task-confirmation-modal-second {
  .modal-content {
    max-height: 12.125rem;
  }
}
.task-confirmation-modal-tertiary {
  .modal-content {
    max-height: 16.125rem;
  }
}

.task-confirmation-modal {
  .modal-content-container {
    max-height: inherit;
  }
  .modal-footer {
    padding: 1.25rem 0rem;
    .btn-solid {
      min-width: 6.25rem;
      height: 2rem;
      border: none;
      font-weight: 500;
      line-height: 1.25rem;
      transition: all 0.2s ease;
      background-color: $blue-11;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        background-color: #346aff !important;
        border-color: #346aff !important;
      }
    }
    .btn-ghost {
      min-width: 6.25rem !important;
      height: 2rem !important;
      border: 0.0625rem solid #e5e7eb !important;
      font-weight: 500 !important;
      line-height: 1.25rem !important;
      transition: all 0.2s ease !important;
      color: #6b7280 !important;
      &:focus-visible {
        outline: none;
      }
      &:hover,
      &:active {
        color: #6b7280 !important;
        background-color: #f3f4f6 !important;
      }
    }
    .bs-modal-footer-action-buttons {
      button {
        &:last-of-type {
          margin-left: 0px !important;
        }
      }
    }
    .btn-solid-primary:disabled {
      background-color: $blue-11;
    }
  }
}

.snooze-select
  > .transfer-container
  > .transfer-body
  > .transfer-content
  > .transfer-list
  > li {
  &:last-child {
    border-top: 1px solid $gray-16;
  }
}

.tasker-modal {
  .modal-dialog {
    width: 30.0625rem;
  }
  .modal-header {
    max-height: 5rem;
    min-height: 5rem;
    background-color: $gray-19;
    padding: 1.125rem 0 1.125rem 1.5rem;
    position: relative;

    .tasker-pagination-wrapper {
      position: absolute;
      right: 0;
      top: 1.125rem;
      height: 2.5rem;
      width: 12.5625rem;
      background-color: $gray-1;
      border: 1px solid $gray-13;
      border-top-left-radius: 1.5625rem;
      border-bottom-left-radius: 1.5625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
    .close {
      width: 2.5rem !important;
      height: 2.75rem !important;
      position: absolute;
      left: -2.5rem;
      top: 0;
      background-color: $popover-arrow-color !important;
      border-bottom-right-radius: 0px;
      color: $gray-1 !important;
      padding: 0px !important;
      opacity: 0.800000011920929 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-body {
    padding: 0 !important;
    .url-banner {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      background-color: $red-17;
      border: 1px solid $red-11;
    }
    .cs-accordion {
      .accordion__item {
        padding: 0;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none;
        background-color: transparent !important;
      }

      .accordion__heading {
        padding: 0.75rem 1.5rem;
        transition: all 0.2s ease;
        background-color: $blue-17;
        border-top: 1px solid $blue-12;
        // border-bottom: 1px solid $blue-12;
      }

      .accordion__button {
        padding: 0;
        line-height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $blue-17;

        &::before {
          display: none;
        }

        svg {
          color: #9ca3af;
        }
      }

      .accordion__panel {
        border: none;
        padding: 0 1.5rem 0.75rem 1.5rem;
        border-top: 0px;
        background-color: $blue-17;
        word-wrap: break-word;
      }
    }
    .cs-acc-bottom {
      width: 100%;
      height: 1px;
      background-color: $blue-12;
    }
    .task {
      &__content {
        padding: 0 1.5rem;
        &-area.size-sm {
          height: 2.5rem;
          .cs-txt-ell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-area {
          width: 27rem;
          padding: 0.75rem 1rem;
          height: 13.125rem;
          border: 1px solid $gray-13;
          border-radius: 0.25rem;
          background-color: $gray-19;
          word-wrap: break-word;
        }
      }
    }
    .prospect-custom-outcome-select {
      .selected-outcome-main {
        span {
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.125rem;
        }
      }
    }
    .task-note {
      &__wrapper {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $orange-18;
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        width: 1.875rem;
        height: 1.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &-icon {
          span {
            opacity: 0;
            visibility: hidden;
            display: none;
            -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
          }
        }

        &:hover {
          width: 7rem;
          .task-note__wrapper-icon {
            span {
              display: inline-block;
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .modal-footer > * {
    margin: 0px;
  }
  .modal-footer {
    padding: 0 1.5rem !important;
    justify-content: space-between !important;

    .icon-container {
      &:hover {
        svg,
        span {
          color: $gray-12;
        }
      }
    }
  }
}

.cs-task-note {
  .modal-content {
    height: 23.25rem;
  }
  .modal-dialog {
    width: 51.375rem;
    max-height: 23.25rem;
  }
  .modal-header {
    background-color: $orange-18;
    padding: 0px;
  }
  .modal-body {
    padding: 0px !important;
    background-color: $yellow-11;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    .content-area {
      background-color: inherit;
      border-width: 0px;
      margin-top: 0px !important;
      padding: 1.125rem 0.5rem 0.5rem 1.5rem;
      &__input {
        background-color: inherit;
        height: 17.4375rem;
      }
    }
  }
}

.bs-accordions {
  @keyframes "fadein" {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &.accordion {
    border: none;
    border-radius: $border-radius;
    *[data-hidden] {
      display: none;
    }
  }
  .accordion__item {
    border: 1px solid $gray-5;
    border-bottom: 0;
    + {
      .accordion__item {
        border-top: 1px solid $gray-5;
        .accordion__heading {
          &:focus {
            outline-color: transparent;
            outline: 0;
          }
          .accordion__button {
            &:focus {
              outline-color: transparent;
              outline: 0;
            }
          }
        }
      }
    }
    .accordion__heading {
      &:focus {
        outline-color: transparent;
        outline: 0;
      }
      .accordion__button {
        &:focus {
          outline-color: transparent;
          outline: 0;
        }
      }
    }
    &:first-child {
      border-radius: $border-radius-top;
    }
    &:last-child {
      border-radius: $border-radius-bottom;
      border-bottom: 1px solid $gray-5;
    }
  }
  .accordion__button {
    background-color: $gray-2;
    color: $gray-9;
    padding: 12px;
    &:hover {
      background-color: $gray-2;
    }
    &:before {
      position: relative;
      top: -2px;
    }
  }
  .accordion__button[aria-expanded='true'] {
    &::before {
      transform: rotate(45deg);
    }
  }
  .accordion__button[aria-selected='true'] {
    &::before {
      transform: rotate(45deg);
    }
  }
  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
  }
}

// @import '../../../../../assets/css/custom-variable';

.datepicker_button {
  background-color: #f3f4f6 !important;
  color: #4b5563 !important;
  min-width: 128px;
  height: 2rem;
  padding: 0px 0px 0px 0px;
  border-radius: 2px !important;
  font-family: inter, sans-serif !important;
  border: solid lightgray 0.5px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: space-evenly;
}

.react-datepicker-wrapper {
  width: auto !important;
  position: 'absolute' !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.15rem;
}
.icon-padding {
  padding: 0 10px 0 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  padding: 0.7rem 0;
}

.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end,
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start {
  background-color: $blue-6 !important;
  color: $white-text;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 4px !important;
  background-color: $blue-1 !important;
  color: inherit;
}

.react-datepicker__day-selecting-range-start {
  background-color: red !important;
}

.react-datepicker__day--selected {
  background-color: $blue-6 !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker {
  overflow: hidden;

  border: none !important;
  border-radius: 4px;

  padding: 1rem !important;

  background: #ffffff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);

  &__header {
    padding: 0 !important;

    border-bottom: 1px solid #f3f4f6 !important;
    background-color: #ffffff !important;

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 1rem;

      .label {
        font-family: $font-family-roboto;
        font-style: normal;
        font-weight: $font-weight-medium;
        font-size: 0.875rem;
        line-height: 1.25rem;

        color: $blue-15;
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        button {
          width: 1.5rem;
          height: 1.5rem;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;
          background-color: transparent;
        }
      }
    }
  }

  &__day-names {
    margin: 0 !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__day,
  &__day-name,
  &__time-name {
    width: 2rem !important;
    height: 2rem !important;

    padding: 6px 0px !important;
    margin: 0 !important;
    border-radius: 0.25rem !important;

    font-family: $font-family-roboto;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    text-align: center !important;
    line-height: 1.25rem !important;

    color: $gray-11 !important;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__day {
    font-weight: $font-weight-normal;
    color: $blue-15 !important;

    user-select: none;

    &--outside-month,
    &--disabled {
      color: $gray-11 !important;
    }

    &--selected,
    &--range-start,
    &--range-end,
    &--selecting-range-start {
      font-weight: $font-weight-semibold;
      color: $white !important;
      background-color: $blue-11 !important;
    }
    &--keyboard-selected{
      background-color: $gray-1;
      font-weight: $font-weight-normal;
    }
    &--keyboard-selected:hover{
      background-color: #f0f0f0;
    }
  }

  &__month {
    margin: 0.5rem 0 0 0 !important;
  }

  &__week {
    display: flex !important;
  }
}

.helpscout-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $white;
  background: linear-gradient(46deg, #374151 17.53%, #6B7280 96.95%);
  flex-shrink: 0;
  border: none;
  position: relative;
  right: 0.1rem;
}

.helpscout-logout{
  position: fixed;
  left: 0.6rem;
  bottom: 0.8rem; 
}
.performance-stats {
  width: 2.5rem;
  height: 1.125rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid;
  justify-content: space-evenly;

  &--increment {
    background: $green-11;
    border-color: $green-11;

    .performance-stats__label {
      color: $green-12;
    }
  }
  &--decrement {
    background: $red-19;
    border-color: $red-19;

    .performance-stats__label {
      color: $red-16;
    }
  }

  &__label {
    line-height: 0.625rem;
    margin-right: 0.0625rem;
  }
}

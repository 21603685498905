.plan-cycle-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 10.375rem;
  height: 2.375rem;

  padding: 0.25rem;

  position: relative;

  border-radius: 6.1875rem;
  border: 0.0625rem solid #e5e7eb;
  background: #f3f4f6;

  &--option {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 4.875rem;
    height: 1.75rem;

    z-index: 1;

    span {
      color: #6b7280;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    &.selected {
      span {
        color: $blue-11;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .selected-option-bg {
    width: 4.875rem;
    height: 1.75rem;

    position: absolute;
    top: 0.25rem;
    bottom: 0.25rem;

    transition: all 0.25s ease-out;

    border-radius: 6.1875rem;
    background: #fff;

    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);

    &.monthly {
      transform: translateX(0%);
    }
    &.annually {
      transform: translateX(100%);
    }
  }
}

.block-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .subscription-ended-container {
    .empty-list {
      &__content-vertical {
        margin-top: -2.5rem;
        p {
          margin-top: 0.225rem;
          text-align: center;
          width: 45.1875rem;
          color: $gray-15;
        }
      }
    }
  }
}

.prospect-filters-modal {
  .modal-dialog {
    margin: 6.875rem auto 0 auto;
    overflow: hidden;

    width: 53.875rem;
    max-width: 53.875rem;
    min-width: 53.875rem;
    max-height: 35rem;
  }

  &--container {
    padding: 1.5rem 0 1.25rem 0;
    border-radius: 0.25rem;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 1.5rem;

    padding: 0 1.5rem;
    margin-bottom: 1rem;

    &__left,
    &__right {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    &__left {
      h1 {
        color: $blue-15;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }
    }

    &__right {
      .header-button {
        color: #6b7280;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;

        cursor: pointer;

        transition: all 0.2s ease;

        svg {
          display: block;
        }

        span {
          width: 6.3125rem;
        }

        &:hover {
          color: $blue-11;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.35;
          &:hover {
            color: #6b7280;
          }
        }
      }
    }
  }

  &--content {
    max-height: 26.5rem;
    min-height: 9rem;
    // overflow-y: auto;

    padding: 0 1.5rem;

    .os-scrollbar-vertical {
      padding-right: 0.25rem;

      .os-scrollbar-handle {
        border-radius: 0.25rem;
        width: 0.25rem;
        background-color: $gray-13;
      }
    }

    &::-webkit-scrollbar {
      width: 0.3125rem;
    }
  }

  &--group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 1rem;
    padding: 1rem 0.75rem;
    border-radius: 0.25rem;
    background: #f9fafb;

    .add-filter-condition-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;

      width: 5.3125rem;
      height: 1rem;

      cursor: pointer;
      transition: all 0.2s ease;

      span {
        width: 4.0625rem;

        color: #4b5563;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
      }

      svg {
        display: block;

        width: 1rem;
        height: 1rem;

        color: #4b5563;
      }

      &:hover {
        span,
        svg {
          color: $blue-11;
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
        span,
        svg {
          color: #6b7280;
        }
        &:hover {
          span,
          svg {
            color: #6b7280;
          }
        }
      }
    }
  }

  &--condition-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    width: 100%;

    .condition-row-section {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      width: 100%;
      position: relative;

      .transfer-select-btn {
        width: 22.25rem;
        padding: 0.375rem 0.5rem;

        .placeholder {
          color: #9ca3af;
        }

        .shd-pills {
          height: 1.375rem;
          padding: 0.125rem 0.5rem;
          border: 0.0625rem solid #d1d5db;
          background: #f3f4f6;

          .pills-label {
            color: #1f2937;
            line-height: 1rem;
          }
          .pills-close {
            width: 0.875rem !important;
            height: 0.875rem !important;
            min-width: 0.875rem !important;
            min-height: 0.875rem !important;
          }
        }
      }
    }

    .prospect-filters-condition-logic {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      gap: 0.5rem;

      width: 4rem;

      p {
        margin: 0;
        color: #9ca3af;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }

    .remove-condition-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 1.25rem;
      height: 1.25rem;

      padding: 0.125rem;
      border-radius: 0.125rem;

      transition: all 0.2s ease;

      svg {
        width: 1.125rem;
        height: 1.125rem;

        color: #6b7280;

        display: block;
      }

      &:hover {
        background-color: #fee2e2;

        svg {
          color: #b91c1c;
        }
      }
    }

    .bs-input-number-container {
      max-width: 22.25rem;

      .bs-input-number {
        &:focus,
        &:focus-within {
          outline: none;
          box-shadow: none;
        }
      }

      input {
        color: #1f2937;
        line-height: 1.25rem;

        &::placeholder {
          color: #9ca3af;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.25rem;
        }
      }
    }

    .bs-input-affix-wrapper {
      padding: 0.375rem 1rem;
      max-width: 22.25rem;
      height: 2rem;
      .bs-input {
        color: #1f2937;
        line-height: 1.25rem;

        &::placeholder {
          color: #9ca3af;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.25rem;
        }
      }
      &:focus,
      &:focus-within {
        outline: none;
        box-shadow: none;
      }
    }

    .prospect-filters-text-input-container {
      width: 22.25rem;
      height: 2rem;
      padding: 0.25rem 0.5rem;

      position: absolute;
      top: 0;
      right: 0;

      border-radius: 0.25rem;
      border: 0.0625rem solid #d1d5db;
      background-color: $white;

      display: flex;
      align-items: center;
      gap: 0.3125rem;
      flex-wrap: wrap;

      &.focused {
        min-height: 2rem;
        height: auto;
        .bs-input-affix-wrapper {
          display: flex;
        }
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border-color: $blue-11;
        outline: none;
        box-shadow: none;
      }

      .prospect-filters-text-input-pills {
        display: flex;
        align-items: center;
        gap: 0.3125rem;
        flex-wrap: wrap;

        .shd-pills {
          height: 1.375rem;
          padding: 0.125rem 0.5rem;
          border: 0.0625rem solid #d1d5db;
          background: #f3f4f6;

          .pills-label {
            color: #1f2937;
            line-height: 1rem;
          }
          .pills-close {
            width: 0.875rem !important;
            height: 0.875rem !important;
            min-width: 0.875rem !important;
            min-height: 0.875rem !important;
          }
        }
      }
      .bs-input-affix-wrapper {
        padding: 0;
        border: none;

        display: none;

        width: 6.25rem;
        height: 1.25rem;
        line-height: 1.25rem;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    width: 100%;
    padding: 0 1.5rem;
    padding-top: 1.25rem;

    .shd-button {
      min-width: 6.25rem;
    }
  }

  &--group-logic {
    display: flex;
    justify-content: center;
    align-items: center;

    .filter-logic-select {
      height: 1.5rem;
      .bs-select-box {
        width: 4rem;
        height: 1.5rem;
        padding: 0.125rem 0.5rem;
        border: 0.0625rem solid #bfdbfe;
        background: #eff6ff;
      }
    }
  }

  .shd-input {
    width: 100%;

    .input {
      width: 100%;
      &:focus {
        outline: none;
      }
    }

    .shd-input--label {
      display: none;
    }
  }

  .bs-input-number-container {
    width: 100%;
  }

  .number-input-container {
    display: flex;
    align-items: center;

    gap: 0.25rem;

    .number-input-separator {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 1rem;
      height: 1rem;

      svg {
        width: 100%;
        height: 100%;
        color: #9ca3af;
      }
    }

    .bs-input-number-container {
      width: 4.6875rem;

      .bs-input-number {
        min-width: 4.6875rem;
      }
    }
  }
}

.prospect-filters-select,
.prospect-filters-multi-select-dropdown-container {
  display: flex;

  width: 100%;
  max-width: 22.25rem;
  height: 2rem;

  &.filter-fields-select {
    width: 11.625rem;
    .bs-select-box {
      width: 11.625rem;
    }
  }

  &.filter-operation-select {
    width: 7.5rem;
    .bs-select-box-icon-wrapper {
      margin-left: 0;
    }
  }

  &.filter-logic-select {
    width: 4rem;
    .bs-select-box-icon-wrapper {
      margin-left: 0;
    }
  }

  &.w-100 {
    .bs-select-box {
      width: 100%;
    }
  }

  &.date-input-select-box {
    position: relative;
  }

  .bs-select-box {
    width: 100%;
    max-width: 22.25rem;
    height: 2rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid var(--gray-300, #d1d5db);
    background: #fff;

    padding: 0.375rem 0.5rem;

    &:focus,
    &:active {
      box-shadow: none;
    }

    &:focus-within {
      border-color: $blue-11;
    }

    .bs-select-box-text {
      color: #1f2937;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      &.placeholder {
        color: #9ca3af;
      }
    }
    .bs-select-box-icon-wrapper {
      svg {
        color: #6b7280;
      }
    }
  }
}

.prospect-filters-select-dropdown,
.prospect-filters-multi-select-dropdown {
  padding: 0 !important;

  &.filter-fields-dropdown {
    width: 16rem !important;
  }

  &.filter-operation-dropdown {
    width: max-content !important;
    min-width: 7.5rem;
  }

  &.filter-logic-dropdown {
    width: 4rem !important;
  }

  &.date-input-dropdown {
    width: 22.25rem !important;
  }

  border-radius: 4px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: #fff;

  box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
    8px 16px 56px -24px rgba(156, 157, 161, 0.24);

  .transfer-list {
    li {
      height: 2rem;
      padding: 0.375rem 0.75rem !important;

      font-style: normal;
      font-weight: 400 !important;
      line-height: 20px !important;

      .option-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.prospect-filters-multi-select-dropdown-container {
  width: 22.25rem;

  .bs-select-box {
    width: 100% !important;
  }

  .bs-select-box-text {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .shd-pills {
      height: 1.375rem;
      padding: 0.125rem 0.5rem;
      border: 0.0625rem solid #d1d5db;
      background: #f3f4f6;

      .pills-label {
        color: #1f2937;
        line-height: 1rem;
      }
      .pills-close {
        width: 0.875rem !important;
        height: 0.875rem !important;
        min-width: 0.875rem !important;
        min-height: 0.875rem !important;
      }
    }
  }
}

.prospect-filters-multi-select-dropdown {
  width: 22.25rem !important;

  &.teams-dropdown {
    width: 18.75rem !important;
  }
}

.prospect-filters-save-as-a-view-modal {
  .modal-dialog {
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    width: 22rem;

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.25rem;

      width: 100%;

      margin-bottom: 1.5rem;
    }
  }
  .rb-group {
    .bs-radio-wrapper {
      span {
        &:last-of-type {
          color: #6b7280;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }
      }
    }
  }

  &--input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.5rem;

    p {
      margin: 0;
      color: #6b7280;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .bs-input-affix-wrapper {
      padding: 0.375rem 0.5rem !important;
      max-width: 22.25rem;
      .bs-input {
        color: #1f2937;
        line-height: 1.25rem;

        &::placeholder {
          color: #9ca3af;
          font-size: 0.875rem;
          font-weight: $font-weight-normal;
          line-height: 1.25rem;
        }
      }
      &:focus,
      &:focus-within {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .bs-input-container {
      margin-bottom: 0 !important;
    }

    .input-error-container {
      .bs-input-label-suffix {
        color: #b91c1c;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 0.25rem !important;
      }
    }

    .shd-input {
      width: 100%;
      .shd-input--label {
        display: none;
      }
      .input {
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.prospect-filters-owners-dropdown {
  .transfer-container {
    max-width: 352px !important;
    min-width: 352px !important;
    .transfer-content {
      min-height: 19rem !important;
    }
  }
}

.prospect-filters-date-picker-popover {
  .arrow {
    display: none;
  }

  .bs-popover-inner-content {
    padding: 0 !important;

    .react-datepicker {
      box-shadow: none;
      padding: 0 !important;

      &__month-container {
        width: 13.5rem;
        min-height: 15rem;

        padding: 0.75rem !important;

        border-radius: 0.25rem !important;
        border: none !important;
      }

      &__header {
        border-bottom: none !important;

        .pick-date-and-time-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: $blue-15;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            line-height: 1rem;
            margin: 0;
          }

          .pick-date-and-time-action-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.25rem;

            button {
              width: 1rem;
              height: 1rem;

              text-align: center;

              color: $blue-15;
              padding: 0;

              border: none;
              background-color: transparent;

              &:hover,
              &:focus,
              &:focus-within,
              &:active {
                outline-color: $gray-23;
              }

              svg {
                width: 1rem;
                height: 1rem;
                display: block;
              }

              &:disabled {
                color: $gray-23;
              }
            }
          }
        }
      }

      &__current-month {
        display: flex;
        align-items: center;

        padding: 0;
      }

      &__header__dropdown {
        display: none;
      }

      &__day-names {
        height: 1.5rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.25rem;

        margin-top: 0.875rem !important;
      }

      &__day-name {
        width: 1.5rem !important;
        height: 1.5rem !important;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 !important;

        color: $gray-11;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;
      }

      &__month {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
      }

      &__week {
        height: 1.5rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.25rem;
      }

      &__day {
        width: 1.5rem !important;
        height: 1.5rem !important;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.2s ease;

        padding: 0 !important;

        color: $blue-15 !important;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: 1rem !important;

        &:hover {
          background-color: $blue-17;
        }
        &--disabled {
          color: $gray-23 !important;
        }
        &--selected,
        &--keyboard-selected {
          color: $white !important;
          background-color: $blue-11 !important;
        }
      }

      &__input-time-container {
        position: absolute;
        right: 0rem;
        top: 2.75rem;
        margin: 0;
        width: 168px;
        .react-datepicker-time__caption {
          display: none;
        }
        .react-datepicker-time__input-container {
          width: 100%;

          .react-datepicker-time__input {
            margin: 0;

            &:focus-visible {
              outline: none;
            }

            .react-datepicker-time__input {
              margin: 0;
              padding: 0.375rem 0.5rem;
              border-radius: 4px;
              border: 1px solid #e5e7eb;

              &:hover {
                border-color: $blue-11;
              }

              &:focus-visible {
                outline-color: $blue-11;
              }

              &::-webkit-calendar-picker-indicator {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.radius-box {
  background: #ddd;
  width: 100px;
  height: 32px;
  margin: 0 auto;
}
.radius-box-lg {
  background: #ddd;
  width: 100px;
  height: 40px;
  margin: 0 auto;
}
.radius-box-sm {
  background: #ddd;
  width: 100px;
  height: 24px;
  margin: 0 auto;
}
.border-radius {
  border-radius: $border-radius;
}
.border-radius-top {
  border-radius: $border-radius-top;
}
.border-radius-right {
  border-radius: $border-radius-right;
}
.border-radius-bottom {
  border-radius: $border-radius-bottom;
}
.border-radius-left {
  border-radius: $border-radius-left;
}

.switchToggle {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: relative;
    &:disabled + label {
      background: $gray-4;
      cursor: not-allowed;
    }
    &:checked {
      &:disabled + label {
        background: $green-13;
        &::before {
          color: $green-13;
          left: unset;
          right: 2px;
        }
      }
    }
  }
  &.switch-small {
    label {
      min-width: 28px;
      height: 16px;
      &::after {
        width: 12px;
        height: 12px;
      }
      &:active {
        &:after {
          width: 16px;
        }
      }
      span {
        font-size: 14px;
        line-height: 16px;
        left: 34px;
      }
    }
  }
  label {
    cursor: pointer;
    min-width: 44px;
    height: 22px;
    background: $gray-6;
    border-radius: 100px;
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: $gray-1;
      border-radius: 90px;
      transition: 0.3s;
    }
    &:active {
      &:after {
        width: 24px;
      }
    }
    span {
      display: inline-block;
      vertical-align: top;
      font-size: $text-16;
      line-height: $text-style-2-line-height;
      position: absolute;
      left: 50px;
    }
  }

  &.switch-text {
    input {
      &:checked + label {
        background: $green-13;
        &:before {
          content: '1';
          z-index: 9;
        }
      }
      &:checked {
        &:disabled + label {
          &::before {
            left: 4px;
            color: $gray-1;
          }
        }
      }
      & + label {
        &:before {
          content: '0';
        }
      }
      &:disabled + label {
        background: $gray-4;
        cursor: not-allowed;
      }
    }
  }
  &.switch-icon {
    input {
      & + label {
        &:before {
          font-family: $icomoon-font-family;
          content: '\eb53';
        }
      }
      &:disabled + label {
        background: $gray-4;
        cursor: not-allowed;
      }
      &:checked + label {
        &:before {
          font-family: $icomoon-font-family;
          content: '\e956';
          z-index: 9;
        }
      }
      &:checked {
        &:disabled + label {
          background: $green-13;
          cursor: not-allowed;
          &::before {
            left: 4px;
            color: $gray-1;
          }
        }
      }
    }
  }

  input {
    &:checked + label {
      background: $green-13;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 4px;
        width: 18px;
        height: 18px;
        border-radius: 90px;
        transition: 0.3s;
        text-indent: 0;
        color: $gray-1;
        text-align: center;
        font-size: $text-16;
      }
      &:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  &.switch-pending {
    input[type='checkbox'] {
      & + label {
        &:before {
          font-family: $icomoon-font-family;
          content: '\ebcc';
          animation: spin 2s linear infinite;
          font-size: $text-12;
          left: 2px;
          top: 2px;
          z-index: 9;
          color: $gray-6;
        }
      }
      &:checked {
        + label {
          &::before {
            left: unset;
            right: 2px;
          }
        }
      }
    }
    &.switch-small {
      input[type='checkbox'] {
        & + label {
          &:before {
            font-family: $icomoon-font-family;
            content: '\ebcc';
            animation: spin 2s linear infinite;
            font-size: 8px;
            left: 3px;
            top: 3px;
            z-index: 9;
            color: $gray-6;
            width: 10px;
            height: 10px;
          }
        }
        &:checked {
          &:disabled + label {
            &::before {
              right: 3px;
            }
          }
          + label {
            &::before {
              right: 3px;
              left: unset;
            }
          }
        }
      }
    }
  }
  input + label {
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 22px;
      width: 18px;
      height: 18px;
      border-radius: 90px;
      transition: 0.3s;
      text-indent: 0;
      color: $gray-1;
      text-align: center;
      font-size: $text-16;
    }
  }
}

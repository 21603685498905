.testimonials-carousel {
  width: 100%;

  .carousel {
    height: 21.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .testimonial {
      height: 18.375rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;

      .quote {
        display: flex;
        justify-content: flex-start;
        width: 3.625rem;
        height: 3.625rem;
      }

      span {
        font-size: 1.25rem;
        line-height: 1.75rem;
        text-align: start;
        color: $blue-15;

        margin: 0;
        padding: 0;
      }

      &--meta {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      &--profile {
        width: 3rem;
        height: 3.75rem;
        border-radius: 3.8254rem;
        background: linear-gradient(
          178deg,
          #1d4ed8 6.18%,
          rgba(29, 78, 216, 0.27) 98.98%
        );

        img {
          width: 100%;
          height: 100%;
          border-radius: 3.8254rem;
          object-fit: cover;
        }
      }
      &--details {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 0.25rem;

        h3 {
          font-weight: $font-weight-semibold;
          font-size: $text-16;
          line-height: 1.125rem;
          color: $blue-15;
          margin: 0;
        }

        p {
          font-weight: $font-weight-medium;
          font-size: 13.5px;
          line-height: 1.125rem;
          color: $gray-15;
          margin: 0;
        }
      }
    }
  }

  .indicators {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    // margin-top: 2rem;

    .indicator {
      width: 0.625rem;
      height: 0.625rem;
      background: #ccd2fb;
      border-radius: 0.625rem;
      border: none;
      transition: all 0.2s ease;

      &.active {
        width: 1.5625rem;
        background: #a2ace9;
      }
    }
  }
}

.transfer-overlay {
  padding: 0.1875rem;
  z-index: 1061;
  &.agency-client-dropdown {
    .transfer-container {
      width: 480px;
      .transfer-list {
        li {
          :hover {
            button {
              border: none;
              background: $blue-17;
            }
          }
          button {
            border: none;
            background: $white;
          }
          &.active button {
            border: none;
            background: $blue-17;
          }
        }
      }
    }
  }
  &.bulk-email-account-client {
    .transfer-container {
      width: 406px;
    }
  }
  &.general-settings-agency-dropdown {
    .transfer-container {
      width: 406px;
    }
  }
  &.custom-outcome-dropdown {
    .transfer-container {
      .transfer-body {
        .transfer-search {
          height: 32px;
        }
      }
    }

    &.transfer-container-transform {
      .transfer-container {
        transform: translateY(-437px);
        min-height: 458px;
        .transfer-body {
          .transfer-content {
            word-break: break-all;
            max-height: none;
            overflow-y: initial;
          }
        }
      }
    }
    &.transfer-container-dynamic-top {
      .transfer-container {
        max-height: 335px;
        min-height: 335px;
        overflow-y: scroll;
        transform: translate(9px, -371px);
        .transfer-body {
          .transfer-content {
            word-break: break-all;
            max-height: none;
            overflow-y: initial;
          }
        }
      }
    }
    &.transfer-container-dynamic-bottom {
      .transfer-container {
        max-height: 335px;
        min-height: 335px;
        overflow-y: scroll;
        transform: translateX(9px);
        .transfer-body {
          .transfer-content {
            word-break: break-all;
            max-height: none;
            overflow-y: initial;
          }
        }
      }
    }
  }
}

.transfer-container {
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: $gray-1;
  min-width: 14.5rem;
  .transfer-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid $gray-4;
  }
  .transfer-body {
    position: relative;
    padding: 0.5rem 0px;
    .transfer-disabled {
      background: $gray-3;
      position: absolute;
      height: 100%;
      z-index: 999;
      width: 100%;
      opacity: 0.5;
      cursor: not-allowed;
    }
    .transfer-body-title {
      margin: 0 0.375rem;
      display: flex;
      padding: 0.5rem 0 0.25rem 0;
    }
    .transfer-search {
      height: 2.5rem;
      background-color: $gray-19;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.375rem 1rem 0.375rem 1rem;
      .transfer-search-input {
        height: 1.25rem;
        padding: 0;
        border: none;
        border-radius: 0;
        background-color: transparent;
        align-items: center;
        &:hover,
        &:focus-within {
          border: none;
          border-color: transparent;
          border-right-width: 0;
          box-shadow: none;
        }
        input {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: $gray-17;
          background-color: transparent;
          &::placeholder {
            color: $gray-11;
          }
        }
        .sh-search {
          color: $gray-11;
        }
        span {
          margin-left: 0 !important;
          margin-top: 0.0625rem;
        }
      }
    }
    .transfer-tags {
      margin: 0.375rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $gray-4;
    }
    .transfer-content {
      max-height: 15.725rem;
      overflow-y: auto;
      word-break: break-all;

      .bs-tabs-nav {
        margin-bottom: 0;
      }
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b0adab;
        border-radius: 16px;
        border: 3px solid #fff;
      }
      .transfer-separator {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1px;
        padding: 0px 24px;
        background: #e5e7eb;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
      .transfer-list {
        list-style: none;
        margin: 0;
        padding: 0;
        div > li {
          padding: 0.625rem 1rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: $text-14;
          line-height: $text-style-2-line-height;
          color: $blue-15;
          &:last-child {
            border-bottom: 0;
          }
          &:hover {
            background-color: $blue-17;
          }
          &.active {
            background-color: $blue-17;
          }
          small {
            font-size: $text-12;
            line-height: $text-style-1-line-height;
            color: $gray-6;
          }
          .checkbox-wrapper {
            margin-right: 0.5rem;
          }
        }
      }
      .transfer-empty {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.725rem 0;
        img {
          height: 2.5rem;
          margin-bottom: 0.5rem;
        }
      }
      .load-more-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 0.5rem;
      }
    }
    .transfer-extra-content {
      max-height: 6.725rem;
    }
    .transfer-footer {
      padding: 0.4rem 1rem;
      .custom-outcome-dropdown-footer {
        display: flex;
        align-items: center;
        align-self: stretch;
        background: $gray-1;
        cursor: pointer;
        justify-content: center;
        margin: auto;
        padding-top: 0.5rem;
        border: none;
        p {
          color: $blue-11;
          font-family: $font-family-roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.transfer-container
  .transfer-body
  .transfer-content
  .transfer-list
  div
  > li:hover {
  background-color: $blue-17;
}

.password-validation-label {
  font-weight: 600;
  font-size: 8px;
  line-height: 16px;
  color: #6b7280;
  margin: 0;
  margin-top: 5px;
}
.password-validation-checklist-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0;
  gap: 70px;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    white-space: pre;

    li {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      margin-bottom: 3px;
      color: #9ca3af;

      span {
        width: 14px;
        height: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        i {
          font-size: 0.9rem;
        }
      }

      p {
        margin-bottom: 0;
        font-size: 10px;
        line-height: 16px;
      }

      &.valid {
        color: #047857;
      }

      &.error {
        color: #b91c1c;
      }
    }
  }
}

.bulk-action-icon-button {
  width: 2rem;
  height: 2rem;
  padding: 0.3125rem !important;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 0.25rem;

  transition: all 0.2s;

  i {
    font-size: 1.25rem;
    color: $gray-15;
  }

  &:hover {
    background-color: $blue-13 !important;
    i {
      color: $blue-11;
    }
  }
}

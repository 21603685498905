.cold-email-tips {
  position: relative;

  .cold-email-tips-carousel {
    width: 24.8125rem;
    border-radius: 0.25rem;
    background-color: $blue-17;
    .carousel-inner {
      height: 100%;
      padding: 0.9375rem;
      p {
        margin: 0;
      }
    }
    .carousel-item {
      width: 100%;
      height: 5rem;
      border-radius: 0.25rem;
      .caurosel-title {
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        color: $blue-15;
        margin-bottom: 0.3125rem;
      }
      .caurosel-content {
        font-weight: normal;
        font-size: 0.625rem;
        line-height: 1rem;
        color: $gray-15;
        a {
          color: $blue-11;
          text-decoration: underline;
          &:hover {
            color: $blue-11;
          }
        }
      }
      .caurosel-badge {
        padding: 0 0.3125rem;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 1.8125rem;
        height: 0.875rem;

        background: $green-13;
        border: 0.0625rem solid $green-13;
        border-radius: 0.25rem;

        font-weight: $font-weight-semibold;
        font-size: 0.5rem;
        line-height: 0.875rem;
        color: $white;

        margin-bottom: 0.5rem;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 1rem;
      height: 1rem;
      left: auto;
      top: auto;
      bottom: 0.9375rem;
      right: 0.9375rem;
      opacity: 1;
      &:hover {
        opacity: 1;
      }
    }
    .carousel-control-prev {
      right: 2rem;
    }
  }

  .caurosel-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15.75rem;
    height: 1rem;
    padding: 0px 0.9375rem;
    position: absolute;
    bottom: 0.9375rem;
    .caurosel-footer-text {
      font-size: 0.625rem;
      color: $blue-11;
      margin: 0;
      font-weight: $font-weight-semibold;
    }
  }
}

.ribbon {
  position: absolute;
  width: 2.625rem;
  height: 1.25rem;
  right: -8px;
  top: -0.625rem;
  font-size: 0.75rem;
  font-weight: $font-weight-normal;
  border-radius: 1px 1px 0px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ribbon:after,
.ribbon:before {
  content: '';
  position: absolute;
}
.ribbon:before {
  height: 0;
  width: 0;
  bottom: -0.53125rem;
  right: 0.1px;
  border-right: 0.5625rem solid transparent;
}

.ribbon.rib-primary {
  background: $blue-11;
}
.ribbon.rib-primary:before {
  border-top: 0.5625rem solid $blue-11;
}

.ribbon.rib-success {
  background: $green-6;
}
.ribbon.rib-success:before {
  border-top: 0.5625rem solid $green-6;
}

.ribbon.rib-warning {
  background: $yellow-6;
}
.ribbon.rib-warning:before {
  border-top: 0.5625rem solid $yellow-6;
}

.ribbon.rib-danger {
  background: $red-18;
}
.ribbon.rib-danger:before {
  border-top: 0.5625rem solid $red-12;
}

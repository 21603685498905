.upgrade-plan-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .upgrade-plan-header {
    div {
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 1.25rem;
      }
    }
  }
  .upgrade-plan-tabs {
    padding: 0rem 2.125rem;
    flex-grow: 1;
    .bs-tabs-nav {
      margin-bottom: 1.5rem !important;
      display: flex;
      align-items: start !important;
      &::before {
        border-bottom: 0rem solid #e8e8e8 !important;
      }
      .bs-tabs-nav-wrap {
        height: 1.875rem !important;
        align-self: auto !important;
        top: 0;
      }
    }
  }
}

.lead-finder-tab {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

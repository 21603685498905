.leads-tabbing {
  .bs-tabs {
    .bs-tabs-nav {
      height: 2.938rem;
      margin-bottom: 0px;
      color: $gray-30;

      .bs-tabs-nav-wrap {
        padding-left: 1.4375rem;

        .bs-tabs-nav-list {
          .bs-tabs-tab {
            margin-right: 1.25rem;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            color: $gray-30;

            &.bs-tabs-tab-active {
              color: $blue-11;
              font-weight: $font-weight-semibold;
            }
          }

          .bs-tabs-ink-bar-animated {
            height: 0.1875rem;
            border-radius: 0.125rem 0.125rem 0 0;
          }
        }
      }
    }
  }
}

.no-search-lead {
  height: calc(100vh - 6.938rem);
  display: flex;
  align-items: center;
  justify-content: center;

  .no-search-result {
    margin: 0;
  }
}

// Alert Message
.alert-message{
  .no-search-lead{
    height: calc(100vh - 9.2505rem);
  }
}

.leads-filter-bulk-search {
  .modal-dialog {
    max-width: 55.25rem !important;
    .modal-title {
      color: $blue-15;
    }
    .modal-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .modal-footer {
      padding-bottom: 0.5rem;
      .btn-solid-primary {
        background-color: #1D4ED8;
        border-color: #1D4ED8;
      }
      .btn-ghost-primary {
        border-color: #D9D9D9;
        color: #6B7280;
        &:active {
          border-color: #D9D9D9;
          color: #6B7280;
        }
        &:hover {
          background-color: #f3f4f6;
        }
      }
    }

    .search-input-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.625rem;
      .search-input-label {
        display: flex;
        align-items: center;
        .label-icon {
          font-size: 1.25rem;
          display: flex;
          color: #6B7280;
        }
        .label {
          font-size: 0.875rem;
          margin-left: 0.375rem;
          font-weight: 600;
          color: $blue-15;
        }
      }
      .show-exact-match-check {
        .bs-checkbox + span {
          padding-left: 6px;
          padding-right: 0px;
        }
        .checkbox-label-text {
          font-size: 0.75rem !important;
          color: $blue-15 !important;
        }
      }
    }
  }
}

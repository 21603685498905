.recent-search {
  width: 100%;
  height: calc(100vh - 7.688rem);
  overflow-y: scroll;

  .icon-alignment {
    text-align: center;
  }

  h3 {
    font-weight: $font-weight-semibold;
    font-size: 0.875rem;
    color: $blue-15;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  .service-activity {
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    color: $blue-15;
    margin-bottom: 0;
    padding-top: 2rem;
    text-align: start;
  }

  .latest-activity {
    font-size: 0.875rem;
    font-weight: $font-weight-normal;
    color: $gray-15;
    padding-top: 0.5rem;
    margin-bottom: 0;
  }

  .table-border {
    border: 1px solid $gray-13;
    width: 32.375rem;
    border-radius: 0.25rem;
    margin: 1rem 0;

    .table-data {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-13;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      .clock-bg {
        background-color: $gray-16;
        height: 2rem;
        width: 2rem;
        display: flex;
        border-radius: 50%;

        .clock-icon {
          margin: auto;
          height: 1.125rem;
          width: 1.125rem;
          color: $gray-15;
        }
      }

      .prospect-details {
        margin-left: 0.75rem;

        .bold-text {
          font-size: 0.875rem;
          color: $blue-15;
          font-weight: $font-weight-semibold;
          line-height: 1.188rem;
          word-break: break-all;
        }
        .normal-text {
          font-size: 0.875rem;
          color: $blue-15;
          font-weight: $font-weight-normal;
          line-height: normal;
          padding-left: 0.1875rem;
        }

        .days {
          color: $gray-11;
          font-size: 0.75rem;
          padding-top: 0.25rem;
          font-weight: $font-weight-normal;
          line-height: normal;
        }
      }

      .Trash-icon {
        color: $gray-15;
        height: 1.125rem;
        width: 1.125rem;

        &:hover {
          color: $blue-11;
        }
      }
    }
    &.table-nodata{
      border: unset;
    }
  }
}

// Alert Message
.alert-message{
  .recent-search{
    height: calc(100vh - 9.188rem);
  }
}
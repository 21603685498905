$popover-dark-bg: #1f2937;

.popover {
  font-family: $font-family-base !important;
}

.bs-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  border: 0;
  border-radius: calc(#{$border-radius} * 2);
  padding: 0.25rem;
  .popover-body {
    padding: 0;
  }
  .bs-popover-title {
    min-width: 177px;
    min-height: 32px;
    padding: 18px 16px 4px;
    padding-bottom: 2px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
  }
  .bs-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65);
    p {
      margin: 0;
    }
  }

  &.dark {
    background-color: $popover-dark-bg;

    .bs-popover-title {
      background: $popover-dark-bg;
      border-bottom: none;
      color: white;
    }

    .bs-popover-inner-content {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.bs-popover-left {
  > .arrow {
    &::before {
      border-left-color: $gray-1;
    }
  }
  &.dark > .arrow {
    &::before {
      border-left-color: $popover-dark-bg;
    }
    &::after {
      border-left-color: $popover-dark-bg;
    }
  }
}

.bs-popover-top {
  > .arrow {
    &::before {
      border-top-color: $gray-1;
    }
  }
  &.dark > .arrow {
    &::before {
      border-top-color: $popover-dark-bg;
    }
    &::after {
      border-top-color: $popover-dark-bg;
    }
  }
}

.bs-popover-right {
  > .arrow {
    &::before {
      border-right-color: $gray-1;
    }
  }
  &.dark > .arrow {
    &::before {
      border-right-color: $popover-dark-bg;
    }
    &::after {
      border-right-color: $popover-dark-bg;
    }
  }
}

.bs-popover-bottom {
  > .arrow {
    &::before {
      border-bottom-color: $gray-1;
    }
  }

  &.dark > .arrow {
    &::before {
      border-bottom-color: $popover-dark-bg;
    }
    &::after {
      border-bottom-color: $popover-dark-bg;
    }
  }
}

.bs-popover-inner {
  &[x-placement^='top-start'] {
    @extend .bs-popover-top;
    .arrow {
      transform: none !important;
      left: 16px !important;
      bottom: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='top-end'] {
    @extend .bs-popover-top;
    .arrow {
      transform: none !important;
      right: 16px !important;
      bottom: calc(-0.5rem + 1px);
      left: unset !important;
    }
  }
  &[x-placement^='bottom-start'] {
    @extend .bs-popover-bottom;
    .arrow {
      transform: none !important;
      left: 16px !important;
      top: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='bottom-end'] {
    @extend .bs-popover-bottom;
    .arrow {
      transform: none !important;
      right: 16px !important;
      top: calc(-0.5rem + 1px);
      left: unset !important;
    }
  }
  &[x-placement^='right-start'] {
    @extend .bs-popover-right;
    .arrow {
      left: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='right-end'] {
    @extend .bs-popover-right;
    .arrow {
      left: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='left-start'] {
    @extend .bs-popover-left;
    .arrow {
      right: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='left-end'] {
    @extend .bs-popover-left;
    .arrow {
      right: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='top'] {
    .arrow {
      bottom: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='right'] {
    .arrow {
      left: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='left'] {
    .arrow {
      right: calc(-0.5rem + 1px);
    }
  }
  &[x-placement^='bottom'] {
    .arrow {
      top: calc(-0.5rem + 1px);
    }
  }
}

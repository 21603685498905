.billing-subscription {
  .bs-tabs {
    .bs-tabs-nav {
      .bs-tabs-nav-wrap {
        .bs-tabs-nav-list {
          .bs-tabs-tab {
            margin-right: 1.25rem;
            padding-bottom: 0.375rem;

            &:hover {
              color: $blue-11 !important;
            }

            .bs-tabs-tab-btn {
              font-weight: $font-weight-medium;
            }

            &.bs-tabs-tab-active {
              .bs-tabs-tab-btn {
                font-weight: $font-weight-bold;
                color: $blue-11 !important;
              }
            }
          }

          .bs-tabs-ink-bar {
            border-radius: 0.25rem;
            background: $blue-11;
          }
        }
      }
    }
  }
}

.email_outreach {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: 0.75rem;

  svg {
    margin-right: 0.5rem;
  }

  h2 {
    font-size: 1rem;
    font-weight: $font-weight-bold;
    color: $blue-15;
    line-height: normal;
    margin-bottom: 0;
  }

  &.lead_finder {
    margin-top: 1.5rem;
  }
}

.email-outreach-container {
  border: 1px solid $gray-13;
  border-radius: 0.25rem;
  padding: 1.25rem;
  word-wrap: break-word;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(234, 237, 239, 0.65);

  .email-content {
    .card {
      border-bottom: 1px solid $gray-4;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .card {
    box-shadow: unset !important;
    border: none;
    background-color: unset;
    border-radius: 0;
    padding: 0;
  }
}

.subscription-plan {
  &__container {
    .card {
      box-shadow: 0px 2px 4px rgba(234, 237, 239, 0.65);

      .card-body {
        padding: 0px;

        .progress-bar-container {
          width: 47.6133%;

          .used-label {
            span {
              font-weight: $font-weight-medium;
            }
          }
        }

        .remaining-text {
          font-weight: $font-weight-medium;
        }
      }
    }

    .accordion__panel {
      padding-top: 0.75rem !important;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;

    .refresh-text {
      span {
        color: $gray-15;
        font-weight: $font-weight-medium;
      }
    }

    .canceled-plan {
      color: $blue-15;
      background-color: $red-19;
      border-radius: 6.25rem;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      padding: 0.094rem 0.5rem;
      margin-left: 0.5rem;
    }

    .subscription-email-title {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      margin-left: 0.25rem;
    }

    .subscription-plan-title {
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      margin-left: 0.25rem;
    }

    .email-quota {
      padding-top: 0.125rem;
    }

    .paused-plan {
      color: $blue-15;
      background-color: $orange-18;
      border-radius: 6.25rem;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      padding: 0.094rem 0.5rem;
      margin-left: 0.5rem;
    }

    .plan-price-wrap {
      .manage-billing-container {
        position: absolute;
        width: 11.875rem;
        right: 0;
        top: 2.3125rem;
        padding: 0.5rem 0;
        background: $white;
        box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
          8px 16px 56px -24px rgba(156, 157, 161, 0.24);
        border: 1px solid $gray-13;
        border-radius: 0.25rem;

        .option {
          padding: 0.625rem 1rem;

          &:hover {
            background: $blue-17;
          }
        }
      }

      span {
        margin-right: 0.1875rem;
      }

      .plan-price-detail {
        color: $popover-arrow-color;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.5rem;
        line-height: 10px;
      }

      .upgrade-modify-subscription-btn.btn-solid {
        background: $blue-11;
      }

      .cancel-subscription-btn.btn-solid {
        color: $red-13;
        font-weight: $font-weight-medium;
      }

      .reactivate-subscription-btn {
        font-weight: $font-weight-medium;
        border: 1px solid $blue-11;
        background-color: $blue-11;
      }
    }
  }

  &__expire-info {
    width: 16.313rem;
    background: $red-14;
    border-radius: 0.25rem;
    padding: 0.125rem 0.625rem;
    text-align: center;
    margin-top: 1.25rem;

    span {
      font-weight: $font-weight-medium;
    }
  }

  &__number-input {
    .slot-input {
      height: 2.5rem;

      .bs-input-number-input-wrap {
        height: inherit;

        input {
          height: inherit;
        }
      }
    }

    .number-input-waring-text {
      color: $red-13;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.625rem;
      line-height: 0.938rem;
      margin-top: 0.5rem;
    }
  }

  &__bill-overview-wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 1rem;
  }

  &__divider {
    width: 100%;
    height: 0.125rem;
    background: $gray-13;
  }

  &__loader-container {
    height: 8.0625rem;

    img {
      height: 2.5rem;
    }
  }

  .progress-bar-wrapper {
    height: 0.625rem;

    &__bar {
      @keyframes progressBar {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }
  }
}

.accordion {
  &__item {
    border: 1px solid $gray-14 !important;

    &:first-child {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
  }
}

.bs-accordions.accordion {
  background: $gray-19;
}

.ltd-summary {
  justify-content: flex-start;
  gap: 5.625rem;
}

.invoices-text {
  margin: 0.938rem 0;
}

.expired-on-wrap {
  background: $red-14;
  max-width: 9.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}

.reactivate-subscription-modal {
  color: $blue-15;

  .modal-content {
    min-height: 15.5rem;
    width: 28rem;

    .bs-modal-body {
      padding-top: 0;
    }
  }

  .modal-title {
    color: $blue-15;
  }

  .modal-header {
    border-bottom: none;
    min-height: 5rem;

    .close {
      margin-top: -0.6rem;
    }
  }

  .modal-footer {
    margin-left: 5%;
    margin-right: 6%;
    padding: 0;
  }

  .reactive-subscription-modal-cancel-btn.btn-ghost {
    &:hover {
      color: unset;
      border: 1px solid $solid-pr-ot-bdr;
    }

    &:focus {
      outline: none;
      color: unset;
      border: 1px solid $solid-pr-ot-bdr;
    }
  }

  .reactive-subscription-modal-submit-btn {
    background: $blue-11;
  }
}

.resume-subscription-modal {
  color: $blue-15;

  .modal-content {
    height: 17.5rem;
    width: 28rem;

    .bs-modal-body {
      padding-top: 0;
    }
  }

  .modal-title {
    color: $blue-15;
    line-height: $text-style-1-line-height;
  }

  .modal-header {
    border-bottom: none;
    min-height: 5rem;

    .close {
      margin-top: -0.6rem;
    }
  }

  .modal-footer {
    margin-left: 5%;
    margin-right: 6%;
    padding: 0;
  }

  .resume-subscription-modal-cancel-btn.btn-ghost {
    &:hover {
      color: unset;
      border: 1px solid $solid-pr-ot-bdr;
    }

    &:focus {
      outline: none;
      color: unset;
      border: 1px solid $solid-pr-ot-bdr;
    }
  }

  .reesume-subscription-modal-submit-btn {
    background: $blue-11;
  }
}

.ltd-expire-soon {
  width: 20.125rem;
  height: 1.625rem;
  background: $orange-18 !important;
  border-radius: 4px;
  padding: 0;
  margin-top: 20px;

  .alert-body {
    width: 100%;
    align-items: center;
    justify-content: center;

    .content-div {
      text-align: center;
    }
  }
}

.billing-overview-element-wrapper {
  gap: 0.3125rem;
}

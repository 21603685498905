.all-features-list-modal {
  .modal-dialog {
    max-width: 56.25rem;
    .modal-content {
      min-height: 90vh;
    }
    .modal-body {
      overflow: hidden;
      padding: 1.875rem 0 0 0;
      max-height: calc(90vh - 3.5rem);
    }
  }

  .features-table {
    .features-header {
      padding: 0 1.5rem;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .feature-title {
        width: 34.5rem;
        height: 3.75rem;
        justify-content: flex-start;
        border: 0.0625rem solid $gray-16;
        border-top-left-radius: 0.25rem;
        padding-left: 1.25rem;
      }
      .plan-name {
        width: 9.375rem;
        height: 3.75rem;
        border: 0.0625rem solid $gray-16;
        &:last-of-type {
          border-top-right-radius: 0.25rem;
        }
      }
      .starter-plan-name {
        width: 11rem !important;
      }
      .cst-plan-name {
        width: 12.5rem !important;
      }
    }
    .features-content {
      overflow: hidden;
      overflow-y: auto;
      max-height: calc(90vh - 9.1875rem);
      padding: 0 0.75rem;
      margin: 0 0.75rem;
    }
    .features-section {
      width: 852px;
      &:last-of-type {
        margin-bottom: 24px;
      }
      &--header {
        height: 3rem;
        padding: 0.9375rem 1.25rem;
        background: $gray-19;
        border: 0.0625rem solid $gray-16;
      }
      .feature {
        .cst-meta {
          width: 9.375rem !important;
        }
        .starter-plan-meta {
          width: 11rem !important;
        }
        .feature-name {
          width: 34.5rem;
          padding-left: 1.25rem;
          border: 0.0625rem solid $gray-16;
        }
        .feature-meta {
          width: 12.5rem;
          height: 3rem;
          border: 0.0625rem solid $gray-16;
        }
      }
    }
  }

  .email-warmup-bg {
    background: rgba(255, 251, 235, 0.5);
  }

  .email-outreach-bg {
    background: rgba(239, 246, 255, 0.5);
  }
}

.cold-email-plan-comparison {
  .modal-dialog {
    max-width: 59.875rem;
    .modal-content {
      min-height: 90vh;
    }
  }

  .features-table {
    .features-header {
      .best-value-plan {
        &__label-wrapper {
          left: -2px;
          top: -1.3125rem;
          width: 100%;
          .bs-tag {
            width: 7.375rem;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
      .feature-title {
        width: 23rem;
      }
    }
    .features-section {
      width: 56.88rem;

      .feature {
        .feature-name {
          width: 23rem;
          .feature-tooltip {
            text-decoration: underline;
            text-decoration-style: dashed;
            text-decoration-color: $gray-11;
            text-underline-offset: 4px;
          }
        }
        .feature-meta {
          .feature-tooltip {
            text-decoration: underline;
            text-decoration-style: dashed;
            text-decoration-color: $gray-11;
            text-underline-offset: 4px;
          }
        }
      }
    }
  }

  .starter-bg {
    background: rgba(236, 254, 255, 0.5);
  }

  .professional-bg {
    background: rgba(239, 246, 255, 0.5);
  }

  .business-bg {
    background: rgba(255, 251, 235, 0.5);
  }

  .email-outreach-pro-bg {
    background: rgba(255, 251, 235, 0.5);
  }
}

.link-badge-anchor {
  text-decoration: none !important;
  .link-badge_Icon {
    color: $gray-11;
  }
  .link-badge-button {
    display: flex;
    height: 1.5rem;
    border: 1px $gray-13 solid !important;
    border-radius: 6.25rem !important;
    padding: 0 0.375rem 0 0.375rem;
    background: $gray-1 !important ;
    gap: 0.25rem;
    align-items: center !important;
    color: $gray-12;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
    &:hover {
      border: 1px $blue-19 solid !important;
      transition: 0.1s ease-in-out;
    }
  }
}

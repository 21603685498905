.factor-details-modal {
    .modal-dialog {
        max-width: 38.375rem;

        .modal-title {
            font-size: $text-16;
            font-weight: $font-weight-semibold;
            color: $blue-15;
            line-height: $text-style-3-line-height;
        }

        .modal-body {
            padding: 1.5rem;
            padding-top: 1.3rem;
            padding-bottom: 0;  
            margin-bottom: 1.5rem;
            max-height: 60vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .factor-modal-content{
                margin-bottom: 14px;
            }

            .modal-content-title {
                font-size: $text-14;
                font-weight: $font-weight-semibold;
                color: $gray-15;
                line-height: $text-style-3-line-height;
                margin-bottom: 0.5rem;

                .modal-content-title-domain {
                    color: $blue-15;
                }

                .modal-content-title-ip {
                    color: $blue-15;
                }
            }

            .alert {
                padding: 0.5rem 1rem;
            }

            .modal-content-alert-title {
                font-size: $text-12;
                font-weight: $font-weight-semibold;
                color: $blue-15;
                line-height: 16px;
            }
            .modal-content-alert-sub-title {
                font-size: $text-12;
                font-weight: $font-weight-normal;
                color: $blue-15;
                line-height: 16px;
            }
            
            .dmarc-container {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .dmarc-content {
                    background-color: $gray-19;
                    padding: 1rem;
                    border: 0.0625rem solid $gray-13;
                    border-radius: 0.25rem;

                    code {
                        font-family: $font-family-sans-serif;
                        font-size: 14px;
                        line-height: $text-style-1-line-height;
                        color: $gray-12;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                .dmarc-content-footer {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .spf-container {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .spf-content {
                    background-color: $gray-19;
                    padding: 1rem;
                    border: 0.0625rem solid $gray-13;
                    border-radius: 0.25rem;

                    code {
                        font-family: $font-family-sans-serif;
                        font-size: 14px;
                        line-height: $text-style-1-line-height;
                        color: $gray-12;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                .spf-content-footer {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .table-container {
                thead {
                    .table-cell {
                        line-height: 16px !important;
                    }
                }
            }
        }

        .modal-footer {
            max-height: 10rem;
            padding: 12px 20px;
            justify-content: flex-start;


            .blacklist-footer-title {
                font-size: $text-14;
                color: $gray-12;
                font-weight: $font-weight-medium;
                display: flex;
                align-items: center;
                line-height: $text-style-1-line-height;
                margin-bottom: 0.25rem;
                margin-top: 0.25rem;
            }

            .blacklist-footer-content {
                font-size: $text-12;
                color: $gray-15;
                line-height: $text-style-1-line-height;
                margin-bottom: 0.125rem;

                .blacklist-footer-content-title {
                    font-weight: $font-weight-semibold;
                    color: $gray-12;
                }
            }
        }
    }
}
.prospect-tag-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.prospect-tag-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.delete-domain-confirmation-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }
  .shd-modal--header {
    padding: 1.25rem 1.5rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
      text-wrap: nowrap !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 24px !important;
    p {
      margin-bottom: 0;
      color: #1f2937;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .section-one {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    .section-two {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      margin-top: 24px;
    }
  }

  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
    .shd-button--primary {
      background-color: #b91c1c;
    }
  }
}

.revoke-cancellation-confirmation-modal {
  .modal-dialog {
    max-width: 28.125rem !important;
  }
  .shd-modal--header {
    padding-bottom: 1rem !important;
    .shd-modal--title {
      line-height: 1.5rem !important;
    }
  }
  .shd-modal--body {
    padding-bottom: 1.5rem !important;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
  .shd-modal--footer {
    padding: 1.25rem 1.5rem !important;
  }
}

.domains-container {
  width: 100%;
  height: 100%;

  background-color: #f9fafb;

  .domains-content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;
    height: 100%;
    padding: 1.5rem;
  }

  .domains-actions-container {
    .bs-input-affix-wrapper:focus,
    .bs-input-affix-wrapper:focus-within {
      box-shadow: none;
      border-color: $blue-11;
    }

    .search-input {
      width: 12.625rem;
      height: 2rem;

      justify-content: flex-start;
      align-items: center;
      padding: 0.3125rem 0.625rem;

      background: $white;
      margin-right: 0.25rem;

      .input-icons-left {
        width: 1rem;
        height: 1rem;

        margin: 0 !important;
        margin-right: 0.25rem !important;

        .bs-input-prefix {
          color: #d1d5db;
          margin: 0;
          font-size: 1rem;
        }
      }

      .input-icons-right {
        width: 1rem;
        height: 1rem;

        margin: 0 !important;
        margin-left: 0.25rem !important;

        .bs-input-suffix {
          color: #6b7280;
          margin: 0;
          font-size: 1rem;
          cursor: pointer;
        }
      }

      .bs-input {
        background: $white;
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        &::placeholder {
          color: #d1d5db;
        }
      }
    }
  }

  .domains-table-container {
    .table-list .react-bootstrap-table .table-organism {
      .table-organism-header {
        background: $white;
        box-shadow: none;

        tr {
          &:hover {
            background-color: transparent !important;
          }
        }

        th {
          border: 1px solid #f3f4f6;
          border-left: none !important;
          padding: 0 !important;

          &:first-of-type {
            border-left: 1px solid #f3f4f6 !important;
          }

          &:last-of-type {
            width: 4.4375rem;
          }

          height: 2.875rem;
          max-height: 46px;

          div {
            align-items: center;
            height: 100%;
            padding: 0 1rem;
          }

          span {
            color: #6b7280;
            font-size: 0.75rem !important;
            font-weight: 700 !important;
            line-height: 1rem !important;
          }

          &.table-header-loading {
            & > span {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 0 1rem;
            }
          }
        }
      }
      .table-organism-body tr.disable-row td .table-cell-text {
        color: #9ca3af !important;
      }
      .table-organism-body {
        tr {
          &:hover {
            box-shadow: none;
            td {
              background-color: #f9fafb;
            }
          }
        }
        td {
          padding: 0 1rem !important;

          background-color: white;
          height: 2.5rem;
          max-height: 2.5rem;

          border: none;
          border-right: 1px solid #f3f4f6;
          border-bottom: 1px solid #f3f4f6;

          &:first-of-type {
            border-left: 1px solid #f3f4f6;
          }

          .table-cell-text {
            color: #1f2937;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            text-wrap: nowrap;
          }

          .domains-mailboxes-count {
            &.active,
            &:hover {
              color: $blue-11;
              cursor: pointer;
            }
          }

          .icon-element {
            width: 1.5rem;
            height: 1.5rem;
            padding: 0.125rem;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0.25rem;
            transition: all 0.2s ease;

            &.disabled {
              cursor: default !important;
              opacity: 0.3;
              &:hover {
                cursor: default !important;
                background-color: transparent;
              }

              svg {
                cursor: default !important;
              }
            }

            svg {
              width: 1.25rem;
              height: 1.25rem;
              display: block;
              color: #6b7280;
            }

            &:hover {
              background-color: #f3f4f6;
            }
          }
        }
      }
    }
  }
}

.domains-mailboxes-popover {
  padding: 0.5rem !important;
  border-radius: 0.25rem !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 13, 56, 0.12) !important;
  max-width: unset;

  .bs-popover-inner-content {
    padding: 0 !important;

    display: flex;

    .domains-mailboxes {
      padding: 0.5rem;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .domains-mailbox-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &__profile {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.75rem;
        }
        &__email {
          margin: 0;
          color: #1f2937;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }
    }
  }
}

.domains-empty-list {
  display: flex;
  flex: 1;
  height: auto;
  justify-content: center;
  align-items: center;
  height: 100%;

  .empty-list {
    display: flex;
    height: 100%;
    width: 554px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      padding: 8px;
    }

    .empty-list-header {
      width: 554px;
      margin-top: 16px;
      margin-bottom: 8px;
      color: $blue-15;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: 24px;
      text-wrap: nowrap;
    }

    .empty-list-note {
      width: 554px;
      color: $gray-15;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-normal;
      line-height: 20px;
    }
  }
}

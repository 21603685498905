$gray-1: #ffffff !default;
$gray-10: #000000 !default;

.page-header {
  color: $gray-10;
  margin: 0;
  padding: 1rem 2.5rem !important;
  box-shadow: 5px 0px 10px rgba(220, 224, 230, 0.25);
  height: 4.5rem;
  position: relative !important;
  z-index: 10;
  background: $gray-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
  p {
    margin: 0;
  }
}
.semibold-3 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}
.danger-alert {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.onboarding-modal {
  &-q-1--wrapper {
    .modal-dialog {
      width: 30.75rem !important;
      min-width: 30.75rem !important;
      max-width: 30.75rem !important;
      transition: width 2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      .modal-content {
        .modal-body {
          padding: 3rem 3.5rem !important;
        }
      }
    }
  }
  &-q--wrapper {
    .modal-dialog {
      width: 53.125rem !important;
      min-width: 53.125rem !important;
      max-width: 53.125rem !important;
      transition: width 2s;
    }
  }
  &--wrapper {
    overflow-y: hidden !important;
    .modal-dialog {
      .modal-content {
        width: 100%;

        border-radius: 1rem !important;
        background-color: $blue-32;

        .modal-content-container {
          height: 100%;
        }
        .modal-body {
          max-height: 100%;
          overflow-y: scroll;
          padding: 3.5rem 4rem;

          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 3rem;

          .disable {
            cursor: not-allowed !important;
            pointer-events: none !important;
          }

          .radio-container {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .selected {
              outline: 2px solid $blue-31 !important;
            }
            .cs-radio-wrapper {
              display: flex;
              width: 23.75rem;
              padding: 0.75rem 1rem;
              align-items: center;
              gap: 12px;
              border-radius: 0.5rem;
              outline: 1px solid $blue-13;
              background-color: $gray-1;
              cursor: pointer;
              margin-bottom: 0px !important;

              &:hover {
                outline: 1px solid $blue-31;
                box-shadow: 0px 2px 6px 0px rgba(4, 21, 76, 0.12);
              }

              .radio {
                display: block;
                user-select: none;
                text-align: left;
                margin-bottom: 0px;
                width: inherit;
                & + .radio {
                  margin-top: 12px;
                }
                input {
                  display: none;
                  & + div {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    &:before {
                      content: '';
                      display: block;
                      position: absolute;
                      top: 0.8125rem;
                      left: 0px;
                      border-radius: 50%;
                      margin-right: 5px;
                      width: 16px;
                      height: 16px;
                      outline: 1px solid $gray-11;
                      background-color: $gray-1;
                    }
                    &:after {
                      content: '';
                      display: block;
                      width: 10px;
                      height: 10px;
                      background-color: $blue-31;
                      position: absolute;
                      border-radius: 50%;
                      top: 1rem;
                      left: 3px;
                      opacity: 0;
                      transform: scale(0, 0);
                      transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
                    }
                  }
                  &:checked + div:before {
                    outline: 2px solid $blue-31;
                  }
                  &:checked + div:after {
                    opacity: 1;
                    transform: scale(1, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.25rem;

    &.isLoading {
      margin-bottom: 1.88rem;
    }

    h1 {
      font-weight: $font-weight-medium;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $blue-15;
      margin: 0;

      span {
        font-weight: $font-weight-bold;
      }
    }
    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-15;
      margin: 0;
    }
  }

  &--question-wrapper {
    height: 23rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .cs-progress-bar-wrapper {
      width: 31.25rem;
      height: 0.5rem;
      position: relative;
      overflow: hidden;
      border-radius: 1.875rem;
      background-color: $blue-30;
      margin-bottom: 2.5rem;
      transition: width 2s linear;

      span {
        display: block;
        height: 100%;
        border-radius: 1.875rem;
      }
    }
  }

  &--question-number {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  &--question {
    font-weight: $font-weight-semibold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $blue-15;
    margin: 0;
    margin-bottom: 3.5rem;
    text-align: center;
  }

  &--options {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    button {
      border: 0px !important;
    }
  }

  &--option {
    padding: 1rem 1.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.5rem;
    outline: 1px solid $blue-13;
    box-sizing: border-box;
    background: $gray-1;

    position: relative;
    transition: all 0.2s ease-in-out;

    span {
      font-weight: $font-weight-medium;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $blue-15;
    }

    &:hover {
      outline: 2px solid $blue-31;
      box-shadow: 2px 2px 16px -3px rgba(91, 132, 198, 0.11),
        8px 8px 16px -24px rgba(40, 94, 183, 0.06);
      span {
        color: $blue-31;
      }
    }

    &.active,
    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible {
      outline: none;
      border-radius: 8px;
      background: linear-gradient(46deg, #2563eb, #3b82f6);
      box-shadow: 2px 2px 16px -3px rgba(91, 132, 198, 0.11),
        8px 8px 16px -24px rgba(40, 94, 183, 0.06);
      span {
        color: $gray-1 !important;
      }
    }
  }

  &--text-option {
    width: 18.625rem;
    padding: 0;
    position: relative;
    input {
      width: 100%;
      padding: 1rem 1.25rem;
      color: $blue-11;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      border: none;

      &::placeholder {
        color: #9ca3af;
      }

      &.active,
      &:focus,
      &:active,
      &:focus-within,
      &:focus-visible {
        outline: none;
      }
    }
    &:hover {
      outline: 2px solid $blue-31;
    }

    &.active,
    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible {
      outline: 2px solid $blue-31;
      background: $gray-1;
    }
    span.error-message {
      position: absolute;
      bottom: -20px;
      left: 0;
      color: #b91c1c !important;
      font-size: 12px;
      line-height: 1rem;
    }
  }

  &--footer {
    width: 100%;
    height: 1.25rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-btn {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-15;
      padding: 0;
      height: 1.25rem;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;

    .indicator {
      width: 0.625rem;
      height: 0.625rem;
      background: $blue-12;
      border-radius: 0.625rem;
      border: none;
      transition: all 0.2s ease;

      &.active {
        width: 1.5625rem;
      }
    }
  }

  &--loader {
    gap: 1.88rem;

    p {
      margin: 0;

      color: $blue-15;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
}

.email-accounts-notification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.875rem;
  margin-left: 10.875rem;
  margin-right: 10.875rem;

  p {
    margin: 0;
  }

  .notification-wrapper {
    display: flex;
    flex-direction: column;
    width: 46.3125rem;
    height: 26.6014rem;

    .notification-image {
      width: 32.0625rem;
      height: 16.25rem;
      margin-left: 3.6688rem;
    }
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .notification-title {
      color: $blue-15;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      line-height: 1.5rem;
      margin-top: 2.6031rem;
      text-wrap: nowrap;
    }

    .notification-description {
      color: #6b7280;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      margin-top: 1rem;
      text-wrap: nowrap;
    }

    .notification-action-btn {
      margin-top: 2rem;

      span {
        width: 6.3125rem !important;
        height: 1.25rem !important;
        padding: 0 !important;
        font-weight: $font-weight-medium !important;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        text-align: center !important;
      }
    }
  }
}

.info-alert {
  &.alert {
    &.alert-primary {
      display: flex;
      justify-content: space-between;
      .alert-icon {
        margin-right: 18px;
        i {
          color: $blue-6;
        }
      }
      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: $gray-8;
        i {
          color: $blue-6;
          margin-right: 5px;
        }
      }
      .alert-button {
        .btn {
          color: $blue-6;
        }
      }
      .close {
        top: -2px;
        color: $gray-8;
      }
    }
  }
}

.warning-alert {
  &.alert {
    &.alert-warning {
      display: flex;
      justify-content: space-between;
      .alert-icon {
        margin-right: 18px;
        i {
          color: $gold-6;
        }
      }
      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: $gray-8;
        i {
          color: $gold-6;
          margin-right: 5px;
        }
      }
      .alert-button {
        .btn {
          color: $gold-6;
        }
      }
      .close {
        top: -2px;
        color: $gray-8;
      }
    }
  }
}

.danger-alert {
  &.alert {
    &.alert-danger {
      display: flex;
      justify-content: space-between;
      .alert-icon {
        margin-right: 18px;
        i {
          color: $red-6;
        }
      }
      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: $gray-8;
        i {
          color: $red-6;
          margin-right: 5px;
        }
      }
      .alert-button {
        .btn {
          color: $red-6;
        }
      }
      .close {
        top: -2px;
        color: $gray-8;
      }
    }
  }
}

.success-alert {
  &.alert {
    &.alert-success {
      display: flex;
      justify-content: space-between;
      .alert-icon {
        margin-right: 18px;
        i {
          color: $green-6;
        }
      }
      p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        color: $gray-8;
        i {
          color: $green-6;
          margin-right: 5px;
        }
      }
      .alert-button {
        .btn {
          color: $green-6;
        }
      }
      .close {
        top: -2px;
        color: $gray-8;
      }
    }
  }
}

.alert {
  .alert-heading {
    font-size: $text-16;
    line-height: $text-style-3-line-height;
    color: $gray-9;
  }
  .alert-icon {
    i {
      font-size: $heading-2;
    }
  }
  p {
    font-size: $text-14;
    line-height: $text-style-2-line-height;
  }
  .alert-button {
    .btn {
      padding: 0;
      font-size: $text-14;
      line-height: $text-style-2-line-height;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .close {
    top: -2px;
    color: $gray-8;
    &:focus {
      outline: 0;
    }
  }
  &.alert-primary {
    background: $blue-1;
    border-color: $blue-3;
    &.alert-banner {
      border-color: $blue-1;
    }
  }
  &.alert-primary-2 {
    background: $blue-17;
    .alert-message-text {
      color: $blue-11 !important;
    }
  }
  &.alert-warning {
    background: $gold-1;
    border-color: $gold-3;
    &.alert-banner {
      border-color: $gold-1;
    }
  }

  &.alert-warning-2 {
    background: $yellow-11;
    .alert-message-text {
      color: $orange-12 !important;
    }
    .upgrade-link {
      color: $orange-12 !important;
    }
  }

  &.alert-danger {
    background: $red-1;
    border-color: $red-3;
    &.alert-banner {
      border-color: $red-1;
    }
  }

  &.alert-success {
    background: $green-1;
    border-color: $green-3;
    &.alert-banner {
      border-color: $green-1;
    }
  }
}

.alert-component {
  .new-theme {
    &.warning-alert {
      background: $yellow-11;
      color: $orange-12;
      font-size: $text-12;
      border: none;
    }

    &.danger-alert {
      background: $red-17;
      color: $red-12;
      font-size: $text-12;
      border: none;
    }

    .icon-div {
      width: 1.5rem;
    }

    .content-div {
      width: 100%;
    }
  }
}

.area-chart-container {
  height: 90%;
}

.yaxis-label-hidden {
  display: none;
}

.yaxis-label {
  font-size: 0.8rem;
}

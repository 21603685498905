.bs-table.bs-table-middle {
  font-size: 14px;
}
.bs-table.bs-table-middle .bs-table-title,
.bs-table.bs-table-middle .bs-table-footer,
.bs-table.bs-table-middle .bs-table-thead > tr > th,
.bs-table.bs-table-middle .bs-table-tbody > tr > td,
.bs-table.bs-table-middle tfoot > tr > th,
.bs-table.bs-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.bs-table.bs-table-middle .bs-table-thead th.bs-table-column-has-sorters {
  padding: 0;
}
.bs-table.bs-table-middle .bs-table-thead .bs-table-filter-column {
  margin: -12px -8px;
}
.bs-table.bs-table-middle .bs-table-thead .bs-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.bs-table.bs-table-middle .bs-table-thead .bs-table-column-sorters {
  padding: 12px 8px;
}
.bs-table.bs-table-middle .bs-table-expanded-row-fixed {
  margin: -12px -8px;
}
.bs-table.bs-table-middle
  .bs-table-tbody
  .bs-table-wrapper:only-child
  .bs-table {
  margin: -12px -8px -12px 25px;
}
.bs-table.bs-table-small {
  font-size: 14px;
}
.bs-table.bs-table-small .bs-table-title,
.bs-table.bs-table-small .bs-table-footer,
.bs-table.bs-table-small .bs-table-thead > tr > th,
.bs-table.bs-table-small .bs-table-tbody > tr > td,
.bs-table.bs-table-small tfoot > tr > th,
.bs-table.bs-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.bs-table.bs-table-small .bs-table-thead th.bs-table-column-has-sorters {
  padding: 0;
}
.bs-table.bs-table-small .bs-table-thead .bs-table-filter-column {
  margin: -8px -8px;
}
.bs-table.bs-table-small .bs-table-thead .bs-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.bs-table.bs-table-small .bs-table-thead .bs-table-column-sorters {
  padding: 8px 8px;
}
.bs-table.bs-table-small .bs-table-expanded-row-fixed {
  margin: -8px -8px;
}
.bs-table.bs-table-small
  .bs-table-tbody
  .bs-table-wrapper:only-child
  .bs-table {
  margin: -8px -8px -8px 25px;
}
.bs-table-small .bs-table-thead > tr > th {
  background-color:  $gray-16;
  position: sticky;
  top : 0;
  z-index: 1;
}
.bs-table-small .bs-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.bs-table.bs-table-bordered .bs-table-title {
  border: 1px solid $gray-4;
  border-bottom: 0;
}
.bs-table.bs-table-bordered thead > tr > th,
.bs-table.bs-table-bordered tbody > tr > td,
.bs-table.bs-table-bordered tfoot > tr > th,
.bs-table.bs-table-bordered tfoot > tr > td {
  border-right: 1px solid $gray-4;
}
.bs-table.bs-table-bordered .bs-table-cell-fix-right-first::after {
  border-right: 1px solid $gray-4;
}
.bs-table.bs-table-bordered table thead > tr:not(:last-child) > th {
  border-bottom: 1px solid $gray-4;
}
.bs-table.bs-table-bordered .bs-table-container {
  border: 1px solid $gray-4;
  border-right: 0;
  border-bottom: 0;
}
.bs-table.bs-table-bordered .bs-table-expanded-row-fixed {
  margin: -16px -17px;
}
.bs-table.bs-table-bordered .bs-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid $gray-4;
  content: '';
}
.bs-table.bs-table-bordered.bs-table-scroll-horizontal
  tr.bs-table-expanded-row
  > td,
.bs-table.bs-table-bordered.bs-table-scroll-horizontal
  tr.bs-table-placeholder
  > td {
  border-right: 0;
}
.bs-table.bs-table-bordered.bs-table-middle .bs-table-expanded-row-fixed {
  margin: -12px -9px;
}
.bs-table.bs-table-bordered.bs-table-small .bs-table-expanded-row-fixed {
  margin: -8px -9px;
}
.bs-table.bs-table-bordered .bs-table-footer {
  border: 1px solid $gray-4;
  border-top: 0;
}
.bs-table-wrapper {
  max-width: 100%;
}
.bs-table-wrapper::before {
  display: table;
  content: '';
}
.bs-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.bs-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  list-style: none;
  position: relative;
  z-index: 0;
  clear: both;
  font-size: 14px;
  background: $gray-1;
  border-radius: 2px;
}
.bs-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.bs-table-thead > tr > th,
.bs-table-tbody > tr > td,
.bs-table tfoot > tr > th,
.bs-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.bs-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.bs-table-cell-ellipsis.bs-table-cell-fix-left-last,
.bs-table-cell-ellipsis.bs-table-cell-fix-right-first {
  overflow: visible;
}
.bs-table-cell-ellipsis.bs-table-cell-fix-left-last .bs-table-cell-content,
.bs-table-cell-ellipsis.bs-table-cell-fix-right-first .bs-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-table-title {
  padding: 16px 16px;
  border-radius: 2px 2px 0 0;
}
.bs-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: $gray-2;
  border-radius: 0 0 2px 2px;
}
.bs-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: $gray-2;
  border-bottom: 1px solid $gray-4;
  transition: background 0.3s ease;
}
.bs-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.bs-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.bs-table-tbody > tr > td {
  border-bottom: 1px solid $gray-4;
  transition: background 0.3s;
}
.bs-table-tbody > tr.bs-table-row {
  &.active-row {
    td {
      background: $gray-2;
    }
  }
}
.bs-table-tbody > tr.bs-table-row:hover > td {
  background: $blue-1;
}
.bs-table-tbody > tr.bs-table-row-selected > td {
  background: #e6f7ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.bs-table-tbody > tr.bs-table-row-selected:hover > td {
  background: #dcf4ff;
}
.bs-table-tbody > tr .bs-table-wrapper:only-child .bs-table {
  margin: -16px -16px -16px 33px;
}
.bs-table-tbody > tr .bs-table-wrapper:only-child .bs-table td {
  background: transparent;
}
.bs-table-tbody
  > tr
  .bs-table-wrapper:only-child
  .bs-table-tbody
  > tr:last-child
  > td {
  border-bottom: 0;
}
.bs-table-tbody
  > tr
  .bs-table-wrapper:only-child
  .bs-table-tbody
  > tr:last-child
  > td:first-child,
.bs-table-tbody
  > tr
  .bs-table-wrapper:only-child
  .bs-table-tbody
  > tr:last-child
  > td:last-child {
  border-radius: 0;
}
.bs-table tfoot > tr > th,
.bs-table tfoot > tr > td {
  border-bottom: 1px solid $gray-4;
}
.bs-table-pagination.bs-pagination {
  margin: 16px 0;
}
.bs-table-pagination-left {
  float: left;
}
.bs-table-pagination-center {
  text-align: center;
}
.bs-table-pagination-right {
  float: right;
}
.bs-table-thead th.bs-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.bs-table-thead th.bs-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.bs-table-thead
  th.bs-table-column-has-sorters:hover
  .bs-table-filter-trigger-container {
  background: #f7f7f7;
}
.bs-table-thead th.bs-table-column-sort {
  background: #f5f5f5;
}
td.bs-table-column-sort {
  background: $gray-2;
}
.bs-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.bs-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px 16px;
}
.bs-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.bs-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.bs-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.bs-table-column-sorter-up,
.bs-table-column-sorter-down {
  display: inline-block;
  font-size: 11px;
}
.bs-table-column-sorter-up.active,
.bs-table-column-sorter-down.active {
  color: #1890ff;
}
.bs-table-column-sorter-up + .bs-table-column-sorter-down {
  margin-top: -0.3em;
}
.bs-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px -16px;
}
.bs-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.bs-table-thead tr th.bs-table-column-has-sorters .bs-table-filter-column {
  margin: 0;
}
.bs-table-thead
  tr
  th.bs-table-column-has-sorters
  .bs-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.bs-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.bs-table-filter-trigger-container-open,
.bs-table-filter-trigger-container:hover,
.bs-table-thead
  th.bs-table-column-has-sorters:hover
  .bs-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.bs-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}
.bs-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bs-table-filter-trigger-container-open .bs-table-filter-trigger,
.bs-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
}
.bs-table-filter-trigger.active {
  color: #1890ff;
}
.bs-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  min-width: 120px;
  background-color: $gray-1;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.bs-table-filter-dropdown .bs-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.bs-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.bs-table-filter-dropdown .bs-checkbox-wrapper + span,
.bs-table-filter-dropdown-submenu .bs-checkbox-wrapper + span {
  padding-left: 8px;
}
.bs-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid $gray-4;
}
.bs-table .bs-table-selection-col {
  width: 60px;
}
table tr th.bs-table-selection-column,
table tr td.bs-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.bs-table-selection-column .bs-radio-wrapper,
table tr td.bs-table-selection-column .bs-radio-wrapper {
  margin-right: 0;
}
.bs-table-selection {
  position: relative;
}
.bs-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.bs-table-selection-extra .anticon {
  display: inline-block;
  font-size: 10px;
  color: #bfbfbf;
}
.bs-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.bs-table-expand-icon-col {
  width: 48px;
}
.bs-table-row-expand-icon-cell {
  text-align: center;
}
.bs-table-row-indent {
  float: left;
  height: 1px;
}
.bs-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 12px;
  vertical-align: -2px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  user-select: none;
}
.bs-table-row-expand-icon:focus,
.bs-table-row-expand-icon:hover {
  color: #40a9ff;
}
.bs-table-row-expand-icon:active {
  color: #096dd9;
}
.bs-table-row-expand-icon:focus,
.bs-table-row-expand-icon:hover,
.bs-table-row-expand-icon:active {
  border-color: currentColor;
}

.bs-table-row-expanded::before {
  content: '\e90c';
  font-family: '#{$icomoon-font-family}';
}
.bs-table-row-collapsed:before {
  content: '\e911';
  font-family: '#{$icomoon-font-family}';
}
.bs-table-row-expand-icon::before {
  top: 2px;
  right: 0;
  left: 1px;
  position: absolute;
  transition: transform 0.3s ease-out;
}
.bs-table-row-spaced {
  display: none;
  content: none;
}
.bs-table-row-indent + .bs-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.bs-table-expanded-row > td,
tr.bs-table-expanded-row:hover > td {
  background: #fbfbfb;
}
.bs-table .bs-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.bs-table-tbody > tr.bs-table-placeholder {
  text-align: center;
}
.bs-table-empty .bs-table-tbody > tr.bs-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.bs-table-tbody > tr.bs-table-placeholder:hover > td {
  background: $gray-1;
}
.bs-table-cell-fix-left,
.bs-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: $gray-1;
}
.bs-table-cell-fix-left-first::after,
.bs-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.bs-table-cell-fix-right-first::after,
.bs-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.bs-table .bs-table-container::before,
.bs-table .bs-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.bs-table .bs-table-container::before {
  left: 0;
}
.bs-table .bs-table-container::after {
  right: 0;
}
.bs-table-ping-left:not(.bs-table-has-fix-left) .bs-table-container {
  position: relative;
}
.bs-table-ping-left:not(.bs-table-has-fix-left) .bs-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.bs-table-ping-left .bs-table-cell-fix-left-first::after,
.bs-table-ping-left .bs-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.bs-table-ping-right:not(.bs-table-has-fix-right) .bs-table-container {
  position: relative;
}
.bs-table-ping-right:not(.bs-table-has-fix-right) .bs-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.bs-table-ping-right .bs-table-cell-fix-right-first::after,
.bs-table-ping-right .bs-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
@media all and (-ms-high-contrast: none) {
  .bs-table-ping-left .bs-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .bs-table-ping-right .bs-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}

.bs-table-title + .bs-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bs-table-title
  + .bs-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-radius: 0;
}
.bs-table-title
  + .bs-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-radius: 0;
}
.bs-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.bs-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.bs-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.bs-table-wrapper-rtl {
  direction: rtl;
}
.bs-table-rtl {
  direction: rtl;
}
.bs-table-wrapper-rtl .bs-table table {
  text-align: right;
}
.bs-table-wrapper-rtl .bs-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.bs-table-wrapper-rtl .bs-table-thead > tr > th {
  text-align: right;
}
.bs-table-tbody > tr .bs-table.bs-table-rtl {
  margin: -16px 33px -16px -16px;
}
.bs-table-wrapper.bs-table-wrapper-rtl .bs-table-pagination {
  float: left;
}
.bs-table-wrapper.bs-table-wrapper-rtl .bs-table-pagination-left {
  float: left;
}
.bs-table-wrapper.bs-table-wrapper-rtl .bs-table-pagination-right {
  float: right;
}
.bs-table-wrapper.bs-table-wrapper-rtl .bs-table-pagination-center {
  float: initial;
  text-align: center;
}
.bs-table-wrapper-rtl .bs-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.bs-table-wrapper-rtl .bs-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.bs-table-wrapper-rtl .bs-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.bs-dropdown-rtl .bs-table-filter-dropdown .bs-checkbox-wrapper + span,
.bs-dropdown-rtl .bs-table-filter-dropdown-submenu .bs-checkbox-wrapper + span,
.bs-dropdown-menu-submenu-rtl.bs-table-filter-dropdown
  .bs-checkbox-wrapper
  + span,
.bs-dropdown-menu-submenu-rtl.bs-table-filter-dropdown-submenu
  .bs-checkbox-wrapper
  + span {
  padding-right: 8px;
  padding-left: 0;
}
.bs-table-wrapper-rtl .bs-table-selection {
  text-align: center;
}
.bs-table-wrapper-rtl .bs-table-selection-extra {
  right: auto;
  left: -10px;
}
.bs-table-wrapper-rtl .bs-table-row-indent {
  float: right;
}
.bs-table-wrapper-rtl .bs-table-row-expand-icon {
  float: right;
}
.bs-table-wrapper-rtl .bs-table-row-indent + .bs-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.bs-table-wrapper-rtl .bs-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.bs-table-wrapper-rtl .bs-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.bs-table-wrapper-rtl .bs-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.bs-space {
  display: inline-flex;
}
.bs-space-item {
  a {
    color: $blue-6;
  }
}
.bs-item-sep {
  color: #6d6d6d;
  margin: 0 10px;
}

/////////////////////

.bs-table2 {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1rem;
  &.bs-table2-middle {
    .bs-table2-thead {
      > tr {
        > th {
          padding: 12px 8px;
        }
      }
    }
    .bs-table2-tbody {
      > tr {
        > td {
          padding: 12px 8px;
        }
      }
    }
  }
  &.bs-table2-small {
    .bs-table2-thead {
      > tr {
        > th {
          padding: 8px;
        }
      }
    }
    .bs-table2-tbody {
      > tr {
        > td {
          padding: 8px;
        }
      }
    }
  }
  .bs-table2-thead {
    &.fixed-header {
      table-layout: fixed;
    }
    tr {
      th {
        font-weight: 500;
        text-align: left;
        border-bottom: 1px solid #e8e8e8;
        color: #262626;
        background: #fafafa;
        transition: background 0.3s ease;
        border-top: 0;
        padding: 1rem;
        font-size: $text-14;
        vertical-align: middle;
        &:first-child {
          border-top-left-radius: 2px;
        }
        &:focus {
          outline: 0;
        }
        .no-visibility {
          visibility: hidden;
          float: right;
        }
        &:hover {
          .no-visibility {
            visibility: visible;
            float: right;
          }
        }
        .bs-sort-icon {
          float: right;
          visibility: visible;
          i {
            color: #9a9a9a;
          }
        }
      }
    }
  }
  .bs-table2-tbody {
    &.fixed-header-tbody {
      table-layout: fixed;
    }
    tr {
      &:hover {
        background: #e6f7ff;
      }
      &.active-row {
        background: #fafafa;
        &:hover {
          background: #e6f7ff;
        }
      }
      td {
        position: relative;
        padding: 1rem;
        overflow-wrap: break-word;
        border-bottom: 1px solid #e8e8e8;
        transition: background 0.3s;
        border-top: 0;
        color: #595959;
        font-size: $text-14;
        vertical-align: middle;
        .bs-space-item a {
          color: #0137fc;
          cursor: pointer;
          &:hover {
            color: $blue-6;
            text-decoration: underline;
          }
        }
        .bs-item-sep {
          color: #6d6d6d;
          margin: 0 10px;
        }
      }
    }
  }
  .selection-cell-header {
    input[type='checkbox'] {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0 auto;
    }
    &[data-row-selection] {
      width: 48px;
    }
  }
  &.table-bordered {
    .bs-table2-thead {
      &.fixed-header {
        table-layout: fixed;
      }
      th {
        border-right: 1px solid #e8e8e8;
        border-left: 0;
      }
    }
    .bs-table2-tbody {
      &.fixed-header-tbody {
        table-layout: fixed;
      }
      tr {
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      td {
        border-right: 1px solid #e8e8e8;
        border-left: 0;
      }
    }
  }
  .selection-cell {
    input[type='checkbox'] {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0 auto;
    }
  }
  .bs-table2-row-expand-icon {
    color: #1890ff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    display: inline-flex;
    float: left;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    padding: 0;
    color: inherit;
    line-height: 12px;
    vertical-align: -2px;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
    user-select: none;
    align-items: center;
    justify-content: center;
  }
  .bs-table2-row-expand-icon:focus,
  .bs-table2-row-expand-icon:hover {
    color: #40a9ff;
  }
  .bs-table2-row-expand-icon:active {
    color: #096dd9;
  }
  .bs-table2-row-expand-icon:focus,
  .bs-table2-row-expand-icon:hover,
  .bs-table2-row-expand-icon:active {
    border-color: currentColor;
  }
  .bs-table2-row-expand-icon::before {
    position: absolute;
    transition: transform 0.3s ease-out;
  }
  .bs-table2-row-expanded::before {
    content: '\e90c';
    font-family: '#{$icomoon-font-family}';
  }
  .bs-table2-row-collapsed:before {
    content: '\e911';
    font-family: '#{$icomoon-font-family}';
  }
}

.textWarning {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: $gray-8;
}
.textWarning span:first-of-type {
  margin-top: 2px;
}

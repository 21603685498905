.spintax-icon {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.spintax-dropdown {
  width: 17.313rem !important;
  height: 23.188rem !important;

  .transfer-header {
    padding: 0.5rem 0 !important;
    border: none !important;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: calc(100% - 1.5rem);
      height: 0.0625rem;
      background-color: $gray-13;
      position: absolute;
      bottom: 0;
      left: 0.75rem;
      right: 0.75rem;
    }
  }

  .spintax-header {
    padding: 0.5rem 0.75rem;
    gap: 0.25rem;

    .insert-new-spintax {
      gap: 0.25rem;
      color: $blue-11;

      span {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
      }
    }
    .spintax-info-icon {
      margin-top: 0.125rem;
    }

    &:hover {
      background: $blue-17;
    }
  }

  .transfer-container {
    height: 100%;
    .transfer-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      .transfer-content {
        flex-grow: 1;
        max-height: none;
        overflow-y: hidden !important;
      }
    }
  }
  li {
    padding: 0 !important;
  }
  li:hover {
    background-color: transparent !important;
  }
  .main-title {
    font-size: 14px;
    line-height: 20px;
    color: #1f2937;
    font-weight: 500;
  }
  .main-item {
    padding: 0.375rem 0;
    height: 6.125rem;
    .main-title {
      padding: 0 0.75rem;
    }
  }
  .inner-item {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    color: #6b7280;
    padding: 0.125rem 0.75rem;
    font-weight: 400;

    &:hover {
      background-color: #eff6ff;
    }
  }
}

.tooltip-spintax {
  .tooltip-inner {
    max-width: 16.25rem !important;
  }
}

.tab-wrapper {
  display: flex;
  max-width: 9.375rem;
  height: 2.8125rem;
  justify-content: center;
  font-weight: 400;
  align-items: center;
  width: 100%;

  .icon-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;

    i {
      font-size: 1.5rem;
    }
  }

  .label-wrapper {
    margin-left: 0.5rem;
    margin-right: 1.25rem;
  }

  .counter-wrapper {
    margin-right: 0.9375rem;
    color: $gray-12;
  }

  &:hover {
    color: $blue-11;
    .counter-wrapper {
      color: $blue-11;
    }
  }
}

.bs-tabs-tab-active {
  .tab-wrapper {
    .counter-wrapper {
      color: $blue-11;
    }
  }
}

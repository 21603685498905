.summary-gadget {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;

  width: 100%;
  height: 4.125rem;

  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #ffffff;

  &--content {
    height: 1.25rem;

    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 0.5rem;
    p {
      margin: 0;
    }
  }

  &--label {
    color: $blue-15;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    font-size: 0.75rem;
    line-height: 1.125rem;
    transition: all 0.2s ease;
    width: max-content;
  }

  &--value {
    font-weight: $font-weight-semibold;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: $blue-15;
  }

  &--percentage {
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $gray-11;
  }
}

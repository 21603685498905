.email-verification-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  .valid-contact {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 1.375rem;
    font-size: 1rem;
    width: 22.75rem;

    .status-name {
      display: flex;
      justify-content: space-between;
      min-width: 8.125rem;
      color: $green-16;
    }
    .status-content {
      margin-right: -0.4rem;
    }
  }
  .risky-contact {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
    font-size: 1rem;
    width: 22.75rem;

    .status-name {
      display: flex;
      justify-content: space-between;
      min-width: 8.125rem;
      color: $orange-13;
    }
  }
}

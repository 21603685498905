.bs-empty {
  padding: 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.bs-empty-image {
  height: 100px;
  margin-bottom: 8px;
  img {
    height: 100%;
  }
  svg {
    height: 100%;
    margin: auto;
  }
}
.bs-empty-description {
  margin: 0;
}
.bs-empty-footer {
  margin-top: 16px;
}
.bs-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
  .bs-empty-image {
    height: 40px;
  }
}
.bs-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  .bs-empty-image {
    height: 35px;
  }
}
.bs-empty-img-default-ellipse {
  fill-opacity: 0.8;
  fill: #f5f5f5;
}
.bs-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.bs-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.bs-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.bs-empty-img-default-path-4 {
  fill: #dce0e6;
}
.bs-empty-img-default-path-5 {
  fill: #dce0e6;
}
.bs-empty-img-default-g {
  fill: #fff;
}
.bs-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.bs-empty-img-simple-g {
  stroke: #d9d9d9;
}
.bs-empty-img-simple-path {
  fill: #fafafa;
}
.bs-empty-rtl {
  direction: rtl;
}

.breadcrumb {
  background-color: $breadcrumb-custom-bg;
  @include border-radius($breadcrumb-custom-border-radius);
  font-size: $text-14;
}

.breadcrumb-item {
  a {
    color: $gray-7;
    text-decoration: none;
    &:hover {
      color: $blue-5;
    }
  }
  .dropdown {
    display: flex;
  }
  .btn-link {
    padding: $btn-custom-padding-null;
    color: $gray-7;
    text-decoration: none;
    font-size: $text-14;
    border: 0;
    display: flex;
    align-items: center;
    &:hover {
      color: $blue-5;
      border: 0;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
    i {
      position: relative;
      left: 5px;
      top: 1px;
    }
  }
  &.active {
    color: $breadcrumb-custom-active-color;
  }
  + .breadcrumb-item {
    &::before {
      color: $breadcrumb-custom-divider-color;
    }
  }
}

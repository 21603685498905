.rb-group {
  display: inline-flex;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn-solid-group {
    align-items: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.45;
    border-radius: 4px;
    margin-left: 2rem;
    margin-right: 3rem;

    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
    &.btn-solid-primary-group {
      &:not(:disabled):not(.disabled).active {
        background-color: #eff6ff !important;
        border-color: #eff6ff;
        color: $blue-11;
        border-radius: 0.25rem;
        font-weight: $font-weight-semibold;

        &:hover,
        &.hover {
          background-color: #eff6ff;
        }
        &:active {
          background-color: #eff6ff;
        }
      }
      &:active,
      &.active {
        &:disabled,
        &.disabled {
          cursor: not-allowed;
        }
      }
      &:disabled,
      &.disabled {
        cursor: not-allowed;
        color: $gray-600;
      }
    }
    &.btn-solid-outlined-group {
      &:active,
      &.active {
        &:disabled,
        &.disabled {
          cursor: not-allowed;
        }
      }
      &:disabled,
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.btn-group {
  > .btn-solid:not(:first-child) {
    align-items: center;
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.chevron-right-icon {
  margin-top: 0.9375rem;
  margin-right: 1.3125rempx;
  color: #d1d5db;
}

// New Radio Button Group
.radio-button {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: not-allowed;
  &__container {
    display: flex;
    position: relative;
    background: $gray-16;
    border: 1px solid $gray-13;
    border-radius: 6.1875rem; // just a high number to create pill effect
  }

  &__label {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 5.1rem;
    border-radius: 6.1875rem; // just a high number to create pill effect
    cursor: pointer;
    transition: color 0.15s ease-in;
    z-index: 1;
  }

  input[disabled] + label {
    pointer-events: none;
  }

  input[type='radio'] {
    display: none;
    &:checked {
      & + label {
        color: $blue-11;
      }
    }
  }

  input[id='radio-1'] {
    &:checked {
      & ~ .glider {
        transform: translateX(0);
      }
    }
  }

  input[id='radio-2'] {
    &:checked {
      & ~ .glider {
        transform: translateX(100%);
      }
    }
  }

  .glider {
    position: absolute;
    height: 1.75rem;
    width: 4.875rem;
    background: $white;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    margin: auto 0.25rem;
    top: 0;
    bottom: 0;
    border-radius: 6.1875rem; // just a high number to create pill effect
    transition: 0.25s ease-out;
  }

  @media (max-width: 700px) {
    &__container {
      transform: scale(0.6);
    }
  }
}

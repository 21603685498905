.reports-container {
  background-color: $gray-19;
  .reports-main {
    .reports-filter-col {
      padding: 0;
    }
    .reports-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1.5rem;
      background-color: $white;
      border-bottom: 1px solid $gray-14;
      box-shadow: 0px 2px 4px $gray-13;
      .sequence-filter-menu-left {
        display: flex;
        align-items: center;
        gap: 1.375rem;

        .report-help {
          border: 1px solid $blue-13;
          border-radius: 1.25rem;
          padding: 0.3125rem 1rem;
        }

        .rotate-90 {
          transform: rotate(90deg);
          display: inline-block;
        }
      }
      .sequence-filter-menu-right {
        display: flex;
        align-items: center;
        .divider-vertical {
          height: 1.25rem;
          width: 1px;
          background-color: $gray-26;
          margin-left: 1.25rem;
          margin-right: -0.75rem;
        }
        .report-date-filter-select-wrap {
          width: 10.1875rem;
          height: 2rem;
          .unibox-date-filter-select {
            width: 191px;
            height: 32px;

            .transfer-container {
              .transfer-body {
                .transfer-content {
                  .transfer-list {
                    li {
                      .separator {
                        border-bottom-width: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .render-text {
            border: none;
          }
          .bs-select-box {
            height: 100%;
            border: 1px solid $gray-26;
            background-color: $gray-19 !important;
          }
          .report-date-picker {
            .react-datepicker {
              position: 'absolute' !important;
              z-index: 10;
              margin-top: 0.225rem;
            }
          }
          .date-renderer {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
              width: 1.125rem;
              height: 1.125rem;
            }

            span {
              font-weight: $font-weight-medium;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $blue-15;
            }
          }
        }
      }
    }

    .reports-stats-container {
      height: calc(100vh - 8rem);
      overflow: hidden;
    }

    .reports-empty {
      display: flex;
      justify-content: center;
      margin-top: 13%;
      .empty-block {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .sequences-report-graph {
      margin-top: 1.5rem;
      margin-left: 1.7rem;
      margin-right: 1.2rem;

      .graph-wrapper {
        background: $white;
        border: 1px solid $gray-13;
        border-radius: 0.125rem;
        margin-bottom: 1.5rem;
        height: 21.625rem;
        padding: 1.25rem 0.75rem;
        .sequence-graph-groupby-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: -0.5rem;
          .dropdown-custom {
            display: flex;
            justify-content: space-between;
            width: 6.2rem;
            border: 1px solid $gray-22;
            border-radius: 0.225rem;
            background-color: white;
            z-index: 1;

            .dropdown-toggle-custom {
              width: 100%;
              justify-content: space-between;
            }

            .dropdown-menu-custom {
              min-width: 4.75rem;
              border-radius: 0.225rem;
              margin-top: 0.225rem;
              .dropdown-item-custom {
                border: none;
              }
              .selected-item {
                background: $blue-13;
              }
            }
          }
        }
      }
      .graph-loader-wrap {
        width: 100%;
        height: 21.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $gray-22;
        border-radius: 0.225rem;
        margin-bottom: 1.5rem;
        .graph-loader {
          width: 5rem;
        }
      }
    }
  }
}

.sentiment-summary-dropdown {
  margin-left: 8px;
  border: none;
  background: $gray-16;
  width: 25px;
  height: 25px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .stage-icon-wrapper {
    color: $blue-15;
    margin-top: 0.1rem;
  }
}

.stage-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }

  .stage-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    .stage-indicator {
      border-radius: 4px;
      margin-right: 10px;
      width: 3px;
      height: 24px;
      flex-shrink: 0;
      &.positive {
        background: $green-23;
      }
      &.negative {
        background: $red-15;
      }
      &.neutral {
        background: $gray-11;
      }
    }

    .stage-name {
      flex-grow: 1;
      color: $blue-15;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .stage-count-wrapper {
    display: flex;
    align-items: center;
    width: 20%;
    .stage-count {
      margin-right: 0.5rem;
      font-weight: bold;
      color: $blue-15;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }

    .stage-percentage {
      color: #888;
      color: $gray-15;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      font-size: 14px;
    }
  }
}

.percentage-tooltip {
  .tooltip-inner {
    max-width: 250px;
  }
}

.select-sentiment-summary-transfer {
  display: inline-flex;
  padding: 0px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid $gray-13;
  background: $white-text;
  box-shadow: 3px 8px 24px -6px rgba(15, 34, 67, 0.16);
  max-height: 164px;
  max-width: 25rem;
  min-width: 15.625rem;
  // width: 310px;
  overflow-y: scroll;
  overflow-x: hidden;

  .popover-body {
    width: 100%;
    .bs-popover-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
    }
  }
}

.report-body-wrapper > tr {
  .text-container {
    width: auto !important;
  }
}

.transfer-multi-select {
  .transfer-container {
    max-width: 240px;
  }
}

.report-graph-tooltip {
  max-width: 15rem;
  text-align: center;
  font-size: 0.625rem;
}

.report-date-filter-select
  .transfer-container
  .transfer-body
  .transfer-content
  .transfer-list
  > li {
  font-family: $font-family-roboto;
  font-weight: $font-weight-normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $blue-15;
}

.global-tab-style {
  background-color: $gray-16;
  border-radius: 0.25rem;
  height: 2rem;
  width: 14rem;
  padding: 0px 0.125rem;

  div {
    width: 7rem;
  }
  .active {
    background-color: $white;
    border-radius: 0.25rem;
    height: 1.7rem;
    width: 6.8rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
.cs-report-checkbox {
  .bs-checkbox {
    .bs-checkbox-input:checked {
      border: none !important;
    }
    .bs-checkbox-inner {
      border-radius: 0.25rem;
    }
    span:last-child {
      color: $popover-arrow-color;
    }
  }
  span:last-child {
    padding-right: 0px !important;
    color: $popover-arrow-color;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.reports {
  position: relative;
  z-index: 1;

  &--content {
    .bs-tabs-tab {
      margin: 0 1.5rem 0 0;
    }

    .bs-tabs-content-holder {
      height: 88vh;
      overflow-y: scroll;
    }
    .bs-tabs-nav {
      width: 100%;
      height: 3.5rem;
      margin: 0 0 0 0 !important;

      padding: 0rem 1.5rem;

      background: $white;
      border-bottom: 0.0625rem solid $gray-14;
      box-shadow: 0rem 0.125rem 0.25rem 0rem $gray-13;

      box-sizing: border-box;

      &::before {
        border-bottom: none;
      }

      .bs-tabs-nav-list {
        gap: 1.5rem;

        .bs-tabs-tab {
          margin: 0 !important;
        }

        .bs-tabs-ink-bar {
          border-radius: 2px 2px 0px 0px;
          background: $blue-11;
        }
      }
      .bs-tabs-tab-active {
        color: $blue-11 !important;
        font-weight: $font-weight-semibold !important;
      }
    }

    .sequence-reports {
      &__card {
        padding: 1rem 1rem 0px 1rem;
        border-radius: 0.25rem;
        border: 1px solid #f3f4f6;
        background-color: $gray-1;
        position: relative;

        .graph-loading-container {
          .graph-loading-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(
              255,
              255,
              255,
              0.1
            ); /* Semi-transparent background */
            backdrop-filter: blur(2px); /* Blurring effect */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          }
        }

        .graph-filters {
          .report-tab {
            width: 10.625rem;
            height: 2rem;
            border: 1px solid $gray-14;
            div {
              width: 5.25rem;
            }
            .active {
              transition: all 0.7s;
            }
          }
          &__left {
            .cs-switch {
              margin-top: 0.25rem;
              &.purple {
                label {
                  margin-bottom: 0px !important;
                  background: $purple-16 !important;
                  &::after {
                    top: 1.5px;
                  }
                }
              }
              &.blue {
                label {
                  margin-bottom: 0px !important;
                  background: $blue-24 !important;
                  &::after {
                    top: 1.5px;
                  }
                }
              }
            }
          }
          &__middle {
            .time-frame {
              display: flex;
              gap: 0.25rem;

              &__item {
                padding: 0.1875rem 1.3125rem;
                &__text {
                  color: #d1d5db;
                }
              }

              &__active {
                border-radius: 0.25rem;
                outline: 1px solid #eff6ff;
                background: #eff6ff;
              }

              &__disabled {
                pointer-events: none;
              }
            }
          }
        }
        .chart-container-secondary {
          .apexcharts-legend {
            div:nth-last-child(2),
            div:nth-last-child(1) {
              display: none !important;
            }
          }
        }
        .chart-container {
          position: relative;
          width: 97.6%;

          .apexcharts-xaxis .apexcharts-xaxis-label {
            font-size: 0.625rem;
            font-weight: 400;
            line-height: 0.875rem;
            font-family: 'Inter', sans-serif !important;
            color: $gray-12;
            &:nth-of-type(1) {
              transform: translateX(1.375rem);
            }
          }
          .vertical-line {
            height: 75%;
            width: 0.75rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            position: absolute;
          }
          .vertical-line-email {
            .timeline-label {
              width: 4.1875rem;
            }
          }
          .vertical-line-left {
            left: -1.1875rem;
            top: 1.375rem;
          }
          .vertical-line-right {
            right: -1.25rem;
            top: 1.375rem;

            .timeline-line {
              &:first-child {
                background: linear-gradient(
                  0deg,
                  #dbafff 0%,
                  #dbafff 100%
                ) !important;
              }
              &:last-child {
                background: #9eecb0;
              }
            }
          }

          .timeline-line {
            width: 2px;
            flex: 1.5;
            background-color: #60a5fa;
            position: relative;
            border-radius: 0.25rem;
          }

          .timeline-label {
            flex: 1;
            transform: rotate(-90deg);
            right: 0;
            display: flex;
            align-items: center;
            color: #9ca3af;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.125rem;
          }
        }
      }
    }
  }

  &--filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.25rem;

    .date-filter-select-wrap {
      width: 12.625rem;
      border-radius: 0.25rem;
      border: 1px solid $gray-23;
      background-color: $gray-19;
    }

    .bs-select-box {
      width: 10.1875rem;
      height: 2rem;

      border-radius: 4px;
      border: 1px solid $gray-26;
      background: $gray-19;

      gap: 0.5rem;

      &:focus,
      &:focus-within {
        outline: none;
        box-shadow: none;
        border: 1px solid $blue-11;
      }

      .bs-select-box-text {
        color: $blue-15;
        font-weight: $font-weight-medium;
        font-size: 0.75rem;
        line-height: 1.125rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;

        .select-icon {
          width: 1rem;
          height: 1rem;

          display: flex;
          justify-content: center;
          align-items: center;

          color: $gray-11;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .bs-select-box-icon-wrapper {
        justify-content: center;
        margin: 0;
        color: $gray-12;
      }
    }

    .reports-filter-divider {
      width: 0.125rem;
      height: 2rem;
      border-radius: 0.125rem;
      background: $gray-16;
    }

    .action-btn--export {
      margin-left: 0;
      min-width: 4.5625rem !important;
      width: 4.5625rem !important;
    }

    .export-dropdown-span {
      font-weight: 400;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .export-dropdown-view {
      display: flex;
      width: fit-content;
      padding: 0.75rem 0px;
      // height: 5.5rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      position: absolute;
      border: 2px solid $gray-16;
      border-radius: 6px;
      background-color: #ffffff;
      right: 1rem;
      top: 3rem;
      z-index: 2;
      border-radius: 4px;
      border: 1px solid $gray-13;
      background: #fff;
      box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
        8px 16px 56px -24px rgba(156, 157, 161, 0.24);

      .menu-item {
        padding: 0.375rem 0.75rem;
        color: $popover-arrow-color;
        &:hover {
          color: $blue-11;
          background-color: $blue-17;
        }
        .menu-text {
          font-weight: 500;
          line-height: 0.2rem;
        }
      }
    }
    .date-filter-select-wrap {
      min-width: 10.1875rem;
      max-width: 15.1875rem;
      height: 2rem;
      width: fit-content;
    }
  }

  .vertical-strock {
    border-radius: 5px;
    border: 1px solid $gray-16;
    background: #fff;
    width: 2px;
    height: 30px;
    flex-shrink: 0;
  }

  .graph-share-wrapper {
    padding: 0.375rem;
    &:hover {
      background-color: $blue-13;
      border-radius: 0.25rem;
      svg {
        color: $blue-11;
      }
    }
  }

  .export-dropdown-icon {
    color: $gray-15;
    height: 20px;
    width: 20px;
  }

  .export-cursor-pointer {
    cursor: pointer;
  }

  .prospects-widgets-container {
    .prospects-widgets-content {
      display: flex;
      gap: 20px;
      justify-content: space-between;
    }
    .segment-container {
      flex: 1;
      .segment-header {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        svg {
          width: 0.875rem;
          height: 0.875rem;
          color: #9ca3af;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            color: $blue-11;
          }
        }
      }
    }
    .grid-container {
      padding: 0.5rem 0.4375rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      row-gap: 0.25rem;
      border-radius: 0.25rem;
      outline: 1px solid $gray-26;
      background-color: $gray-1;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 90%;
        height: 0.0625rem;
        background-color: $gray-16;
        border-radius: 0.1875rem;
        top: 50%;
        left: 3.8%;
      }
    }

    .grid-item {
      position: relative;
      height: 4.125rem;
      text-align: center;
      border: 1px solid transparent;
    }

    .grid-item:nth-child(3n + 2):before,
    .grid-item:nth-child(3n + 2):after {
      content: '';
      position: absolute;
      top: 0.7188rem;
      bottom: 0;
      width: 1px;
      height: 2.6875rem;
      background-color: #f3f4f6;
    }

    .grid-item:nth-child(3n + 2):before {
      left: -5.5px;
    }
    .grid-item:nth-child(3n + 2):after {
      right: -5.5px;
    }

    .grid-container > .grid-item:last-child:before {
      border-right: none;
    }

    .label-container {
      gap: 0.25rem;
    }

    .label {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    .value-container {
      height: 1.25rem;
    }

    .value {
      color: $blue-15;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      line-height: 1.25rem;
    }
    .sub-value {
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin-left: 0.5rem;
    }
  }

  .emails-widgets-container {
    margin: 1.5rem;
    width: calc(100% - 3rem);

    .emails-widgets-header {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin-bottom: 0.5rem;

      svg {
        width: 0.875rem;
        height: 0.875rem;
        color: #9ca3af;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          color: $blue-11;
        }
      }
    }

    .emails-widgets-summary {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;

      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-26;
      background: $white;
    }
  }

  .sequences-report {
    padding: 0 1.5rem;

    .table-list {
      height: calc(100vh - 21rem) !important;

      .pagination-container {
        position: relative;
        z-index: -1000;
        .pagination-row {
          bottom: 1.5625rem;
          right: 1.5rem;
          z-index: 1;
          height: 1.75rem;
        }
      }
      .bs-table-content {
        position: relative;
        z-index: 2;
      }

      .table-organism-header {
        z-index: 2 !important;

        .fixed-th {
          position: sticky;
          left: 0;
          z-index: 1;
          width: 100%;
          background-color: #f9fafb;
          &.sequence-name-col {
            left: 2.7919rem;
          }
        }
      }
      .table-organism-body {
        tr > td {
          background: $white;
          transition: all 0.2s ease;
        }
        tr:hover {
          box-shadow: none !important;
          td {
            background-color: $gray-19;
          }
        }
      }

      .prospects-report-header-wrapper {
        tr > th {
          padding-left: 0.75rem;
          padding-right: 0.75rem;

          &:last-of-type {
            border-left: 1px solid $gray-13;
          }
        }
      }
      .report-header-wrapper {
        background: $gray-16;
        border: 1px solid $gray-13;
        tr {
          .opened {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 0rem;
              bottom: 0.5625rem;
              background: $gray-13;
              width: 0.125rem;
              height: 1.75rem;
            }
          }
          .replied,
          .unsubscribed {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              right: 0.1rem;
              bottom: 0.5625rem;
              background: $gray-13;
              width: 0.125rem;
              height: 1.75rem;
            }
          }

          .replied {
            &::after {
              right: 0.125rem;
            }
          }
          th:first-of-type {
            padding-left: 1.5rem !important;
          }
        }
      }
      .report-body-wrapper {
        height: 3.25rem;
        .text-container {
          width: auto;
        }
        tr > td {
          &:first-child {
            border-left: 1px solid $gray-13;
            padding-left: 1rem !important;
          }
          &:last-child {
            border-right: 1px solid $gray-13;
          }
        }
      }
      .email-report-body-wrapper {
        tr > td {
          &:first-child {
            padding-left: 1.5rem !important;
          }
        }
      }
      .report-body-wrapper > tr {
        .email-sent,
        .replied {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: 0.1rem;
            bottom: 0.5625rem;
            background: $gray-13;
            width: 0.125rem;
            height: 1.75rem;
          }
        }
        .opened {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 0px;
            bottom: 0.5625rem;
            background: $gray-13;
            width: 0.125rem;
            height: 1.75rem;
          }
        }
        .replied {
          &::after {
            right: 0.125rem;
          }
        }
        .sequence-owner,
        .unsubscribe {
          transition: 0.2s ease;
          background-color: $white;
        }
        &:hover {
          .sequence-owner,
          .unsubscribe {
            background-color: $gray-19;
          }
        }
      }
    }

    .react-bootstrap-table {
      .table {
        margin-bottom: 0 !important;
      }
      .table-list {
        position: relative;
        height: fit-content;
        margin-left: 1.7rem;
        margin-right: 1.3rem;
        .text-stack-directional-container {
          justify-content: left;
        }

        .table-organism-header {
          position: sticky;
          top: 0;
        }

        .react-bootstrap-table {
          overflow-x: auto;
        }

        .sequence-name {
          cursor: pointer;
          .text-container {
            overflow: hidden;
            text-overflow: ellipsis;
            color: $blue-11;
            width: auto !important;
          }
        }
        .sequence-owner {
          div {
            width: 7rem !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .sequence-avatar {
              background-color: $blue-11 !important;
              height: 1.5rem;
              width: 1.5rem;
              .text-primary {
                color: $white !important;
                font-size: 0.625rem;
              }
              .bs-avatar-string {
                bottom: -10%;
              }
            }
          }
        }

        .email-sent {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: -0.4rem;
            bottom: 0.575rem;
            background: $gray-13;
            width: 0.125rem;
            height: 2.6875rem;
          }
        }
      }
    }
  }

  .managers-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
  }

  .sequences-prospects-report {
    .table-list {
      height: calc(100vh - 21rem) !important;
    }
  }
}

.reports-filter-dropdown {
  &.transfer-overlay {
    padding: 0.125rem 0 0 0;
  }
  .transfer-container {
    padding: 0;
    border: 0.0625rem solid $gray-13;
    box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
      0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

    .transfer-body {
      .transfer-search {
        height: 2rem;
        padding: 0.375rem 1rem;
        border: 0.0625rem solid $gray-16;
      }

      .transfer-list {
        li {
          height: 2rem;
          padding: 0.375rem 1rem !important;

          line-height: 20px !important;

          &:hover,
          &.active {
            background: $blue-17;
          }

          .option-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .transfer-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 3rem;
        border: 0.0625rem solid $gray-13;

        .bs-checkbox-wrapper {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  &.sequence-filter {
    .transfer-container {
      width: 26.25rem !important;
      .transfer-body {
        padding-bottom: 0;
      }
    }
  }
}

.report-plan-based-restriction {
  width: 100%;
  height: calc(100vh - 7.375rem);

  overflow-y: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  .shd-empty-list {
    justify-content: flex-start;
    gap: 0;

    &--image {
      width: auto;
      height: auto;
    }

    &--body {
      gap: 0.5rem;
      max-width: 37.5rem;
      padding-bottom: 2rem;
    }

    &--title {
      text-align: center;
    }

    &--description {
      color: #6b7280;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 2rem;
    }

    .shd-empty-list--body {
      gap: 0;
      .shd-empty-list--description {
        margin-top: 0.5rem;
      }
    }
  }
}

.reports-popover {
  width: 17.8125rem;
  max-width: 17.8125rem;
  &.bs-popover-inner {
    border-radius: 0.25rem;
    border: 0.0625rem solid #f3f4f6;
    background: #fff;
    padding: 0.625rem;
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.25));
    .bs-popover-inner-content {
      padding: 0;
      .content {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;

        svg {
          color: #9ca3af;
        }
      }
      p {
        color: $blue-15;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        margin: 0;
        width: 14.625rem;

        a {
          color: $blue-11;
        }
      }
      .popover-close {
        width: 1rem;
        height: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.report-widget-cursor {
  cursor: default;
  color: $blue-15;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.report-widget-percentage {
  color: $blue-15;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.reports-widget-tooltip {
  .tooltip-inner {
    max-width: 14.875rem !important;
  }
}

.report-trend-widget-wrapper {
  width: fit-content;
}

.report-trend-widget-tooltip {
  .tooltip-inner {
    max-width: 10.875rem !important;
  }
}

.reports-countBy-tooltip {
  .tooltip-inner {
    max-width: 15.875rem !important;
  }
}

.share-graphical-reports-modal {
  .modal-dialog {
    max-width: 58.5rem;
  }
  .modal-body {
    .image-preview {
      width: 100%;
      .header-input {
        width: 15rem;
      }
      &__content {
        margin-top: 0.875rem;
        height: 28.5rem;
        border-radius: 0.25rem;
        padding: 1.625rem 1rem 0.5rem 1rem;
        .content-wrapper {
          border-radius: 0.5rem;
          border: 1px solid $gray-16;
          background-color: $gray-1;
          height: 25rem;

          .header-container {
            position: absolute;
            padding: 0.125rem 0.75rem;
            border-radius: 1.25rem;
            border: 1px solid $gray-16;
            background-color: $gray-1;
            top: -0.8125rem;
          }
          .statistics {
            margin-top: 1.625rem;
            &__card {
              .item > .content {
                .semibold-4 {
                  font-size: 1rem !important;
                }
                .reports-widget-percentage {
                  font-size: 0.625rem;
                }
                .report-widget-cursor {
                  font-size: 0.625rem;
                }
              }
              .vertical-stock {
                margin: 0 0.75rem;
                height: 2.5rem;
              }
            }
          }
          .image-content {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .img {
              width: 100%;
              display: flex;
              justify-content: center;
              position: relative;
              .chart-container {
                width: 93% !important;
                height: fit-content;
                .vertical-line {
                  height: 67%;
                  width: 0.625rem;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-around;
                  position: absolute;
                }
                .vertical-line-left {
                  left: 1rem;
                  top: 1.875rem;
                }
                .vertical-line-right {
                  right: 1rem;
                  top: 1.5rem;

                  .timeline-label {
                    margin: 1.25rem 0;
                  }

                  .timeline-line {
                    &:first-child {
                      background: linear-gradient(
                        0deg,
                        #dbafff 0%,
                        #dbafff 100%
                      ) !important;
                    }
                    &:last-child {
                      background: #9eecb0;
                    }
                  }
                }
                .timeline-line {
                  width: 2px;
                  flex: 1.5;
                  background-color: #60a5fa;
                  position: relative;
                  border-radius: 0.25rem;
                }
                .timeline-label {
                  flex: 1;
                  transform: rotate(-90deg);
                  right: 0;
                  display: flex;
                  align-items: center;
                  color: #9ca3af;
                  font-size: 0.75rem;
                  font-weight: 400;
                  line-height: 1.125rem;
                  margin: 0.5rem 0px;
                }

                .apexcharts-xaxis .apexcharts-xaxis-label {
                  font-size: 0.625rem;
                  font-weight: 400;
                  line-height: 0.875rem;
                  font-family: 'Inter', sans-serif !important;
                  color: $gray-12;
                  &:nth-of-type(1) {
                    transform: translateX(1.375rem);
                  }
                }
                .apexcharts-legend {
                  .apexcharts-inactive-legend {
                    display: none !important;
                  }
                }
                .apexchartsgraph-report {
                  width: 100% !important;
                  display: flex;
                  justify-content: center;
                  .apexcharts-svg {
                    width: 100% !important;
                  }
                }
              }
              .chart-container-secondary {
                width: 91% !important;
              }
              .chart-container-primary {
                .apexcharts-legend {
                  div:nth-last-child(1) {
                    display: none !important;
                  }
                }
              }
              .chart-container-tertiary {
                .apexcharts-legend {
                  .apexcharts-inactive-legend {
                    display: none !important;
                  }
                  div:first-child {
                    display: none !important;
                  }
                }
              }
            }
            .hide-numbers
              > .chart-container
              > .apexchartsgraph-report
              > .apexcharts-svg
              > .apexcharts-yaxis-label {
              display: none;
            }
          }
        }
      }
    }
  }

  .client-logo {
    height: 14px;
  }
}

.statistic-card {
  flex: 1;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  border: 1px solid #f3f4f6;
  background-color: $gray-1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.statistic-card.flex-2 {
    flex: 2;
  }
  &.statistic-card.flex-3 {
    flex: 3;
  }

  .vertical-stock {
    width: 0.0625rem;
    height: 100%;
    border-radius: 0.1875rem;
    background-color: #f3f4f6;
    margin: 0 2.2938rem;
  }
}

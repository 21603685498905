.prospect-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-grow: 1;

  .pagination-container {
    z-index: 0;
  }

  .dropdown-menu-custom {
    .dropdown-item-custom {
      i {
        font-size: 1.125rem;
        color: $blue-6;
      }
    }
  }

  .sm-table {
    .table-list {
      // height: calc(100vh - 11.5rem);
    }
  }

  .md-table {
    .table-list {
      // height: calc(100vh - 8.3rem);
    }
  }

  .smm-table {
    .table-list {
      // height: calc(100vh - 12rem);
    }
  }

  .mdd-table {
    .table-list {
      // height: calc(100vh - 14.5rem);
    }
  }

  // .is-email-sm-not-verified{
  //   .table-list{
  //     height: calc(100vh - 12.6rem);
  //   }
  // }
  // .is-email-md-not-verified{
  //   .table-list{
  //     height: calc(100vh - 10.25rem);
  //   }
  // }
  // .is-email-smm-not-verified{
  //   .table-list{
  //     height: calc(100vh - 13.15rem);
  //   }
  // }
  // .is-email-mdd-not-verified{
  //   .table-list{
  //     height: calc(100vh - 15.5rem);
  //   }
  // }

  // .is-email-sm-not-verified{
  //   .table-list{
  //     height: calc(100vh - 12.6rem);
  //   }
  // }
  // .is-email-md-not-verified{
  //   .table-list{
  //     height: calc(100vh - 10.25rem);
  //   }
  // }
  // .is-email-smm-not-verified{
  //   .table-list{
  //     height: calc(100vh - 13.15rem);
  //   }
  // }
  // .is-email-mdd-not-verified{
  //   .table-list{
  //     height: calc(100vh - 15.5rem);
  //   }
  // }

  .table-list {
    overflow: scroll;
    flex-grow: 1;

    .react-bootstrap-table .table-organism {
      .table-organism-header {
        background: $gray-16;

        tr > th {
          border-top: 1px solid $gray-13;
          border-bottom: 1px solid $gray-13;

          &:first-of-type {
            border-left: 1px solid $gray-13;
          }

          &:last-of-type {
            border-right: 1px solid $gray-13;
          }
        }
      }

      .table-organism-body td {
        padding: 0.5625rem 0.75rem !important;

        &:first-of-type {
          border-left: 0.0625rem solid $gray-13;
          vertical-align: top !important;
          padding-left: 1.875rem !important;

          .bs-checkbox-wrapper {
            margin-top: 0.125rem;
          }
        }

        &:last-of-type {
          border-right: 0.0625rem solid $gray-13;
        }
      }
    }

    .bs-table-content {
      background-color: $gray-19;
      padding: 0px 1.5rem;
    }

    .prospect-body-wrapper > tr {
      background-color: $gray-1;

      &:hover {
        background: $gray-19;
      }
    }

    .selection-cell {
      padding-right: 0px;
      width: 3.125rem !important;
    }

    .name-field {
      width: 20rem;

      .contact-field-name-container {
        .email-field {
          color: $gray-12 !important;
          font-size: 0.75rem !important;
          font-weight: $font-weight-normal !important;
        }
      }
    }

    .bs-mr-6 {
      margin-right: 0.375rem;
    }

    .sequence-detail {
      max-width: 20%;

      .sequence-name-wrap {
        max-width: 13.0625rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .sequence-name {
          color: $blue-11;
          cursor: pointer;
        }
      }
    }

    .latest-status {
      min-width: 9rem;
    }

    .last-activity {
      min-width: 8.3125rem !important;
    }

    .tag-detail {
      width: 18%;
    }

    .created-col {
      .created-day {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: $gray-8;
      }

      .created-by {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: $blue-6;
      }
    }

    .owner-wrap {
      width: 1.75rem;
    }
  }

  .empty-prospect {
    width: 100%;
    height: 100%;

    span {
      width: 9.5rem;

      .add-prospect-inner {
        display: flex;

        .add-prospect-btn {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: $blue-11;
          margin-right: 0;
        }

        .dropdown-custom {
          margin-left: 1px;

          .dropdown-toggle-custom {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background: $blue-11;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .prospect-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $gray-19;

    .prospect-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.375rem 1.5rem;

      .bulk-action-button {
        .bulk-action-button-icon {
          color: $blue-6;
        }

        .bulk-action-button-text {
          color: $blue-6;
        }
      }

      .prospect-search-bar-and-filter {
        display: flex;
        align-items: center;
        width: 87%;
        gap: 0.8rem;

        .search-bar {
          form {
            .bs-input-affix-wrapper {
              background: $gray-1;
            }

            .bs-input {
              background: $gray-1;

              &::placeholder {
                color: $gray-23;
              }
            }
          }
        }

        .filter-border {
          width: 0.125rem;
          height: 2rem;
          background: $gray-23;
          border-radius: 0.125rem;
        }

        .prospect-filter-icon-container {
          width: 2rem;
          height: 2rem;
          padding: 0.3125rem !important;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;
          border-radius: 0.25rem;

          transition: all 0.2s;
          cursor: pointer;

          i {
            font-size: 1.25rem;
            color: $gray-15;
          }

          &:hover {
            background-color: $blue-13 !important;

            i {
              color: $blue-11;
            }
          }
        }

        .bulk-action-image-icon {
          background-repeat: no-repeat;
          background-position: center center;
          padding: 0.375rem;
          width: 2.125rem;
          height: 2rem;
          cursor: pointer;

          &:hover {
            background: $blue-13;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 0.225rem;
            padding: 0.375rem;
          }
        }
      }

      .prospect-filter-icon-container {
        display: flex;
        padding: 0.313rem;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background-color: $blue-13;
        }

        .icon {
          font-size: 1.25rem;

          &:hover {
            color: $blue-11;
          }
        }
      }

      .prospect-export {
        padding-top: 6.5px;

        .sh-chevron-down {
          &::before {
            content: '';
            position: absolute;
            background: $white;
            right: 0;
            width: 0.063rem;
            height: 100%;
          }
        }
      }
    }

    .react-horizontal-scrolling-menu--wrapper {
      padding-left: 1.6875rem;
    }

    .prospect-banner {
      padding-right: 0.5rem;
      margin: 0 1.4625rem 0 1.5rem;

      .prospect-selected-row-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5625rem 0;

        .divider-vertical {
          height: 1.25rem;
          width: 0.063rem;
          background: $gray-11;
          margin: 0px 0.75rem;
        }
      }

      .prospect-selected-row-info-waring {
        background: $yellow-11;
      }

      .prospect-selected-row-info-danger {
        background: $red-17;
      }
    }
  }
}

.add-to-sequence-container {
  .add-to-sequence-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .modal-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .unsubscribeTextWarning {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.688rem;
    line-height: 1.25rem;
    color: $gray-8;
  }

  .unsubscribeTextWarning span:first-of-type {
    margin-top: 0.125rem;
  }
}

.sequence-select-dropdown {
  li:has(.current-sequence) {
    background-color: #eff6ff;
  }
  li .current-sequence {
    color: $blue-11;
  }
}

.add-sequence-prospect-modal {
  .modal-dialog {
    .modal-content {
      .modal-content-container {
        .bs-modal-header {
          border: none;
          max-height: unset;

          svg {
            color: $blue-11;
            width: 1.5rem;
            height: 1.5rem;
          }

          h2 {
            margin-left: 0.625rem;
            line-height: normal;
            font-size: 1rem;
            margin-bottom: 0;
            font-weight: $font-weight-semibold;
          }
        }

        .bs-modal-body {
          padding-top: 0.313rem;

          .modal-text {
            color: $gray-15;
            font-weight: $font-weight-semibold;
            font-size: 0.75rem;
          }
        }

        .bs-modal-footer {
          max-height: unset;
          padding: 1.25rem 1.5rem;

          .bs-modal-footer-action-buttons {
            margin: 0;

            .btn-ghost-primary {
              min-width: 6.25rem;
              border-color: $gray-13;
              color: $gray-15;
              text-transform: capitalize;
              font-weight: 500;
            }

            .btn-solid-primary {
              min-width: 6.25rem;
              margin-left: 1rem !important;
              background-color: $blue-11;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.email-verification-modal {
  .modal-dialog {
    max-width: 31.25rem;
  }

  .modal-header {
    .close {
      position: absolute;
      top: -0.5rem;
      right: 0.5rem;
    }
  }

  .modal-body {
    padding: 0 1.5rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
  }

  .email-verification {
    flex: 1;
    display: flex;
    flex-direction: column;

    .valid-contact {
      flex: 0 0;
    }

    .risky-contact {
      flex: 0 0;
    }

    .verification-error {
      align-self: center;
      min-width: 18rem;
    }
  }
}

.email-verification-purchase-action-required-modal {
  .modal-dialog {
    max-width: 30rem;
  }

  .modal-body.bs-modal-body {
    padding: 0;
  }
}

.tag-modal {
  .modal-dialog {
    max-width: 28.12rem;
  }

  .modal-body {
    padding: 0 1.5rem;
    height: 16.5rem;
    display: flex;
    flex-direction: column;
  }
}

.unsubscribe-prospect-modal {
  .modal-dialog {
    max-width: 28.12rem;
  }

  .modal-body {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
  }
}

.action-states-modal {
  margin-top: 0;

  .modal-dialog-centered {
    align-items: center;
  }

  .modal-dialog {
    max-width: 22.9375rem;
  }

  .modal-header {
    min-height: 0px;
  }

  .modal-body {
    .bg-blue-container {
      background: $blue-17;
      border-radius: 0.25rem;
      padding: 1rem;
      margin-top: -0.9375rem;
    }

    .animation-container {
      height: 8.75rem;
    }
  }

  .success-checkmark {
    width: 5rem;
    height: 7.188rem;
    margin: 0 auto;

    .check-icon {
      width: 5rem;
      height: 5rem;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4caf50;

      &::before {
        top: 0.188rem;
        left: -2px;
        width: 1.875rem;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 1.875rem;
        width: 3.75rem;
        transform-origin: 0 50%;
        border-radius: 0 6.25rem 6.25rem 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: '';
        height: 6.25rem;
        position: absolute;
        background: #ffffff;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 0.313rem;
        background-color: #4caf50;
        display: block;
        border-radius: 0.125rem;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(76, 175, 80, 0.5);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: #ffffff;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
}

.prospect-details-modal {
  animation-name: moveInRight;
  animation-duration: 0.3s;
  animation-timing-function: linear;

  .modal-content {
    min-width: 72vh;
    max-height: 100%;
    height: 100vh;
    border-radius: 0px !important;

    .modal-content-container > .bs-modal-body {
      max-height: 100% !important;
    }
  }

  .modal-header {
    position: relative;
    align-self: flex-end;
    border-bottom-color: transparent;
    padding: 0 1rem;
    margin: 0;
    max-height: 2.5rem;
    min-height: 2rem;

    .btn-solid.close {
      width: 2.5rem !important;
      height: 2.75rem !important;
      position: absolute;
      left: -2.5rem;
      background-color: $popover-arrow-color !important;
      border-bottom-right-radius: 0px;
      color: $gray-1 !important;
      padding: 0px !important;
      opacity: 0.800000011920929 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-dialog {
    max-width: 70vh;
    max-height: 1024px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;

    .modal-body {
      padding: 0 2rem 0px 1.5rem;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 4rem) !important;
    }
  }

  .prospect-details-container {
    display: flex;
    flex-direction: column;

    .prospect-details {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 2.25rem;

      .prospect-info {
        .prospect-name {
          font-size: 1rem;
          font-weight: 600;
          color: $gray-9;
          margin-bottom: 0.5rem;

          .cs-avatar {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .dropdown-custom {
          .btn-solid {
            padding: 0px;
          }
        }

        .prospect-email {
          font-size: 14px;
          font-weight: 400;
          color: $gray-8;
          margin-bottom: 0.5rem;
        }

        .prospect-timezone {
          font-size: 14px;
          font-weight: 400;
          color: $gray-7;
          margin-bottom: 0.5rem;
        }

        .prospect-tag-container {
          display: flex;
          align-items: center;

          .prospect-tag-icon {
            margin-right: 1rem;
          }

          .prospect-tag-row {
            flex-wrap: wrap;
            row-gap: 0.5rem;
          }

          .prospect-add-tag {
            color: $blue-11;
            cursor: pointer;
            letter-spacing: 0em;
            text-align: left;
            height: 1.5625rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .prospect-info-action {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .action-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: auto;

            button {
              padding: 1px 16px 1px 16px;
            }
          }
        }
      }
    }

    .prospect-tab-action {
      position: relative;
      height: 100%;

      .bs-tabs {
        height: 100%;

        .bs-tabs-content-holder {
          height: 100%;

          .bs-tabs-content {
            height: 100%;

            .table-list {
              height: calc(100vh - 14rem);
              overflow-y: auto;

              .react-bootstrap-table {
                .table-organism-body > tr {
                  td {
                    vertical-align: top !important;

                    .dropdown-custom {
                      margin-top: -0.25rem;

                      button {
                        padding: 0px;
                      }
                    }
                  }

                  td:first-of-type {
                    padding-left: 0px;
                  }

                  td:last-child {
                    padding-right: 0px;
                  }

                  &:hover {
                    background-color: transparent;
                    box-shadow: none;
                  }
                }
              }
            }

            .form-body {
              height: calc(100vh - 17.938rem);
              overflow-y: auto;
              overflow-x: hidden;
              @media only screen and (min-width: 1200px) and (max-width: 1400px) {
                height: calc(100vh - 19rem);
              }
            }
          }
        }
      }

      .empty-sequence {
        height: 100%;
        width: 100%;
      }

      .empty-list {
        &__inner {
          font-size: 0.75rem;
          width: 87%;
        }

        &__content {
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.prospect-modal {
    .modal-dialog {
      max-height: max-content;

      .modal-content {
        .modal-content-container {
          .modal-header {
            min-height: unset;
            max-height: unset;
            padding-top: 32px;

            .close {
              top: 0;

              svg {
                margin-top: 0 !important;
              }
            }
          }
          .bs-modal-body {
            .prospect-details-container {
              .prospect-form-field {
                margin: 0 !important;
              }

              .prospect-modal-footer {
                box-shadow: unset;
                .save-btn {
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-prospect-manually-modal-spinner {
  height: 12.5rem;
  align-items: center;
}

.download-sample-file {
  width: 75%;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(100vw);
  }
}

.export-button-wrapper {
  margin-left: 10px;
  max-width: 100px;

  button {
    width: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  img {
    margin-bottom: 2px;
  }
}

.add-prospect-button {
  padding-left: 0.5rem;

  .add-prospect-inner {
    display: flex;
    min-width: 11.5rem;

    .add-prospect-btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background: $blue-11;

      height: 2rem;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;

      border: none;

      padding: 0.25rem 0.9375rem;
    }

    .dropdown-custom {
      display: flex;
      background: #1d4ed8;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      &::before {
        content: '';

        display: inline-block;

        width: 0.0625rem;
        height: 2rem;
        background: rgba(255, 255, 255, 0.2);
      }

      .dropdown-toggle-custom {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: $blue-11;

        border: none;

        padding: 0.5rem;
        height: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          display: none;
        }
      }
    }
  }
}

.sequence-name-popover.popover {
  max-width: 34rem !important;
}

.sequence-name-popover {
  .data-card {
    &__header {
      display: flex;

      .sequence-title-wrap {
        width: 12.75rem;
      }

      .status-title-wrap {
        width: 7.125rem;
        padding-left: 0.5rem;
      }

      .outcome-title-wrap {
        width: 11.5rem;
        padding-left: 1rem;
      }
    }

    &__content {
      overflow: scroll;
      overflow-x: hidden;
      max-height: 9rem;

      &::-webkit-scrollbar {
        width: 0.225rem;
      }

      .prospect-sequence {
        display: flex;
        align-items: center;
        margin: 0px;

        .sequence-name {
          padding: 0.5rem 1rem 0.5rem 0px;
          width: 12.75rem;
          color: $blue-11;
          cursor: pointer;
        }

        .sequence-status-badge {
          width: 7.8rem;
          padding-left: 0.3rem;
        }

        .select-outcome {
          padding-left: 1rem;

          .prospect-outcome-select {
            height: 28px;
            .cs {
              &__single-value {
                margin-left: 0px;
              }

              &__control {
                border-width: 0px !important;
                background-color: transparent !important;
              }

              &__indicator-separator {
                display: none;
              }

              &__indicator {
                color: $gray-15;
                padding-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.custom-content-with-form {
  position: absolute;
  bottom: 6px;
  padding: 1rem;

  .map-fields-step {
    display: flex;
    align-items: center;

    .step-label {
      width: 150px;
    }
  }
}

.prospect-list-content--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 0.75rem;

  margin: 1.5rem 1.5rem 1rem 1.5rem;

  &__left {
    flex: 1;
  }
  &__left,
  &__right {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  &__right {
    min-width: 16.125rem;
    gap: 1.25rem;
    .add-prospect-button {
      padding: 0;
      .add-prospect-inner {
        min-width: unset;
      }
    }
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    cursor: pointer;

    &.selected,
    &:hover {
      .filter-icon {
        svg {
          color: $blue-11;
        }
      }
      p {
        color: $blue-11;
      }
    }

    .filter-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 1.25rem;
      height: 1.25rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: #6b7280;
      }
    }

    p {
      margin: 0;
      color: #6b7280;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 1rem;
      height: 1rem;
      padding: 0rem 0.25rem;

      border-radius: 1.25rem;
      background: #2563eb;

      color: #eff6ff;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem;
    }
  }

  &__divider {
    width: 0.125rem;
    height: 1.75rem;

    border-radius: 0.0625rem;
    background: #e5e7eb;
  }

  &__saved-filter {
    display: flex;
    flex: 1;
    .saved-filters {
      display: flex;
      flex: 1;
      &__items {
        display: flex;
        flex: 1;
        gap: 0.5rem;
      }
      &__item {
        padding: 0.375rem 0.5rem;
        border-radius: 1.5rem;
        outline: 1px solid $gray-13;
        transition: all 0.2s ease;

        position: relative;

        // white-space: nowrap;

        &:hover,
        &-active,
        &:focus-within {
          transition: all 0.2s ease;
          background-color: $blue-13;
          color: $blue-31;
          border: none;
          outline: $blue-13;
        }

        &-count {
          background-color: $blue-31;
          padding: 0 0.25rem;
          color: $blue-17;
          border-radius: 1.25rem;
          min-width: 1rem;
          text-align: center;
        }

        .view-list {
          &__btn {
            padding: 0.375rem 0.5rem;
          }
          &__popup {
            position: absolute;
            top: 2.25rem;
            left: 0;
            width: 17.5rem;
            height: auto;
            max-height: 25rem;
            overflow-y: auto;
            padding: 0.75rem 0rem;
            border-radius: 4px;
            border: 1px solid $gray-13;
            box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
              8px 16px 56px -24px rgba(156, 157, 161, 0.24);
            background-color: $gray-1;
            z-index: 99;

            &-input {
              margin-bottom: 0.75rem;
              .shd-search-input {
                width: 100%;
                border-radius: 0;
                border-left: none;
                border-right: none;
                border-color: #f3f4f6 !important;
                background-color: #f9fafb;

                .input {
                  flex-grow: 1;
                  input {
                    background-color: #f9fafb;
                  }
                }
                .cross-icon {
                  margin-left: auto;
                }
              }
            }
            &-items {
              // padding: 0.5rem 0rem;

              // &.show-border-bottom {
              //   border-bottom: 1px solid #f3f4f6;
              // }
            }
            &-item-header {
              padding: 0.375rem 1rem;
              // border-bottom: 1px solid #f3f4f6;
            }
            &-items-list {
              background: rgba(249, 250, 251, 0.6);
              border-top: 1px solid #f3f4f6;
              border-bottom: 1px solid #f3f4f6;
              padding: 0.5rem 0px;
            }
            &-item-active {
              background-color: #eff6ff;
            }
            &-item {
              height: 2rem;
              padding: 0.375rem 1rem;
              transition: all 0.2s;

              .pin-unpin-btn {
                width: 1.375rem;
                height: 1.375rem;

                display: flex;
                align-items: center;
                padding: 0.25rem;

                border-radius: 1.5rem;
                transition: all 0.2s ease;

                &:hover {
                  background: $white;
                }
              }

              &-action {
                display: none;
              }

              &:hover {
                background-color: #eff6ff;

                .view-list__popup-item-action {
                  display: flex !important;

                  .shd-avatar--sm {
                    width: 1.25rem;
                    height: 1.25rem;
                    border-radius: 58.333px;
                    border: 0.833px solid #dbeafe;
                    background-color: #eff6ff;
                    padding: 0.125rem;

                    span {
                      font-size: 0.5208rem !important;
                    }
                  }

                  .dropdown-custom {
                    button {
                      padding: 0;
                    }
                    .icon-hover {
                      width: 0.875rem !important;
                      height: 0.875rem !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.saved-filer-edit-modal {
  .modal-dialog {
    max-width: 22.0625rem;
  }
  .modal-body {
    .edit-view-input {
      .shd-input {
        &--label {
          display: none;
        }
        .input {
          width: 100%;
        }
      }
    }
  }
}

.prospect-table-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: #f9fafb;
  display: flex;
  position: relative;

  .prospect-table-empty-ui {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      margin: 1rem 0rem 0.5rem 0rem;

      color: #1f2937;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    p {
      margin: 0;
      color: #6b7280;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.verification-status-icon {
  font-weight: $font-weight-medium;
  line-height: 1.125rem;
}

.verification-sub-status-icon {
  font-weight: $font-weight-medium;
  line-height: 1.125rem;

  span {
    flex: 1 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .verification-sub-status-type {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    padding-left: 0;

    .dot {
      width: 0.25rem;
      min-width: 0.25rem;
      height: 0.25rem;
      border-radius: 0.125rem;

      &.valid {
        background-color: #34d399;
      }
      &.bad {
        background-color: #f87171;
      }
      &.risky {
        background-color: #f59e0b;
      }
    }
  }
}


/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.bs-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: $text-14;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
  .bs-steps-item {
    &:not(.bs-steps-item-active) {
      > .bs-steps-item-container[role='button'] {
        cursor: pointer;
        .bs-steps-item-title {
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
        }
        .bs-steps-item-subtitle {
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
        }
        .bs-steps-item-description {
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
        }
        .bs-steps-item-icon {
          .bs-steps-icon {
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
          }
        }
        &:hover {
          .bs-steps-item-title {
            color: $blue-6;
          }
          .bs-steps-item-subtitle {
            color: $blue-6;
          }
          .bs-steps-item-description {
            color: $blue-6;
          }
        }
      }
      &:not(.bs-steps-item-process) {
        > .bs-steps-item-container[role='button'] {
          &:hover {
            .bs-steps-item-icon {
              border-color: $blue-6;
              .bs-steps-icon {
                color: $blue-6;
              }
            }
          }
        }
      }
    }
  }
  &:not(.bs-steps-vertical) {
    .bs-steps-item-custom {
      .bs-steps-item-icon {
        width: auto;
        background: none;
      }
    }
  }
}
.bs-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
  &:last-child {
    flex: none;
    > .bs-steps-item-container {
      > .bs-steps-item-tail {
        display: none;
      }
      > .bs-steps-item-content {
        > .bs-steps-item-title {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
.bs-steps-item-container {
  outline: none;
}
.bs-steps-item-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: $text-16;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
  > .bs-steps-icon {
    position: relative;
    color: $blue-6;
    line-height: 1;
  }
}
.bs-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.bs-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
  &::after {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: #f0f0f0;
    border-radius: 1px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    content: '';
  }
}
.bs-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  font-size: $text-16;
  line-height: $text-style-3-line-height;
  &::after {
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: '';
  }
}
.bs-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  font-weight: $font-weight-normal;
  font-size: $text-14;
}
.bs-steps-item-description {
  font-size: $text-14;
}
.bs-steps-item-wait {
  .bs-steps-item-icon {
    background-color: $gray-1;
    border-color: $gray-6;
    > .bs-steps-icon {
      color: $gray-6;
      .bs-steps-icon-dot {
        background: $gray-6;
      }
    }
  }
  > .bs-steps-item-container {
    > .bs-steps-item-content {
      > .bs-steps-item-title {
        color: $gray-7;
        &::after {
          background-color: $gray-4;
        }
      }
      > .bs-steps-item-description {
        color: $gray-7;
      }
    }
    > .bs-steps-item-tail {
      &::after {
        background-color: $gray-4;
      }
    }
  }
}
.bs-steps-item-process {
  .bs-steps-item-icon {
    background-color: $gray-1;
    border-color: $blue-6;
    background: $blue-6;
    > .bs-steps-icon {
      color: $gray-1;
      .bs-steps-icon-dot {
        background: $blue-6;
      }
    }
  }
  > .bs-steps-item-container {
    > .bs-steps-item-content {
      > .bs-steps-item-title {
        color: $gray-9;
        font-weight: $font-weight-semibold;
        &::after {
          background-color: $gray-4;
        }
      }
      > .bs-steps-item-description {
        color: $gray-7;
      }
    }
    > .bs-steps-item-tail {
      &::after {
        background-color: $gray-4;
      }
    }
  }
  .bs-steps-item-title {
    font-weight: 500;
  }
}
.bs-steps-item-finish {
  .bs-steps-item-icon {
    background-color: $gray-1;
    border-color: $blue-6;
    > .bs-steps-icon {
      color: $blue-6;
      .bs-steps-icon-dot {
        background: $blue-6;
      }
    }
  }
  > .bs-steps-item-container {
    > .bs-steps-item-content {
      > .bs-steps-item-title {
        color: $gray-8;
        &::after {
          background-color: $blue-6;
        }
      }
      > .bs-steps-item-description {
        color: $gray-7;
      }
    }
    > .bs-steps-item-tail {
      &::after {
        background-color: $blue-6;
      }
    }
  }
}
.bs-steps-item-error {
  .bs-steps-item-icon {
    background-color: $gray-1;
    border-color: $red-6;
    > .bs-steps-icon {
      color: $red-6;
      .bs-steps-icon-dot {
        background: $red-6;
      }
    }
  }
  > .bs-steps-item-container {
    > .bs-steps-item-content {
      > .bs-steps-item-title {
        color: $red-6;
        &::after {
          background-color: #f0f0f0;
        }
      }
      > .bs-steps-item-description {
        color: $red-6;
      }
    }
    > .bs-steps-item-tail {
      &::after {
        background-color: #f0f0f0;
      }
    }
  }
}
.bs-steps-item.bs-steps-next-error {
  .bs-steps-item-title {
    &::after {
      background: $red-6;
    }
  }
}
.bs-steps-item-disabled {
  cursor: not-allowed;
}
.bs-steps-horizontal {
  &:not(.bs-steps-label-vertical) {
    .bs-steps-item {
      margin-right: 16px;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
        .bs-steps-item-title {
          padding-right: 0;
        }
      }
    }
    .bs-steps-item-tail {
      display: none;
    }
    .bs-steps-item-description {
      max-width: 140px;
      white-space: normal;
    }
  }
  &.bs-icon-stepper {
    .bs-steps-item {
      .bs-steps-item-container {
        .bs-steps-item-content {
          .bs-steps-item-title {
            line-height: $text-style-5-line-height;
          }
        }
      }
    }
  }
}
.bs-steps-item-custom {
  .bs-steps-item-icon {
    height: auto;
    background: none;
    border: 0;
    > .bs-steps-icon {
      // top: 0px;
      // left: 0.5px;
      // width: 32px;
      // height: 32px;
      font-size: $text-16;
      line-height: 1.625rem !important;
      i {
        line-height: 1.625rem !important;
      }
    }
  }
}
.bs-steps-item-custom.bs-steps-item-process {
  .bs-steps-item-icon {
    > .bs-steps-icon {
      color: $blue-6;
    }
  }
}
.bs-steps-small.bs-steps-horizontal {
  &:not(.bs-steps-label-vertical) {
    .bs-steps-item {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.bs-steps-small {
  .bs-steps-item-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    font-size: $text-12;
    border-radius: 24px;
  }
  .bs-steps-item-title {
    padding-right: 12px;
    font-size: $text-14;
    line-height: $text-style-3-line-height;
    &::after {
      top: 12px;
    }
  }
  .bs-steps-item-description {
    font-size: $text-14;
  }
  .bs-steps-item-tail {
    top: 8px;
  }
  .bs-steps-item-custom {
    .bs-steps-item-icon {
      width: inherit;
      height: inherit;
      line-height: inherit;
      background: none;
      border: 0;
      border-radius: 0;
      > .bs-steps-icon {
        font-size: $text-18;
        line-height: 1rem;
        -webkit-transform: none;
        transform: none;
      }
    }
  }
}
.bs-steps-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .bs-steps-item {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow: visible;
  }
  .bs-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .bs-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .bs-steps-item-title {
    line-height: 32px;
  }
  .bs-steps-item-description {
    padding-bottom: 12px;
  }
  > .bs-steps-item {
    > .bs-steps-item-container {
      > .bs-steps-item-tail {
        position: absolute;
        top: 0;
        left: 16px;
        width: 1px;
        height: 100%;
        padding: 38px 0 6px;
        &::after {
          width: 1px;
          height: 100%;
        }
      }
      > .bs-steps-item-content {
        > .bs-steps-item-title {
          &::after {
            display: none;
          }
        }
      }
    }
    &:not(:last-child) {
      > .bs-steps-item-container {
        > .bs-steps-item-tail {
          display: block;
        }
      }
    }
  }
}
.bs-steps-vertical.bs-steps-small {
  .bs-steps-item-container {
    .bs-steps-item-tail {
      position: absolute;
      top: 0;
      left: 12px;
      padding: 30px 0 6px;
    }
    .bs-steps-item-title {
      line-height: 24px;
    }
  }
}
.bs-steps-rtl.bs-steps-vertical {
  .bs-steps-item-icon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  > .bs-steps-item {
    > .bs-steps-item-container {
      > .bs-steps-item-tail {
        right: 16px;
        left: auto;
      }
    }
  }
}
.bs-steps-rtl.bs-steps-vertical.bs-steps-small {
  .bs-steps-item-container {
    .bs-steps-item-tail {
      right: 12px;
      left: auto;
    }
  }
}
.bs-steps-label-vertical {
  .bs-steps-item {
    overflow: visible;
  }
  .bs-steps-item-tail {
    margin-left: 58px;
    padding: 3.5px 24px;
  }
  .bs-steps-item-content {
    display: block;
    width: 116px;
    margin-top: 8px;
    text-align: center;
  }
  .bs-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }
  .bs-steps-item-title {
    padding-right: 0;
    padding-left: 0;
    &::after {
      display: none;
    }
  }
  .bs-steps-item-subtitle {
    display: block;
    margin-bottom: 4px;
    margin-left: 0;
    line-height: 1.5715;
  }
}
.bs-steps-label-vertical.bs-steps-small {
  &:not(.bs-steps-dot) {
    .bs-steps-item-icon {
      margin-left: 46px;
    }
  }
}
.bs-steps-dot {
  .bs-steps-item-title {
    line-height: 1.5715;
  }
  .bs-steps-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 64px;
    padding: 0;
    &::after {
      width: calc(100% - 20px);
      height: 3px;
      margin-left: 12px;
    }
  }
  .bs-steps-item {
    &:first-child {
      .bs-steps-icon-dot {
        left: 2px;
      }
    }
  }
  .bs-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: transparent;
    border: 0;
    .bs-steps-icon-dot {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      &::after {
        position: absolute;
        top: -12px;
        left: -26px;
        width: 60px;
        height: 32px;
        background: rgba(0, 0, 0, 0.001);
        content: '';
      }
    }
  }
  .bs-steps-item-content {
    width: 140px;
  }
  .bs-steps-item-process {
    .bs-steps-item-icon {
      position: relative;
      top: -1px;
      width: 10px;
      height: 10px;
      line-height: 10px;
    }
  }
}
.bs-steps-dot.bs-steps-small {
  .bs-steps-item-title {
    line-height: 1.5715;
  }
  .bs-steps-item-tail {
    top: 2px;
    width: 100%;
    margin: 0 0 0 70px;
    padding: 0;
    &::after {
      width: calc(100% - 20px);
      height: 3px;
      margin-left: 12px;
    }
  }
  .bs-steps-item {
    &:first-child {
      .bs-steps-icon-dot {
        left: 2px;
      }
    }
  }
  .bs-steps-item-icon {
    width: 8px;
    height: 8px;
    margin-left: 67px;
    padding-right: 0;
    line-height: 8px;
    background: transparent;
    border: 0;
    .bs-steps-icon-dot {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      &::after {
        position: absolute;
        top: -12px;
        left: -26px;
        width: 60px;
        height: 32px;
        background: rgba(0, 0, 0, 0.001);
        content: '';
      }
    }
  }
  .bs-steps-item-content {
    width: 140px;
  }
  .bs-steps-item-process {
    .bs-steps-item-icon {
      position: relative;
      top: -1px;
      width: 10px;
      height: 10px;
      line-height: 10px;
    }
  }
}
.bs-steps-vertical.bs-steps-dot {
  .bs-steps-item-icon {
    margin-top: 8px;
    margin-left: 0;
    background: none;
  }
  .bs-steps-item {
    > .bs-steps-item-container {
      > .bs-steps-item-tail {
        top: 2px;
        left: -9px;
        margin: 0;
        padding: 22px 0 4px;
      }
    }
    &:first-child {
      .bs-steps-icon-dot {
        left: 0;
      }
    }
  }
  .bs-steps-item-process {
    .bs-steps-icon-dot {
      left: -2px;
    }
  }
}
.bs-steps-navigation {
  padding-top: 12px;
  .bs-steps-item {
    overflow: visible;
    text-align: center;
    &:not(.bs-steps-item-active) {
      .bs-steps-item-container[role='button'] {
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
    }
    &:last-child {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      &::after {
        display: none;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 100%;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -14px;
      margin-left: -2px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: none;
      border-left: none;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      content: '';
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: inline-block;
      width: 0;
      height: 2px;
      background-color: $blue-6;
      -webkit-transition: width 0.3s, left 0.3s;
      transition: width 0.3s, left 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      content: '';
    }
  }
  .bs-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    text-align: left;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    .bs-steps-item-content {
      max-width: auto;
    }
    .bs-steps-item-title {
      max-width: 100%;
      padding-right: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::after {
        display: none;
      }
    }
  }
  .bs-steps-item.bs-steps-item-active {
    &::before {
      left: 0;
      width: 100%;
    }
  }
}
.bs-steps-navigation.bs-steps-small {
  .bs-steps-item-container {
    margin-left: -12px;
  }
}
.bs-stepper-pane.fade.active {
  visibility: visible;
  opacity: 1;
}
.bs-stepper {
  .content.fade.active {
    visibility: visible;
    opacity: 1;
  }
  &:not(.vertical) {
    .bs-stepper-pane.dstepper-none {
      display: none;
    }
    .content.dstepper-none {
      display: none;
    }
  }
}
@media (max-width: 480px) {
  .bs-steps-horizontal.bs-steps-label-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .bs-steps-item {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      overflow: visible;
    }
    .bs-steps-item-icon {
      float: left;
      margin-right: 16px;
    }
    .bs-steps-item-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }
    .bs-steps-item-title {
      line-height: 32px;
    }
    .bs-steps-item-description {
      padding-bottom: 12px;
    }
    > .bs-steps-item {
      > .bs-steps-item-container {
        > .bs-steps-item-tail {
          position: absolute;
          top: 0;
          left: 16px;
          width: 1px;
          height: 100%;
          padding: 38px 0 6px;
          &::after {
            width: 1px;
            height: 100%;
          }
        }
        > .bs-steps-item-content {
          > .bs-steps-item-title {
            &::after {
              display: none;
            }
          }
        }
      }
      &:not(:last-child) {
        > .bs-steps-item-container {
          > .bs-steps-item-tail {
            display: block;
          }
        }
      }
    }
  }
  .bs-steps-horizontal.bs-steps-label-horizontal.bs-steps-small {
    .bs-steps-item-container {
      .bs-steps-item-tail {
        position: absolute;
        top: 0;
        left: 12px;
        padding: 30px 0 6px;
      }
      .bs-steps-item-title {
        line-height: 24px;
      }
    }
  }
  .bs-steps-rtl.bs-steps-horizontal.bs-steps-label-horizontal {
    .bs-steps-item-icon {
      float: right;
      margin-right: 0;
      margin-left: 16px;
    }
    > .bs-steps-item {
      > .bs-steps-item-container {
        > .bs-steps-item-tail {
          right: 16px;
          left: auto;
        }
      }
    }
  }
  .bs-steps-rtl.bs-steps-horizontal.bs-steps-label-horizontal.bs-steps-small {
    .bs-steps-item-container {
      .bs-steps-item-tail {
        right: 12px;
        left: auto;
      }
    }
  }
  .bs-steps-navigation {
    > .bs-steps-item {
      margin-right: 0 !important;
      &::before {
        display: none;
      }
      &::after {
        position: relative;
        top: -2px;
        left: 50%;
        display: block;
        width: 8px;
        height: 8px;
        margin-bottom: 8px;
        text-align: center;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      > .bs-steps-item-container {
        > .bs-steps-item-tail {
          visibility: hidden;
        }
      }
    }
    > .bs-steps-item.bs-steps-item-active {
      &::before {
        top: 0;
        right: 0;
        left: unset;
        display: block;
        width: 3px;
        height: calc(100% - 24px);
      }
    }
  }
}

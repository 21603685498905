.prospect-form-contact-field {
  .add-email {
    font-weight: $font-weight-semibold;
    font-size: 0.75rem;

    &:hover {
      color: $blue-11;
    }
  }

  .more-contact {
    padding: 0 0.5rem;
    height: 1.25rem;
    background-color: $gray-13;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $blue-13;
      color: $blue-11;
      cursor: pointer;
    }

    svg {
      margin-top: 0.125rem;
    }
  }

  .individual-contact-field {
    .bs-select-box {
      width: 100%;
    }

    .email-varification-icon {
      top: 50%;
      margin: 0;
      transform: translateY(-50%);
      right: 0.833rem;
    }

    .contact-col {
      padding: 0 0 0 0.5rem;

      .trash-icon {
        display: flex;

        &:hover {
          cursor: pointer;
          color: $red-16 !important;
        }
      }

      .disable-trash-icon {
        display: flex;
        opacity: 0.35;

        &:hover {
          cursor: default;
        }
      }

      .radio-button {
        height: 1rem;
        width: 1rem;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }

        &:checked {
          cursor: pointer;
        }
      }

      .bs-input-affix-wrapper {
        width: 100%;
      }

      .bounced-tag {
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);

        display: flex;
        justify-content: center;
        align-items: center;

        .bs-tag {
          padding: 0 0.25rem;
          width: 3.125rem;
          height: 0.875rem;
          border-radius: 0.25rem;
          background-color: $orange-15;
          border: none;
          color: $blue-15;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.875rem;
        }
      }

      .verification-status {
        .bs-input {
          padding-right: 1.2rem !important;
          text-overflow: ellipsis !important;
          overflow: hidden !important;
          &:hover {
            text-overflow: unset !important;
          }
        }
      }
      .verification-status.bounced-status {
        .bs-input {
          padding-right: 5rem !important;
          text-overflow: ellipsis !important;
          overflow: hidden !important;
        }
      }
      .bs-radio-wrapper .bs-radio-inner:after {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
}

.tooltip-primary-email {
  left: -1.125rem !important;
  .arrow {
    left: 1.2rem !important;
  }
}

.confirmation-modal-v3 {
  &--title {
    img,
    svg {
      margin-top: 0 !important;
    }
  }
}

// Confirmation Modal
.confirmation-modal {
  .modal-content {
    .modal-content-container {
      .bs-modal-header {
        padding-top: 0 !important;
        padding: 1.5rem;

        h2 {
          line-height: normal;
          margin-left: 0.625rem !important;
        }
      }

      .bs-modal-body {
        padding: 0 1.5rem 1rem 1.5rem !important;

        p {
          margin-bottom: 0;
        }
      }

      .bs-modal-footer {
        padding: 1.25rem 1.5rem !important;
        margin: 0 !important;
        min-height: unset !important;
        max-height: unset !important;

        .btn-ghost-outlined {
          min-width: 6.25rem;
          border-color: $gray-13;
          color: $gray-15;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
        }

        .btn-solid-primary {
          min-width: 6.25rem;
          margin-left: 1rem !important;
          background-color: $blue-11;
          font-weight: $font-weight-medium;
        }
      }
    }
  }
}

//custom email field spacing
.modal-dialog {
  max-width: 32.5rem;
}

.fade {
  .modal-content {
    .modal-content-container {
      .bs-modal-body {
        .modal-body {
          margin-left: 0 !important;
        }
      }
    }
  }
}

// Prospect Delete Modal
.prospect-delete-modal {
  .modal-content {
    .modal-content-container {
      .bs-modal-header {
        padding: 1.5rem;
        border-bottom: none;

        .modal-title {
          h2 {
            line-height: normal !important;
            margin-left: 0.625rem !important;
          }

          .delete-title {
            margin-left: 0.625rem !important;
          }
        }
      }

      .bs-modal-body {
        padding-top: 0 !important;
        padding-bottom: 1rem !important;
        margin-left: 0;
      }

      .bs-modal-footer {
        padding: 1.25rem 1.5rem;
        max-height: unset;

        .bs-modal-footer-action-buttons {
          margin: 0;

          .btn-ghost-outlined {
            min-width: 6.25rem;
            border-color: $gray-13;
            color: $gray-15;
            text-transform: capitalize;
            font-weight: $font-weight-medium;
          }

          .btn-solid-primary {
            margin-left: 1rem;
            min-width: 6.25rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.add-prospect {
  .modal-content {
    .modal-content-container {
      .modal-body {
        padding: 0;

        .prospect-form-field-content {
          padding: 1rem 0.1875rem 0 1.25rem;
        }
      }
    }
  }
}

.modal-custom-footer {
  .modal-content {
    .modal-content-container {
      .modal-body {
        .prospect-form-field {
          padding: 0 !important;
        }

        .prospect-modal-footer {
          box-shadow: unset;
          padding-bottom: 0 !important;
          padding-right: 0.25rem;
        }

        .custom-content-with-form {
          padding-left: 0.3125rem;
        }
      }
    }
  }
}

.prospect-modal-footer {
  padding: 1rem 1.5rem;
  box-shadow: 0px -2px 10px 0px rgba(4, 21, 76, 0.06);
  border-top: 1px solid $gray-13;

  .cancel-btn {
    min-width: 4.9375rem;
    color: $gray-15;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    padding: 0.375rem 1rem;
    background-color: $white;
    border: 1px solid $gray-5;
    margin: 0 1rem 0 0;
  }

  .save-btn {
    min-width: 4.0625rem;
    background-color: $blue-11;
    padding: 0.375rem 1rem;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    margin: 0;
  }
}

.prospect-label-field {
  font-weight: $font-weight-semibold;
  font-size: 0.875rem;
  color: $blue-15;
  line-height: 1.25rem;
  padding-left: 1.1875rem;
  margin: 0.375rem 0 0.375rem 0 !important;
  width: 100%;
}

// tooltip arrow
.tooltip-arrow {
  .arrow {
    left: 0.25rem !important;
  }
}

.prospect-label{
  margin-bottom: 0.375rem !important;
}

.email-id-tooltip {
  .arrow {
    transform: translateX(1.25rem) !important;
  }
  .tooltip-inner {
    max-width: 16.4375rem !important;
  }
}

.bounced-tooltip .tooltip-inner {
  max-width: 13.8125rem !important;
}

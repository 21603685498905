.layout-columns-button {
  width: 2.875rem;
  height: 2.875rem;
  padding: 0.75rem 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  position: absolute;
  top: 0;
  right: 1.5rem;

  z-index: 3;

  border-top-right-radius: 0.25rem;
  border: 0.0625rem solid #f3f4f6;
  background: #ffffff;

  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #6b7280;
  }

  &:hover {
    border: 0.0625rem solid #dbeafe;
    background: #eff6ff;

    svg {
      color: $blue-11;
    }
  }
}

.shd-modal.edit-table-column .modal-content {
  border: none !important;
}

.edit-table-column {
  .modal-body {
    height: 22.375rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.edit-table-container {
  display: flex;

  .col-left {
    width: 18.75rem;

    padding: 1rem 1.5rem 1rem 0rem;

    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1rem;
    flex: 1;
  }
  .col-right {
    width: 15.25rem;

    padding: 1rem 1.5rem;

    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1rem;
    flex: 1;

    background: #f9fafb;
  }

  .col-title {
    color: #4b5563;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin: 0;
  }

  .shd-search-input {
    input {
      font-size: 0.875rem;
    }
  }

  .fields-section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.5rem;

    .fields-title {
      color: #6b7280;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      margin: 0;
    }
    .fields-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: 0.25rem;
      .field {
        display: flex;
        align-items: center;
        align-self: stretch;
        gap: 0.5rem;
        border-radius: 0.25rem;
        cursor: pointer;
        transition: all 0.2s ease;
        .field-label {
          input {
            width: 1rem;
            height: 1rem;

            border-radius: 0.25rem;
            border: 0.0625rem solid #9ca3af;
            background: #fff;
          }

          width: 100%;
          padding: 0.375rem 0.5rem;
          margin: 0;

          cursor: pointer;

          color: #1f2937;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          display: flex;
          align-items: center;
          align-self: stretch;
          gap: 0.5rem;
        }

        &:hover {
          background: #eff6ff;
        }
      }
    }
  }

  .dnd-fields-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.5rem;
  }

  .dnd-fields {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.5rem;

    .dnd-field {
      width: 12.25rem;

      padding: 0.375rem 0.5rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      border-radius: 0.125rem;
      background: #fff;
      cursor: grab;
      transition: all 0.2s ease;

      position: relative;

      &:hover {
        transition: all 0.2s ease;
        box-shadow: 0px 2px 6px 0px rgba(4, 21, 76, 0.12);
        .dnd-field--remove {
          display: flex;
        }
        .dnd-field--icon {
          display: block;
        }
      }

      &.isDragging {
        cursor: grabbing;
        .dnd-field--icon {
          cursor: grabbing;
        }
      }

      &:hover + .dnd-field--remove {
        display: flex;
      }
      &.isDragging:hover + .dnd-field--remove {
        display: none;
      }

      &.non-removable {
        cursor: default;
        &:hover {
          box-shadow: none;
        }
      }

      span {
        color: #1f2937;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        user-select: none;
      }

      &--icon {
        width: 0.875rem;
        height: 0.875rem;

        cursor: grab;

        display: none;
        position: absolute;
        left: -0.875rem;

        transition: all 0.2s ease;

        svg {
          width: 0.875rem;
          height: 0.875rem;

          display: block;

          color: #6b7280;
        }
      }
    }
    .dnd-field--remove {
      display: none;
      align-items: center;
      gap: 0.5rem;

      padding: 0.0625rem;

      border-radius: 0.125rem;
      transition: all 0.2s ease;

      position: absolute;
      right: 0.375rem;
      top: 0.5rem;

      z-index: 99;

      cursor: pointer;

      svg {
        width: 0.875rem;
        height: 0.875rem;
        color: #6b7280;
      }

      &:hover {
        display: flex;
        background: #fee2e2;

        svg {
          color: #b91c1c;
        }
      }
    }
  }
}

.footer-reset-col-button {
  height: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  p {
    color: #9ca3af;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0;
    transition: all 0.2s ease;
  }
  &:hover {
    p {
      color: $blue-11;
    }
  }
}

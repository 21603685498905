.pricing-card {
  .card {
    width: 15.625rem;
    height: 24.0625rem;
  }
  &__details {
    &-description {
      line-height: 1rem !important;
      margin-top: 0.625rem;
    }
    &-currency {
      margin-right: 0.1875rem;
    }
    &-price-detail {
      line-height: 0.605rem;
      margin-left: 0.4375rem;
      margin-top: 2px;
      .mb-2-5 {
        margin-bottom: 0.35rem !important;
      }
    }
    .bs-tag {
      border-radius: 0.25rem;
      border-width: 0px;
      margin: 0px;
      font-size: 0.5rem;
      cursor: auto;
    }
  }
  &__divider {
    width: 100%;
    height: 0.125rem;
    background: $gray-16;
  }
  &__features {
    .green-stroke {
      width: 100%;
      padding-bottom: 0.25rem;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background-color: $green-20;
        border-radius: 0.25rem;
      }
    }
    ul {
      margin-top: 0.625rem;
      list-style-type: none;
      padding-left: 0;
      li {
        i {
          cursor: inherit;
        }
      }
    }
  }
  .ghost-primary-new.btn-ghost-primary {
    border-color: $blue-11;
    color: $blue-11;
    &:hover {
      background-color: $blue-11;
      color: $white;
    }
  }
  .ghost-primary-new.btn-ghost-primary:disabled {
    &:hover {
      background-color: transparent;
      color: $gray-5;
    }
  }
  .ghost-secondary-new.btn-ghost-primary {
    border: 1px solid $gray-11;
    color: $gray-11;
  }
  .ghost-secondary-new-disable.btn-ghost-primary:disabled {
    border: 1px solid $gray-15;
    color: $gray-15;
    opacity: 0.35;
    &:hover,
    &:active {
      color: $gray-15 !important;
      background-color: transparent !important;
      cursor: not-allowed !important;
    }
  }
}
.best-value-plan {
  .card {
    border: 1px solid $blue-11;
    border-top-width: 0.25rem;
  }
}
.pricing-card-lifetime-plan {
  .card {
    height: 25.5625rem;
  }
}

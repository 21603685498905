.prospect-tag-popover {
  min-width: 6.875rem;
  .popover-body {
    .bs-popover-inner-content {
      padding: 0;
    }
  }
}

.prospect-tag-text {
  max-width: 5.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.overlay-popover-content-wrap {
  display: flex;
  flex-direction: column;
  max-height: 10rem;
  overflow-y: auto; 
  border: 1px solid #d1d5db; 
  border-radius: 0.25rem; 
}

.text-wrap {
  padding: 0.5rem 1rem;
}

.text-wrap span {
  color: #4338ca;
}

.text-wrap:hover {
  background: #e0e7ff;
}

.overlay-popover-content-wrap::-webkit-scrollbar {
  width: 6px;
}

.overlay-popover-content-wrap::-webkit-scrollbar-track {
  background: #f0f4f8;
}

.overlay-popover-content-wrap::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 0.1875rem;
}


.ooo {
  .ooo-header {
  }

  &--setting {
    margin-top: 1.5rem;

    .setting-toggle {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .switchToggle {
        width: 2rem;
        height: 1rem;

        input + label {
          background: $gray-11;
        }
        input:checked + label {
          background: $blue-11;
        }
      }

      p {
        color: $blue-15;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0;
      }
    }
    .setting-options {
      display: flex;
      justify-content: center;
      flex-direction: column;

      margin: 0.75rem 0 1rem 0.875rem;

      padding-left: 1.375rem;
      border-radius: 0.25rem;
      border-left: 0.1875rem solid $gray-16;

      .rb-group {
        gap: 0.375rem;
      }

      .bs-radio-wrapper {
        margin: 0;
        align-items: center;

        .bs-radio {
          width: 1rem;
          height: 1rem;
          top: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          &.bs-radio-checked {
            .bs-radio-inner {
              border-width: 0.0938rem;
              border-color: $blue-11;
            }
          }
          .bs-radio-input {
            width: 1rem;
            height: 1rem;
          }

          .bs-radio-inner {
            width: 1rem;
            height: 1rem;
            top: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            border-width: 0.0938rem;
            border-color: $gray-11;
            &::after {
              position: static;
              width: 0.5rem;
              height: 0.5rem;
            }
          }
        }
      }

      .setting-options-radio-label {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        p {
          color: $gray-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin: 0;
        }

        .bs-input-number-container {
          .bs-input-number {
            width: 4.625rem;
            min-width: 4.625rem;
            height: 2rem;
            border-radius: 0.25rem;
            border: 0.0625rem solid $gray-13;
            background: $gray-19;

            &:hover,
            &:focus,
            &:focus-within {
              border-color: $blue-11;
              box-shadow: none;
            }

            .bs-input-number-handler-wrap {
              width: 1.125rem;
              background: $gray-16;
              border-left: none;
              border-radius: 0 0.25rem 0.25rem 0;
              .bs-input-number-handler {
                color: $gray-12;
                .bs-input-number-handler-up-inner,
                .bs-input-number-handler-down-inner {
                  color: $gray-12;
                }
                &:hover {
                  .bs-input-number-handler-up-inner,
                  .bs-input-number-handler-down-inner {
                    color: $blue-11;
                  }
                }
              }
            }
            .bs-input-number-input {
              color: $blue-15;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: normal;
            }
          }
          &.bs-input-number-error {
            .bs-input-number {
              border-color: $red-20;
              &:hover,
              &:focus,
              &:focus-within {
                border-color: $red-20;
              }
            }
          }
        }
      }
    }
  }
}

.change-email-modal {
  .popup-title {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    .popup-title-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
        height: 100%;
        color: $orange-14;
      }
    }
    h1 {
      font-weight: $font-weight-semibold;
    }
  }
  .width-100 {
    width: 100px;
  }
}

.attachment {
  width: 198px;
  height: 3rem;

  background: $gray-1;

  border: 0.0625rem solid $gray-23;
  border-radius: 0.25rem;
  box-sizing: border-box;

  padding: 0.625rem;
  position: relative;

  display: flex;
  justify-content: space-between;

  overflow: hidden;
  transition: all 0.2s ease;

  &--upload-failed {
    background: $red-17;
    border-color: $red-20;
  }

  &--uploading {
    border-style: dashed;
  }

  &:hover {
    cursor: pointer;
    background: $gray-16;
    transition: all 0.2s ease;
  }

  &--meta {
    display: flex;
    gap: 0.5rem;
  }

  &--icon {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-top: 0.0625rem;
  }

  &--details {
    h3 {
      margin: 0;
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: $blue-15;
      cursor: pointer;
      transition: all 0.2s ease;
      white-space: nowrap;
      &:hover {
        color: $blue-11;
      }
    }
    p {
      margin: 0;
      margin-top: 2px;
      font-weight: $font-weight-medium;
      font-size: 0.625rem;
      line-height: 0.75rem;
      color: $gray-15;
    }
    .upload-failed {
      color: $red-12;
    }
  }

  &--action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    cursor: pointer;
    color: $gray-15;
    i {
      font-size: 0.875rem;
    }
    &:hover {
      color: $blue-15;
    }
  }

  &--progress {
    width: 12.375rem;
    height: 3px;

    position: absolute;
    bottom: 0;
    left: 0;

    .progress {
      height: 3px;
      background-color: transparent;
      border-radius: 0px 0.125rem 0.125rem 0px;
      margin-bottom: -1px;
      .progress-bar {
        background-color: $blue-11;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
}

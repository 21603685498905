.do-no-contact-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .do-not-contact-banner{
    padding: 1.5rem 1.5rem 0 1.5rem;    
  }

  .do-no-contact-list-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
    .globalSettings__Header__Right {
      gap: 1rem !important;
    }
    .search-input {
      margin: 0;
      outline: none;
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
    .shd-button {
      width: 5.5rem;
    }
  }
  .do-not-contact-list-table-wrapper {
    .do-not-contact-list-table {
      padding: 0 1.5rem;
    }
  }
}

.do-not-contact-list-empty-ui {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  .do-not-contact-list-empty-ui-icon {
    width: 5rem;
    height: 5rem;
  }
  .do-not-contact-list-empty-ui-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  }
  .shd-button {
    width: 7.875rem;
  }
  h1 {
    color: #1f2937;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: 1.5rem;
    margin: 0;
  }
  p {
    color: #6b7280;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
  }
}

.do-not-contact-list-table,
.do-not-contact-list-details-table {
  height: auto !important;
  max-height: calc(100vh - 9.5rem) !important;
  .table-organism-header {
    background-color: #f3f4f6 !important;
    tr:hover {
      background-color: inherit !important;
    }

    & > tr > th {
      height: 2.375rem;
      padding: 0.5rem 0.75rem;
      &:first-of-type {
        padding-left: 1.5rem !important;
        border-radius: 0 !important;
      }
      &:last-of-type {
        min-width: 2.8125rem;
        width: 2.8125rem;
        padding-right: 1.5rem !important;
        border-radius: 0 !important;
      }
    }
    .selection-cell-header {
      width: 4rem !important;
      max-width: 4rem;
    }
  }
  .table-organism-body {
    tr td {
      height: 3.625rem;
      max-height: 3.625rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      &:first-of-type {
        padding-left: 1.5rem !important;
        border-left: 0.0625rem solid #e5e7eb !important;
      }
      &:last-of-type {
        padding-right: 1.5rem !important;
        border-right: 0.0625rem solid #e5e7eb !important;

        .grid-flex {
          div[role='button'] {
            width: 2.125rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.25rem;
            transition: all 0.2s ease;
            &:hover {
              background: #dbeafe;
              svg {
                color: $blue-11;
              }
            }
            svg {
              width: 1.125rem;
              height: 1.125rem;
              color: #6b7280;
            }
          }
        }

        .grid-action .btn-solid {
          padding: 0 !important;
          border: none;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.75rem;

          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: #eff6ff;
          }
          .icon-hover {
            font-size: 1.25rem;
            width: 1.25rem;
            height: 1.25rem;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
    .selection-cell {
      width: 4rem;
      max-width: 4rem;
    }
  }

  p,
  .last-login-cell {
    color: $blue-15;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    margin: 0;
  }
}

.do-not-contact-list-details-table {
  max-height: none !important;
}

.add-modify-dnc-list-modal {
  .modal-dialog {
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
  }
  &--container {
    .title-container {
      gap: 0.625rem;
      svg {
        color: $blue-11;
      }
      h5 {
        font-size: 1rem;
        font-weight: 600;
        color: $blue-15;
        line-height: 1.5rem;
        margin: 0;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
      .bs-input-container {
        display: flex;
        flex-direction: column;
        margin: 0;
      }
      .bs-input-label-suffix {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        color: #b91c1c;
        margin: 0rem;
      }
      .radio-input-wrapper {
        h1 {
          color: $blue-15;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 1.5rem;
          margin-bottom: 0.75rem;
        }
        .radio-input-container .rb-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .bs-radio-wrapper {
            display: flex;
            gap: 0.5rem;
            margin: 0;

            .bs-radio {
              top: 0;
              .bs-radio-inner {
                display: flex;
                justify-content: center;
                align-items: center;

                top: 0.125rem;

                &::after {
                  position: static;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }

            .radio-input-btn {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              h2 {
                color: $blue-15;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1.25rem;
                margin: 0;
              }

              p {
                color: #6b7280;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1rem;
                margin: 0;
              }
            }
          }
        }
      }
      .agency-client-wrapper {
        width: 100% !important;
        height: 2rem !important;
        background: $white;
        padding: 0.375rem 0.75rem !important;
        .agency-client-main {
          // color: #9ca3af;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          span {
            margin: 0 !important;
          }
        }
      }
    }
  }
  .btn-solid-primary {
    min-width: 6.25rem !important;
    &:disabled {
      background-color: $blue-11 !important;
      color: #ffffff;
      border: 0;
    }
  }
  .cancel-btn {
    min-width: 6.25rem !important;
    height: 2rem !important;
    border: 0.0625rem solid #e5e7eb !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
    transition: all 0.2s ease !important;
    color: #6b7280 !important;
    &:focus-visible {
      outline: none;
    }
    &:hover,
    &:active {
      color: #6b7280 !important;
      background-color: #f3f4f6 !important;
    }
  }

  .modal-content {
    width: 28.125rem !important;
    .bs-modal-body {
      padding: 1.5rem;
    }

    .bs-input-label-prefix {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      color: $gray-15;
      margin-bottom: 0.25rem;
    }
    .bs-input {
      font-size: 0.875rem;
    }
    .modal-footer {
      padding: 0;
      max-height: 4.5rem;
      min-height: 4.5rem;
      margin: 0 1.5rem;
      .bs-modal-footer-action-buttons {
        margin: 0;
      }
    }
  }
}

.agency-select-dropdown {
  .transfer-container {
    width: 25.125rem !important;
  }
}

.do-not-contact-list-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f9fafb;
  overflow-y: auto;
  padding: 1.5rem;

  &--header {
    .go-back-btn {
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $blue-15;
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .list-details {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .skeleton-loader {
        display: flex;
        &:first-of-type {
          height: 1.25rem;
        }
        &:last-of-type {
          height: 1rem;
        }
        span {
          display: flex;
        }
      }

      .list-details-inner {
        display: flex;
        gap: 0.5rem;
        .list-name {
          color: $blue-15;
          font-size: 1rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 1.25rem;
        }
        .do-not-contact-list-details-popover-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 1.25rem;
          padding: 0.125rem 0.375rem;
          border-radius: 0.25rem;
          background: #e5e7eb;
          cursor: default;
          span {
            color: #6b7280;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 1rem;
          }
        }
        .edit-list-btn {
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #6b7280;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      p {
        margin: 0;
        color: #6b7280;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  &--input-wrapper {
    margin-bottom: 2rem;
    .input-label {
      color: #6b7280;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
      margin: 0;
      margin-bottom: 0.25rem;
    }
    .helper-alert-warning-wrapper {
      margin-top: 0.75rem;
      margin-bottom: 1.5rem;
    }
    .emails-and-domains-input {
      &--container {
        width: 100%;
        min-height: 8.875rem;
        max-height: 14.125rem;

        overflow-y: auto;

        padding: 0.75rem 1rem;

        display: grid;
        grid-template-rows: auto 1fr;
        gap: 0.375rem;

        border-radius: 0.25rem;
        border: 0.0625rem solid #e5e7eb;
        background: $white;

        cursor: text;

        &:focus,
        &:focus-within {
          border-color: $blue-11;
        }

        &.error {
          border-color: #fca5a5;
        }
      }

      &--error-msg {
        color: #b91c1c;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
      }

      &--helper-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 0.25rem;
      }

      &--pills {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.375rem;
        .shd-pills {
          background-color: #f3f4f6;
          border-color: #d1d5db;
        }
      }

      &--input {
        border: none;
        outline: none;

        width: 100%;

        font-weight: $font-weight-medium;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: $blue-15;

        padding: 0;

        background: $white;

        &:disabled {
          background-color: transparent;
        }

        &:active,
        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #9ca3af;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }
    }
  }

  &--actions {
    .bs-select-box {
      display: flex;
      width: 8.75rem;
      height: 2rem;
      padding: 0.375rem 1rem;
      justify-content: space-between;
      align-items: center;
    }
    .actions-left {
      gap: 1rem;
    }
    .actions-right {
      gap: 1rem;
      .action-btn--export {
        margin: 0 !important;
      }
    }
  }
}

.helper-alert-warning-wrapper {

  &.rounded-text-color {
    width: max-content;
    height: 1.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    p {
      display: flex;
      line-height: 1rem;
    }
    svg {
      color: #b45309;
      margin-right: 0.25rem;
    }
  }

  p {
    margin: 0;
    color: $blue-15;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1rem;
    span {
      font-weight: $font-weight-medium;
    }
  }
}

.dnc-search-input {
  width: 12.625rem !important;
  height: 2rem;

  &.lg {
    width: 18.75rem !important;
    max-width: 18.75rem !important;
  }

  justify-content: flex-start;
  align-items: center;
  padding: 0.3125rem 0.625rem !important;

  background: $white !important;
  .input-icons-left {
    width: 1rem;
    height: 1rem;

    margin: 0 !important;
    margin-right: 0.25rem !important;

    .bs-input-prefix {
      color: #d1d5db;
      margin: 0;
      font-size: 1rem;
    }
  }

  .input-icons-right {
    width: 1rem;
    height: 1rem;

    margin: 0 !important;
    margin-left: 0.25rem !important;

    .bs-input-suffix {
      color: #6b7280;
      margin: 0;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .bs-input {
    background: $white;
    color: $blue-15;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    &::placeholder {
      color: #d1d5db;
    }
  }
}

.do-not-contact-list-details-popover {
  display: flex;

  border-radius: 0.5rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;
  box-shadow: 0.1875rem 0.5rem 1.5rem -0.375rem rgba(15, 34, 67, 0.16) !important;

  .arrow {
    display: none;
  }

  .bs-popover-inner-content {
    padding: 0 !important;
  }

  .do-not-contact-list-details-popover-content {
    min-width: 9rem;
    height: 3.75rem;
    padding: 0.75rem;
    border: 0.0625rem solid #f3f4f6;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;

    .do-not-contact-list-details-count {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: $blue-15;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;
        margin: 0;
      }
    }
  }
}

.dnc-upload-csv-modal {
  .modal-dialog {
    width: 45rem;
    max-width: 45rem;
    .modal-content {
      height: 34.5rem;
      .modal-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .modal-header {
        height: 4rem;
        padding: 1.25rem 1.5rem;
        .modal-title {
          color: $blue-15;
        }
        .close {
          font-size: 1.5rem;
          color: $blue-15;
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
      }
      .modal-footer {
        height: 4.5rem;
        max-height: 4.5rem;
        padding: 1.25rem 1.5rem;
        .sample-file-btn {
          display: inline-flex;
          align-items: center;
          border-radius: 0.25rem;
          height: 1.5rem;
          color: $blue-11;
          text-decoration: none;
          .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.25rem;
            height: 1.25rem;
            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
          p {
            margin: 0;
            height: 100%;
            display: flex;
            padding: 0.125rem 0.5rem;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            line-height: 1.25rem;
          }
        }
        .bs-modal-footer-action-buttons {
          margin: 0;
          .btn-ghost-primary,
          .btn-solid-primary {
            width: 6.25rem;
            height: 2rem;
          }
          .btn-ghost-primary {
            border: 0.0625rem solid #e5e7eb;
            font-weight: $font-weight-medium;
            line-height: 1.25rem;
            transition: all 0.2s ease;
            color: #6b7280;
            &:hover {
              color: #6b7280;
              background-color: #f3f4f6;
            }
          }
        }
      }
    }
  }
  .import-help-text {
    color: #6b7280;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.375rem;
    margin-bottom: 0;
  }
  .import-column-mapping {
    margin: 1.25rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      margin: 0;
    }
    .bs-select-box {
      width: 20.375rem;
      height: 2rem;
      padding: 0.375rem 0.75rem;
    }
  }
  .import-report-wrapper {
    height: 100%;

    .import-report-content {
      width: 27.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      h3 {
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: $font-weight-semibold;
        color: #8c8c8c;
        margin: 0;
      }
      .import-report-content-container {
        width: 100%;
        border: 0.0625rem solid #f3f4f6;
        border-radius: 0.25rem;

        .content-inner {
          background: #f9fafb;
          padding: 0.625rem 1rem;

          &:first-of-type {
            border-bottom: 0.0625rem solid #f3f4f6;
          }

          p {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            line-height: 1.375rem;
            color: #595959;
            margin: 0;
          }

          .content-inner-right {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .count-icon-wrapper {
              .count {
                font-size: 0.875rem;
                line-height: 1.375rem;
                margin-right: 0.5rem;
              }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            a {
              text-align: right;
              font-size: 0.75rem;
              margin-right: 2em;
              margin-top: 0.4375rem;
              font-weight: $font-weight-medium;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.file-selector-input {
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 42rem;
    height: 13.875rem;

    border-radius: 0.5rem;
    background: #f9fafb;

    &.post-select-stage-container {
      background: #ecfdf5;
      .shd-drag-and-drop {
        border: 1px solid #a7f3d0;
      }
    }
  }

  &--stage {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .shd-drag-and-drop {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;

      border-radius: 0.5rem;
      border: 0.0625rem dashed $gray-23;
      &.dragging {
        border: none;
      }
    }
  }

  &--stage-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.pre-select-content {
      .icon-wrapper {
        width: 1.875rem;
        height: 1.875rem;
        margin-bottom: 1.5rem;
        svg {
          width: 100%;
          height: 100%;
          color: #9ca3af;
        }
      }
      .title {
        color: $blue-15;
        text-align: center;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        margin-bottom: 1rem;
      }
      .or-label {
        color: #6b7280;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        margin-bottom: 1rem;
      }
      .choose-file-input {
        width: 7.5rem;
        height: 2rem;
        border: 0.0625rem solid $blue-11;
        border-radius: 0.25rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > input {
          display: none;
        }

        .choose-file {
          width: 100%;
          height: 1.875rem;

          display: flex;
          justify-content: center;
          align-items: center;

          color: $blue-11;
          font-size: 0.875rem;
          line-height: 1.875rem;
          font-weight: $font-weight-semibold;
        }
      }
    }

    &.post-select-content {
      .icon-wrapper {
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.875rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .filename {
        color: $blue-15;
        text-align: center;
        font-size: 1rem;
        font-weight: $font-weight-medium;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
      .import-wrapper,
      .action-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }
      .import-wrapper {
        margin-bottom: 1.125rem;
        .import-icon {
          width: 1.25rem;
          height: 1.25rem;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 100%;
            height: 100%;
            color: #059669;
          }
        }
        .import-message {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
      .action-wrapper {
        padding: 0.375rem 1rem;
        cursor: pointer;
        transition: all 0.2s ease;
        .action-icon {
          width: 1.25rem;
          height: 1.25rem;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 100%;
            height: 100%;
            color: #6b7280;
          }
        }
        .action-message {
          display: block;
          margin-top: 0.0625rem;
          color: #6b7280;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;
        }
      }
    }
  }
}

.dnc-sequence {
  font-weight: $font-weight-medium;
}
.upgrade-plan-billing-section {
  width: 18.125rem;
  .card {
    box-shadow: 0px 2px 4px rgba(234, 237, 239, 0.65);
  }

  &__header {
    background: $gray-16;
    border-radius: 0.25rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    text-align: center;
  }

  &__loader-container {
    height: 17.8125rem;
    img {
      height: 3.125rem;
    }
  }

  &__divider {
    width: 100%;
    height: 0.125rem;
    background: $gray-13;
  }

  &__price-details-wrap {
    margin-top: 1.6875rem;

    .price-details {
      .additional_user_accounts {
        display: flex;
        flex-direction: column;
        .remaining_days {
          font-size: 0.5rem;
          font-weight: $font-weight-medium;
        }
      }
      .subtotal-discount-amount {
        padding-top: 1.0625rem;
      }
      .already_paid_amount {
        margin-top: 0.625rem;
      }
      .days-of-bill-text {
        font-size: 0.5rem;
        font-weight: $font-weight-normal;
      }
    }
    .promo-code-container {
      margin-top: 0.625rem;
      margin-bottom: 1.25rem;
      .coupon-code-input-wrapper {
        .coupon-code-input.bs-input-affix-wrapper {
          background: $gray-16;
        }
        .coupon-code-input {
          height: 2.5rem;
          align-items: center;
          .bs-input {
            background: $gray-16;
          }
          .input-error {
            height: inherit;
            background: $gray-16;
            align-items: center;
          }
          .input-succeeded {
            height: inherit;
            background: $gray-16;
            align-items: center;
            input {
              pointer-events: none;
            }
            i {
              cursor: pointer;
            }
          }
          label {
            font-style: normal;
            font-weight: 500;
            font-size: 0.5rem;
            line-height: 14px;
            width: 100%;
            text-align: right;
            margin-bottom: 1.25rem;
          }

          .icon-loading {
            i {
              animation: spin 2s linear infinite;
            }
          }
        }
      }
    }
  }

  &__total-after-discount-wrap {
    padding-top: 1.0625rem;
    margin-top: 1.9375rem;
  }

  .note-wrap {
    width: 96.5%;
    margin-top: 1.25rem;
    color: $gray-15;
    font-size: 0.625rem;
    .note-txt {
      font-weight: $font-weight-medium;
      margin-right: 0.12rem;
    }
  }
}

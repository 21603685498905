.tag-button-1 {
  height: 1.75rem !important;
  padding: 0.375rem 0.75rem !important;
  color: $gray-15 !important;
  background-color: transparent !important;
  border: 0.0625rem $gray-23 solid !important;
  border-radius: 0.25rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
  cursor: pointer;
  transition: all 0.2s ease !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  font-weight: $font-weight-medium !important;
  &:hover {
    border: 0.0625rem $blue-11 solid !important;
    color: $blue-11 !important;
  }
}
.tag-dropdown-view {
  position: absolute !important;
  margin-left: 21.65rem !important;
  margin-top: 2rem !important;
  background: white !important;
  cursor: pointer !important;
}
.menu-item {
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  cursor: pointer;
  &:hover {
    background-color: $blue-17 !important;
  }
}
.dropdown-container {
  border-radius: 0.25rem !important;
  border: 0.0625rem solid $gray-13 !important;
  background: #fff !important;
  display: inline-flex !important;
  padding: 0.5rem 0rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  height: 5rem !important;
  width: 10.6875rem !important;
  list-style: none !important;
  margin: 0 !important;
}
.tag-text {
  margin-top: 0.3rem !important;
}

.add-remove-tag-email-modal {
  .modal-header {
    border: none !important;
  }
  .modal-body {
    padding: 0 !important;
  }
  .modal-dialog {
    max-width: 34.12rem !important;
    overflow: visible !important;
  }
  .modal-content {
    overflow: visible !important;
    // border-radius: 0.75rem !important;
  }
  .bs-modal-body {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .modal-body {
    min-height: 7.75rem;
    max-height: 64%;
    display: flex !important;
    flex-direction: column !important;
    overflow: visible !important;
  }
  .modal-footer {
    max-height: none;
    min-height: 0;
    padding: 1.5rem 0;
    margin: 0 1.5rem;
  }
  .modal-footer > * {
    margin: 0;
  }
  .bs-modal-container .bs-modal-footer .bs-modal-footer-action-buttons button {
    margin-left: 0;
  }
}
.tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal-header-title {
  display: flex;
  margin-top: 1.5rem;
}
.p-text-tag {
  color: $blue-15 !important;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 20px;
}
.email-tab-tags {
  .selected-tags-container {
    .bs-tag-blue-17 {
      background: $blue-13;
    }
  }
  &.remove-tag-modal {
    .selected-tags-container {
      .bs-tag-blue-17 {
        background: $red-19;
      }
    }
  }
  .bs-tag {
    margin-right: 0;
  }
}
.dropdown {
  &:hover {
    background: $blue-17;
  }
}
.tag-list-font {
  font-weight: $font-weight-medium;
  margin: 0;
}
.add-tag-btn {
  display: flex !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.tag-button-container {
  position: relative !important;
  display: inline-block !important;

  .tag-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    cursor: pointer;
    svg {
      color: $gray-15;
    }
    &:hover {
      background-color: $blue-13;
      svg {
        color: $blue-11;
      }
    }

    &.selected {
      background-color: $blue-13;
      cursor: default;
      svg {
        color: $blue-11;
      }
    }
  }
  .disabled {
    opacity: 0.35;
    cursor: not-allowed;
    &:hover {
      background-color: $gray-16;
      svg {
        color: $gray-15;
      }
    }
  }
}
.add-btn-container {
  position: absolute !important;
  z-index: 99999 !important;
}

.modal-input {
  .fixed-height-input-container {
    padding: 0.5rem 1rem;
    max-height: 12rem !important;
    overflow-y: auto;
    &:hover {
      border-radius: 0.25rem;
      border: 0.0625rem solid $blue-11;
    }
    &:focus-within {
      border-radius: 0.25rem;
      border: 0.0625rem solid $blue-11;
    }
    &:active {
      border-radius: 0.25rem;
      border: 0.0625rem solid $blue-11;
    }
  }
}

.email-tab-tags {
  .selected-tags-container {
    .bs-tag-blue-17 {
      background: $blue-13;
    }
  }
  &.remove-tag-modal-color {
    .bs-tag-blue-17 {
      background: $red-19 !important;
    }
  }
}

.cancel-btn {
  min-width: 6.25rem !important;
  height: 2rem !important;
  border: 0.0625rem solid #e5e7eb !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  transition: all 0.2s ease !important;
  color: #6b7280 !important;
  margin-right: 0;
  &:focus-visible {
    outline: none;
  }
  &:hover,
  &:active {
    color: #6b7280 !important;
    background-color: #f3f4f6 !important;
  }
}

.submit-btn {
  min-width: 6.25rem;
  height: 2rem;
  border: none;
  font-weight: 500;
  line-height: 1.25rem;
  transition: all 0.2s ease;
  background-color: $blue-11;
  &:hover {
    background-color: #1e40af !important;
  }
  &:disabled {
    background-color: $blue-11 !important;
    color: #fff !important;
    opacity: 0.65 !important;
  }
}

.bs-input-number-container {
  position: relative;
  .bs-input-number {
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: $gray-8;
    font-size: $text-14;
    line-height: 1.5715;
    background-color: $gray-1;
    background-image: none;
    transition: all 0.3s;
    display: inline-block;
    min-width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid $gray-5;
    border-radius: $border-radius;
    &::placeholder {
      color: $gray-6;
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }
    &:hover {
      border-color: $blue-5;
      border-right-width: 1px !important;
      + {
        .bs-form-item-children-icon {
          opacity: 0;
          transition: opacity 0.24s linear 0.24s;
        }
      }
      .bs-input-number-handler-wrap {
        opacity: 1;
      }
    }
    &:focus,
    &:focus-within {
      border-color: $blue-5;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: $input-number-shadow;
    }
  }
  &.bs-input-number-error {
    .bs-input-number {
      border-color: #f5222d;
      &:focus,
      &:focus-within {
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.5);
      }
    }
    .bs-input-number-label-suffix {
      color: #f5222d;
    }
  }
  .bs-input-number-inner {
    position: relative;
  }
  .bs-input-number-label-prefix {
    color: $gray-9;
    font-size: $text-14;
    line-height: $text-style-2-line-height;
    margin-bottom: 0.125rem;
  }
  .bs-input-number-label-suffix {
    color: $gray-7;
    font-size: $text-14;
    line-height: $text-style-2-line-height;
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
  .bs-input-number-disabled {
    color: $gray-6;
    background-color: $gray-3;
    cursor: not-allowed;
    opacity: 1;
    &:hover {
      border-color: $gray-5;
      border-right-width: 1px !important;
    }
    .bs-input-number-input {
      cursor: not-allowed;
    }
    .bs-input-number-handler-wrap {
      display: none;
    }
  }
}
.bs-input-number-focused {
  border-color: $blue-5;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: $input-number-shadow;
}

.bs-input-number[disabled] {
  color: $gray-6;
  background-color: $gray-3;
  cursor: not-allowed;
  opacity: 1;
  &:hover {
    border-color: $gray-5;
    border-right-width: 1px !important;
  }
}
textarea.bs-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.bs-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
  padding: 0;
  input {
    height: 38px;
  }
}
.bs-input-number-sm {
  padding: 0px 7px;
  padding: 0;
  input {
    height: 22px;
    padding: 0 7px;
  }
}
.bs-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: $gray-7;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
  &:active {
    background: $gray-3;
  }
  &:hover {
    .bs-input-number-handler-up-inner {
      color: $blue-5;
    }
    .bs-input-number-handler-down-inner {
      color: $blue-5;
    }
  }
}
.bs-input-number-handler-up-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: $gray-7;
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
  top: 50%;
  margin-top: -5px;
  text-align: center;
  > * {
    line-height: 1;
  }
  &::before {
    display: none;
  }
  .bs-input-number-handler-up-inner-icon {
    display: block;
  }
  .bs-input-number-handler-down-inner-icon {
    display: block;
  }
}
.bs-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: $gray-7;
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  > * {
    line-height: 1;
  }
  &::before {
    display: none;
  }
  .bs-input-number-handler-up-inner-icon {
    display: block;
  }
  .bs-input-number-handler-down-inner-icon {
    display: block;
  }
}
.bs-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  color: $gray-8;
  outline: 0;
  transition: all 0.3s linear;
  &::placeholder {
    color: $gray-6;
  }
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}
.bs-input-number-input[type='number'] {
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
.bs-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: $gray-1;
  border-left: 1px solid $gray-5;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
  .bs-input-number-handler {
    .bs-input-number-handler-up-inner {
      display: inline-block;
      font-size: 0.625rem;
      min-width: auto;
      margin-right: 0;
    }
    .bs-input-number-handler-down-inner {
      display: inline-block;
      font-size: 0.625rem;
      min-width: auto;
      margin-right: 0;
    }
  }
  &:hover {
    .bs-input-number-handler {
      height: 40%;
    }
  }
}
.bs-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
  &:hover {
    height: 60% !important;
  }
}
.bs-input-number-handler-down {
  top: 0;
  border-top: 1px solid $gray-5;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  &:hover {
    height: 60% !important;
  }
}
.bs-input-number-handler-up-disabled {
  cursor: not-allowed;
  &:hover {
    .bs-input-number-handler-up-inner {
      color: $gray-6;
    }
  }
}
.bs-input-number-handler-down-disabled {
  cursor: not-allowed;
  &:hover {
    .bs-input-number-handler-down-inner {
      color: $gray-6;
    }
  }
}

.input-number-error-caption {
  color: $red-6;
}
.input-number-error-border {
  border-color: $red-6;
  &:focus,
  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.5);
    border-color: $red-5;
  }
  &:hover {
    border-color: $red-5;
  }
}

.prospects-filter-container {
  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.4375rem;
  }

  ::-webkit-scrollbar-track {
    background: $gray-1;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-23;
    border-radius: 0.25rem;
  }
  .label-text {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0em;
    text-align: left;
    color: $gray-15;
  }
  .basic-multi-select {
    .css-1k1cxo2-control,
    .css-167jrn2-control {
      background-color: $gray-19 !important;
    }
  }
  .filter-container {
    .prospect-filter-date-piker {
      .react-datepicker-wrapper {
        width: 100% !important;
        .react-datepicker__input-container {
          .date-picker-container {
            background: $gray-19 !important;
          }
        }
      }
    }
  }
}

.date-picker-container {
  display: flex !important;
  border: 0.0625rem solid $gray-13;
  border-radius: 0.25rem;
  padding-left: 0 !important;
  .cal-icon {
    color: $gray-11;
    font-size: 1rem;
  }
  .close-icon {
    font-size: 1rem;
  }
  .input {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1rem;
    color: $blue-15;
    font-weight: 500;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: $gray-15;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  background: $gray-19 !important;
}

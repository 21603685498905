.warmup-banner {
  padding: 0.75rem 1.5rem;
  span {
    max-width: 56.75rem;
  }
}
.warmup-container {
  .header-container {
    margin-left: 1.875rem;
    margin-right: 1.875rem;

    .bs-select-box {
      width: 19.6875rem;
      background: $gray-19;
      color: $blue-15;
      margin-right: 1.875rem;

      img {
        margin-right: 0.5rem;
      }
    }

    label {
      margin-bottom: 0 !important;
    }

    .reconnect-btn {
      cursor: pointer;
      p {
        font-weight: $font-weight-medium;
      }
      img {
        color: $gray-15;
        margin-right: 0.5rem;
      }
    }
    .warmup-setting-btn {
      margin-left: 1rem;
      color: $gray-15;
      border-color: $gray-15;
      img {
        margin-right: 0.5rem;
      }
      &:hover {
        color: $gray-15;
        border-color: $gray-15;
      }
    }
  }
}

.email-warmup-report-stat {
  margin: 0 1.875rem;
  border: 0.0625rem solid $gray-14;
  border-radius: 0.25rem !important;
  overflow: hidden;

  &--header {
    height: 2.5rem;
    background-color: $gray-16;
    border-bottom: 0.0625rem solid $gray-14;
    padding: 0 2.5rem;
  }
  &__item {
    font-weight: $font-weight-semibold;
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: $gray-12;
    padding: 0;
    span {
      font-weight: normal;
      margin-left: 0.125rem;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 12.5rem;
    }
    &:nth-of-type(3) {
      margin-left: 4.375rem;
    }
  }

  &--body {
    padding: 0 2.5rem;
  }
  &--body-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      min-width: 12.5rem;
      max-width: 12.5rem;
    }

    &.deliverability-item {
      width: 43.75rem;
    }
  }
}

.email-warmup-separator {
  width: 0rem;
  height: 10.9375rem;
  background-color: $gray-13;
  border: 0.0625rem solid $gray-13;
  border-radius: 0.25rem;
  margin-left: 0.3125rem;

  &--large {
    margin-right: 1.5rem;
  }

  &--small {
    height: 7.25rem;
  }
}

.email-deliverability-stats {
  margin-top: 1.25rem;
  &:last-of-type {
    margin-bottom: 1.25rem;
  }
  p {
    line-height: 0.9375rem;
    color: $gray-15;
  }
  h4 {
    margin-top: 0.625rem;
    line-height: 1.25rem;
    color: $blue-15;
    div {
      margin-left: 0.625rem;
    }
  }
}

.chart-tooltip {
  padding: 0.625rem 1rem;

  width: 10.75rem;
  height: 2.5rem;

  background: $blue-16;

  box-sizing: border-box;
  box-shadow: 0.1875rem 0.5rem 20.25rem -0.375rem rgba(15, 34, 67, 0.16);
  border-radius: 0.5rem;

  &__legend {
    width: 0.125rem;
    margin-right: 0.5rem;
    box-sizing: border-box;

    &--spam {
      background: $red-19;
      border: 0.03125rem solid $red-16;
    }
    &--inbox {
      background: $green-11;
      border: 0.03125rem solid $green-16;
    }
  }

  &__label,
  &__percent {
    line-height: 1.25rem;
    color: $gray-1;
  }
}

.chart-legend {
  &--wrapper {
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  &__marker {
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    box-sizing: border-box;

    &.inbox {
      background: $green-11;
      border: 0.0625rem solid $green-16;
    }

    &.spam {
      background: $red-19;
      border: 0.0625rem solid $red-16;
    }
  }
  &__label {
    font-weight: $font-weight-normal;
    font-size: 0.75rem;
    color: $blue-15;
  }
}

.deliverability-stats {
  &__wrapper {
    margin-bottom: 1rem;
    span {
      line-height: 1.25rem;
      &:first-of-type {
        width: 6.25rem;
        margin-right: 1.25rem;
        color: $gray-15;
      }
      &:last-of-type {
        color: $blue-15;
      }
    }
  }
}

.setting-input-row {
  margin-bottom: 1.25rem;
  .bs-input-number-container .bs-input-number-disabled {
    opacity: 0.5;
  }
}
.settings-input {
  .bs-input-number-input {
    border-radius: 0.25rem;
    height: 2.25rem;
  }
  .bs-input-number-handler-wrap {
    background: $gray-16;
  }

  &--label,
  label.bs-input-label-prefix {
    font-size: $text-12;
    font-weight: $font-weight-semibold;
    line-height: 0.9375rem;
    color: $gray-15;
    margin-bottom: 0.3125rem;
    &--recommendation {
      font-weight: normal;
    }
  }
  .bs-input-number-input-wrap,
  .bs-input-affix-wrapper {
    background: $gray-19;
    border-radius: 0.25rem;
    height: 2.25rem;
    input {
      background: $gray-19;
      font-size: $text-14;
      line-height: 1.0625rem;
      color: $blue-15;
    }
  }
}

.error-label {
  display: flex;
  margin-top: 0.3125rem;
  font-size: 0.625rem;
  color: $red-12;
}

.empty-list--container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .empty-list {
    &__photo-vertical {
      height: auto;
      margin-bottom: 1.5rem;
    }
    &__title-vertical {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    &__content-vertical {
      height: auto;
      p {
        margin: 0;
        margin-bottom: 0.375rem;
        text-align: center;
        width: 45.1875rem;
        color: $gray-15;
      }
    }
  }
}

.table-wrapper {
  height: 100%;
  .table-list {
    height: calc(100vh - 13.5rem);

    &.email-warmup-table-list {
      .grid-flex {
        justify-content: flex-start;
      }
      .react-bootstrap-table {
        .table-organism {
          .table-organism-header > tr > th:last-of-type {
            text-align: left !important;
          }
        }
      }
    }
  }
}

.tooltip__max-width {
  .tooltip-inner {
    width: 100%;
    max-width: 100%;
  }
}

.green-ellipse {
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  margin-left: 0.625rem;
  background-color: $green-16;
}

.email-warmup-report-select {
  .transfer-container {
    .transfer-body {
      .transfer-content {
        .transfer-list > li {
          height: 2.5rem;
          padding: 0;
          &:hover {
            background-color: transparent;
            cursor: default;
          }

          .option {
            width: 100%;
            height: 100%;
            padding: 0.4rem 1rem;
            &:hover {
              cursor: pointer;
              background-color: $blue-17;
            }
            &.disabled {
              &:hover {
                background-color: transparent;
                cursor: not-allowed;
              }
              span {
                color: $gray-15;
              }
            }
          }
        }
      }
    }
  }
}

.from-name-text {
  padding-bottom: 0.1875rem;
}

.from-email-text {
  color: $gray-12;
}

.warmup-settings-modal {
  .bs-modal-footer {
    .bs-modal-footer-action-buttons {
      button {
        margin: 0 !important;
        &:first-of-type {
          margin-right: 1.25rem !important;
        }
      }
    }
  }
}

.cancel-btn {
  &.btn-ghost-primary {
    color: $gray-15;
    border-color: $gray-15;
    font-weight: $font-weight-medium;
  }
}

.search-email-warmup,
.add-email-account-btn {
  height: 2rem !important;
}

.email-warmup-switch-tooltip {
  .tooltip-inner {
    text-align: left !important;
  }
}

.enable-email-warmup-consent {
  color: $blue-15 !important;
  user-select: none;
  .bs-checkbox-inner:after {
    top: 45% !important;
  }
}

.email-warmup-collaboration {
  background-color: $gray-19;
  min-height: calc(100vh - 62px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3.125rem;

  &--heading {
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: $gray-17;

    margin: 0;
    padding: 0;
  }

  &--logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .logo-container {
      width: 178px;
      height: 72px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: #ffffff;
      box-shadow: 0px 0px 32px rgba(31, 41, 55, 0.03),
        0px 10.6829px 32px rgba(31, 41, 55, 0.03);
      border-radius: 8px;
    }
  }

  &--desc {
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    color: $gray-15;
    margin: 0;
    max-width: 46.25rem;

    a {
      color: $blue-11;
    }
  }

  &--description {
    font-weight: $font-weight-medium;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray-15;

    margin: 0;
    padding: 0;
  }

  &--buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
  }
}

.email-verification-error-container {
  .email-verification-error-content {
    .email-verification-error-message {
      display: flex;
      flex-direction: column;
    }
    .verification-credits {
      color: $gray-15;
      margin-top: 1rem;
    }
    .email-verification-error-table {
      max-width: 21.625rem;
      height: 4.6875rem;

      background: $gray-19;

      display: flex;
      flex-direction: column;

      border: 0.0625rem solid $gray-16;
      border-radius: 0.25rem;

      .credit-table {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0.625rem 1rem 0.5rem 1rem;

        &:first-of-type {
          border-bottom: 0.0625rem solid $gray-16;
        }

        p {
          font-size: 0.75rem !important;
          font-weight: $font-weight-medium !important;
          color: $gray-15 !important;
        }
      }
    }
  }
}

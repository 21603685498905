.prospects-filters-modal {
  animation-name: moveInRight;
  animation-duration: 0.3s;
  animation-timing-function: linear;

  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.4375rem;
  }

  ::-webkit-scrollbar-track {
    background: $gray-1;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-23;
    border-radius: 0.25rem;
  }

  .modal-dialog {
    width: 72vh;
    max-width: none;
    height: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
    border-radius: 1.25rem;
  }

  .modal-content {
    height: 100%;
    max-height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-header {
    display: flex;
    flex: 1;
    padding: 1.875rem 1.875rem;
    max-height: 2.5rem;
    min-height: 2rem;
  }

  .modal-body {
    padding: 1.87rem;
    height: 27.3rem;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 4rem) !important;
    overflow-y: scroll;

    .multi-select {
      &__control {
        background-color: $gray-19;
      }
      &__placeholder {
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        color: $gray-11;
      }
    }
  }

  .modal-footer {
    padding: 0 1.87rem;
    padding-top: 0.4375rem;
  }

  .rst-btn {
    color: $blue-11;
    font-weight: 500;
  }

  .radio-input-wrapper {
    margin-bottom: 1.25rem;

    .radio-input-container .rb-group {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      .bs-radio-wrapper {
        margin-top: 0.625rem;
        margin-left: 0;
        display: flex;
        align-items: center;

        .bs-radio {
          top: 0 !important;
          .bs-radio-inner {
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              position: static;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .radio-input-btn {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          h2 {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            margin: 0;
          }

          p {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            margin: 0;
          }
        }
      }
    }
  }

  .show-conversation-title {
    display: flex;
    align-items: center;
    line-height: 1.125rem !important;
  }

  .disabled-filter {
    opacity: 0.35;
    pointer-events: none;
  }

  .enabled-radio {
    color: #1f2937;
  }

  .disabled-radio {
    color: #9ca3af;
  }

  .custom-tooltip {
    width: 10rem;
  }
}

.bs-notification-container {
  z-index: 1060;
  // positioned elements are in the same stack when using zIndex
  position: relative;
}

.bs-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  &.left {
    align-items: flex-start;
  }
  &.right {
    align-items: flex-end;
  }
  &.center {
    align-items: center;
  }
  &.top {
    top: 30px;
    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
    .notification-container {
      margin-top: 15px;
      // override bootstrap's .toast:not(:last-child) margin-bottom property.
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &.bottom {
    bottom: 30px;
    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
    .notification-container {
      margin-top: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bs-toast {
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 0;
    background: $gray-1;
    min-width: 385px;
  }
  .bs-toast-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: $gray-9;
    background-color: $gray-1;
    background-clip: padding-box;
    border-bottom: 0;
    font-size: $text-16;
    line-height: $text-style-3-line-height;
    font-weight: $font-weight-normal;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      &:focus {
        outline: 0;
      }
    }
  }
  .bs-toast-body {
    font-size: $text-14;
    line-height: $text-style-2-line-height;
    color: $gray-8;
  }
}

.notification-container {
  background: $gray-1;
  border-radius: $border-radius;
  font-size: $text-14;
  line-height: $text-style-2-line-height;
  color: $gray-8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0;
  padding: 0.625rem 1rem;
  flex-basis: unset;
  max-width: fit-content;
  border: 0;
  backdrop-filter: unset;
  pointer-events: auto;
  .notification-icon {
    font-size: $text-18;
    margin-right: 5px;
    i {
      position: relative;
      top: 1px;
    }
    &.icon-basic {
      i {
        color: $blue-6;
      }
    }
    &.icon-error {
      i {
        color: $red-6;
      }
    }
    &.icon-success {
      i {
        color: $green-6;
      }
    }
    &.icon-warning {
      i {
        color: $gold-6;
      }
    }
  }
  .notification-desc {
    display: inline-flex;
    margin-left: 23px;
    p {
      margin-bottom: 0;
    }
  }
}

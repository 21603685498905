.tags-autosuggest-container {
  ::-webkit-scrollbar {
    width: 5px;
    height: 4.93rem;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 4px;
  }
  &.tags-container-contact-modal {
    #react-autowhatever-1 {
      left: 1.5rem !important;
    }
  }
}

.add-new-tag {
  background: #eff6ff;
  color: #1d4ed8;
  cursor: pointer;
}

.tags-container {
  margin-top: 0.9rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  overflow-y: auto;
  height: 9.375rem;

  &.tag-container-style {
    height: 5.375rem;
  }
}

.no-tags-text {
  color: #9ca3af;
  text-align: center;
}

.selected-tags-inline-input {
  border: none;
  outline: none;
  width: 30rem;
}

.tags-row-gap {
  row-gap: 10px;
}

.tags-saved-scroll {
  max-height: 4.875rem;
  overflow-y: auto;
}

.fixed-height-input-container {
  max-height: 6.25rem;
  overflow-y: auto;
  padding: 0.5rem;
  border: 0.0625rem solid #d1d5db;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tags-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
}

.selected-tags-inline-input {
  width: 100%;
  border: none;
  outline: none;
}

.error-text {
  color: #b91c1c;
  font-size: 12px;
  margin-top: 0.25rem;
}

.loader-background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1025;
  background: #ffffff;
  opacity: 1;
  //transition: opacity 0.15s linear;
  &.fade {
    opacity: 0;
  }
}

.loader-wrapper {
  width: 32px;
  height: 32px;
  margin: auto;
}

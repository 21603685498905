.bs-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: $text-14;
  list-style: none;
  display: inline-block;
  line-height: $text-style-2-line-height;
  color: $gray-10;
  cursor: pointer;

  .bs-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: $text-14;
    line-height: $text-style-2-line-height;
    list-style: none;
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;

    &:hover {
      &::after {
        visibility: visible;
      }

      .bs-checkbox-inner {
        border-color: $blue-11;
      }
    }

    +span {
      padding-right: 8px;
      padding-left: 8px;
    }

    &.bs-checkbox-indeterminate {
      .bs-checkbox-input {
        &:checked {
          ~ {
            .bs-checkbox-inner {
              &:after {
                top: 50%;
                left: 22%;
                width: 5.71428571px;
                height: 9.14285714px;
              }
            }
          }
        }
      }
    }
  }

  +.bs-checkbox-wrapper {
    margin-left: 8px;
  }

  .bs-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    &:checked {
      ~ {
        .bs-checkbox-inner {
          background-color: $blue-11;
          border-color: $blue-11;

          &:after {
            position: absolute;
            display: table;
            border: 2px solid $gray-1;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            content: ' ';
          }
        }
      }
    }

    &:focus {
      +.bs-checkbox-inner {
        border-color: $blue-11;
      }
    }
  }

  &:hover {
    .bs-checkbox-input {
      ~ {
        .bs-checkbox-inner {
          border-color: $blue-11;
        }
      }
    }

    .bs-checkbox-disabled {
      &::after {
        visibility: hidden;
      }
    }

    .bs-checkbox {
      &::after {
        visibility: visible;
      }
    }
  }

  .bs-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: $gray-1;
    border: 1px solid $gray-11;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:after {
      position: absolute;
      top: 50%;
      left: 22%;
      display: table;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid $gray-1;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: ' ';
    }
  }

  .bs-checkbox-indeterminate {
    .bs-checkbox-inner {
      background-color: $gray-1;
      border-color: $gray-11;

      &::after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: $blue-11;
        border: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
    }
  }

  &.bs-checkbox-wrapper-disabled {
    .bs-checkbox {
      &.bs-checkbox-disabled {
        cursor: not-allowed;

        .bs-checkbox-inner {
          background-color: $gray-3;
          border-color: $gray-11 !important;

          &::after {
            background-color: $gray-3;
            border-color: $gray-11;
            animation-name: none;
          }
        }

        .bs-checkbox-checked {
          .bs-checkbox-inner {
            &::after {
              animation-name: none;
            }
          }
        }

        .bs-checkbox-input {
          cursor: not-allowed;
        }

        +span {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }

        &:hover {
          &::after {
            visibility: hidden;
          }
        }

        &.bs-checkbox-indeterminate {
          .bs-checkbox-inner {
            &::after {
              background-color: $gray-11;
              border-color: $gray-11;
            }
          }
        }
      }
    }
  }
}
.merge-variable-tags {
  &--overlay {
    padding: 0.375rem 0 0 0 !important;
  }

  &--container {
    border: 0.0625rem solid #e5e7eb;
    padding: 0.75rem 0rem;
    width: 15.5rem;
    height: 22.625rem;
  }

  &--tabs {
    .bs-tabs-nav {
      margin-bottom: 0 !important;
      &::before {
        display: none;
      }
      .bs-tabs-nav-list {
        width: 100%;
        padding: 0rem 0.75rem;
        gap: 1rem;
        .bs-tabs-tab {
          margin: 0;
          padding: 0 0 0.375rem 0;
          .bs-tabs-tab-btn {
            display: flex;
            align-items: center;
            font-weight: 500;
            position: relative;
            .new-badge {
              position: absolute;
              right: -2.25rem;
              width: 1.75rem;
            }
            div {
              display: flex;
              align-items: center;
            }
          }
          &.bs-tabs-tab-active {
            color: #1d4ed8;
          }
        }
        .bs-tabs-ink-bar {
          border-radius: 0.25rem;
          background: #1d4ed8;
        }
      }
    }
  }

  &--search-input.bs-input-affix-wrapper {
    height: 2.125rem;
    padding: 0.375rem 0.75rem;
    margin: 0.75rem 0rem;
    border: none;
    border-radius: 0;
    border-top: 0.0625rem solid #f3f4f6;
    border-bottom: 0.0625rem solid #f3f4f6;
    background: #f9fafb;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    line-height: 1rem;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border-color: #f3f4f6;
    }

    &:focus,
    &:focus-within,
    &:active {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }

    &::before {
      display: none;
    }

    .input-icons-left {
      margin: 0 !important;
      .bs-input-prefix {
        margin: 0 !important;
        margin-top: 0.125rem !important;
        i {
          font-size: 1rem;
          color: #9ca3af;
        }
      }
    }

    input {
      height: 20px;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;

      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-17;
      background-color: transparent;

      &::placeholder {
        color: #9ca3af;
      }

      &:hover,
      &:focus-within {
        border: none;
        border-color: transparent;
        border-right-width: 0;
        box-shadow: none;
      }
    }
  }

  &--list {
    height: 15.75rem;
    overflow-y: auto;

    .no-tags-found {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      p {
        margin: 0;
        margin-top: 0.5rem;
        color: #6b7280;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }

    .merge-tag-item {
      width: 100%;
      padding: 0.375rem 0.75rem;
      transition: all 0.2s ease;

      &:hover {
        background-color: #eff6ff;
      }

      span {
        display: block;
        color: $blue-15;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
      }

      &.disabled-tag {
        cursor: not-allowed;
        span {
          color: #9ca3af !important;
        }
      }
    }

    .shd-accordion {
      width: 100%;

      .accordion__item {
        padding: 0;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none;
        background-color: transparent !important;
      }

      .accordion__heading {
        padding: 0.375rem 0.75rem;
        transition: all 0.2s ease;
        &:hover {
          background: #eff6ff;
        }
      }

      .accordion__button {
        padding: 0;
        color: #1f2937;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        background-color: transparent !important;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before {
          display: none;
        }

        svg {
          width: 1.125rem;
          height: 1.125rem;
          color: #9ca3af;
        }
      }

      .accordion__panel {
        border: none;
        padding: 0.5rem 0rem;

        border-top: 1px solid #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        background: rgba(249, 250, 251, 0.6);

        .merge-tag-item {
          display: flex;
          align-items: center;
          align-self: stretch;
          padding: 0.375rem 1.75rem;
          transition: all 0.2s ease;

          span {
            color: #1f2937;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:hover {
            background: #eff6ff;
          }
        }
      }
    }
  }
}

.reply-forward-editor-tags-dropdown {
  .merge-variable-tags--container {
    height: 14.5rem;
  }
  .merge-variable-tags--list {
    height: 7.75rem;
  }
}

.tags-tab-popover {
  width: 11.875rem !important;
  margin-top: 0.5rem !important;
  z-index: 1062;
  padding: 0 !important;
  border-radius: 0.25rem !important;
  background-color: $blue-15 !important;
  box-shadow: none !important;
  .arrow {
    &::before,
    &::after {
      border-bottom-color: $blue-15 !important;
    }
  }
  .bs-popover-inner-content {
    padding: 0.625rem !important;
    p {
      color: $white;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
      span {
        display: block;
      }
      .tags-tab-popover-link {
        color: #9ca3af;
        text-decoration-line: underline;
        text-underline-offset: 0.125rem;
      }
    }
  }
}

.notification-circle {
  align-items: end;
  justify-content: end;
  cursor: pointer;
}

.notification {
  height: 1.3rem;
  width: 1.3rem;
  &:hover {
    color: $gray-15 !important;
  }
}

.notification-red {
  min-width: 2rem;
  height: 2rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  background: #fee2e2;
  margin-right: 0 !important;
}
.notification-green {
  min-width: 2rem;
  height: 2rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  background: #d1fae5;
  margin-right: 0 !important;
}
.notification-purple,
.notification-yellow {
  min-width: 2rem;
  height: 2rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  background: #ede9fe;
  margin-right: 0 !important;
}

.notification-yellow {
  background: #fffbeb;
}

.notification-gray {
  min-width: 2rem;
  height: 2rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
  background: #f3f4f6;
  margin-right: 0 !important;
}
.no-notifications {
  height: 25rem;
}

.no-notification-text {
  color: #6b7280;
  font-size: 12px;
  font-weight: 400;
}

.notification-end-text {
  color: #9ca3af;
  font-size: 12px;
  font-weight: 500;
}

.active-notification {
  background-color: #f3f4f6;
  border-radius: 1rem;
}

.custom-position {
  width: 0.4169rem !important;
  height: 0.4169rem !important;
  border: 0.0625rem solid $gray-1;
  top: 10px !important;
  right: 10px !important;
}

.custom-position-icon {
  top: 5px !important;
  right: 4px !important;
}

.notification-view-area {
  height: 30rem;
  width: 30rem;
  background-color: white;
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  border: 2px solid #f3f4f6;
  border-radius: 6px;
  overflow-y: scroll;
  z-index: 4 !important;
}

.notification-font {
  font-size: 14px;
  font-weight: 600;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-pointer {
  cursor: progress;
}

.text-dark-red {
  color: #b91c1c;
}

.notification-content {
  color: #1f2937;
  font-size: 12px;
  span {
    font-weight: 600;
    font-size: 12px;
  }
  .notification-data {
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
  }
  .notification-data-bold {
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
    cursor: pointer;
  }
}

.action-button {
  color: #1d4ed8;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &.view-email-accounts-btn {
    white-space: nowrap;
    position: absolute;
    right: 0;
  }
}

.mark-read {
  color: #1d4ed8;
  font-weight: 400;
  cursor: pointer;
}

.date-time-content {
  color: #9ca3af;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0.5rem !important;
  // margin-bottom: 0.8rem !important;
}

.overflow-auto {
  overflow: auto;
}

#NF_badge_cont {
  position: absolute !important;
  top: -0.5rem;
}
.NF_badge_cont.NF_visible {
  visibility: visible;
  pointer-events: auto;
}
.NF_badge_cont {
  display: block;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}
.NF_badge.NF_visible {
  opacity: 1;
  transform: scale(1);
}
.NF_badge.NF_visible {
  background: linear-gradient(0deg, #ef4444, #ef4444),
    linear-gradient(0deg, rgba(17, 24, 39, 0), rgba(17, 24, 39, 0));
}
.NF_badge {
  display: block;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  color: $white-text;
  cursor: pointer;
  position: absolute;
  top: 0.4375rem;
  left: 1rem;
  will-change: scale;
  transition: all 0.3s;
  font-family: Inter;
  font-size: 0.5625rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: center;
}

.NF_animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.NF_bounce {
  animation-name: NF_bounce;
  transform: center bottom;
}
@keyframes NF_bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

.NF_loder {
  width: 100%;
  height: 30rem;
}

.notification-row {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.notification-progress-bar {
  .progress-bar-wrapper {
    height: 0.3125rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    max-width: 14.75rem;
    span {
      border-radius: 0.25rem;
      background-color: $green-14 !important;
    }
  }

  .download-report {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $blue-11;
    margin-top: 0.5rem;
    .download-report-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.action-button-wrap {
  white-space: nowrap;
}

.trulyinbox-email-account-consent-modal {
  .modal-content {
    border-radius: 0.5rem !important;
  }

  .modal-dialog {
    max-width: 34.313rem;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 28.375rem !important;
    padding: 0.5rem !important;

    @media screen and (max-width: $screen-1366) {
      overflow-y: auto;
    }
    .trulyinbox-email-account-consent-wrapper {
      width: 100%;
      display: flex;
      .header-content {
        color: $gray-12;
        font-family: 'Inter', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        margin-bottom: 0;
      }
      .header-points {
        margin-left: -1rem;
        display: flex;
        flex-direction: column;
        gap: 0.375rem;
        li {
          color: $gray-15;
          font-family: 'Poppins', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.125rem;
        }
      }
      .consent-container {
        margin-top: 0.563rem;
        display: flex;
        width: 31.313rem;
        padding: 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        border-radius: 0.5rem;
        background: $blue-17;
        p {
          color: $blue-15;
          font-family: 'Poppins', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.125rem;
        }
        ul {
          margin-left: -1rem;
          color: $gray-15;
          font-family: 'Poppins', sans-serif;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.125rem;
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
        }
      }
      .terms-and-conditions {
        margin-top: 2.188rem;
        .bs-checkbox-wrapper {
          color: $blue-15;
          font-family: 'Inter', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }
    }
  }

  .modal-footer {
    padding-bottom: 0.5rem;
    .submit-btn {
      background: $blue-11;
      color: $white;
      &.disabled {
        opacity: 0.35;
      }
    }
  }
}

.bs-progress-bar-container {
  display: flex;
  .bs-progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden; // force rounded corners by cropping it
    line-height: 0;
    background-color: $gray-3;
    flex: 0.9;
    margin: 7px 10px 7px 0;
  }
  &.square {
    .bs-progress {
      border-radius: 0;
    }
  }
  &.round {
    .bs-progress {
      border-radius: 0.5rem;
    }
  }
  &.mini {
    .bs-progress {
      height: 0.375rem;
    }
    span {
      font-size: $text-12;
      line-height: $text-style-1-line-height;
    }
  }
  &.error {
    .bs-progress-bar {
      background: $red-6;
    }
    span {
      i {
        color: $red-6;
      }
    }
  }
  &.success {
    .bs-progress-bar {
      background: $green-6;
    }
    span {
      i {
        color: $green-6;
      }
    }
  }
  span {
    flex: 0.1;
    text-align: left;
    font-size: 14px;
  }
  .bs-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: $progress-bar-color;
    text-align: center;
    white-space: nowrap;
    background-color: $blue-6;
    @include transition($progress-bar-transition);
  }
}

/////

/* Progress Bars */

.bs-progress-circle {
  position: relative;
  display: inline-flex;
  margin: $spacing;
  width: $size-lg;
  height: $size-lg;
  border-radius: 50%;
  background-color: $background-color-default;
  align-items: center;
  &.mini {
    width: $size-sm;
    height: $size-sm;
    &:before {
      width: $size-sm-before;
      height: $size-sm-before;
    }
  }
  &.error {
    i {
      color: $background-color-fill-error;
    }
    &:after {
      background: $background-color-fill-info;
    }
  }
  &.success {
    i {
      color: $background-color-fill-success;
    }
    &:after {
      background: $background-color-fill-info;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: $size-lg-before;
    height: $size-lg-before;
    border-radius: 50%;
    background-color: $gray-1;
  }
  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $background-color-fill-info;
  }
}

.bs-progress-circle-number {
  position: absolute;
  width: 100%;
  text-align: center;
  color: $gray-8;
  margin: 0;
  font-weight: $font-weight-normal;
}

/**
* $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
*/

@for $i from 0 through $loops {
  .bs-progress-circle.progress--#{$i * $step}:after {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(
          90deg,
          $background-color-default 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          $nextDeg,
          $background-color-fill-info 50%,
          $background-color-default 50%,
          $background-color-default
        );
    } @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient(
          $nextDeg,
          $background-color-fill-info 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          270deg,
          $background-color-fill-info 50%,
          $background-color-default 50%,
          $background-color-default
        );
    }
  }

  .bs-progress-circle.error.progress--#{$i * $step}:after {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(
          90deg,
          $background-color-default 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          $nextDeg,
          $background-color-fill-error 50%,
          $background-color-default 50%,
          $background-color-default
        );
    } @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient(
          $nextDeg,
          $background-color-fill-error 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          270deg,
          $background-color-fill-error 50%,
          $background-color-default 50%,
          $background-color-default
        );
    }
  }

  .bs-progress-circle.success.progress--#{$i * $step}:after {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(
          90deg,
          $background-color-default 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          $nextDeg,
          $background-color-fill-success 50%,
          $background-color-default 50%,
          $background-color-default
        );
    } @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient(
          $nextDeg,
          $background-color-fill-success 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(
          270deg,
          $background-color-fill-success 50%,
          $background-color-default 50%,
          $background-color-default
        );
    }
  }
}

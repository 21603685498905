.temp-auth-indicator-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;

  .temp-auth-indicator {
    margin: auto;
    text-align: center;
  }
}

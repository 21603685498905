.include-phone-numbers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  .switchToggle {
    width: 2.25rem;
    height: 1.125rem;

    display: flex;
    justify-content: center;
    align-items: center;

    input:checked + label {
      background: $blue-11;
    }

    label {
      margin: 0;
      width: 2.25rem;
      min-width: 2.25rem;
      height: 1.125rem;

      display: flex;
      align-items: center;

      &::after,
      &::before {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }

  p {
    color: $gray-15;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    margin: 0;
  }
}

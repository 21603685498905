.btn-dropdown-custom {
  color: $blue-6;
  font-size: $text-14;
  i {
    position: relative;
    top: 2px;
    margin-left: 2px;
  }
  &:hover {
    color: $blue-5;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
  &:active,
  &.active {
    color: $gray-6;
  }
  &.disabled,
  &:disabled {
    color: $blue-7;
  }
}

// The dropdown wrapper (`<div>`)
.dropup-custom,
.dropright-custom,
.dropdown-custom,
.dropleft-custom {
  position: relative;
}

.dropdown-toggle-custom {
  white-space: nowrap;

  // Generate the caret automatically
}

// The dropdown menu
.dropdown-menu-custom {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-custom-min-width;
  padding: $dropdown-custom-padding-y 0;
  margin: $dropdown-custom-spacer 0 0; // override default ul
  @include font-size($text-14);
  color: $dropdown-custom-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-custom-bg;
  background-clip: padding-box;
  box-shadow: $dropdown-custom-box-shadow;
  @include border-radius($border-radius);
  .dropdown-item-custom {
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu#{$infix}-right {
      right: 0;
      left: auto;
    }
  }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.

.dropup-custom {
  .dropdown-menu-custom {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-custom-spacer;
  }

  .dropdown-toggle-custom {
    @include caret(up);
  }
}

.dropright-custom {
  .dropdown-menu-custom {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-custom-spacer;
  }

  .dropdown-toggle-custom {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft-custom {
  .dropdown-menu-custom {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-custom-spacer;
  }

  .dropdown-toggle-custom {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu-custom {
  &[x-placement^='top'],
  &[x-placement^='right'],
  &[x-placement^='bottom'],
  &[x-placement^='left'] {
    right: auto;
    bottom: auto;
  }
}
.dropdown-submenu > {
  .dropdown-menu-custom {
    &[x-placement^='bottom'] {
      top: -2.1875rem !important;
      left: 100% !important;
      margin-top: -1px;
      margin-left: 0px;
      border-radius: $border-radius;
      display: none;
    }
    &[x-placement^='right'] {
      top: 0;
      left: -22px !important;
      margin-top: -4px;
      margin-left: 0px;
      border-radius: $border-radius;
      display: none;
    }
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider-custom {
  @include nav-divider(
    $dropdown-custom-divider-bg,
    $dropdown-custom-divider-margin-y,
    true
  );
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item-custom {
  display: flex;
  align-items: center;
  width: 100%; // For `<button>`s
  padding: 0.4375rem 1rem;
  clear: both;
  font-weight: $font-weight-normal;
  color: $gray-8;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-custom-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-custom-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-custom-inner-border-radius);
    }
  }

  @include hover-focus() {
    color: $dropdown-custom-link-hover-color !important;
    text-decoration: none;
    @include gradient-bg($dropdown-custom-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-custom-link-active-color;
    text-decoration: none;
    outline: 0;
    @include gradient-bg($dropdown-custom-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-custom-link-disabled-color;
    // Remove CSS gradients if they're enabled
    pointer-events: none;
    @if $enable-gradients {
      background-image: none;
    }
    &:hover {
      background-color: $gray-3;
      outline: 0;
    }
    &:focus {
      background-color: $gray-3;
      outline: 0;
    }
  }
  i {
    font-size: $text-16;
  }
  span {
    margin: 0 4px;
  }
}

.dropdown-menu-custom.show {
  display: block;
}

// Dropdown section headers
.dropdown-header-custom {
  display: block;
  padding: $dropdown-custom-header-padding;
  margin-bottom: 0; // for use with heading elements
  @include font-size($font-size-sm);
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text-custom {
  display: block;
  padding: $dropdown-custom-item-padding-y $dropdown-custom-item-padding-x;
  color: $dropdown-custom-link-color;
}

.dropdown-submenu {
  position: relative;
  display: flex;
}
.dropdown-submenu > .dropdown-menu-custom {
  top: 0;
  left: 24px !important;
  margin-top: -4px;
  border-radius: $border-radius;
  display: none;
}
.dropdown-submenu:hover > .dropdown-menu-custom {
  display: block;
}

.dropdown-submenu > span:after {
  display: block;
  content: '\ea15';
  font-family: '#{$icomoon-font-family}' !important;
  position: absolute;
  top: 7px;
  right: 10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu-custom {
  left: -100%;
  margin-left: 10px;
}

.dropdown-custom {
  &.dropdown-custom-btn {
    .btn-solid-dropdown-custom {
      padding: 0.314rem 0.5rem;
      display: flex;
      align-items: center;
    }
  }
  .dropdown-btn-default {
    display: inline-flex;
    align-items: center;
  }
}

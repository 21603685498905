.bs-message-container {
  z-index: 1070;
  // positioned elements are in the same stack when using zIndex
  position: relative;
}

.bs-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  &.top {
    top: 15px;
    .message-container {
      margin-top: 15px;
      // override bootstrap's .toast:not(:last-child) margin-bottom property.
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &.bottom {
    bottom: 15px;
    .message-container {
      margin-top: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.message-container {
  background: $gray-1;
  border-radius: $border-radius;
  font-size: $text-14;
  line-height: $text-style-2-line-height;
  color: $gray-8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0;
  padding: 0.625rem 1rem;
  flex-basis: unset;
  max-width: unset;
  width: max-content;
  border: 0;
  backdrop-filter: unset;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.show {
    display: flex;
    align-items: center;
  }
  &.theme-new.variant-success {
    color: $green-14;
    background: $green-11;
    border-radius: 0.25rem;
    height: 3rem;
    box-shadow: none;
    .message-desc {
      font-weight: 500;
    }
  }
  &.theme-new.variant-error {
    color: $red-13;
    background: $red-14;
    border-radius: 0.25rem;
    height: 3rem;
    box-shadow: none;
    .message-desc {
      font-weight: 500;
    }
  }
  &.theme-new.variant-warning {
    color: $gold-6;
    background: $gold-1;
    border-radius: 0.25rem;
    height: 3rem;
    box-shadow: none;
    .message-desc {
      font-weight: 500;
    }
  }
  .message-icon {
    font-size: $text-14;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.375rem;
    img {
      width: 20px;
      height: 20px;
      margin-top: 0.125rem;
    }
    &.icon-basic {
      i {
        color: $blue-6;
      }
    }
    &.icon-error {
      i {
        color: $red-6;
      }
    }
    &.icon-success {
      i {
        color: $green-6;
      }
    }
    &.icon-warning {
      i {
        color: $gold-6;
      }
    }
    &.icon-loading {
      i {
        color: $blue-6;
        animation: spin 2s linear infinite;
      }
    }
  }
  .message-desc {
    display: inline-flex;
    width: max-content;
    p {
      margin-bottom: 0;
    }
  }
  .message-close {
    cursor: pointer;
    margin-left: 2rem;
  }
}

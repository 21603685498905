.sequence-setup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  background: $gray-1;
  padding: 1.5rem;

  .sequence-setup-progress {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    &.poor {
      background-color: rgba(254, 242, 242, 0.5);
      svg {
        color: $red-18;
      }
    }
    &.average {
      background-color: rgba(255, 251, 235, 0.5);
      svg {
        color: $yellow-12;
      }
    }
    &.excellent {
      background-color: rgba(236, 253, 245, 0.5);
      svg {
        color: $green-16;
      }
    }
    .sequence-setup-refresh {
      position: absolute;
      right: -6.875rem;
      top: 0;
      cursor: pointer;
    }
  }
  .circular-progressbar-text {
    font-weight: $font-weight-semibold;
    font-size: 1.25rem !important;
    line-height: 1.75rem;
    color: $blue-16;
  }
  .sequence-setup-title {
    font-weight: $font-weight-medium;
    font-size: $text-14;
    line-height: 1.0625rem;
    color: $blue-15;
    margin: 1.5rem 0 0.625rem 0;
  }
  .sequence-setup-content {
    font-size: $text-12;
    line-height: 0.9375rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 1rem;
  }
  .sequence-setup-description {
    font-size: $text-12;
    line-height: 0.9375rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 1rem;
    max-width: 47.5rem;
    text-align: center;
  }

  .sequence-setup-factor-details {
    width: 100%;
    .sequence-setup-factor-details-title {
      font-weight: $font-weight-semibold;
      font-size: $text-14;
      line-height: 1.0625rem;
      color: $blue-15;
      margin: 0;
      margin-bottom: 1.25rem;
    }
  }
}

.webhook {
  flex-direction: column;
  height: 100%;

  display: flex;

  &--header {
    .webhook-header-content-right {
      display: flex;
      gap: 0.5rem;

      .premium-feature-wrapper {
        height: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .webhook-header-content-left,
    .webhook-details-header {
      flex-direction: row;
      align-items: center;
      p {
        margin: 0 !important;
      }
    }
    .webhook-view-setup-guide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 0.25rem;

      height: 28px;
      padding: 0.25rem 0.75rem;

      border-radius: 0.875rem;
      border: 0.0625rem solid #e5e7eb;
      background: #fff;

      transition: all 0.2s ease;

      &:hover {
        border-color: #93c5fd;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 1.125rem;
        width: 1.125rem;

        svg {
          color: #4b5563;
        }
      }
      .text {
        color: #4b5563;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
      }
    }
    .webhook-details-status {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;

      width: 6.25rem;
      height: 1.25rem;

      .switchToggle {
        width: 2.5rem;
        height: 1.25rem;

        label {
          min-width: 2.5rem;
          height: 1.25rem;
          margin: 0;
          &::after {
            width: 1rem;
            height: 1rem;
          }
        }

        input:checked + label {
          background-color: $blue-11;
        }
      }

      p {
        margin: 0;
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }
    }
    .webhook-back-btn {
      width: 1.25rem;
      height: 1.25rem;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 100%;
        height: 100%;
        color: $blue-15;
      }
    }
    &.webhook-details-and-logs-header {
      margin: 0;
      background-color: #f9fafb;
    }
  }

  &--empty-ui {
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .webhook-empty-ui-image {
      width: 5rem;
      height: 5rem;
    }
    h1 {
      color: #1f2937;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $font-weight-semibold;
      line-height: 1.5rem;
      margin: 0;
    }
  }

  &--table {
    .table-organism-header {
      background-color: #f3f4f6 !important;
      tr:hover {
        background-color: inherit !important;
      }

      & > tr > th {
        height: 2.375rem;
        padding: 0.5rem 0.75rem;
        &:first-of-type {
          padding-left: 1.5rem !important;
          border-radius: 0 !important;
        }
        &:last-of-type {
          min-width: 2.8125rem;
          width: 2.8125rem;
          padding-right: 1.5rem !important;
          border-radius: 0 !important;
        }
      }
    }
    .table-organism-body {
      tr td {
        height: 2.5rem;
        max-height: 2.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:first-of-type {
          padding-left: 1.5rem !important;
          border-left: 0.0625rem solid #e5e7eb !important;
        }
        &:last-of-type {
          padding-right: 1.5rem !important;
          border-right: 0.0625rem solid #e5e7eb !important;

          .grid-action .btn-solid {
            padding: 0 !important;
            border: none;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.75rem;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: #eff6ff;
            }
            .icon-hover {
              font-size: 1.25rem;
              width: 1.25rem;
              height: 1.25rem;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    p {
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
      margin: 0;
    }

    .webhook-status-cell {
      .switchToggle {
        width: 1.75rem;
        height: 1rem;

        input:checked + label {
          background: $blue-11;
        }
      }
    }

    .webhook-name-cell {
      p {
        font-weight: $font-weight-medium;
      }
    }

    .webhook-url-cell {
      p {
        display: flex;
        align-items: center;
        gap: 0.3125rem;
      }
      .webhook-failed-icon {
        width: 1rem;
        height: 1rem;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        svg {
          width: 1rem;
          height: 1rem;
          color: $red-12;
        }
      }
    }
    .trigger-event-cell {
      .trigger-event-label {
        color: $blue-11;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        line-height: 1.125rem;
        margin: 0;
      }
    }
  }

  &--form {
    width: 61.16%;
    .webhook-form-inner {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
    .pdr-30 {
      padding-right: 1.875rem;
    }

    .webhook-form-field {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.3125rem;
      flex: 1;

      .webhook-form-field-label {
        display: flex;
        align-items: center;
        gap: 0.3125rem;

        p {
          margin: 0;
          color: #6b7280;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 1rem;
        }

        svg {
          width: 1rem;
          height: 1rem;
          color: #6b7280;
        }
      }
      .webhook-form-field-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.3125rem;

        border-radius: 0.25rem;

        input,
        textarea {
          height: 2rem;
          padding: 0.375rem 1rem;

          border-radius: 0.25rem;
          border: 0.0625rem solid #d1d5db;

          background: $white;
          transition: all 0.2s ease;

          color: $blue-15;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 1.25rem;

          &::placeholder {
            color: #9ca3af;
          }

          &:focus,
          &:focus-visible,
          &:focus-within,
          &:active,
          &:hover {
            outline: none;
            border-color: $blue-11;
            box-shadow: none;
          }
        }

        textarea {
          height: 4.5rem;
          resize: none;
        }

        span {
          color: #b91c1c !important;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 0.75rem !important;
          font-style: normal;
          font-weight: 400;
          line-height: 1rem !important;
        }

        &.input-error {
          input,
          textarea {
            border-color: #b91c1c;
            box-shadow: none;

            &:focus,
            &:focus-visible,
            &:focus-within,
            &:active,
            &:hover {
              outline: none;
              border-color: #b91c1c;
              box-shadow: none;
            }
          }
        }
      }
      .webhook-form-field-helper-text {
        color: #6b7280;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        margin: 0;
      }
    }

    .event-to-send {
      padding-bottom: 1.5rem;
      .example-data-btn {
        color: $blue-11;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 0.75rem;
        font-weight: $font-weight-semibold;
        line-height: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      .events-container {
        margin-top: 0.1875rem;
        padding: 0.5rem 0rem;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;

        border-radius: 0.25rem;
        border: 0.0625rem solid #d1d5db;

        .event {
          width: 100%;

          .bs-checkbox-wrapper {
            padding: 0.5rem 1rem;
            transition: all 0.2s ease;

            display: flex;
            align-items: center;
            gap: 0.75rem;

            .bs-checkbox-inner {
              border-radius: 0.25rem;
            }
            .bs-checkbox + span {
              color: $blue-15;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25rem;
              padding: 0;
            }
            &:hover {
              background: #eff6ff;
            }
          }
        }
      }
    }

    .http-headers {
      display: flex;
      gap: 0.625rem;

      .http-headers-help-icon {
        width: 1.25rem;
        height: 1.25rem;

        margin-top: 0.625rem;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: #6b7280;
        }
      }
    }

    .accordion {
      width: 100%;

      border-radius: 0.25rem;
      background: #f9fafb;

      .accordion__item {
        border: 0.0625rem solid #d1d5db;
        padding: 0.625rem 1rem;
        border-radius: 0.25rem !important;
        background-color: transparent !important;

        .accordion__heading {
          background-color: transparent !important;

          .accordion:hover {
            background-color: transparent !important;
          }

          .accordion__button {
            height: 1.25rem;
            padding: 0;

            display: flex;
            justify-content: space-between;

            color: $blue-15;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            background-color: transparent !important;

            .http-header-title {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
            .optional-txt {
              color: #6b7280;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25rem;
              margin: 0;
              cursor: default !important;
            }

            .accordion:hover {
              background-color: transparent !important;
            }

            &::before {
              display: none;
            }
          }
        }

        .accordion__panel {
          padding: 0.625rem 0 0 1.5rem;

          .header-input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
          }

          .header-input {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding-right: 1.5rem;

            position: relative;

            .webhook-form-field {
              width: 14rem;
            }
            .delete-header-input-btn {
              width: 1.125rem;
              height: 1.125rem;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #6b7280;
              padding: 0;
              border: none;
              box-shadow: none;
              outline: none;
              background-color: transparent;
              transition: all 0.3s ease;

              position: absolute;
              right: 0;
              bottom: 0.5rem;

              &:focus,
              &:hover,
              &:focus-visible {
                color: $blue-15;
                border: none;
                box-shadow: none;
                outline: none;
                background-color: transparent;
              }
            }
          }
          .add-new-header-btn {
            margin-top: 0.5rem;
            padding-left: 0;
            padding-right: 0;
            height: 2rem;
            &:hover,
            &:focus {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 4rem;
    background-color: $white;
    border: 0.0625rem solid #e8eff5;
    border-top: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .webhook-content-wrapper {
    display: flex;
    gap: 1.5rem;

    height: 100%;
    overflow-y: auto;

    padding: 1.5rem;

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border: 0.0625rem solid #e8eff5;

    background: #fff;

    &.isExpanded {
      .webhook--form {
        display: none;
      }
      .webhook-req-res-preview {
        width: 100%;
      }
    }

    .webhook-req-res-preview {
      margin-top: 1.3125rem;

      width: 38.84%;
      height: 25.375rem;

      border-radius: 0.25rem;
      border: 0.0625rem solid #d1d5db;
      background: #f9fafb;

      padding: 1rem;

      &.success {
        border: 0.0625rem solid #6ee7b7;
      }
      &.failed {
        border: 0.0625rem solid #fca5a5;
      }
    }
  }
}

.webhook-lg-tooltip {
  .tooltip-inner {
    max-width: 252px !important;
  }
}

.webhook-url-tooltip {
  .tooltip-inner {
    max-width: 17.125rem !important;
  }
}

.http-headers-help-tooltip {
  .tooltip-inner {
    max-width: 16.3125rem !important;
  }
}

.webhook-delete-modal.confirmation-modal-v3 {
  .modal-dialog {
    width: 28.125rem !important;
    .modal-header {
      min-height: auto;
      padding: 1.5rem !important;

      .modal-title {
        .confirmation-modal-v3--title {
          padding: 0;
          align-items: center;
          gap: 0.625rem;

          img,
          svg {
            margin: 0;
          }

          h2 {
            margin: 0;
            line-height: 1 5rem;
          }
        }
      }
    }
    .modal-content .bs-modal-body {
      padding: 1.5rem !important;
      padding-top: 0 !important;

      p {
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin: 0;
      }
    }
    .modal-footer {
      margin: 0 1.5rem 1.25rem 1.5rem;
      border-top: 0.0625rem solid #f3f4f6;

      button {
        min-width: 6.25rem;
        border: none;

        &.cancel-btn {
          color: #6b7280 !important;
        }
      }
    }
  }
  .modal-dialog
    .modal-footer
    .bs-modal-footer-action-buttons
    button:last-of-type {
    margin-left: 1rem !important;
  }
}

.webhook-details-and-logs-tabs {
  height: 100%;
  background-color: #f9fafb;

  .bs-tabs-content-holder {
    display: flex;
    .bs-tabs-content {
      flex: 1;
    }
    .bs-tabs-tabpane {
      display: flex;
      flex: 1;
    }
  }
  .bs-tabs-nav {
    margin-bottom: 1.5rem !important;
    display: flex;
    align-items: start !important;
    &::before {
      border-bottom: 0rem solid #e8e8e8 !important;
    }
    .bs-tabs-nav-wrap {
      height: 1.875rem !important;
      align-self: auto !important;
      top: 0;
    }
  }

  .webhook-logs {
    overflow-y: auto;

    display: flex;
    flex: 1;
    gap: 3rem;
    padding: 1.5rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid #e8eff5;

    background: #fff;

    &.isExpanded {
      .webhook-logs--list {
        display: none;
      }
      .webhook-logs--selected {
        width: 100%;
      }
    }

    .selected-log-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      margin-bottom: 1.5rem;

      h1 {
        color: $blue-15;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
        margin: 0;
      }
      p {
        color: #6b7280;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    &--list {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;

      width: 61.16%;

      .webhook-logs-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        padding-bottom: 0.5rem;
        border-bottom: 0.0625rem solid #eaedef;

        h1 {
          color: $blue-15;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 1.25rem;
          margin: 0;
        }

        .bs-select-box {
          width: 8.75rem;
          padding: 0.375rem 1rem;

          display: flex;
          justify-content: space-between;
          align-items: center;

          border-radius: 4px;
          border: 0.0625rem solid #d1d5db;
          background: $white;

          .bs-select-box-text {
            color: $blue-15;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
          }
        }
      }

      .webhook-logs-list-rows {
        width: 100%;
        padding-top: 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .webhook-logs-list-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          padding: 0.5rem 1rem;

          cursor: pointer;
          transition: all 0.2s ease;

          .left {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .log-type-dot {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            margin-top: 0.125rem;

            &.success {
              background-color: #10b981;
            }
            &.failed {
              background-color: #ef4444;
            }
          }

          p {
            margin: 0;
            color: $blue-15;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
          }

          &.active {
            background-color: #dbeafe;
            &:hover {
              background-color: #dbeafe;
            }
          }
          &:hover {
            background-color: #f9fafb;
          }
        }
      }
    }

    &--selected {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;

      width: 38.84%;
      height: max-content;

      &.visible {
        background: #f9fafb;
      }

      .log-view {
        font-size: 0.75rem !important;
      }

      border-radius: 0.25rem;
      padding: 1rem;

      .webhook-req-res-preview {
        width: 100%;
      }

      .code-preview-header {
        color: $blue-15;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
      }
    }
  }
}

.http-header-loader {
  border: 0.0625rem solid #d1d5db;
  padding: 0.625rem 1rem;
  border-radius: 0.25rem !important;
  background: #f9fafb;
  width: 35.125rem;

  display: flex;
  justify-content: space-between;
}

.events-container-loader {
  margin-top: 0.1875rem;
  padding: 0.625rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;

  border-radius: 0.25rem;
  border: 0.0625rem solid #d1d5db;
}

.webhook-req-res-preview {
  display: flex;
  flex-direction: column;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    .header-right {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    h1 {
      color: $blue-15;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      margin: 0;
    }

    .webhook-response-badge {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.25rem;
      padding: 0.125rem 0.375rem 0.125rem 0.625rem;
      border-radius: 0.75rem;
      background: #d1fae5;

      span {
        color: #065f46;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }

      svg {
        width: 1rem;
        height: 1rem;
        color: #10b981;
      }

      &.failed {
        span {
          color: #991b1b;
        }
        svg {
          color: #ef4444;
        }
        background: #fee2e2;
      }
    }
  }
  .last-triggered {
    margin: 0;
    color: #6b7280;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 0.5rem;
  }

  &--code {
    position: relative;
    display: flex;
    flex: 1;
    margin-top: 1rem !important;
    padding: 0.75rem !important;
    border-radius: 0.25rem;
    background-color: $white;
    border: 0.0625rem solid #f3f4f6;

    pre {
      margin: 0 !important;
      flex: 1;

      code span {
        color: #6b7280;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        &.hljs-string {
          color: #1d4ed8;
        }
      }
    }
    .json-copy-btn {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;

      width: 1.25rem;
      height: 1.25rem;

      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: #9ca3af;
      }

      &:hover {
        svg {
          color: $blue-15;
        }
      }
    }
  }
}

.code-loader {
  width: 100%;
  min-height: 10.375rem;
  flex-direction: column;

  * {
    width: 100% !important;
  }
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.no-logs-found {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: $blue-15;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    color: #6b7280;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
  }
}

.premium-feature-tooltip {
  .arrow {
    top: 0.625rem !important;
  }
  .tooltip-inner {
    margin-top: 0.625rem !important;
    max-width: 15.25rem !important;
  }
}

.plan-restriction-modal {
  .modal-dialog {
    width: 28.125rem !important;
  }
  .bs-modal-body {
    padding: 0 !important;
  }

  &--title {
    padding: 1.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.25rem 0.25rem 0 0;
    border: 0.125rem solid $gray-16;
    border-bottom: none;

    .title {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      .title-icon {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
          color: $blue-11;
        }
      }
      h1 {
        color: $blue-15;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: $font-weight-bold;
        margin: 0;
        font-weight: $font-weight-semibold;
      }
    }
  }

  &--description {
    padding: 0 1.5rem 1.25rem 1.5rem;

    display: flex;
    align-items: center;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--title,
  &--description {
    p {
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;

      span.bold {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;

    padding: 0 1.5rem 1.5rem 1.5rem;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--divider {
    display: flex;
    align-items: center;

    width: 25.125rem;
    height: 0.125rem;

    border-radius: 0.25rem;
    margin: 0 24px;

    background-color: $gray-16;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    height: 4.5rem;
    padding: 1.25rem 1.5rem;

    border-radius: 0 0 0.25rem 0.25rem;
    border: 0.125rem solid $gray-16;
    border-top: none;

    .shd-button--secondary {
      width: 6.25rem;
      color: $gray-15;
      border-color: $gray-13;

      &:hover,
      &:active,
      &:focus {
        color: $blue-11;
        background-color: transparent;
        border-color: $blue-11;
      }
    }
  }
}

.webhook-spinner {
  width: 1.125rem;
  height: 1.125rem;
  border-width: 0.1875rem;
  border-color: $blue-11;
  border-right-color: transparent;
}

.cursor-default {
  cursor: default !important;
}

.trigger-event-popover {
  width: 13.75rem !important;
  max-height: 10.625rem;

  margin-left: -1.5rem !important;
  padding: 0.5rem 0rem !important;

  .popover-body {
    width: 100%;
  }

  display: flex;

  border-radius: 0.5rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;
  box-shadow: 0.1875rem 0.5rem 1.5rem -0.375rem rgba(15, 34, 67, 0.16) !important;

  .arrow {
    display: none;
  }

  .bs-popover-inner-content {
    padding: 0 !important;
  }

  .trigger-event-popover-content {
    h2 {
      display: flex;
      align-items: center;
      padding: 0.625rem 1rem;
      margin: 0;

      color: #4b5563;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 1.25rem;
    }
    .trigger-event-wrapper {
      max-height: 108px;
      overflow-y: auto;
    }
    .trigger-event {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: 0.5rem 1rem;

      height: 2.25rem;

      p {
        color: $blue-15;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

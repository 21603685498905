.filter-option-bar-container {
  .selected {
    display: flex;
    align-items: center;
    height: 48px;
    background-color: $gray-1;
    cursor: pointer;
    .filter-text {
      display: flex;
      justify-content: space-around;
      height: 34px;
      width: 100%;
      align-items: center;
      border-left: 5px solid $blue-8;
      .label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $gray-9;
      }
      .value {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $blue-9;
      }
    }
  }
  .unselected {
    display: flex;
    align-items: center;
    height: 48px;
    background-color: $gray-3;
    cursor: pointer;
    .filter-text {
      display: flex;
      justify-content: space-around;
      height: 34px;
      width: 100%;
      align-items: center;
      .label {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $gray-9;
      }
      .value {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $gray-6;
      }
    }
  }
}

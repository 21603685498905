.auto-search-container {
  position: relative;
  padding: 0 0.75rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid $gray-13;

  .search-icon {
    svg {
      color: $gray-11;
      margin-right: 0.375rem;
    }
  }

  .auto-search-custome-input {
    border: none !important;
    outline: none !important;
    padding: 0;
    color: $blue-15;
    font-weight: $font-weight-normal;
    flex: 1;


    &::placeholder {
      color: $gray-400;
      font-size: 0.75rem;
      font-weight: $font-weight-normal;
    }

    &:hover {
      border: none !important;
      box-shadow: none;
    }

    &:focus,
    &:focus-within {
      border-color: $blue-5;
      outline: 0;
      box-shadow: none !important;
    }
  }

  .clear-icon {
    max-width: 16.375rem;
    margin-top: 0.313rem;
    position: relative;
    right: 0.8rem;

    i {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }
}
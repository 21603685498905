.report {
  justify-content: center;

  .report-content {
    flex: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .report-data-wrapper {
      align-self: center;
    }
    .report-data {
      text-align: right;
      justify-content: center;
      .head-body-spacer {
        height: 2rem;
      }

      tbody > tr > td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &.status {
          padding-right: 0.875rem;
        }

        &.count,
        &.icon-wrapper {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        &.download-link-wrapper {
          padding-left: 0.75rem;

          a {
            color: $blue-6;
          }
        }
      }
    }

    .verification-section {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-self: center;
      background-color: $gray-3;
      .verification-spinner {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 1rem;
        padding-top: 1rem;
        .spinner-loading {
          margin-left: 5px;
          display: inline-flex;
          align-items: center;
          i.icon-loading {
            animation: spin 2s linear infinite;
            color: $blue-5;
            font-weight: 500;
            font-size: 1.5rem;
          }
        }
      }
      .content-title {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
      .content-description {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
    }
  }
  .action-state-data {
    margin-top: 1.375rem;
    border: 1px solid $gray-16;
    border-radius: 0.25rem;
    &__row {
      background: $gray-19;
      padding: 0.625rem 1rem;
      &:not(:first-child) {
        border-top: 1px solid $gray-16;
      }
      .count {
        margin-right: 0.75rem;
      }
      .icon-wrapper {
        margin-top: 0.125rem;
      }
      .right-space {
        margin-right: 6.875rem;
      }
      .margin-space {
        margin-right: 2.75rem;
      }
      .download-link-wrapper {
        a {
          color: $blue-11;
        }
      }
    }
  }

  .failed-report-download-search-add {
    text-align: right;
    font-size: $text-12;
    margin-right: 2em;
    margin-top: 7px;
    font-weight: 500 !important;
    cursor: pointer;
    text-decoration: none;
  }
  .failed-report-download-search-add:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .report-header-failed {
    font-weight: 600;
  }
  .report-table-data {
    width: 27.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .report-table-inner {
    width: 100%;
  }

  .unsubscribeTextWarning {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $gray-8;
  }
}

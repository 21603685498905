.filter-tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  background: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  max-width: 50rem;
  flex-wrap: nowrap;
  margin-right: 1.25rem;
  margin-bottom: 1rem;

  .text {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #6b7280;
  }
  .filter-name {
    white-space: nowrap;
    text-transform: capitalize;
  }
  .filter-text {
    font-weight: 600;
    color: #1f2937;
  }
  .applied-filters {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
  .close-icon {
    align-self: flex-end;
    font-size: 14px;
  }
}

.schedule-reply-modal {
  .modal-content {
    width: 27.9375rem;
    margin: 0 auto;
  }
  .timezone-wrapper {
    .bs-select-box {
      padding: 0.375rem 1rem;

      border: 0.0625rem solid #e5e7eb;
      background: #f9fafb;

      .selected-timezone {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .timezone-value {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        .sequence-timezone-label {
          color: #9ca3af;
          font-size: 0.625rem;
          line-height: 1.25rem;
        }
      }
    }
  }
  .schedule-condition-wrapper {
    gap: 0.5rem;
    .schedule-condition-header {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      svg {
        color: #9ca3af;
      }
    }
    .bs-checkbox-wrapper {
      line-height: 1rem;
      .bs-checkbox {
        .bs-checkbox-inner {
          border-radius: 0.25rem;
        }
      }
      .bs-checkbox + span {
        color: $blue-15;
        font-size: 0.75rem;
        line-height: 1rem;
        user-select: none;
      }
    }
  }
  .recently-used-schedules-wrapper {
    gap: 0.625rem;
    .recently-used-schedules-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  &--content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    margin-bottom: 1.5rem;
  }

  &--content-label {
    margin: 0;
    color: $gray-15;
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
    line-height: 1rem;
  }

  &--schedule {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.375rem 1rem;
    background: $white;
    transition: all 0.2s ease;

    .day,
    .date {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    &:hover {
      background: $blue-17;
    }
    &.initial {
      background: $blue-13;
    }
    &.selected {
      background: #dbeafe;
    }
  }

  &--divider {
    width: 100%;
    height: 0.0625rem;
    background: $gray-16;
    display: flex;
    align-self: center;
    margin-bottom: 1.5rem;
  }

  &--footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.125rem;
      height: 1.125rem;
      svg {
        width: 100%;
        height: 100%;
        color: $blue-15;
      }
    }
    p {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
    }
    &:hover {
      svg,
      p {
        color: $blue-11;
      }
    }
  }
}

.schedule-condition-tooltip {
  .tooltip-inner {
    max-width: 15.125rem !important;
  }
}

.pick-date-time-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
  }
}

.pick-date-and-time-container {
  margin-bottom: 1.5rem;
  height: 20.5rem;

  .pick-date-and-time-calendar {
    display: flex;
    justify-content: space-between;

    .selected-date-container {
      width: 10.5rem;
      height: 2rem;
      padding: 0.375rem 0.5rem;
      border-radius: 4px;
      background-color: #f3f4f6;

      p {
        margin: 0;
        color: $blue-15;
        font-size: 0.875rem;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
      }
    }
    .custom-time-input-filters {
      margin-top: 0.75rem;
      width: 10.5rem;
      padding: 0.375rem 0.5rem;
      border-radius: 4px;
      border: 1px solid #e5e7eb;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        border-color: $blue-11;
      }

      &:focus-visible {
        outline-color: $blue-11;
      }

      &.time-error {
        border-color: $red-16;
      }

      .hh-mm-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .react-time-picker__wrapper {
          border: none;
          .react-time-picker__inputGroup {
            min-width: none;
            padding: 0;

            color: $blue-15;
            font-size: 14px;
            font-weight: $font-weight-medium;
            line-height: 1.25rem;

            .react-time-picker__inputGroup__input {
              outline: none;
            }

            .react-time-picker__inputGroup__divider {
              margin: 0rem 0.5rem;
            }
          }
        }

        .react-time-picker__inputGroup__amPm {
          display: none;
        }
      }

      .am-pm-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        position: relative;

        .am-pm-text {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 1.75rem;
          height: 1.5rem;

          padding: 0.125rem 0.25rem;

          color: #9ca3af;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          border: 0.0625rem solid transparent;

          cursor: pointer;
          position: relative;

          transition: all 0.2s ease;

          &.active {
            color: $blue-11;
          }
        }
        .active-border {
          position: absolute;
          bottom: 0;
          left: 0;

          width: 1.75rem;
          height: 1.5rem;
          border: 0.0625rem solid rgb(191, 219, 254);

          border-radius: 0.125rem;
          transition: left 0.2s ease-out;

          &.am {
            left: 0%;
          }
          &.pm {
            left: 54%;
          }
        }
      }
    }
    .time-error-msg {
      color: $red-16;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      margin: 0;
      margin-top: 0.25rem;
    }
  }

  .react-datepicker {
    box-shadow: none;
    padding: 0 !important;

    &__month-container {
      width: 13.5rem;
      min-height: 15rem;

      padding: 0.75rem !important;

      border-radius: 0.25rem !important;
      border: 0.0625rem solid $gray-16 !important;
    }

    &__header {
      border-bottom: none !important;

      .pick-date-and-time-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: $blue-15;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          line-height: 1rem;
          margin: 0;
        }

        .pick-date-and-time-action-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.25rem;

          button {
            width: 1rem;
            height: 1rem;

            text-align: center;

            color: $blue-15;
            padding: 0;

            border: none;
            background-color: transparent;

            &:hover,
            &:focus,
            &:focus-within,
            &:active {
              outline-color: $gray-23;
            }

            svg {
              width: 1rem;
              height: 1rem;
              display: block;
            }

            &:disabled {
              color: $gray-23;
            }
          }
        }
      }
    }

    &__current-month {
      display: flex;
      align-items: center;

      padding: 0;
    }

    &__header__dropdown {
      display: none;
    }

    &__day-names {
      height: 1.5rem;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;

      margin-top: 0.875rem !important;
    }

    &__day-name {
      width: 1.5rem !important;
      height: 1.5rem !important;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 !important;

      color: $gray-11;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1rem;
    }

    &__month {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__week {
      height: 1.5rem;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
    }

    &__day {
      width: 1.5rem !important;
      height: 1.5rem !important;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.2s ease;

      padding: 0 !important;

      color: $blue-15 !important;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      line-height: 1rem !important;

      &:hover {
        background-color: $blue-17;
      }
      &--disabled {
        color: $gray-23 !important;
      }
      &--selected,
      &--keyboard-selected {
        color: $white !important;
        background-color: $blue-11 !important;
      }
    }

    &__input-time-container {
      position: absolute;
      right: 0rem;
      top: 2.75rem;
      margin: 0;
      width: 168px;
      .react-datepicker-time__caption {
        display: none;
      }
      .react-datepicker-time__input-container {
        width: 100%;

        .react-datepicker-time__input {
          margin: 0;

          &:focus-visible {
            outline: none;
          }

          .react-datepicker-time__input {
            margin: 0;
            padding: 0.375rem 0.5rem;
            border-radius: 4px;
            border: 1px solid #e5e7eb;

            &:hover {
              border-color: $blue-11;
            }

            &:focus-visible {
              outline-color: $blue-11;
            }

            &::-webkit-calendar-picker-indicator {
              display: none;
            }
          }
        }
      }
    }
  }
}

.timezone-dropdown-select {
  .transfer-list div {
    li.active {
      color: $blue-11 !important;
      background-color: transparent !important;

      &:hover {
        background-color: $blue-17 !important;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(10px); // Adjust as needed
    opacity: 1;
  }
}

@keyframes slideBorder {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.arrow {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  margin-right: 18px;
}

.right {
  margin-left: 18px;
}

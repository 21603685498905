.mailbox-emails {
  &--container {
    height: 100%;
    background-color: $gray-19;
    display: flex;
    flex-direction: column;
  }
  &--header {
    padding: 1.5625rem 1.875rem;
  }
  &--search {
    width: 12.625rem !important;
    height: 2rem !important;
    margin-right: 1.25rem;
  }
  &--recipients {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 2.5rem;
    &__name {
      margin: 0 !important;
      color: $blue-15;
      white-space: nowrap;
    }
    &__tooltip {
      .tooltip-inner {
        word-break: break-all;
      }
    }
    &__email-warpper {
      display: flex;
      align-items: center;
    }
    &__email {
      color: $gray-12;
    }
    &__badge {
      padding: 0px 0.1875rem;

      max-width: 3.75rem;
      min-width: 3rem;
      height: 1.25rem;

      background: $gray-16;
      border: 0.0625rem solid $blue-13;
      border-radius: 0.25rem;
      color: $gray-15;
      cursor: pointer;
      user-select: none;
    }

    &__list {
      max-height: 7.4375rem;
      overflow-y: auto;
      padding: 0.375rem;
      span {
        color: $gray-1;
        display: block;
        text-align: left;
        font-family: $font-family-sans-serif;
        margin-bottom: 0.375rem;
      }
      &::-webkit-scrollbar {
        width: 0.25rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background-color: $gray-15;
      }
    }
  }
  &--recipient-popover {
    font-weight: $font-weight-medium;
    font-size: 0.625rem;
    line-height: 0.9375rem;

    .bs-popover-inner-content {
      padding: 0 !important;
    }
  }
  &--activity-modal {
    .modal-header {
      border-bottom: none;
      align-items: flex-start;
    }
    .modal-body {
      padding: 0 !important;
      overflow: hidden;
      max-height: calc(90vh - 6.1rem) !important;
    }
    .modal-dialog {
      width: 45rem;
      max-width: 45rem;
    }
    .modal-content {
      height: 35rem;
      border-radius: 0.5rem !important;
    }
  }
  &--activity-tabs {
    .bs-tabs-tabpane {
      &.mailbox-email-link-clicked {
        .log-activity {
          &::after {
            height: calc(100% - 3.25rem);
          }
        }
        .icon-log-container {
          height: auto;
          align-items: flex-start;
          .icon-container-wrapper {
            height: 2rem;
          }
        }
      }
    }
    .bs-tabs-nav {
      height: 3rem;
      padding: 0 1.5rem;

      &::before {
        border-bottom-color: $gray-16;
      }
    }
    .bs-tabs-tab {
      &.bs-tabs-tab-active {
        background-color: $blue-17;
        .tab-wrapper {
          .label-wrapper {
            color: $blue-11;
            font-weight: $font-weight-medium;
          }
          .counter-wrapper {
            color: $blue-11;
          }
        }
      }
      .tab-wrapper {
        .label-wrapper {
          color: $blue-15;
        }
        .icon-wrapper img {
          width: 1.5rem;
          height: 1.5rem;
        }
        .counter-wrapper {
          color: $gray-12;
          font-weight: $font-weight-medium;
        }
      }
    }
    .bs-tabs-ink-bar {
      border-radius: 0.25rem;
      background-color: $blue-11;
    }
    .bs-tabs-content-holder {
      height: 100%;
      padding-left: 1.5rem;
      .bs-tabs-content {
        height: 24rem;
        overflow-y: auto;

        .log-activity {
          .day-text {
            background-color: $gray-16;
            color: $blue-15;
            border-radius: 0.125rem;
            font-weight: $font-weight-medium;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  &--btn-solid {
    &__danger {
      background-color: $red-16 !important;
      border-color: $red-16 !important;
    }
  }
  &--empty-list {
    .empty-list {
      height: 100vh;
      .empty-list__body-vertical {
        width: auto;
        text-align: center;
      }
    }
  }
  &--table-actions {
    height: 100%;
    div {
      color: $gray-15;
      font-size: 1.125rem;
      transition: all 0.2s ease;
      &.bell-off {
        color: $gray-11;
      }
      &:hover,
      &.bell-off:hover {
        color: $blue-11;
      }
    }
  }
  &--activity-label {
    color: $blue-15;
  }
  &--activity-log {
    color: $gray-15;
    img {
      margin-top: -0.125rem;
    }
  }
  &--activity-link {
    width: max-content;
    max-width: 38rem;
    .link-wrapper {
      max-width: 38rem;
      height: 1.75rem;

      padding: 0.25rem 0.625rem;
      margin-top: 0.25rem;

      background: $gray-19;

      border: 0.0625rem solid $gray-13;
      border-radius: 0.25rem;
      box-sizing: border-box;

      color: $gray-15;
      font-weight: $font-weight-medium;
      text-decoration: none;

      img {
        margin-right: 0.5rem;
      }
    }
    .tooltiptext {
      max-width: 38rem !important;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
    }
  }
  &--sender-select {
    width: 17.25rem;
    &:hover,
    &:focus {
      border-color: $gray-11 !important;
    }
    .bs-select-box .bs-select-box-icon-wrapper {
      color: $gray-11;
    }
    .selected-option {
      img {
        width: 1rem;
        height: 1.25rem;
      }
    }
    &__option {
      white-space: nowrap;
    }
  }
  .table-list {
    padding-left: 1.5rem;
    margin-right: 1.5rem;
    .react-bootstrap-table {
      .table-organism {
        .table-organism-header > tr > th:last-of-type {
          padding-left: 0;
        }
        .table-organism-body {
          tr {
            td {
              height: 4.0625rem;
              max-height: 4.0625rem;
              &:first-of-type {
                border-left: 0.0625rem solid $gray-13;
              }
              &:last-of-type {
                padding-left: 0;
                border-right: 0.0625rem solid $gray-13;
              }
            }
          }
        }
      }
    }

    .grid-flex {
      justify-content: space-between;
    }
  }
  &--actions {
    button {
      height: 2rem;
      margin-right: 0.3rem;
    }
  }
}

.opened-clicked-col {
  width: max-content;
  max-width: 4.375rem;
  font-weight: $font-weight-medium;
  transition: 0.2s all ease;
  img.img-icon-hover {
    display: none;
    width: 1.25rem;
    height: 1.25rem;
  }
  &:hover {
    color: $blue-11;
    img.img-icon {
      display: none;
    }
    img.img-icon-hover {
      display: inline-block;
    }
  }
  &__disabled {
    color: $gray-11;
  }
  &__no-hover {
    &:hover {
      color: $gray-17;
    }
  }
}

.activity-link-clicked {
  max-width: 38rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .tooltiptext {
    word-break: break-all;
    left: 0 !important;
    width: auto !important;
    max-width: 100% !important;
    margin-left: 0 !important;
  }
}

.mailbox-empty-block {
  width: 374px;
  .empty-icon-wrapper {
    width: 4.8519rem;
    height: 3.125rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .empty-message-wrapper {
    margin-top: 1.5rem;
    .empty-message-heading {
      margin-bottom: 0.625rem;
    }
  }
}

.multi-chip-input {
  &--container {
    width: 100%;
    min-height: 8.875rem;
    // max-height: 346px;

    overflow-y: auto;

    padding: 7px 16px;

    display: grid;
    position: relative;
    grid-template-rows: auto 1fr;
    // gap: 0.375rem;

    border-radius: 4px;
    border: 1px solid $gray-23;
    background-color: $gray-19;

    cursor: text;

    &:focus,
    &:focus-within {
      border-color: $blue-11;
    }

    &.error {
      border-color: #fca5a5;
    }
  }

  &--error-msg {
    color: #b91c1c;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &--helper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.25rem;
  }

  &--pills {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.25rem;
    cursor: pointer;
    .shd-pills {
      background-color: white;
      border-color: #d1d5db;
    }
  }

  &--input {
    border: none;
    outline: none;

    width: 100%;

    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $blue-15;

    padding: 0;

    background-color: $gray-19;

    &:disabled {
      background-color: transparent;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #9ca3af;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }

  &--count {
    position: absolute;
    bottom: 7px;
    right: 16px;
    font-size: 0.75rem;
    color: #9ca3af;
  }
}

.multi-chip-input-tooltip {
  .tooltip-inner {
    max-width: 400px !important;
  }
}
.footer {
  display: flex;
  justify-content: flex-end !important;
}

.create-custom-field-button {
  color: $blue-11 !important;
  font-weight: $font-weight-semibold !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  background-color: $gray-31 !important;
}

.map-field-select {
  .transfer-container .transfer-body .transfer-content {
    height: calc(84vh - 385px);
  }
}

.conflict-action-select {
  .transfer-list {
    li {
      padding: 0 !important;
      div {
        padding: 0.375rem 1rem;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.bs-modal-container {
  .bs-modal-body {
    padding: 1.5rem;
    max-height: calc(90vh - 7rem);
  }

  .bs-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.footer-with-content {
      justify-content: space-between;
    }

    &.hidden-button {
      justify-content: space-between;
    }

    .bs-modal-footer-action-buttons {
      button {
        margin-left: 1rem;
        font-weight: $font-weight-medium;
      }
    }
  }

  .modal-separator{
    background: #F3F4F6;
    display: flex;
    height: 1px;
    padding: 0px 24px;
    align-items: center;
    gap: 4px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  &.modal-with-sidebar {
    .modal-content {
      flex-direction: row;
      background-color: transparent;
      box-shadow: none;
      .modal-content-container,
      .modal-sidebar {
        border-radius: 0.5rem;
        background-color: $white;
      }
      .modal-content-container {
        width: 56.25rem;
      }
      .modal-sidebar {
        width: 18.75rem;
        min-height: 90vh;
        margin-left: 0.125rem;

        .sidebar-body {
          overflow-x: hidden;
        }
      }
    }
  }

  &.modal-with-collapsible-sidebar {
    .modal-content {
      background-color: $white;
      .modal-content-container {
        width: 52.5rem;
        border-radius: 0;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        .modal-header {
          padding-right: 0;
        }
        .modal-body,
        .modal-footer {
          border-right: 0.0625rem solid #e5e7eb;
        }
      }
      .modal-sidebar {
        border-radius: 0;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        width: 27.875rem;
        min-height: 90vh;
        margin-left: 0;

        .sidebar-header {
          .close {
            position: absolute;
            right: 1.125rem;

            font-size: 1.5rem;
            color: $blue-15;
          }
        }

        .sidebar-body {
          padding: 0.75rem 0 0 0 !important;
          overflow-x: hidden;
        }
      }
    }
    &.modal-sidebar-collapsed {
      .modal-content {
        .modal-content-container {
          width: 100%;
        }
        .modal-sidebar {
          width: 1.5rem;
          .sidebar-header {
            width: 1.5rem;
            padding: 0;
          }
          .sidebar-body {
            width: 1.5rem;
          }
        }
      }
    }
  }
}

.collapse-sidebar-btn {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: #f3f4f6;
  padding: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0.625rem;
  right: -0.75rem;

  svg {
    width: 0.875rem;
    height: 0.875rem;
    color: #6b7280;
  }
}

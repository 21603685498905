.templates-container {
  height: 100% !important;
  width: 100% !important;
  overflow-y: hidden;
  .filters {
    padding: 1.5rem 1.875rem;
  }
  div.search {
    .bs-input-affix-wrapper,
    button.add-templates {
      height: 2rem;
    }
  }
  .template-title {
    &:hover {
      cursor: pointer;
    }
    .template-icon {
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease;
      margin-left: 0.25rem;
      i {
        color: $gray-11;
        font-size: 0.875rem;
      }
      &:hover {
        i {
          color: $blue-15;
        }
      }
    }
  }
  .template-list-table {
    padding: 0rem 1.875rem;
  }
  .table-list {
    height: calc(100vh - 8rem);
    overflow-y: auto;

    .react-bootstrap-table .table-organism .table-organism-body {
      tr {
        td {
          &:first-of-type {
            border-left: 0.0625rem solid $gray-13;
          }

          &:last-of-type {
            border-right: 0.0625rem solid $gray-13;
          }
        }
      }
    }
  }
  .empty-list-container {
    height: calc(100vh - 13rem);
    width: 100%;
  }
  .add-templates {
    margin-left: 1.25rem;
  }
}

.template-title-tooltip > .tooltip-inner {
  max-width: 15rem !important;
}

.template-subject-tooltip > .tooltip-inner {
  max-width: 30rem !important;
}

.email-sender-details {
  margin-left: 0.75rem;
  h3 {
    margin: 0;
    font-weight: $font-weight-semibold;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $blue-15;
    margin-bottom: 2px;

    display: flex;
    align-items: center;

    .sender-name {
      display: inline-block;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-right: 0.125rem;
    }

    .email {
      margin-left: 0.25rem;
      font-weight: $font-weight-normal;
    }
  }
  p {
    margin: 0;
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray-15;
  }
  .prospect-details-popup {
    cursor: text !important;
    min-width: 23.625rem;
    box-shadow: 0px 0px 4px 0px $gray-13;
    left: 2px;
    top: 1.375rem;
    z-index: 2;
    .grid-container {
      display: grid;
      grid-template-columns: 31px 1fr; /* 31px for the first column, 1fr for the second (flexible) column */
      column-gap: 16px; /* 16px gap between columns */
    }
  }
}

.email-sent-time {
  margin: 0;
  font-weight: $font-weight-medium;
  font-size: 0.75rem;
  line-height: 1rem;
  color: $gray-15;
}

.email-preview-content {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: $blue-15;

  p {
    margin: 1em 0;
  }
}

.email-preview-attachments {
  gap: 0.625rem;
  &.email-thread {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    .attachment {
      flex: 1 0 calc(100% / 7 - 1rem);
      max-width: calc(100% / 7 - 1rem);
      box-sizing: border-box;
    }
  }
}

.email-preview-tooltip {
  .tooltip-inner {
    max-width: 500px !important;
  }
}

.d-none {
  display: none !important;
}

.view-more-content-btn {
  width: 24px;
  height: 18px;

  border: none;
  margin-bottom: 0.5rem;

  background: #e5e7eb;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1406rem;

  span {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background: #6b7280;
  }
}

//! TODO  Cleanup this file

.bs-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $gray-8;
  font-size: $text-14;
  line-height: $text-style-2-line-height;
  list-style: none;
  position: relative;
  background: $gray-1;
  border-radius: 2px;
}

.bs-card-bordered {
  border: 1px solid $gray-5;
}
.bs-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: $gray-9;
  font-weight: $font-weight-semibold;
  font-size: $text-16;
  background: transparent;
  border-bottom: 1px solid $gray-4;
  border-radius: 2px 2px 0 0;
  &::before {
    display: table;
    content: '';
  }
  &::after {
    display: table;
    clear: both;
    content: '';
  }
  .bs-tabs {
    clear: both;
    margin-bottom: -17px;
    color: $gray-7;
    font-weight: normal;
    font-size: $text-14;
  }
  .bs-tabs-bar {
    border-bottom: 1px solid $gray-4;
  }
}
.bs-card-head-wrapper {
  display: flex;
  align-items: center;
}
.bs-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bs-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: $gray-7;
  font-weight: normal;
  font-size: $text-14;
}
.bs-card-body {
  padding: 24px;
  &::before {
    display: table;
    content: '';
  }
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}
.bs-card-small {
  > .bs-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: $text-14;
    > .bs-card-head-wrapper {
      > .bs-card-head-title {
        padding: 8px 0;
      }
      > .bs-card-extra {
        padding: 8px 0;
        font-size: $text-14;
      }
    }
  }
  > .bs-card-body {
    padding: 12px;
  }
}

// new card structure design
.card {
  border: 1px solid $gray-14;
  border-radius: 0.25rem;
  padding: 1.5rem;
  .card-header {
    border-bottom: 1px solid $gray-14;
    &:first-child {
      border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
    }
  }
  .card-title {
    color: $popover-arrow-color;
    text-transform: capitalize;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .card-body {
    padding: 0.25rem 0px;
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }
  .card-footer {
    border-top: 1px solid $gray-14;
    &:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
    }
  }
}

.bs-select-box {
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: $gray-8;
  font-size: 14px;
  line-height: 1.5715;
  background-color: $gray-1;
  background-image: none;
  border: 1px solid $gray-5;
  border-radius: $border-radius;
  transition: all 0.3s;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: $blue-5;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: $blue-5;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: $input-shadow;
  }

  .bs-select-box-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.placeholder {
      color: $gray-6;
    }
  }

  .bs-select-box-icon-wrapper {
    display: flex;
    align-items: center;
    color: #1f2937;
    margin-left: 0.5rem;
  }
}

.bs-select-box-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.bs-select-box-sm {
  padding: 0 7px;
}

.bs-select-box-disabled {
  color: $gray-6;
  background-color: $gray-3;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    border-color: $gray-5;
    border-right-width: 1px !important;
  }
}

.transfer-select-box {
  .transfer-container {
    min-width: unset;
  }
}

.agency-portal {
  height: calc(100vh - 3.875rem);
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;

    h1 {
      font-weight: $font-weight-bold;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $blue-15;
      margin: 0;
    }
    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: $gray-15;
      margin: 0;
      margin-top: 0.5rem;
    }
    .action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.25rem;
      margin-top: 1.5rem;

      button {
        min-width: 8.875rem;
      }
    }
  }

  &--image {
    height: 22.875rem;

    img {
      width: 100%;
      height: 100%;
      user-select: none;
    }
  }
}

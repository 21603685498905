// stylelint-disable selector-no-qualifying-type

.rb-group {
  display: inline-flex;
}

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn-solid {
    position: relative;
    flex: 1 1 auto;

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    @include hover() {
      z-index: 1;
    }
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
    &.btn-solid-primary {
      &:not(:disabled):not(.disabled).active {
        background-color: $blue-6;
        border-color: $blue-6;
        &:hover,
        &.hover {
          background-color: $blue-5;
          border-color: $blue-5;
        }
        &:active {
          background-color: $blue-7;
          border-color: $blue-7;
        }
      }
      &:active,
      &.active {
        &:disabled,
        &.disabled {
          cursor: not-allowed;
          background: $gray-4;
          color: $gray-1;
          border-color: $gray-4;
        }
      }
      &:disabled,
      &.disabled {
        cursor: not-allowed;
      }
    }
    &.btn-solid-outlined {
      &:active,
      &.active {
        &:disabled,
        &.disabled {
          cursor: not-allowed;
          background: $gray-4;
          color: $gray-1;
          border-color: $gray-5;
        }
      }
      &:disabled,
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.btn-group {
  // Prevent double borders when buttons are next to each other
  > .btn-solid:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: -$btn-border-width;
  }

  // Reset rounded corners
  > .btn-solid:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    @include border-right-radius(0);
  }

  > .btn-solid:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn {
  @extend .btn-sm;
}
.btn-group-lg > .btn {
  @extend .btn-lg;
}

//
// Split button dropdowns
//

.dropdown-toggle-split {
  padding-right: $btn-padding-x * 0.75;
  padding-left: $btn-padding-x * 0.75;

  &::after,
  .dropup &::after,
  .dropright &::after {
    margin-left: 0;
  }

  .dropleft &::before {
    margin-right: 0;
  }
}

.btn-sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm * 0.75;
  padding-left: $btn-padding-x-sm * 0.75;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg * 0.75;
  padding-left: $btn-padding-x-lg * 0.75;
}

// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.btn-group.show .dropdown-toggle {
  @include box-shadow($btn-active-box-shadow);

  // Show no shadow for `.btn-link` since it has no other button styles.
  &.btn-link {
    @include box-shadow(none);
  }
}

//
// Vertical button groups
//

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > .btn-solid,
  > .btn-group {
    width: 100%;
  }

  > .btn-solid:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-top: -$btn-border-width;
  }

  // Reset rounded corners
  > .btn-solid:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    @include border-bottom-radius(0);
  }

  > .btn-solid:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-top-radius(0);
  }
}

.btn-group-toggle {
  > .btn-solid,
  > .btn-group > .btn {
    margin-bottom: 0; // Override default `<label>` value

    input[type='radio'],
    input[type='checkbox'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}

//////////////////// Radio box ///////////////

.bs-radio-wrapper {
  position: relative;
  margin-right: 8px;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: $text-14;
  list-style: none;
  display: inline-block;
  line-height: $text-style-2-line-height;
  color: $gray-8;
  cursor: pointer;
  .bs-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: $text-14;
    line-height: $text-style-2-line-height;
    list-style: none;
    position: relative;
    top: -0.09em;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    &:hover {
      &::after {
        visibility: visible;
      }
      .bs-radio-inner {
        border-color: $blue-6;
      }
    }
    + span {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  + .bs-radio-wrapper {
    margin-left: 8px;
  }
  .bs-radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    &:checked {
      ~ {
        .bs-radio-inner {
          border-color: $blue-6;
          &:after {
            position: absolute;
            display: table;
            border-top: 0;
            border-left: 0;
            transform: scale(1);
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            content: ' ';
          }
        }
      }
    }
    &:focus {
      + .bs-radio-inner {
        border-color: $blue-6;
      }
    }
  }
  &:hover {
    .bs-radio-disabled {
      &::after {
        visibility: hidden;
      }
    }
    .bs-radio-input {
      ~ {
        .bs-radio-inner {
          border-color: $blue-6;
        }
      }
    }
    .bs-radio {
      &::after {
        visibility: visible;
      }
    }
  }
  .bs-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: $gray-1;
    border: 1px solid $gray-5;
    border-radius: 100%;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:after {
      position: absolute;
      top: 3px;
      left: 3px;
      display: table;
      width: 8px;
      height: 8px;
      background-color: $blue-6;
      border-top: 0;
      border-left: 0;
      border-radius: 8px;
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: ' ';
    }
  }
  &.bs-radio-wrapper-disabled {
    .bs-radio {
      &.bs-radio-disabled {
        cursor: not-allowed;
        .bs-radio-inner {
          background-color: $gray-3;
          border-color: $gray-5 !important;
          &::after {
            background-color: $gray-6;
            border-color: $gray-5;
            animation-name: none;
          }
        }
        .bs-radio-checked {
          .bs-radio-inner {
            &::after {
              animation-name: none;
            }
          }
        }
        .bs-radio-input {
          cursor: not-allowed;
        }
        + span {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
        &:hover {
          &::after {
            visibility: hidden;
          }
        }
        &.bs-radio-indeterminate {
          .bs-radio-inner {
            &::after {
              background-color: $gray-6;
              border-color: $gray-5;
            }
          }
        }
      }
    }
  }
}

.radio-vertical {
  flex-direction: column;
  .bs-radio-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    white-space: normal;
    .bs-radio-inner {
      top: 4px;
      left: 1px;
    }
  }
}

.radio-horizontal {
  display: grid;
  grid-template-columns: auto auto;
  .bs-radio-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    white-space: normal;
    margin-right: 32px;
    .bs-radio-inner {
      top: 4px;
      left: 2px;
    }
  }
}

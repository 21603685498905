// Accordian
.people-tab-accordian {
  max-height: calc(100vh - 16.875rem);

  .os-scrollbar-vertical {
    padding-right: 0.25rem;

    .os-scrollbar-handle {
      border-radius: 0.25rem;
      width: 0.25rem;
      background-color: $gray-13;
    }
  }

  &::-webkit-scrollbar {
    width: 0.3125rem;
  }

  .shd-accordion {
    gap: 0 !important;
    grid-gap: 0 !important;

    .accordion__item {
      border-radius: unset !important;
      box-shadow: unset !important;
      border-top: 1px solid $gray-13 !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;

      &:first-child {
        border-top: none !important;
      }

      &:last-child {
        border-bottom: 1px solid $gray-13 !important;
      }

      .accordion-item-heading {
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding: 0 0.75rem !important;
        width: 100%;

        .accordion-minus-icon,
        .accordion-plus-icon {
          color: $gray-15;
          width: 0.875rem;
          height: 0.875rem;
        }

        &:hover {
          background-color: $gray-19;
        }
      }

      .accordion-item-heading-tag {
        display: flex;
        align-items: center;
        padding: 0 0.75rem !important;
        width: 100%;
        height: auto;

        .accordion-minus-icon,
        .accordion-plus-icon {
          color: $gray-15;
          width: 0.875rem;
          height: 0.875rem;
        }

        &:hover {
          background-color: $gray-19;
        }
      }

      .accordian-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100% !important;
        padding: 0.563rem 0;

        .icon {
          display: flex;
          align-items: center;
          color: $gray-15;
        }

        .label {
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          color: $blue-15;
          width: 95%;
          padding: 0 0.375rem;
        }

        .shd-icon {
          font-size: 1.0625rem;
          margin-right: 0.375rem;
          color: $gray-15;
        }

        .bs-tag-close-icon {
          color: $gray-15 !important;
        }
      }

      .accordion__panel {
        padding: 0.75rem;
        background-color: $gray-19;
        padding-top: 0 !important;
        user-select: none;
      }

      .filter-applied-collapse {
        padding: 0 0.5rem 0.5rem 0;

        .filter-tag {
          margin-top: 0 !important;
          margin-bottom: 0.25rem !important;
        }
      }

      &:hover {
        background-color: $gray-19;
      }

      .accordion-item-selected-tag {
        .accordian-label {
          .icon {
            color: $blue-11;
          }

          .label {
            font-size: 0.75rem;
            font-weight: 500;
            color: $blue-11;
            width: 95%;
            padding: 0 0.375rem;
          }
        }
      }
    }

    .accordion__item_selected {
      border-radius: unset !important;
      box-shadow: unset !important;
      border-top: 1px solid $gray-13 !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;

      &:first-child {
        border-top: none !important;
      }

      &:last-child {
        border-bottom: 1px solid $gray-13 !important;
      }

      .accordion-item-heading {
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding: 0 0.75rem !important;
        width: 100%;
        background: $gray-19;
        color: $blue-11;

        .accordion-minus-icon,
        .accordion-plus-icon {
          color: $gray-15;
          width: 0.875rem;
          height: 0.875rem;
        }

        &:hover {
          background-color: $gray-19;
        }
      }

      .accordion-item-heading-tag {
        display: flex;
        align-items: center;
        padding: 0 0.75rem !important;
        width: 100%;
        background: $gray-19;
        color: $blue-11;
        height: auto;

        .accordion-minus-icon,
        .accordion-plus-icon {
          color: $gray-15;
          width: 0.875rem;
          height: 0.875rem;
        }

        &:hover {
          background-color: $gray-19;
        }
      }

      .accordian-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100% !important;
        padding: 0.563rem 0;

        .icon {
          display: flex;
          align-items: center;
          color: $blue-11;
        }

        .label {
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          color: $blue-11;
          width: 95%;
          padding: 0 0.375rem;
        }

        .shd-icon {
          font-size: 1.0625rem;
          margin-right: 0.375rem;
          color: $gray-15;
        }

        .bs-tag-close-icon {
          color: $gray-15 !important;
        }
      }

      .accordion__panel {
        padding: 0.75rem;
        background-color: $gray-19;
        padding-top: 0 !important;
        user-select: none;
      }

      &:hover {
        background-color: $gray-19;
      }
    }
  }
}

// Search Input
.leads-search {
  height: 2.5rem;
  border-bottom: 1px solid $gray-13;
  display: flex;
  align-items: center;

  form {
    width: 100%;
  }

  .bs-input-affix-wrapper:focus,
  .bs-input-affix-wrapper:focus-within {
    border-color: unset;
    border-right-width: unset;
    outline: 0;
    box-shadow: unset;
  }

  .search-input {
    background: none;
    border: none;
    max-width: 16.375rem;
    padding-left: 0.355rem;

    input {
      background: none;
      font-size: 12px;
      font-weight: $font-weight-normal;
      color: $blue-15;

      &:focus,
      :focus-visible,
      :focus-within,
      :active,
      :hover {
        box-shadow: none;
        outline: none;
        border: none;
      }

      &::placeholder {
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
      }
    }

    .input-icons-left {
      .bs-input-prefix {
        position: relative;
        bottom: -0.0625rem;

        .sh-search {
          font-size: 16px;
        }
      }
    }

    .input-icons-right {
      .bs-input-suffix {
        .sh-close-o {
          cursor: pointer;
        }
      }
    }
  }
}

// Bulk Search
.leads-bulk-search {
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-bottom: 1px solid $gray-13;
  margin-bottom: 0;
  padding-left: 0.75rem;
  color: $blue-11;
  gap: 0.5rem;
  cursor: pointer;
}

// Filter
.leads-filter {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  border-bottom: 1px solid $gray-13;
  margin-bottom: 0;
  padding-left: 0.75rem;

  div {
    cursor: pointer;

    .sh-filter {
      font-size: 0.938rem;
      margin-right: 0.5rem;
    }
  }

  button {
    &:hover {
      text-decoration: none;
    }

    span {
      font-weight: $font-weight-medium;
    }
  }
}

.filter-checkbox {
  margin-top: 0.375rem;

  &:first-child {
    margin-top: 0;
  }

  .expandedIcon-checkbox {
    display: flex;
    align-items: start;

    svg {
      margin-right: 0.16rem;
      position: relative;
      top: -0.063rem;
    }

    .minus-plus-icon {
      svg {
        color: $gray-15;
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 0px;
    }

    label {
      display: flex;

      .label-container {
        display: flex;
      }
    }
  }

  .render-checkbox {
    padding-left: 0.125rem;

    .checkbox-number {
      .bs-checkbox-wrapper {
        line-height: 1.188rem;
      }
    }
  }

  .checkbox-children {
    display: flex;
    flex-direction: column;

    label {
      &:first-child {
        margin-left: 0.5rem;
      }

      span {
        &:last-child {
          &:hover {
            color: $blue-11;
          }
        }
      }
    }

    &:hover {
      .bs-checkbox-wrapper {
        color: $blue-11;
      }
    }
  }

  .bs-checkbox-wrapper {
    align-items: start;

    .bs-checkbox {
      margin-top: 0.188rem;

      .bs-checkbox-input {
        &:checked {
          ~ .bs-checkbox-inner {
            background-color: $blue-11;
            border-color: $blue-11;
          }
        }
      }

      .bs-checkbox-inner {
        border-radius: 0.25rem;
      }
    }

    .bs-checkbox-indeterminate {
      .bs-checkbox-inner {
        &::after {
          background-color: $blue-11;
        }
      }
    }

    span + span {
      font-size: 0.75rem;
      font-weight: 400;
      color: $blue-15;
      padding-left: 0.375rem;
      padding-right: 0.188rem;
      width: 100%;
    }
  }

  .checkbox-lable {
    color: $blue-15 !important;
    font-weight: $font-weight-normal;
    font-size: 0.75rem;

    &:hover {
      color: $blue-11 !important;
    }
  }

  .checked-number {
    font-size: 0.625rem;
    font-weight: $font-weight-normal;
    color: $gray-15;
    margin-left: 0.125rem;
  }
}

.prospect-tag-popover {
  padding: 0 !important;

  .popover-body {
    .bs-popover-content {
      width: 16.75rem;

      .bs-popover-inner-content {
        padding: 0;

        p {
          width: 11.75rem;
          margin-right: 0.5rem;
        }

        svg {
          color: $gray-15;

          &:hover {
            color: $blue-11;
          }
        }

        .transfer-container {
          .transfer-body {
            .transfer-search {
              height: 2rem;
              padding: 0.375rem 0.75rem;
              border-top: 1px solid $gray-16;
              border-bottom: 1px solid $gray-16;
            }

            .transfer-content {
              padding-top: 0.5rem;

              .transfer-list {
                li {
                  padding: 0.3125rem 1rem;

                  .search-seved-filter {
                    font-weight: $font-weight-normal;
                  }

                  .edit-icon {
                    margin-right: 0.625rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.prospect-tag-applied {
  padding: 1rem !important;

  .popover-body {
    .bs-popover-content {
      width: 12.75rem;

      .bs-popover-inner-content {
        padding: 0;

        p {
          width: 11.75rem;
        }

        div {
          .saved-applied-filter {
            color: $gray-12;
            font-weight: $font-weight-semibold;
            font-size: 0.875rem;
            padding-top: 0.125rem;
            line-height: normal;
          }

          .applied-filter-active {
            color: $blue-11;
            font-weight: $font-weight-normal;
            font-size: 0.875rem;
            padding-top: 1.125rem;
            line-height: normal;
          }
        }
      }
    }
  }
}

// saved filter
.saved-filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625rem;
  height: 2.5rem;
  width: 16.4375rem;
  position: fixed;
  bottom: 0px;
  background-color: $white;
  box-shadow: 0px 0px 10px 0px rgba(4, 21, 76, 0.08);

  .saved-filters {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
      color: $gray-15;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        position: relative;
        left: 0.1875rem;
      }
    }

    span {
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: 500;
      padding-left: 0.375rem;
    }

    .saved-filter-dot {
      background-color: $blue-23;
      height: 0.5rem;
      width: 0.5rem;
      margin-left: 0.25rem;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  span {
    color: $blue-11;
    font-size: 0.75rem;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  .save-filter {
    .save-filter-txt {
      color: $blue-11;
      font-weight: 500;
      font-size: 12px;

      &.disable-save-filter {
        color: $gray-23;

        &:hover {
          cursor: default;
          color: $gray-23;
        }
      }

      &:hover {
        color: $blue-20;
      }
    }
  }
}

@media only screen and (min-width: $screen-1600) and (max-width: 3840px) {
  .saved-filters-container {
    width: 16.563rem;
  }
}

// Tag component

// for new exact match tags
.filter-container-lead {
  color: $blue-15 !important;
  font-size: 0.75rem !important;
  font-weight: $font-weight-medium !important;
  background-color: $gray-16 !important;
  display: block;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  padding: 0.2rem 0.3rem;
  margin-top: 0.2rem;
  position: relative;

  // close icon in tag
  .remove-icon {
    transform: rotate(45deg);
    font-size: 0.9rem;
  }
}
.filter-tag {
  color: $blue-15 !important;
  font-size: 0.75rem !important;
  font-weight: $font-weight-medium !important;
  background-color: $gray-16 !important;
  margin-top: 0.25rem !important;
  margin-right: 0.25rem !important;
  align-items: start;
  height: 1.375rem !important;

  &-exact-match {
    white-space: unset !important;
    position: relative !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;

    .bs-tag-close-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.3rem;
    }
  }

  .bs-tag-close-icon {
    margin-left: 0.5rem !important;
    font-size: 0.875rem !important;
    width: 1rem;

    .sh-close {
      top: 0.125rem !important;
      color: $gray-15 !important;
    }

    svg {
      color: $gray-15 !important;
    }
  }

  &.more-tag {
    cursor: pointer;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    min-height: 1.375rem;
    position: relative;
    top: 0.188rem;

    div {
      p {
        font-size: 0.75rem;
        line-height: normal;
        font-weight: $font-weight-medium;
      }
    }
  }
}

// filter checkbox container
.filter-checkbox-container {
  margin-top: 0.75rem;
}

// -------------------------
// Checkbox Container
.checkbox-number {
  display: flex !important;
  justify-content: space-between;

  // Plus & Minus Icon
  .include-exclude-icon {
    display: none;
  }

  &:hover {
    // Checkbox Input
    .bs-checkbox-wrapper {
      .bs-checkbox {
        .bs-checkbox-inner {
          border-color: $blue-11;
        }
      }

      // Label & Number
      .label-container {
        .checkbox-lable {
          color: $blue-11 !important;
        }

        .checked-number {
          color: $blue-11;
        }
      }
    }

    // Plus & Minus Icon Hover
    .include-exclude-icon {
      display: flex;

      .include-circle-plus {
        width: 1.125rem;
        height: 1.125rem;
        cursor: pointer;
        margin-right: 0.25rem;
      }
  
      .include-enabled {
        color: $blue-11 !important;
      }
  
      .include-disabled {
        color: $blue-12 !important;
        cursor: not-allowed;
      }

      .circle-minus {
        color: $red-18 !important;
      }
    }
  }
}

.checkbox-number-child {
  padding-left: 0.75rem;

  &:hover {
    // Checkbox Input
    .bs-checkbox-wrapper {
      .bs-checkbox {
        .bs-checkbox-inner {
          border-color: $blue-11;
        }
      }

      // Label
      span {
        &:last-child {
          color: $blue-11 !important;
        }
      }
    }
  }
}

.includes-excludes-text {
  margin-top: 0.75rem;
  margin-bottom: 0;
  color: $gray-15;
  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  line-height: normal;
}

.input-container {
  position: relative;

  .label {
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    margin-bottom: 0.25rem;
  }
  .include-exclude-icon {
    display: flex;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    .include-circle-plus {
      width: 1.125rem;
      height: 1.125rem;
      cursor: pointer;
      margin-right: 0.25rem;
    }

    .include-enabled {
      color: $blue-11 !important;
    }

    .include-disabled {
      color: $blue-12 !important;
      cursor: not-allowed;
    }

    .circle-minus {
      color: $red-18 !important;
    }
  }

  .bs-checkbox-wrapper {
    align-items: start;

    .bs-checkbox {
      .bs-checkbox-input {
        &:checked {
          ~ .bs-checkbox-inner {
            background-color: $blue-11;
            border-color: $blue-11;
          }
        }
      }

      .bs-checkbox-inner {
        border-radius: 0.25rem;
      }
    }

    span + span {
      font-size: 0.75rem;
      font-weight: 400;
      color: $blue-15;
      padding-left: 0.375rem;
      padding-right: 0.188rem;
      width: 100%;
    }
  }
  &.department-search-input {
    margin-top: 0.75rem;

    .autocomplete {
      top: 3.75rem !important;
    }
  }

  &.management-levels-label {
    margin-top: 0.75rem;

    span {
      padding: 0 !important;
    }
  }

  &.sic-code-input {
    margin-top: 0.75rem;
  }

  &.naics-code-input {
    margin-top: 0.75rem;
  }

  &.major-input-school {
    margin-top: 0.75rem;
  }

  &.major-input-degree {
    margin-top: 0.75rem;
  }

  &.major-input {
    margin-top: 0;
  }

  .autocomplete {
    position: absolute !important;
    top: 2.5rem !important;
    border: 1px solid $gray-13;
    box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
      2px 2px 16px -3px rgba(4, 21, 76, 0.16);

    .item:hover {
      .include-exclude-icon {
        display: flex;
      }
    }
    .include-exclude-icon {
      display: none;
      transform: translateY(0%);
    }
    .autocomplete-text {
      white-space: nowrap;
      width: 11rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    &.autocomplete-custome-menu {
      top: 3.75rem !important;
    }
    .autocomplete-menu-list {
      background-color: #eff6ff;
      color: #1f2937;
      cursor: pointer;
    }
  }
}

.search-checkbox {
  margin-top: 0.75rem;

  .search-checkbox-container {
    margin-top: 0.313rem;

    &:first-child {
      margin-top: 0;
    }

    .bs-checkbox-wrapper {
      display: flex;
      align-items: center;

      .label-container {
        display: flex;
        align-items: center;

        .checkbox-lable {
          color: #1f2937 !important;
          font-weight: $font-weight-normal;
          font-size: 0.75rem;
        }
      }

      .bs-checkbox {
        .bs-checkbox-input {
          &:checked {
            ~ .bs-checkbox-inner {
              background-color: $blue-11;
              border-color: $blue-11;
            }
          }
        }

        .bs-checkbox-inner {
          border-radius: 0.25rem;
        }
      }

      .bs-checkbox-indeterminate {
        .bs-checkbox-inner {
          &::after {
            background-color: $blue-11;
          }
        }
      }

      span + span {
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
        color: $blue-15;
        padding-left: 0.375rem;
        padding-right: 0.188rem;
        width: 100%;
      }
    }

    &:hover {
      .label-container {
        .checkbox-lable {
          color: $blue-11 !important;
        }

        .checked-number {
          color: $blue-11 !important;
        }
      }
    }
  }
}

// Include Exclude Icon
.include-exclude-icon {
  display: flex;
  align-items: center;

  .circle-plus {
    color: $gray-400 !important;
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
    margin-right: 0.25rem;

    &:hover {
      color: $blue-11 !important;
    }
  }

  .circle-minus {
    color: $gray-400 !important;
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;

    &:hover {
      color: $red-18 !important;
    }
  }
}

// Dropdown Filter
.dropdown-filter {
  .label {
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    margin-bottom: 0.25rem;
  }

  &.job-change-dropdown {
    margin-top: 0.75rem;
  }
}

// Min & Max Input
.input-group {
  margin-top: 0.75rem;

  .input-group-prepend {
    height: 2.063rem;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    .input-group-text {
      border-radius: 0px;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      border: 1px solid $gray-23;

      &.error-prefix-input {
        border-color: $red-20;
      }
    }
  }

  .min-input-container,
  .max-input-container {
    width: 50%;
  }

  .max-input {
    position: relative;

    .error-message {
      svg {
        position: absolute;
        top: 0.563rem;
        right: 0.563rem;
        cursor: pointer;
      }
    }
  }

  .form-control {
    height: 2.063rem;
    border-radius: 4px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: 1px solid $gray-23;

    &.is-invalid {
      background-image: unset;
      border-color: $red-20 !important;
      box-shadow: unset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.without-prefix {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    &:focus {
      box-shadow: unset;
      border: 1px solid $blue-11;
    }

    &::placeholder {
      color: $gray-11;
    }
  }
}

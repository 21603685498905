.modify-subscription {
  &__highlight-container {
    background: $orange-15;
    height: 1rem;
    padding: 0.125rem 0.3125rem;
    border-radius: 0.25rem;
    margin-bottom: 0.475rem;
  }

  /* Safari  */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      &__highlight-container {
        min-width: 10.1875rem;
      }
    }
  }
  &__circle {
    position: absolute;
    right: -0.75rem;
    top: 0.125rem;
  }
  &__progress-bar-container {
    width: 21.3125rem;
    margin-top: 1.9063rem;
    position: relative;
    .step-icon {
      height: 1.875rem;
      width: 1.875rem;
      border-radius: 50%;
    }
    .active-step {
      .step-icon {
        background: $blue-11;
        color: $white;
      }
    }
    .done-step {
      .step-icon {
        background: $blue-12;
        color: $gray-19;
      }
      span {
        color: $gray-15;
      }
    }
    .disable-step {
      color: $blue-14;

      span {
        color: inherit;
        margin-top: 0.3438rem;
      }
    }
    .cursor-not-allowed {
      cursor: not-allowed;
      .step-icon {
        i {
          cursor: inherit;
        }
      }
    }
  }
  &__divider {
    position: absolute;
    left: 4.4125rem;
    top: 0.9375rem;
    width: 13rem;
    height: 0.125rem;
    background: $gray-13;
  }
  &__select-plan {
    width: 33.125rem;
    &-banner {
      background: $blue-17;
      padding: 0.75rem 1rem;
      border-radius: 0.125rem;
      .ghost-outline-new.btn-ghost-primary {
        border: 1px solid $gray-15;
        color: $gray-15;
      }
    }
  }
  &__select-plan-container {
    gap: 20px;
  }
}

.banner-announcement {
  background: $yellow-11;
  width: 61.25rem;
  padding: 0.625rem 0px;
  border: 1px solid $orange-18;
  border-radius: 0.25rem;
  border-left-width: 4px;
  margin-top: 1.5313rem;
  .coupon-code {
    background: $gray-16;
    border: 1px dashed $gray-23;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
  }
  @media screen and (max-width: $screen-1366) {
    width: 50rem;
  }
  @media screen and (max-width: $screen-1280) {
    width: 46rem;
  }
}

.cold-email-plan-card {
  width: 56.5rem;
  .pricing-card {
    .card {
      height: 29.5625rem;
      width: 13.1875rem;
    }

    &__details-price-wrapper {
      height: 1.25rem;
    }

    &__features {
      ul > li {
        .feature-tooltip {
          text-decoration: underline;
          text-decoration-style: dotted;
        }
      }
    }

    .solid-primary-new.btn-solid-primary {
      &:hover {
        background-color: $blue-11;
        color: $white;
      }
    }
  }
}

.outreach-plans {
  .premium-feature-card {
    width: 38.5rem;
    border-radius: $border-radius;
  }
  .secondary-banner {
    background: $gray-16;
    width: 61.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-13;
  }
  @media screen and (max-width: $screen-1366) {
    .secondary-banner {
      width: 50rem;
    }
  }
  @media screen and (max-width: $screen-1280) {
    .secondary-banner {
      width: 46rem;
    }
  }
  .bs-ml-15-rem {
    margin-left: 14.1rem !important;
  }
  .bs-ml-20-rem {
    margin-left: 20rem !important;
  }
  .bs-ml-23-rem {
    margin-left: 21.3125rem !important;
  }
}

.outreach-plans-card {
  width: 61.25rem;
  &.lead-finder-plans {
    .pricing-card {
      height: 29.375rem;
    }
  }
  .pricing-card {
    .card {
      border-radius: 0px;
      height: 30.9375rem;
      width: 15.3125rem;
      padding: 1.5rem;
      border-right-width: 0px;
    }
    &:first-child {
      .card {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }
    &:last-child {
      .card {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-right-width: 1px;
      }
    }
    &__price-wrapper {
      margin-top: 1.25rem;
    }
    &__features {
      margin-top: 1rem;
      .range-wrap {
        margin-top: 1.875rem;
      }
      .range {
        &__mark {
          &:first-child {
            left: 2px !important;
          }
        }
        &__thumb {
          &-first {
            transform: translate(3px, -5.5px) !important;
          }
          &-last {
            transform: translate(179px, -5.5px) !important;
          }
        }
      }
      ul > li {
        .feature-tooltip {
          text-decoration: underline;
          text-decoration-style: dashed;
          text-decoration-color: $gray-11;
          text-underline-offset: 4px;
        }
      }
    }
    .ghost-secondary-new.btn-ghost-primary {
      border: 1px solid $gray-15;
      color: $gray-15;
      &:hover {
        color: $gray-15;
      }
    }
    .ghost-primary-new.btn-ghost-primary {
      &:hover {
        background: $blue-11;
      }
      &:active {
        color: $white;
      }
    }
    .ghost-primary-new-disable.btn-ghost-primary:disabled {
      opacity: 0.35;
      border: 1px solid $blue-11;
      color: $blue-11;
      &:hover {
        opacity: 0.35;
        color: $blue-11;
        background-color: transparent;
        cursor: not-allowed !important;
      }
    }
    @media screen and (max-width: $screen-1366) {
      .btn-ghost-primary,
      .btn-solid-primary {
        font-size: 0.8125rem;
      }
    }
  }

  .best-value-plan {
    .card {
      border-top-width: 1px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-right-width: 1px;
    }
    &__label-wrapper {
      width: 15.3125rem;
      margin-bottom: -1px;
      .bs-tag {
        cursor: default !important;
        margin-right: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border: 1px;
        font-size: 0.5rem;
      }
    }
  }
  @media screen and (max-width: $screen-1366) {
    width: 50rem;
    .pricing-card {
      .card {
        height: 27.5rem;
        width: 12rem;
      }
    }
    .best-value-plan {
      &__label-wrapper {
        width: 12rem;
      }
    }
  }
  @media screen and (max-width: $screen-1280) {
    width: 46rem;
    .pricing-card {
      .card {
        height: 27.5rem;
        width: 11.25rem;
      }
    }
    .best-value-plan {
      &__label-wrapper {
        width: 11.25rem;
      }
    }
  }
}

.billing-summary-modal {
  .modal-dialog {
    width: 25.5625rem;
    height: 23.5625rem;
  }
  .modal-header {
    border-bottom-width: 0px;
  }

  .modal-body {
    .divider-bottom {
      border-bottom: 2px solid $gray-13;
      padding-bottom: 0.9375rem;
      margin-bottom: 0.9375rem;
      margin-top: 0.625rem;

      .promo-code-container {
        .coupon-code-input-wrapper {
          padding-bottom: 0.625rem;
          .coupon-code-input.bs-input-affix-wrapper {
            background: $gray-25;
            border-color: $gray-16;
          }
          .coupon-code-input {
            height: 2.25rem;
            align-items: center;
            .bs-input {
              background: $gray-25;
            }
            .input-error {
              height: inherit;
              background: $gray-25;
              align-items: center;
            }
            .input-succeeded {
              height: inherit;
              background: $gray-25;
              align-items: center;
              input {
                pointer-events: none;
              }
              i {
                cursor: pointer;
              }
            }
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 0.5rem;
              line-height: 14px;
              width: 100%;
              text-align: right;
            }

            .icon-loading {
              i {
                animation: spin 2s linear infinite;
              }
            }
          }
        }
      }
    }
    .loader-container {
      height: 10px;
      img {
        height: 1.5rem;
      }
    }
  }
}

.solid-primary-new.btn-solid-primary {
  background: $blue-11;
  &:hover {
    background: $blue-20;
  }
}

.searching-empty {
    width: 100%;
    height: calc(100vh - 6.938rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        font-weight: $font-weight-semibold;
        color: $gray-800;
        font-size: 0.875rem;
        color: $blue-15;
        padding-top: 1rem;
        margin-bottom: 0;
        line-height: normal;
    }

    .text-desc {
        text-align: center;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        padding-top: 0.5rem;
        color: $gray-15;
        margin-bottom: 0;
    }

    .leads-profile {
        display: flex;
        background: $blue-17;
        width: 27.75rem;
        padding: 1rem;
        gap: 1rem;
        border-radius: 0.25rem;
        margin-top: 2rem;

        .professional-profile {
            text-align: center;
            align-items: center;
            padding: 1rem 1.5rem;
            background: $white;
            width: 12.375rem;
            height: 7.75rem;
            border-radius: 0.25rem;

            .profile-icon {
                height: 1.5rem;
                width: 1.5rem;
                color: $blue-23;
            }

            .profile-lable {
                font-size: 1rem;
                font-weight: $font-weight-semibold;
                color: $blue-15;
                padding-top: 1rem;
                margin-bottom: 0;
            }

            .profile-text {
                font-size: 0.875rem;
                color: $gray-15;
                padding-top: 0.5rem;
                font-weight: $font-weight-normal;
                margin-bottom: 0;
            }
        }
    }
}

// Alert Message
.alert-message {
    .searching-empty {
        height: calc(100vh - 9.25rem);
    }
}
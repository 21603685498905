.table-container {
    width: 100%;
    max-height: 24rem;
    overflow-x: auto;
    padding: 0;
    position: relative;

    .table {
        margin-bottom: 0;
        width: max-content; // Allow table to grow based on content
        min-width: 100%; // Ensure table takes at least full container width

        thead {
            .table-cell {
                font-size: $text-12;
                color: $gray-15;
                font-weight: $font-weight-bold;
                line-height: $text-style-1-line-height;
                height: 46px;
            }
        }

        .table-cell {
            font-size: $text-14;
            font-weight: $font-weight-medium;
            color: $blue-15;
            line-height: $text-style-1-line-height;
            padding: 8px 16px;
            vertical-align: middle;
            border-top: 0.0625rem solid $gray-13;
            border-bottom: 0.0625rem solid $gray-13;
            border-left: 0.0625rem solid $gray-13;
            border-right: 0.0625rem solid $gray-13;
            white-space: nowrap;
            height: 40px;

            // Set reasonable min and max widths for cells
            min-width: 120px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            background-clip: padding-box;
            text-align: center; // Center align text
            
            // Center all types of content
            > * {
                vertical-align: middle;
                margin: 0 auto;
                text-align: center;
            }

            // Center flex containers
            > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.sticky-column {
                position: sticky;
                left: 0;
                z-index: 1;
                background-color: $white;
                min-width: 200px; // Wider min-width for sticky column
                border-left: none;
                border-right: none;
                text-align: left; // Keep left alignment for sticky column
                
                // Left align content in sticky column
                > * {
                    margin: 0;
                    text-align: left;
                }

                > div {
                    justify-content: flex-start;
                }

                // Create left border using pseudo element
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 0.0625rem;
                    background-color: $gray-13;
                    z-index: 3;
                }

                // Add gradient shadow
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 8px;
                    background: linear-gradient(
                        90deg,
                        #e2e6f5 0%,
                        rgba(226, 230, 245, 0) 108.33%
                    );
                    pointer-events: none;
                    z-index: 3;
                }
            }

            &.border-left-none {
                border-left: none;
            }
        }

        .empty-message {
            text-align: center;
            padding: 1rem;
            color: #6b7280;
        }
    }
}
.api-token-container {
  height: 100%;
  width: 100%;
  .api-token-header {
    .api-token-create-button {
      min-width: 8.375rem;
    }
  }
  .table-list {
    position: relative;
    height: calc(100vh - 13.6rem);
    overflow-y: auto;
    overflow-x: auto;
  }
}

.required:after {
  content: ' *';
  color: red;
}

.required {
  margin-bottom: 0%;
  font-size: 0.7rem;
}

.bs-table-thead {
  text-align: center !important;
}

.save-filter-modal {
  .modal-content {
    .modal-content-container {
      .bs-modal-header {
        padding: 1.5rem 1.5rem 0 1.5rem;
        border: none;
        min-height: unset;

        .modal-title {
          svg {
            color: $blue-11;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.625rem;
          }

          .delete-icon {
            color: $red-12 !important;
          }
        }
      }
    }

    .bs-modal-body {
      padding-bottom: 0;

      .bs-input-container {
        margin-bottom: 0;

        .bs-input-label-prefix {
          font-weight: $font-weight-semibold;
          color: $gray-15;
        }
      }

      p {
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: normal;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .bs-modal-footer {
      max-height: unset;
      min-height: unset;
      margin: 1.5rem 1.5rem 1.25rem 1.5rem;
      padding: 1.25rem 0 0 0;

      .bs-modal-footer-action-buttons {
        margin: 0;

        .btn-ghost-outlined {
          min-width: 6.25rem;
          border-color: $gray-13;
          color: $gray-15;
          text-transform: capitalize;
          font-weight: 500;
        }

        .btn-solid-primary {
          min-width: 7.25rem;
          margin-left: 1rem !important;
          background-color: $blue-11;
          font-weight: 500;
        }

        .btn-solid-danger {
          min-width: 6.25rem;
          color: $white;
          background-color: $red-12;
        }
      }
    }
  }
}
.settings-content {
  padding: 1.5rem 1.5rem 0px 1.5rem;
  background-color: $gray-19;
  &__left-container {
    .sequence-settings-sidebar {
      width: 8.625rem;
      transition: all 0.3s;
      &__item {
        padding: 0.5rem 0.75rem;
        border: 1px solid transparent;
        &:not(:first-child) {
          margin-top: 0.375rem;
        }
      }
      .active {
        background-color: $blue-17;
        border: 1px solid $blue-13;
        border-left: 3px solid $blue-11;
        border-radius: 1px 0.25rem 0.25rem 1px;
        box-shadow: 1px 1px 4px $gray-16;
        padding-left: 0.625rem;
      }
    }
  }
  &__right-container {
    overflow-y: scroll;
    // height: calc(100vh - 20vh);
    height: 100%;

    @media screen and (max-width: $screen-1280) {
      height: calc(100vh - 24.65vh);
    }

    .card {
      border: 1px solid $gray-26 !important;
    }
    .card > .card-body {
      padding: 0;
      .cst-disable.shd-button {
        opacity: 0.3;
        cursor: not-allowed;
      }
      .table-list .empty-list {
        display: none;
      }
      .sequence-email-account-table-list {
        height: 100%;
        .react-bootstrap-table {
          .table-organism {
            .table-organism-header {
              background: $gray-16;
              pointer-events: none;
              tr > th {
                border: none;
                padding: 0.5rem;
                &:first-of-type {
                  padding-left: 1.875rem;
                }
              }
            }
          }
        }
        .sequence-email-account-table-body {
          tr {
            height: 3.75rem;
            td {
              &:first-child {
                border-left: 1px solid $gray-16;
              }
              &:last-child {
                border-right: 1px solid $gray-16;
              }
            }
            &:hover {
              .sequence-settings--action > i {
                display: inline-block;
              }
            }
          }
          .sequence-settings {
            &--owner {
              width: 1rem;
            }
            &--action {
              padding-right: 0px;
              width: 3.5rem;
              i {
                display: none;
              }
            }
          }
        }
        .no-data > tr > td {
          border-left-width: 0px !important;
          border-right-width: 0px !important;
        }
      }

      .pagination-btn {
        position: absolute;
        width: 100%;
        height: 5.1875rem;
        right: 0px;
        bottom: -1px;
        background: linear-gradient(
          rgba(255, 255, 255, 0.5) 43.56%,
          #f9fafb 63.83%
        );
      }

      .bs-select-box {
        width: 19.375rem;
      }

      .tooltip-lg .tooltip-inner {
        max-width: 50rem !important;
      }

      .tooltip-schedule {
        max-width: 450px;

        &.bs-popover-inner {
          background-color: #1f2937;

          .bs-popover-inner-content {
            padding: 0;
          }

          .arrow::after,
          .arrow::before {
            border-right-color: #1f2937;
          }
        }

        .schedule-day {
          width: 40rem;
          padding: 0px 16px 16px 16px;

          display: flex;
          align-items: center;

          p {
            margin: 0;
            color: $white;

            &:first-of-type {
              min-width: 75px;
              font-weight: 500;
              font-size: 12px;
            }

            &:nth-of-type(2) {
              background-color: $gray-12;
              height: 2px;
              width: 15rem;
              border-radius: 1px;
            }

            &:last-of-type {
              margin-left: 10px;

              display: flex;
              span {
                margin: 0px 5px;
                width: 86px;
                height: 20px;
                border-radius: 4px;
                background-color: $blue-16;
                font-feature-settings: 'tnum' on, 'lnum' on;

                display: flex;
                justify-content: center;
                align-items: center;

                font-weight: normal;
                font-size: 11px;
              }
            }
          }
        }

        .schedule-timezone {
          font-size: 12px;
          color: $white;

          padding: 12px 16px;
          margin-bottom: 12px;
          border-bottom: 1px solid $blue-16;
        }
      }

      .average-deal-container {
        border-right: 0px;
        height: 36px;
        width: 33px;
        padding: 8px 0px;
        border-radius: 4px 0px 0px 4px;
        border-width: 1px;
        border-style: solid;
        border-color: #d1d5db;
        background-color: #f9fafb;
        color: #d1d5db;
      }

      .deal-span-icon {
        font-size: 0.875rem;
        width: 0.875rem;
        height: 1.25rem;
        top: 0.5rem;
        left: 0.75rem;
      }

      .average-deal-value-input {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: center;
        height: 2.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #000000;
        .bs-input-number-handler-wrap {
          display: none;
        }
        .bs-input-number-input {
          height: 34px;
        }
      }

      .sequence-setting-verify-status {
        margin-top: 1.625rem;
        &__banner {
          margin-top: 0.5rem;
          width: fit-content;
          span {
            font-size: 0.75rem;
            line-height: 1.125rem;
            margin-left: 0.25rem;
          }
          .banner-message-wrap {
            .anchor-link-text {
              color: $orange-12;
              a {
                text-decoration: underline;
                margin-left: 0.15rem;
              }
            }
          }
        }
        .verify-button {
          margin-left: 1rem;

          .icon-left {
            margin-right: 0.5rem;
            .refresh-svg {
              animation: spin 2s linear infinite;
            }
          }
        }
        .verify-button-pending {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .switchToggle.switch-small label span {
        width: 45rem;
        color: $blue-15;
        font-weight: $font-weight-normal;
      }

      .sequence-setting-tab-switch {
        label {
          min-width: 32px;
          background: $gray-11;
        }
        label span {
          left: 38px;
        }
      }
      .unsub-preset {
        margin-top: 1.4rem;
        border-left: 2px solid $gray-16;
        padding-left: 1.1875rem;
        label {
          color: $gray-9;
        }
        .preset-list {
          display: flex;
          font-size: $text-14;
          font-weight: $font-weight-normal;
          line-height: $text-style-2-line-height;
          color: $popover-arrow-color;
          margin-bottom: 0.5rem;
          .bs-radio-wrapper {
            margin-right: 0.5rem;
          }
          .unsub-link-text {
            color: $blue-6;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .unsubscribe-setting-editor {
        min-height: 20rem;
      }
      .custom-unsub-preset {
        margin-left: 1.4rem;
        width: 35rem;
        height: 10rem;
      }
      .sequence-save-btn {
        margin-bottom: 2rem;
      }
      .error-caption {
        margin-bottom: 0;
        margin-top: 0.125rem;
        color: $red-6;
      }
      .sequence-settings-switch.switchToggle input:checked + label {
        background: $blue-11;
      }
    }
    .associate-client {
      height: 206px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid $gray-26;
      background: $gray-1;
      .agency-client-wrapper {
        &:hover,
        &.agency-client-show {
          border-color: #346aff;
          border-right-width: 0.0625rem !important;
        }
      }
      .btn-solid-primary:disabled {
        border: none;
        &.disabled {
          border: none;
        }
      }
      h3 {
        color: $blue-15;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0px 0px 3px 0px;
      }
      p {
        color: $gray-15;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .content {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .warning-client-associated-badge {
        display: flex;
        height: 24px;
        padding: 0px 8px;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border-radius: 100px;
        background: $yellow-11;
        border: 1px solid $orange-15;
        p {
          color: $orange-12;
          font-family: $font-family-sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin: 0;
        }
      }
    }
  }
}
.sequence-email-account-client-associated-tooltip {
  .arrow {
    position: absolute !important;
    left: 0px !important;
    transform: translate(11px, 0px) !important;
  }
}

.select-email-account-modal {
  .modal-dialog {
    max-width: 58.125rem;
  }
  .modal-content {
    max-height: 44.75rem;
  }
  .modal-body {
    padding-bottom: 0px !important;
    max-height: 27.5rem;

    .table-list .empty-list {
      display: none;
    }
    .select-email-header {
      margin-bottom: 1.25rem;
    }
    .select-count {
      border: 1px solid $orange-18;
      border-radius: 0.25rem;
    }
    .empty-table-list {
      height: 100%;
      margin-top: 1px;
    }
    .select-email-account-table-list-md {
      height: 21rem !important;
    }
    .select-email-account-table-list {
      height: 19rem !important;
    }
    .select-email-account-table-list,
    .select-email-account-table-list-md {
      margin-top: 1px;
      .select-email-account-table-body {
        tr > td {
          &:first-child {
            border-left: 1px solid $gray-16;
          }
          &:last-child {
            border-right: 1px solid $gray-16;
          }
        }
        .disable-row {
          pointer-events: none;
          cursor: not-allowed;
          td {
            opacity: 0.3;
            .bs-checkbox-wrapper
              .bs-checkbox-input:checked
              ~ .bs-checkbox-inner {
              background-color: $gray-1;
              border-color: $gray-5;
            }
          }
        }
      }
      .no-data > tr > td {
        border-left-width: 0px !important;
        border-right-width: 0px !important;
      }
    }
  }
}

.line-break > .tooltip-inner {
  white-space: pre-line;
}

.sender_rotation_heading {
  margin-left: 0.4375rem;
  line-height: 1.375rem;
  background-color: $gray-16;
  border-radius: 50px 50px;
  height: 1.585rem;
  width: 8.8125rem;
}

.sender_rotation_heading_dot {
  height: 0.5625rem;
  width: 0.5625rem;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 2px;
}

.sequence-setting-title {
  color: $blue-15;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.sequence-setting-description {
  color: #1f2937;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;

  a {
    color: $blue-11;
  }
}

.cc-bcc-label {
  color: #6b7280;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.cs-gap-4 {
  gap: 1.5rem !important;
}

.cc-bcc-email-input {
  &--wrapper {
    flex: 1;
    min-width: 27.875rem;
    height: 10.125rem;
  }
  &--container {
    min-width: 27.875rem;
    height: 7.375rem;

    overflow-y: auto;

    padding: 0.4375rem 1rem;

    display: grid;
    grid-template-rows: auto 1fr;

    border-radius: 0.25rem;
    border: 0.0625rem solid #d1d5db;
    background: #f9fafb;

    cursor: text;

    &:focus-visible {
      outline: none;
    }

    &:focus,
    &:focus-within {
      border-color: $blue-11;
    }

    &.disabled {
      cursor: not-allowed;

      &:focus,
      &:focus-within {
        border-color: #d1d5db;
      }
    }

    &.error {
      border-color: #fca5a5;
    }
  }

  &--error-msg {
    color: #b91c1c;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &--helper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.25rem;
  }

  &--count {
    color: #9ca3af;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &--pills {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.375rem;
    .shd-pills {
      height: 1.875rem;
      padding: 0.25rem 0.5rem;
      background-color: $white;
      border: 0.0625rem solid #d1d5db;
      gap: 0.5rem;
      .pills-label {
        line-height: 1rem;
      }
      .pills-close {
        margin: 0;
      }
    }
  }

  &--input {
    border: none;
    outline: none;

    width: 100%;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #1f2937;

    padding: 0;
    margin-top: 0.375rem;

    background: #f9fafb;

    &:disabled {
      background-color: transparent;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #9ca3af;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
}

.settings-with-tooltip {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  position: relative;

  .tooltip-icon {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;

    position: absolute;
    top: -1.5px;
    left: 218px;

    svg {
      width: 100%;
      height: 100%;
      color: $gray-11;
    }
  }
}

.settings-tooltip {
  .tooltip-inner {
    max-width: 22.875rem !important;
  }
}

.sequence-client-tooltip {
  .tooltip-inner {
    max-width: 15.625rem !important;
  }
  .arrow {
    position: absolute !important;
    left: 0px !important;
    transform: translate(5px, 0px) !important;
  }
}

.safety-settings-switch-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 26.625rem;

  .sequence-settings-switch {
    display: flex;
    align-items: center;

    height: 1.25rem;

    label {
      margin: 0;
      color: $blue-15;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;

      span {
        width: max-content !important;
      }
    }
  }
  .toggle-wrapper {
    width: 10.25rem;
  }

  .improve-deliverability-badge {
    height: 1.5rem;

    position: absolute;
    top: -0.125rem;
    left: 15.75rem;

    display: flex;
    align-items: center;

    padding: 0.125rem 0.5rem;
    border-radius: 6.25rem;
    background: $green-11;

    p {
      height: 1.25rem;

      margin: 0;

      color: $green-14;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
.sequence-sending-priority__card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.sequence-sending-priority-title {
  display: flex;
  width: 235px;
  align-items: center;
  gap: 8px;
}

.sender_rotation_heading_priority_setting {
  display: flex;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--gray-white, #fff);
}

.sequence-setting-priprity-title {
  color: #1f2937;
}
.sequence-setting-priprity-description {
  color: #6b7280;
  font-size: 12px;
}

.sending-priority-radio-group {
  .rb-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .bs-radio-wrapper {
      margin: 0 !important;
      .sequence-setting-priprity-title {
        line-height: 1.25rem;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
      }
      .sequence-setting-priprity-description {
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
      }
    }
  }
}
.sending-priority-save-btn {
  width: 5.0625rem;
  padding: 0.375rem 0.75rem;
}
.esp-matching-switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  height: 1.25rem;

  .sequence-settings-switch {
    height: 1rem;
  }

  span {
    margin: 0 !important;
    padding: 0 !important;
  }

  label {
    margin: 0 !important;
    padding: 0 !important;
  }

  &.disabled {
    opacity: 0.35;
  }
}

.esp-matching-table {
  margin-top: 1rem;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid $gray-16;
  border-radius: 0.25rem !important;

  &--head {
    padding: 0.5rem 1rem;
    gap: 1.25rem;
    color: $gray-12;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: 1rem;
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid $gray-16;
    border-right: 1px solid $gray-16;
    height: 2.25rem;
    text-wrap: nowrap;

    &:nth-child(1) {
      width: 10.1875rem;
    }

    &:nth-child(2) {
      width: 8.375rem;
    }

    &:nth-child(3) {
      width: 8.9375rem;
    }

    &:last-child {
      border-right: none;
      width: auto;
      min-width: 7.4375rem;
    }
  }

  &--data {
    padding: 0.5rem 1rem;
    gap: 1.25rem;
    color: $gray-15;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: $font-weight-medium;
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 1.1875rem;
    border-right: 1px solid $gray-16;
    border-bottom: 1px solid $gray-16;
    &:last-child {
      border-right: none;
    }
  }
  &--data-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    height: 1.1875rem;
    width: 8.125rem;
    text-wrap: nowrap;

    span {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  tbody tr:last-child td {
    height: 2.25rem;
    border-bottom: none;
  }
}

.esp-matching-table-icon {
  width: 0.875rem;
  height: 0.875rem;
}

.esp-matching-tooltip-icon {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  color: $gray-11;
  cursor: pointer;
}

.coming-soon-tag {
  display: flex;
  background-color: $blue-13;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  padding: 0.125rem 0.5rem;
  height: 1.125rem;
  color: $blue-15;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: 1rem;
  text-wrap: nowrap;
  width: 6rem;
}

.copy-button {
    background-color: $gray-1;
    border: 0.0625rem solid $gray-13;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: $text-14;
    line-height: $text-style-1-line-height;
    color: $gray-15;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  
    &:hover {
      background-color: $gray-19;
    }
  
    .copy-icon {
      width: 1rem;
      height: 1rem;
    }
  
  }
  
  .click-to-copy-tooltip {
    font-size: 0.75rem;
  }
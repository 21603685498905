.badge-dot-danger {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $red-12;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.badge-dot-success {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $green-14;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.badge-dot-secondary {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $gray-11;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.badge-dot-orange {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $orange-13;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.badge-dot-green {
  z-index: auto;
  width: 8px;
  height: 8px;
  background: $green-13;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

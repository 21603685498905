.connect-smtp-imap-account-modal {
  .modal-content {
    border-radius: 0.5rem !important;
  }

  .modal-dialog {
    max-width: 55.25rem;
  }

  .modal-body {
    overflow-y: scroll;
    background-color: $gray-16;
    padding: 0.5rem !important;

    @media screen and (max-width: $screen-1366) {
      overflow-y: auto;
    }
    .add-smtp-imap-wrapper {
      width: 100%;
      display: flex;

      .form-wrapper {
        width: 66%;
        overflow-y: scroll;
        max-height: calc(90vh - 9.5rem);

        @media screen and (max-width: $screen-1366) {
          overflow-y: hidden;
          max-height: 100%;
        }
        .form-header-wrapper {
          .form-header-title {
            width: 15.25rem;
            .title-txt {
              text-decoration: underline;
              margin-right: 0.125rem;
            }
          }
        }
        .switch-wrap {
          margin-top: 1rem;
          .smtp-imap-switch {
            label {
              span {
                min-width: 19rem;
                font-size: 0.75rem;
                color: $popover-arrow-color;
                font-weight: $font-weight-medium;
              }
            }
          }
          .use-username {
            label {
              span {
                min-width: 9rem;
              }
            }
          }
        }
        .form-container {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 1.5rem;

          .form-item {
            width: 15.25rem;
            margin-top: 0.3rem;
            position: relative;
            .email-provider-dropdown {
              margin-top: 0.125rem;
              .bs-select-box {
                background: $gray-25;
              }
              .bs-select-box-icon-wrapper {
                i {
                  color: $gray-11;
                }
              }
            }
            .form-control-input {
              span {
                background: $gray-25;
                input {
                  background: $gray-25;
                }
              }
              label {
                font-size: 0.75rem;
                color: $gray-15;
                font-weight: $font-weight-semibold;
              }
            }
            &:last-child {
              display: flex;
              justify-content: space-between;

              .port-input-wrap {
                margin-right: 0.5rem;
                .port-input {
                  min-width: 4.9375rem;
                  max-width: 4.9375rem;
                  background: $gray-25;
                }
              }
              .encryption-radio-group-wrap {
                // width: 3rem;
                .encryption-radio-group {
                  margin-top: 0.125rem;
                  .encryption-radio-input {
                    .btn-solid {
                      padding: 0.4rem 0.5625rem;
                      font-size: 0.75rem;
                      background: $gray-25;
                      color: $gray-11;
                      &:focus,
                      &:hover,
                      &.active {
                        z-index: 1;
                        color: $blue-11;
                      }
                    }
                  }
                }
              }
            }
            .validation-txt {
              position: absolute;
              bottom: -0.6rem;
              color: $red-18;
            }
          }
        }
      }

      .tips-wrapper {
        .tips-container {
          width: 17.75rem;
          border-radius: 0.625rem;
          padding: 1rem;
          .tips-title {
            .title-txt {
              line-height: 1.125rem;
            }
          }
          .tips-sections {
            margin-top: -0.5rem;
            .tip-section-points {
              ul,
              ol {
                margin-left: -1.25rem;
                line-height: 1.125rem !important;
                margin-bottom: 0px !important;
              }
            }
          }
          .tips-footer {
            border-top: 1px solid $gray-13;
            padding-top: 1rem;
          }
        }
        .test-email-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          .test-btn.btn-solid {
            margin-left: 0.25rem !important;
            border: 1px solid $gray-13;
          }
        }
      }
    }
  }

  .modal-footer {
    padding-bottom: 0.5rem;
    // padding-right: 1.5rem;

    .submit-btn {
      background: $blue-11;
      color: $white;
      &.disabled {
        opacity: 0.35;
      }
    }
  }
}

.tooltip {
  font-family: $font-family-base !important;

  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    text-align: left;
    padding: 0.625rem;
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    background-color: $blue-15;
  }
  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^='top'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='top-start'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='top-end'] .arrow::before {
    border-top-color: $blue-15;
  }
  &.bs-tooltip-right .arrow::before,
  &.bs-tooltip-auto[x-placement^='right'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='right-start'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='right-end'] .arrow::before {
    border-right-color: $blue-15;
  }
  &.bs-tooltip-bottom .arrow::before,
  &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='bottom-start'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='bottom-end'] .arrow::before {
    border-bottom-color: $blue-15;
  }
  &.bs-tooltip-left .arrow::before,
  &.bs-tooltip-auto[x-placement^='left'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='left-start'] .arrow::before,
  &.bs-tooltip-inner[x-placement^='left-end'] .arrow::before {
    border-left-color: $blue-15;
  }
}

.bs-tooltip-inner {
  &[x-placement^='top-start'] {
    @extend .bs-tooltip-top;
    .arrow {
      transform: none !important;
      left: 16px !important;
    }
  }
  &[x-placement^='top-end'] {
    @extend .bs-tooltip-top;
    .arrow {
      transform: none !important;
      left: unset !important;
      right: 16px;
    }
  }
  &[x-placement^='bottom-start'] {
    @extend .bs-tooltip-bottom;
    .arrow {
      transform: none !important;
      left: 16px !important;
    }
  }
  &[x-placement^='bottom-end'] {
    @extend .bs-tooltip-bottom;
    .arrow {
      transform: none !important;
      left: unset !important;
      right: 16px;
      &::before {
        border-bottom-color: $blue-15 !important;
      }
    }
  }
  &[x-placement^='right-start'] {
    @extend .bs-tooltip-right;
    .arrow {
      transform: none !important;
      top: 5px !important;
    }
  }
  &[x-placement^='right-end'] {
    @extend .bs-tooltip-right;
    .arrow {
      transform: none !important;
      top: unset !important;
      bottom: 5px;
    }
  }
  &[x-placement^='left-start'] {
    @extend .bs-tooltip-left;
    .arrow {
      transform: none !important;
      top: 5px !important;
    }
  }
  &[x-placement^='left-end'] {
    @extend .bs-tooltip-left;
    .arrow {
      transform: none !important;
      top: 5px !important;
    }
  }
  &[x-placement^='top'] {
    .arrow {
    }
  }
  &[x-placement^='right'] {
    .arrow {
    }
  }
  &[x-placement^='left'] {
    .arrow {
    }
  }
  &[x-placement^='bottom'] {
    .arrow {
    }
  }
}

.tooltip-max {
  .tooltip-inner {
    max-width: max-content !important;
  }
}
.tooltip-xxl {
  .tooltip-inner {
    max-width: 15.9375rem !important;
  }
}
.tooltip-xl {
  .tooltip-inner {
    max-width: 14.5rem !important;
  }
}
.tooltip-lg {
  .tooltip-inner {
    max-width: 50rem !important;
  }
}
.tooltip-mdd {
  .tooltip-inner {
    max-width: 15.625rem !important;
  }
}
.tooltip-md {
  .tooltip-inner {
    max-width: 13.75rem !important;
  }
}
.tooltip-center {
  .tooltip-inner {
    text-align: center;
  }
}

/* Tooltip div wrapper */
.tooltip-wrapper {
  position: relative;
}

/* Tooltip text */
.tooltip-wrapper .tooltiptext {
  visibility: hidden;
  max-width: 125%;
  height: auto;
  overflow-wrap: break-word;
  background-color: $popover-arrow-color;
  color: $white-text;
  text-decoration: none;
  border-radius: 0.225rem;
  padding: 0.5rem 0.5rem;
  position: absolute;
  z-index: 10;
  top: 125%;
  left: 1rem;
  margin-left: -2rem;
  transition: opacity 0.3s;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

.tooltip-wrapper .tooltiptext::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $popover-arrow-color transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-wrapper:hover .tooltiptext {
  visibility: visible;
}

// .tooltip-white {
//   .tooltip-inner {
//     background-color: $gray-1 !important;
//     color: $popover-arrow-color !important;
//   }
// }

.shd-modal {
  .modal-content {
    border: 0.125rem solid $gray-16 !important;
  }

  &.border {
    .shd-modal--header {
      padding: 1.25rem 1.5rem !important;
      border-bottom: 0.0625rem solid #e5e7eb !important;
    }
    .shd-modal--footer {
      padding: 1.25rem 1.5rem !important;
      border-top: 0.0625rem solid #e5e7eb !important;
    }
  }

  &--header {
    padding: 1.5rem !important;
    border: none !important;
    min-height: auto !important;
  }

  &--title {
    color: $blue-15 !important;
    line-height: 1.25rem !important;
  }

  &--close-btn {
    width: 1.5rem;
    height: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0.25rem;

    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--body {
    padding: 0 1.5rem !important;
  }

  &--divider {
    width: calc(100% - 3rem);
    height: 0.125rem;
    background: $gray-16;
    display: flex;
    align-self: center;
  }

  &--footer {
    &.with-content {
      justify-content: space-between !important;
    }

    min-height: unset !important;
    max-height: unset !important;
    padding: 1rem 1.5rem !important;
    border: none !important;

    * {
      margin: 0;
    }

    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .shd-button {
        min-width: 6.25rem;
      }

      .shd-button--secondary.cancel-btn {
        min-width: 5rem;

        color: $gray-15;
        line-height: 1.25rem;
        border: 0.0625rem solid $gray-13;

        &:hover,
        &:focus-visible,
        &:active {
          color: $gray-15 !important;
          background-color: $gray-16 !important;
        }
      }
    }
  }
}

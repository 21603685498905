.agency-client-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-grow: 1;
  background-color: $gray-19;

  .agency-empty-list-container {
    overflow-y: auto;
    img {
      height: 378px;
      margin-top: 2rem;
    }
    .content {
      .title {
        font-size: 1rem;
        font-weight: 700;
        color: $blue-15;
        margin: 1.5rem 0 0 0;
        line-height: 1.5rem;
      }
      .sub-title {
        font-size: 0.875rem;
        font-weight: 500;
        color: $gray-15;
        margin: 1rem 0 0 0;
        line-height: 1.25rem;
      }
      .add-client-btn {
        margin-top: 2rem;
      }
    }
  }
}

//client table
.client-table {
  height: 100%;
  .search-bar {
    width: 12.5rem;
  }
  .bs-table-content {
    padding: 0 1.5rem;
    background-color: $gray-19;
  }
  .table-list {
    .react-bootstrap-table {
      .table-organism {
        .switchToggle input:checked + label {
          background-color: $blue-11;
        }
        .table-organism-header {
          background: #f3f4f6 !important;
          box-shadow: none !important;
          border: 1px solid #e5e7eb;
          tr {
            &:hover {
              background: #f3f4f6 !important;
              box-shadow: none !important;
            }
            &:last-of-type {
              padding-right: 1.1rem !important;
            }
          }
          &:hover {
            background: #f3f4f6 !important;
            box-shadow: none !important;
          }
        }
        .table-organism-body {
          tr {
            background-color: $gray-1;
            td {
              padding: 0.625rem 0.75rem !important;
              &:first-of-type {
                padding-left: 2rem !important;
                border-left: 0.0625rem solid #e5e7eb !important;
                vertical-align: center !important;
              }
              &:last-of-type {
                padding-right: 1.1rem !important;
                border-right: 0.0625rem solid #e5e7eb !important;
                vertical-align: center !important;
              }
            }
          }
        }
      }
      .switchToggle {
        display: flex;
        align-items: center;
        justify-content: end;
        label {
          margin-bottom: 0 !important;
        }
      }

      tr:hover {
        box-shadow: 0px 0px 0.25rem rgba(31, 41, 55, 0.16) !important;
      }

      .hoverable-cell {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        .hoverable-cell--actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          div {
            height: 2rem;
            width: 2.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            gap: 1rem;
            color: $gray-15;
            &:hover {
              background-color: $blue-13;
              color: $blue-11 !important;
            }
          }
        }
      }
    }
    .client-name-field {
      .name {
        font-size: 0.875rem;
        font-weight: 600;
        color: $blue-15;
        line-height: 1.25rem;
      }
      .email {
        font-size: 0.75rem;
        font-weight: 400;
        color: $gray-12;
        line-height: 1.25rem;
      }
    }
    .active-email-account-field,
    .active-sequence-field,
    .company-name-field,
    .creat-on-field,
    .total-email-sent-field,
    .client-access-type-field,
    .totel-prospect-field {
      font-size: 0.875rem;
      font-weight: 500;
      color: $blue-15;
      line-height: 1.125rem;
      white-space: nowrap;
    }
  }
}

// agency client action
.agency-client-actions {
  padding: 1.375rem 1.5rem;

  .search-input {
    background-color: #fff;
    input {
      background-color: #fff;
    }
  }
}

//agency add form
.add-client {
  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
  }
  .client-form-container {
    .modal-title {
      font-size: 1rem;
      font-weight: 600;
    }
    .add-client-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
      .bs-input-container {
        display: flex;
        flex-direction: column;
        margin: 0;
      }
      .bs-input-label-suffix {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        color: #b91c1c;
        margin: 0rem;
      }
      .agency-client-permissions-wrapper {
        h1 {
          color: $blue-15;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 1.5rem;
          margin-bottom: 0.75rem;
        }
        .agency-client-permission .rb-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .bs-radio-wrapper {
            display: flex;
            gap: 0.5rem;
            margin: 0;

            .bs-radio {
              top: 0;
              .bs-radio-inner {
                display: flex;
                justify-content: center;
                align-items: center;

                top: 0.125rem;

                &::after {
                  position: static;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }

            .agency-client-radio-btn {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              h2 {
                color: $blue-15;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1.25rem;
                margin: 0;
              }

              p {
                color: #6b7280;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: $font-weight-medium;
                line-height: 1rem;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .btn-solid-primary:disabled {
    background-color: $blue-11 !important;
    color: #ffffff;
    border: 0;
  }
  .agency-cancel-btn {
    min-width: 6.25rem !important;
    height: 2rem !important;
    border: 0.0625rem solid #e5e7eb !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
    transition: all 0.2s ease !important;
    color: #6b7280 !important;
    &:focus-visible {
      outline: none;
    }
    &:hover,
    &:active {
      color: #6b7280 !important;
      background-color: #f3f4f6 !important;
    }
  }
  .agency-submit-btn {
    min-width: 6.25rem;
    height: 2rem;
    border: none;
    font-weight: 500;
    line-height: 1.25rem;
    transition: all 0.2s ease;
    background-color: $blue-11;
    &:focus-visible {
      outline: none;
    }
  }
  .modal-content {
    width: 28.125rem !important;
    min-height: 26.063rem !important;
    .bs-modal-body {
      padding: 1.5rem;
    }

    .modal-title {
      gap: 0.625rem;
      svg {
        color: $blue-11;
      }
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: $blue-15;
        line-height: 1.5rem;
      }
    }
    .bs-input-label-prefix {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      color: $gray-15;
      margin-bottom: 0.25rem;
    }
    .bs-input {
      font-size: 0.875rem;
    }
    .modal-footer {
      padding: 0;
      max-height: 4.5rem;
      min-height: 4.5rem;
      margin: 0 1.5rem;
      .bs-modal-footer-action-buttons {
        margin: 0;
      }
    }
  }
}

//agency client delete modal
.agency-client-delete-modal {
  &.confirmation-modal-v3 .modal-dialog {
    width: 100% !important;
  }
  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
  }
  .modal-content {
    max-height: 16.813rem !important;
    width: 28.125rem !important;
    .modal-content-container {
      height: 100%;
      .modal-header {
        min-height: 0 !important;
      }
      .confirmation-modal-v3--title {
        padding-top: 1.5rem;
        h2 {
          line-height: 1.5rem !important;
          margin-left: 0.625rem !important;
        }
      }
    }
    .bs-modal-body {
      padding: 1rem 1.5rem 1.5rem 1.5rem !important;
      .confirmation-modal-v3--body__contents {
        p {
          &:nth-child(2) {
            margin-top: 1rem !important;
          }
        }
      }
    }
    .client-header {
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: $blue-15;
      }
      margin-bottom: 1rem;
    }
    .agency-sub-title-two {
      margin-top: 1rem;
    }
    .bs-modal-footer {
      .btn-ghost {
        border: 1px solid $gray-13 !important;
        line-height: 0;
        width: 6.25rem;
      }
      .btn-solid {
        width: 6.25rem;
      }
      .btn-loading.btn-solid-primary:disabled {
        background-color: #b91c1c !important;
        border-color: #b91c1c !important;
      }
    }
  }
}

.agency-client-wrapper {
  border: 2px solid green;
  display: flex;
  width: 480px;
  height: 36px;
  padding-right: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $blue-14;
  background: $gray-19;
  padding: 0.5rem;
  // margin-top: 24px;
  // margin-bottom: 24px;
}

.agency-client-disabled {
  cursor: not-allowed;
}

.agency-client-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  .agency-client-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
      width: 1px;
      height: 22px;
      background: $gray-13;
    }
    .agency-cross {
      cursor: pointer;
    }
    .agency-cross-disabled {
      cursor: not-allowed;
    }
  }
}

// associate client tooltip
.tooltip-client {
  &.bs-popover-inner {
    background-color: #1f2937;
    width: 250px;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .bs-popover-inner-content {
      padding: 0;
    }

    .arrow::after,
    .arrow::before {
      border-right-color: #1f2937;
    }
  }
}

.btn-white {
  background: $white;
  border-color: $gray-23;
}
.w-1 {
  width: 11.6875rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}

.agency-client-dropdown {
  &.sequence-associated-client {
    &.transfer-overlay {
      padding: 0.1875rem 0 0 0;
    }
  }
}

.agency-filter-dropdown {
  &.transfer-overlay {
    padding: 0.125rem 0 0 0;
  }
  .transfer-container {
    padding: 0;
    border: 0.0625rem solid $gray-13;
    box-shadow: 0.5rem 1rem 3.5rem -1.5rem rgba(156, 157, 161, 0.24),
      0.125rem 0.125rem 1rem -0.1875rem rgba(4, 21, 76, 0.16);

    .transfer-body {
      .transfer-search {
        height: 2rem;
        padding: 0.375rem 1rem;
        border: 0.0625rem solid $gray-16;
      }

      .transfer-list {
        li {
          height: 2rem;
          padding: 0.375rem 1rem !important;

          line-height: 20px !important;

          &:hover,
          &.active {
            background: $blue-17;
          }

          .option-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .transfer-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 3rem;
        border: 0.0625rem solid $gray-13;

        .bs-checkbox-wrapper {
          color: $blue-15;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  &.agency-filter {
    .transfer-container {
      width: 26.25rem !important;
      .transfer-body {
        padding-bottom: 0;
      }
    }
  }
}

.agency-enable-disable-modal {
  .modal-body {
    .submit-btn.btn-solid {
      width: 5rem;
      margin-left: 0.5rem;
    }
  }
}

.active-sequences-popover {
  .active-sequences-popover-content {
    .active-sequences-wrapper {
      .sequences-snipper-container {
        height: 3.75rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner-border {
          border-color: $blue-11;
          border-right-color: transparent;
        }
      }
    }
  }
}

.agency-client-switch-cell {
  text-align: end;
  .spinner-border {
    height: 1.2rem !important;
    width: 1.2rem !important;
    display: inline-block;
    vertical-align: text-bottom;
    border: 0.15rem solid $blue-11;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }
}

.permission-popover-btn {
  width: 0.875rem;
  height: 0.875rem;
  color: #9ca3af;
  margin-left: 0.25rem;
  transition: all 0.2s ease;
  &:hover {
    color: $blue-11;
  }
}
.permission-popover {
  padding: 0 !important;
  border-radius: 0.25rem !important;
  border: 0.0625rem solid #f3f4f6 !important;
  background: $white !important;

  .bs-popover-inner-content {
    padding: 0.625rem 0.5rem 0.625rem 0.75rem !important;

    display: flex;
    justify-content: center;
    gap: 0.375rem;

    div.content {
      width: 14.625rem;
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
      a {
        text-decoration: none;
        color: $blue-11;
        &:hover {
          text-decoration-color: $blue-11;
          text-decoration: underline;
        }
      }
    }
    div.icon {
      width: 1rem;
      height: 1rem;
      svg {
        color: #9ca3af;
      }
    }
  }
}

.connect-email-account-modal {
  .modal-content {
    height: 32.5rem;
    width: 34.125rem;
    padding: 0.75rem 0.5rem;
    border-radius: 0.5rem;
  }

  &__title {
    width: 90%;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__options {
    margin-top: 1.5rem;

    &-items {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 1rem;
      display: flex;
      height: 7.625rem;
      .item-description {
        width: 7.375rem;
        text-align: center;
        line-height: 0.875rem;
        margin-top: 0.5rem;
      }
    }

    .btn-solid {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      width: 9.375rem;
      position: relative;

      .regular-3 {
        margin-left: 0.4375rem;
      }

      &:hover {
        background: $blue-17;
        border-color: $blue-5;
        .email-label {
          color: $blue-11;
        }
      }
    }
  }
}

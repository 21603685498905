.auto-search-input {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 0.25rem 0.6875rem;
  color: $gray-8;
  font-size: 0.875rem;
  line-height: 1.5715;
  background-color: $gray-1;
  background-image: none;
  border: 1px solid $gray-5;
  border-radius: $border-radius;
  transition: all 0.3s;
  display: inline-flex;
  width: 100%;
  height: 2rem;

  &::placeholder {
    color: $gray-6;
  }

  &:hover {
    border-color: $blue-5;
    border-right-width: 1px !important;
  }

  &:focus,
  &:focus-within {
    border-color: $blue-5;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: $input-shadow;
  }
}

.no-autocomplete {
  color: $gray-15;
  text-align: center;
  padding: 0.5rem;
  padding-top: 0.9375rem;
}

.autocomplete {
  border-top-width: 0;
  border-radius: 0.25rem;
  background-color: $white;
  list-style: none;
  margin-top: 0;
  max-height: 17.0625rem;
  padding-left: 0;
  overflow: auto;
  width: 100%;
  z-index: 10;
  box-shadow: 2px 2px 1rem -3px rgba(4, 21, 76, 0.16);
}

.autocomplete li {
  padding: 0.5rem;
  color: $gray-8;
  font-size: 0.875rem;
  line-height: 1.5715;
}

.autocomplete>.active,
.autocomplete li:hover {
  background-color: $blue-17;
  color: $blue-15;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.referral-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-12;
  background-color: transparent;
  border: none;
}

.badge-referral {
  position: relative;
}

.cello-89vxj4 {
  top: 2px;
  right: 3px;
  width: 70% !important;
  height: 70% !important;
  border-radius: 100% !important;
  background-color: rgba(255, 0, 0, 0) !important;
}

.icon-log-container {
  z-index: 2;
  height: auto;
  align-items: center;
  margin-left: 0.7rem;
}

.icon-container-wrapper {
  padding-top: 1px;
  background-color: white;
  z-index: 3;
  .icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
}

.icon-email-sent {
  background-color: #e0e7ff;
}

.icon-blacklisted {
  background-color: #fee2e2;
}

.icon-removed-from-blacklist {
  background-color: #d1fae5;
}

.icon-email-opened {
  background-color: #cffafe;
}

.icon-link-clicked {
  background-color: #ede9fe;
}

.icon-reply-received-auto {
  background-color: #d1fae5;
}

.icon-reply-received-manual {
  background-color: #d1fae5;
}

.icon-unsubscribed-auto {
  background-color: #fee2e2;
}

.icon-unsubscribed-manual {
  background-color: #fee2e2;
}

.icon-email-failed {
  background-color: #fef3c7;
}

.icon-email-bounced {
  background-color: #ffedd5;
}

.icon-primary-email-changed-auto,.icon-primary-email-changed-manual{
  background-color: #DBEAFE;
  color: #1D4ED8;
}
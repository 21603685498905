.page-link {
  padding: $pagination-custom-padding-y $pagination-custom-padding-x;
  margin-left: $pagination-custom-margin-left;
  line-height: $pagination-custom-line-height;
  color: $pagination-custom-color;
  text-decoration: if($link-decoration == none, null, none);
  border: none;
  background-color: transparent;
  // border: $pagination-custom-border-width solid $pagination-custom-border-color;
  // border-radius: $border-radius;
  min-height: 24px;
  min-width: 23px;
  font-size: $text-14;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: $pagination-custom-hover-color;
    border-radius: $border-radius;
    // background-color: $pagination-custom-hover-bg;
    // border-color: $pagination-custom-hover-border-color;
  }

  &:focus {
    box-shadow: $pagination-custom-focus-box-shadow;
  }
}

.page-item {
  &.active .page-link {
    color: $pagination-custom-active-color;
    background-color: $blue-13;
    border-color: $pagination-custom-active-border-color;
    border-radius: $border-radius;
  }

  &.disabled .page-link {
    color: $pagination-custom-disabled-color;
    background-color: transparent;
    // background-color: $pagination-custom-disabled-bg;
    // border-color: $pagination-custom-disabled-border-color;
  }

  &.custom-ellipsis {
    .page-link {
      border: 0;
      display: inline-block;
      text-align: center;
      cursor: default;
      padding: 0;
      &:hover {
        color: $pagination-custom-color;
      }
      span {
        font-size: $heading-2;
        display: inline-block;
      }
    }
  }
}

.async-progress-bar {
  position: relative;
  min-width: 15.625rem;
  width: 15.625rem;
  max-height: 4.375rem;
  margin-top: 0.75rem;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
    8px 16px 56px -24px rgba(156, 157, 161, 0.24);
  &:first-child {
    margin-top: 0;
  }
  opacity: 1;
  will-change: opacity, max-height, margin-top;

  &.closing {
    margin-top: 0;
    opacity: 0;
    max-height: 0px;
    // This animation can be changed to a better animation
    transition: opacity 0.1s ease-out, max-height 0.3s ease-out,
      margin-top 0.3s ease-out;
  }

  .async-card-body {
    background-color: $white;
    border: 0.0625rem solid $gray-16;
    border-radius: 0.25rem;
    gap: 0.5rem;
    padding: 0.75rem;
    justify-content: space-between;
    background-color: $white;
    flex: 1 1 auto;
    -webkit-box-flex: 1;
    .card-body-container {
      gap: 0.5rem;
      &.completed {
        gap: 0.25rem;
      }
    }
    .text {
      font-size: $text-12;
      font-weight: $font-weight-semibold;
      color: $blue-15;
      max-width: 10.125rem;
      min-width: 9.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1rem;
    }
    .view-button {
      cursor: pointer;
      color: $blue-11;
      font-size: $text-12;
      font-weight: $font-weight-medium;
      line-height: 1rem;
    }
    .async-completed-process {
      margin-top: 0.09375rem;
    }
    .async-inprogress-process {
      margin-bottom: 1.125rem;
    }
  }

  .async-cross-icon {
    color: $gray-15;
    cursor: pointer;
  }
}

.async-stacked-bar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4.625rem;
  right: 1.5rem;
  gap: 0;
  z-index: 9;
  height: max-content;
  transition: height 0.3s ease-out;
}

.in-progress {
  gap: 0.25rem;
}

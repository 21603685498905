.users-and-teams {
  &--tabs {
    height: calc(100% - 2.75rem);
    .bs-tabs-nav {
      margin-bottom: 1.5rem !important;
      display: flex;
      align-items: start !important;
      &::before {
        border-bottom: 0rem solid #e8e8e8 !important;
      }
      .bs-tabs-nav-wrap {
        height: 1.875rem !important;
        align-self: auto !important;
        top: 0;
      }
      .bs-tabs-nav-list {
        gap: 1.25rem;
      }
      .bs-tabs-tab {
        color: $blue-15;
        font-weight: $font-weight-medium;
        margin: 0;
        padding: 0 !important;
        line-height: 1.5rem;
        margin-bottom: 0.375rem;

        &:first-of-type {
          padding: 0 0.0625rem !important;
        }
      }
      .bs-tabs-tab-active {
        color: $blue-11;
        font-weight: $font-weight-semibold;
      }
      .bs-tabs-ink-bar {
        height: 0.1875rem !important;
        border-radius: 0.25rem;
      }
      .bs-tabs-extra-content {
        height: 2.5rem;

        .users-and-teams--actions-wrapper {
          .users-and-teams--search {
            background-color: $gray-1;
            .bs-input-md {
              background-color: $gray-1;
            }
          }
          height: 2rem;
          min-height: 2rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 1rem;
          .bs-input-affix-wrapper:focus,
          .bs-input-affix-wrapper:focus-within {
            box-shadow: none !important;
          }
        }
      }
    }

    .bs-tabs-content {
      height: 100%;
    }
    .bs-tabs-content-holder {
      overflow: hidden;
    }

    .table-list {
      overflow-x: auto;
      height: calc(100vh - 10.7rem) !important;

      .bs-table {
        width: 100%;

        .table-organism-header {
          height: 2.75rem;
          max-height: 2.75rem;
          background-color: #f3f4f6 !important;
          box-shadow: none !important;
          tr {
            th {
              height: 2.75rem;
              line-height: 1.25rem;
              border-top-color: #e5e7eb !important;
              border-bottom-color: #e5e7eb !important;
              box-sizing: border-box;
              padding: 0.6875rem 0 !important;
              &:first-of-type {
                padding-left: 2rem !important;
                border-left: 0.0625rem solid #e5e7eb !important;
              }
              &:last-of-type {
                padding-right: 2rem !important;
                border-right: 0.0625rem solid #e5e7eb !important;
              }
            }
            &:hover {
              background-color: #f3f4f6 !important;
            }
          }
        }

        .table-organism-body {
          tr {
            td {
              height: 3.25rem;
              max-height: 3.25rem;
              padding: 0.375rem 0 0.3125rem 0 !important;
              box-sizing: border-box;

              &:first-of-type {
                padding-left: 2rem !important;
                border-left: 1px solid #e5e7eb;
              }
              &:last-of-type {
                padding-right: 2rem !important;
                border-right: 1px solid #e5e7eb;
              }
            }

            &:hover {
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }

  &--users {
    .text-cell {
      color: #1f2937;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
    }
    .last-login-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-name-cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 2.5rem;
      max-height: 2.5rem;

      h1 {
        color: $blue-15;
        font-size: 0.875rem;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
        margin: 0;
      }
      .user-name-cell-inner {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        p {
          margin: 0;
          color: #4b5563;
          font-size: 0.75rem;
          line-height: 1.25rem;
        }

        span {
          cursor: pointer;

          &.active {
            color: #047857;
          }
          &.invited {
            color: #f59e0b;
          }
          &.failed {
            color: #b91c1c;
          }
          &.disabled {
            color: #d1d5db;
          }
        }
      }
    }
    .teams-badges-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;

      .team-badge {
        height: 1.5rem;
        padding: 0.125rem 0.5rem;
        border-radius: 4px;
        background-color: #dbeafe;

        p {
          margin: 0;
          color: #2563eb;
          font-size: 0.875rem;
          line-height: 1.25rem;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &--teams {
    .team-cell {
      color: #1f2937;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .users-and-teams--table-body-wrapper {
      tr {
        height: 3.25rem;
      }
    }
  }
}

.three-cols-users-table {
  .bs-table {
    .table-organism-header {
      tr {
        th {
          &:nth-of-type(1) {
            min-width: 50.48%;
            width: 50.48%;
            padding-right: 21.44% !important;
          }
          &:nth-of-type(2) {
            min-width: 31.34%;
            width: 31.34%;
            padding-right: 25.96% !important;
          }
          &:nth-of-type(3) {
            min-width: 18.18%;
            width: 18.18%;
          }
        }
      }
    }
    .table-organism-body {
      tr {
        td {
          &:nth-of-type(1) {
            min-width: 50.48%;
            width: 50.48%;
            padding-right: 21.44% !important;
          }
          &:nth-of-type(2) {
            min-width: 31.34%;
            width: 31.34%;
            padding-right: 25.96% !important;
          }
          &:nth-of-type(3) {
            min-width: 18.18%;
            width: 18.18%;
          }
        }
      }
    }
  }
}

.four-cols-users-table {
  .bs-table {
    .table-organism-header {
      tr {
        th {
          &:nth-of-type(1) {
            min-width: 34.81%;
            width: 34.81%;
            padding-right: 5.77% !important;
          }
          &:nth-of-type(2) {
            min-width: 14.71%;
            width: 14.71%;
            padding-right: 9.33% !important;
          }
          &:nth-of-type(3) {
            min-width: 32.04%;
            width: 32.04%;
            padding-right: 4.04% !important;
          }
          &:nth-of-type(4) {
            min-width: 18.18%;
            width: 18.18%;
          }
        }
      }
    }
    .table-organism-body {
      tr {
        td {
          &:nth-of-type(1) {
            min-width: 34.81%;
            width: 34.81%;
            padding-right: 5.77% !important;
          }
          &:nth-of-type(2) {
            min-width: 14.71%;
            width: 14.71%;
            padding-right: 9.33% !important;
          }
          &:nth-of-type(3) {
            min-width: 32.04%;
            width: 32.04%;
            padding-right: 4.04% !important;
          }
          &:nth-of-type(4) {
            min-width: 18.18%;
            width: 18.18%;
          }
        }
      }
    }
  }
}

.four-cols-teams-table {
  .bs-table {
    .table-organism-header {
      tr {
        th {
          &:nth-of-type(1) {
            min-width: 38.94%;
            width: 38.94%;
            padding-right: 6.73% !important;
          }
          &:nth-of-type(2) {
            min-width: 19.62%;
            width: 19.62%;
            padding-right: 16.35% !important;
          }
          &:nth-of-type(3) {
            min-width: 29.13%;
            width: 29.13%;
            padding-right: 9.42% !important;
          }
          &:nth-of-type(4) {
            min-width: 12.31%;
            width: 12.31%;
          }
        }
      }
    }
    .table-organism-body {
      tr {
        td {
          &:nth-of-type(1) {
            min-width: 38.94%;
            width: 38.94%;
            padding-right: 6.73% !important;
          }
          &:nth-of-type(2) {
            min-width: 19.62%;
            width: 19.62%;
            padding-right: 16.35% !important;
          }
          &:nth-of-type(3) {
            min-width: 29.13%;
            width: 29.13%;
            padding-right: 9.42% !important;
          }
          &:nth-of-type(4) {
            min-width: 12.31%;
            width: 12.31%;
          }
        }
      }
    }
  }
}

.invite-user-modal,
.team-detail-modal {
  animation-name: moveInRight;
  animation-duration: 0.3s;
  animation-timing-function: linear;

  &--close-btn {
    width: 2.5rem;
    height: 2.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0px 0px 0px 4px;
    opacity: 0.800000011920929;
    background: $blue-15;

    cursor: pointer;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: $white;
    }
  }

  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.4375rem;
  }

  ::-webkit-scrollbar-track {
    background: $gray-1;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-23;
    border-radius: 0.25rem;
  }

  .modal-dialog {
    width: 35rem;
    max-width: none;
    height: 100vh;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
    border-radius: 0;
  }

  .modal-content {
    height: 100vh;
    max-height: 100vh;
    border: 0;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: none;
  }
  .modal-body {
    height: 100vh;
    max-height: 100vh !important;
    padding: 0 !important;

    display: flex;
    overflow: hidden;
  }

  &--content {
    width: 32.5rem;
    padding: 2rem 1.5rem;
    background-color: $white;
    max-height: 100vh !important;

    overflow: hidden auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.75rem;
  }

  &--wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 29.5rem;
  }

  &--title {
    width: 29.5rem;
    color: $blue-15;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: $font-weight-semibold;
    margin-bottom: 0.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span.icon {
      width: 1.125rem;
      height: 1.125rem;
      color: #6b7280;
      cursor: pointer;
    }
  }

  .bs-input-label-prefix {
    color: $blue-15 !important;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem !important;
    margin-bottom: 0.375rem !important;
  }

  .bs-input-affix-wrapper {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background: #f9fafb;

    &:focus,
    &:focus-within {
      border-color: $blue-11;
      box-shadow: none;
    }

    input {
      color: $blue-15;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      background-color: transparent;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  &--txt-input {
    margin-bottom: 0 !important;
    .bs-input-affix-wrapper.input-error {
      border-color: #fca5a5 !important;
    }
    .bs-input-affix-wrapper.input-error:focus,
    .bs-input-affix-wrapper.input-error:focus-within {
      box-shadow: none !important;
    }
    .bs-input-label-suffix {
      color: #b91c1c !important;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 0.75rem !important;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem !important;
      margin-top: 0.25rem !important;
    }
  }

  &--container {
    width: 29.5rem;
    .user-action-label {
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    .bs-select-box {
      border-radius: 0.25rem;
      border: 0.0625rem solid #d1d5db;
      background: #f9fafb;
      min-height: 2.25rem;
      max-height: 6.25rem;
      overflow-y: auto;
      align-items: center;
      padding: 0.1875rem 0.75rem;

      &:focus,
      &:focus-within {
        border-color: $blue-11;
        box-shadow: none;
      }

      .bs-select-box-text {
        color: $blue-15;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
      }
      .bs-select-box-icon-wrapper {
        width: 1rem;
        height: 1rem;
        justify-content: center;
      }
    }
    &.change-role-action {
      .bs-select-box {
        height: 2.25rem;
        overflow: hidden;
        align-items: center;
        gap: 0.5rem;
        .bs-select-box-icon-wrapper {
          margin: 0;
        }
      }
    }
  }

  .team-members-input {
    .email-input {
      &--container {
        min-height: 8.875rem;
        max-height: 20.125rem;
        padding: 0;
        overflow-y: auto;

        .bs-select-box {
          outline: none;
          box-shadow: none;
          border: none;
          background-color: transparent;
          padding: 0.375rem 1rem;
          height: 100%;
          align-items: flex-start;
          max-height: none;

          .bs-select-box-icon-wrapper {
            display: none;
          }
        }
      }
      &--pills {
        padding: 0.375rem 1rem;
        padding-bottom: 0;
      }
    }
  }
}

.email-input {
  &--container {
    width: 29.5rem;
    min-height: 8.875rem;
    max-height: 20.125rem;

    overflow-y: auto;

    padding: 0.4375rem 1rem;

    display: grid;
    grid-template-rows: auto 1fr;
    gap: 0.375rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid #d1d5db;
    background: #f9fafb;

    cursor: text;

    &:focus,
    &:focus-within {
      border-color: $blue-11;
    }

    &.error {
      border-color: #fca5a5;
    }
  }

  &--error-msg {
    color: #b91c1c;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &--helper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.25rem;
  }

  &--count {
    color: #9ca3af;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &--pills {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.375rem;
    .shd-pills {
      background-color: $white;
    }
  }

  &--input {
    border: none;
    outline: none;

    width: 100%;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #1f2937;

    padding: 0;

    background: #f9fafb;

    &:disabled {
      background-color: transparent;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #9ca3af;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
}

.team-detail-modal {
  .invite-user-modal--content {
    overflow: hidden;
    padding: 0;
  }
  &--wrapper {
    gap: 0;
    width: 100%;
    padding: 2rem 0;
  }
  &--title {
    padding: 0rem 1.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  &--createdOn {
    color: #4b5563;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    padding: 0rem 1.5rem;
  }
  &--total {
    color: #6b7280;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1.125rem;
    padding: 0rem 1.5rem;
  }
  &--users {
    height: calc(100vh - 8.75rem);
    overflow: hidden auto;
    padding: 0 1.5rem;

    .user {
      width: 100%;
      height: 3.25rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid #e5e7eb;
      background: #fff;

      position: relative;

      .user-left {
        display: flex;
        justify-content: center;
        flex-direction: column;

        height: 3.25rem;
        h1 {
          color: #1f2937;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.25rem;
          margin: 0;
        }
        p {
          color: #4b5563;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.25rem;
          margin: 0;

          span {
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;

            &.active {
              color: #047857;
            }
            &.invited {
              color: #f59e0b;
            }
            &.failed {
              color: #b91c1c;
            }
            &.disabled {
              color: #d1d5db;
            }
          }
        }
      }
      .user-right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        div {
          width: 1.125rem;
          height: 1.125rem;

          display: flex;
          justify-content: center;
          align-items: center;

          &.disabled-btn {
            cursor: not-allowed !important;
          }

          svg {
            width: 100%;
            height: 100%;
            color: #6b7280;
          }
        }

        p {
          color: #1f2937;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.25rem;
          margin: 0;
        }
      }
    }

    .see-more-container {
      width: 32.5rem;
      height: 5rem;

      display: flex;
      justify-content: center;
      align-items: flex-end;

      position: absolute;
      left: -1.5rem;
      bottom: -1rem;

      svg {
        position: absolute;

        width: 100%;
        height: 100%;
      }

      span {
        color: #1d4ed8;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        z-index: 1;
        margin-bottom: 0.75rem;
      }
    }
  }
}

.cancel-btn {
  &.btn-ghost-outlined {
    color: #595959 !important;
    font-size: 14px;
    font-weight: 500;
    border-color: #d9d9d9 !important;
    &.disabled {
      opacity: 0.34;
    }

    &:hover {
      color: #595959 !important;
      border-color: #d9d9d9 !important;
    }
  }
}

.delete-btn {
  &.btn-solid-danger {
    background: #b91c1c !important;
    color: $white !important;
    &.disabled {
      opacity: 0.34;
    }
  }
}

.user-action {
  .user-action-label {
    margin: 0;
    margin-bottom: 0.25rem;
    color: #6b7280;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
  }
  .bs-select-box {
    border-radius: 0.25rem;
    border: 0.0625rem solid #d1d5db;
    background: #f9fafb;
    min-height: 2.25rem;
    max-height: 6.25rem;
    overflow-y: auto;
    align-items: flex-start;
    padding: 0.5rem 0.9375rem 0.5rem 1rem;
    gap: 0.5rem;
    box-sizing: border-box;

    &:focus,
    &:focus-within {
      border-color: $blue-11;
      box-shadow: none;
    }

    .bs-select-box-text {
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &.placeholder {
        color: $blue-15;
      }
    }

    .bs-select-box-icon-wrapper {
      width: 1.25rem;
      height: 1.25rem;
      justify-content: center;
      margin: 0;
    }
  }
  &.change-role-action {
    .bs-select-box {
      height: 2.25rem;
      overflow: hidden;
    }
  }
}

.select-search {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: auto;
  color: #1f2937;
  flex: 1;

  &::placeholder {
    color: #9ca3af;
  }

  &:focus {
    outline: none;
  }
}

.users-change-role-select {
  padding: 0.25rem 0 0 0 !important;
}

.team-select {
  .transfer-content {
    max-height: calc(100vh - 25rem) !important;
  }
}

.users-and-teams-empty-list {
  width: 100%;
  height: 100%;

  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  .shd-empty-list {
    gap: 1.5rem;

    &--image {
      width: auto;
      height: auto;
    }

    &--body {
      gap: 0.5rem;
      max-width: 37.5rem;
    }

    &--title {
      text-align: center;
    }

    &--description {
      color: #6b7280;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 2rem;
    }
  }
}

.actionables {
  .action-btn {
    width: 2.125rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
      color: #6b7280;
    }

    &:hover {
      background: #dbeafe;

      svg {
        color: $blue-11;
      }
    }
  }

  .show > .show {
    display: block !important;
  }
  .dropdown-custom > .dropdown-menu-custom {
    display: none;
  }

  .dropdown-custom {
    .dropdown-toggle-custom {
      width: 2.125rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 0.25rem;
      padding: 0;
      border: none;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        color: #6b7280;
      }

      &:hover {
        background: #dbeafe;

        svg {
          color: $blue-11;
        }
      }
    }
    .dropdown-menu-custom {
      // display: flex;
      flex-direction: column;
      align-items: flex-start;

      height: auto;
      padding: 0.5rem 0px;

      border-radius: 0.25rem;
      border: 0.0625rem solid #e5e7eb;
      background: #fff;
      box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
        2px 2px 16px -3px rgba(4, 21, 76, 0.16);

      a {
        display: flex;
        align-self: stretch;
        align-items: center;
        gap: 0.5rem;
        padding: 0.4375rem 1rem;
        height: 2.125rem;

        color: #374151;
        font-size: 0.875rem;
        line-height: 1.25rem;

        svg {
          color: #6b7280;
        }

        &:hover {
          background: #eff6ff;
        }
      }
    }
  }
}

.assign-team-tooltip {
  .tooltip-inner {
    padding: 0.75rem !important;
    max-width: 21.875rem !important;
  }
}

.plan-based-restriction {
  justify-content: flex-start;
  height: calc(100vh - 12.125rem);
  .shd-empty-list {
    justify-content: flex-start;
    gap: 0;
    .shd-empty-list--body {
      gap: 0;
      .shd-empty-list--description {
        margin-top: 0.5rem;
      }
    }
  }
}

.action-modal.confirmation-modal-v3 {
  .modal-dialog {
    width: 27.375rem !important;
    min-height: 10.75rem !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    .modal-header {
      min-height: auto;
      padding: 0px 1.5rem !important;
      .modal-title {
        padding-top: 1.5rem;
        .confirmation-modal-v3--title {
          padding: 0;
          align-items: center;
          gap: 1rem;
          img,
          svg {
            margin: 0;
          }
          h2 {
            margin: 0;
            line-height: 1.25rem;
          }
        }
      }
      .close {
        color: #1f2937;
      }
    }
    .modal-content .bs-modal-body {
      padding: 1.5rem !important;

      .confirmation-modal-v3--body__contents p {
        margin-bottom: 1rem;
        &:last-of-type {
          margin: 0;
        }
      }
    }
    .modal-footer {
      margin: 0 1.5rem 1.25rem 1.5rem;
      border-top: 0.0625rem solid #f3f4f6;

      button {
        min-width: 6.25rem;
      }
    }
  }
  .modal-dialog
    .modal-footer
    .bs-modal-footer-action-buttons
    button:last-of-type {
    margin-left: 1rem !important;
  }
}

.cursor-on-row {
  .table-organism-body {
    tr {
      cursor: pointer;
    }
  }
}

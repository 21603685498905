.logo {
  &--primary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0.625rem;

    img {
      width: auto;
      max-width: 100%;
      height: 2.135rem;
    }

    span {
      color: $white-text;
      margin-left: 0.35rem;
      font-size: 1.5625rem;
    }
  }

  &--with-text {
    img {
      width: 2.135rem;
      height: 2.135rem;
    }
  }
}

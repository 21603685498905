.bs-slider {
  &.rangeslider-horizontal {
    height: 4px;
    border-radius: 0;
    box-shadow: none;
    background: $gray-3;
    .rangeslider__fill {
      background-color: $blue-3;
      border-radius: 0;
      box-shadow: none;
    }
    .rangeslider__handle {
      width: 14px;
      height: 14px;
      box-shadow: none;
      border: 2px solid $blue-3;
      &:focus {
        outline: 0;
      }
      &:hover {
        border-color: $blue-5;
      }
      &:active {
        border-color: $blue-6;
        box-shadow: 0px 0px 0px 3px rgba($blue-6, 0.15);
      }
      &::after {
        box-shadow: none;
        width: unset;
        height: unset;
      }
    }
  }
  &.disabled {
    &.rangeslider-horizontal {
      background: $gray-3;
      .rangeslider__fill {
        background-color: $gray-3;
        cursor: not-allowed;
      }
      .rangeslider__handle {
        border-color: $gray-6;
        cursor: not-allowed;
      }
    }
  }
}

.error-404-container {
  .header {
    box-shadow: 10px 0 30px rgba(220, 224, 230, 0.51);
    height: 5.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding-top: 5.4rem;
    // This is temporary. Get clarity from rakesh about how to apply these properties for the
    // smaller screens and then change it.
    padding-top: 4.4rem;
    .content-header {
      margin-top: 1.875rem;
      margin-bottom: 1rem;
    }
    .external-icon-wrapper {
      margin-top: 2.625rem;
      a {
        text-decoration: none;
        color: #0d1c2e;
        .external-icon {
          font-size: 1.5rem;
        }
      }
    }
  }
}

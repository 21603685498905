.pause-prospect-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;

    .modal-header {
      border-bottom-color: $blue-14;
    }

    .modal-title {
      color: $blue-15;

      .pause-title {
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        span {
          color: $blue-15;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5rem;
        }
      }
    }

    .modal-body {
      padding-top: 30px;

      .resume-text {
        color: $blue-15;
        font-size: 0.875rem;
        line-height: 1.125rem;
        margin: 0;
      }

      .form-check {
        width: 100%;

        display: flex;
        align-items: center;

        cursor: pointer;

        &:first-of-type {
          margin-bottom: 1.25rem;
        }

        input[type='radio'] {
          width: 1rem;
          height: 1rem;
          margin-top: -1px;
          cursor: pointer;
        }
        label {
          display: flex;
          align-items: center;
          gap: 1rem;

          font-size: 0.875rem;
          font-weight: normal;
          color: $blue-15;
          line-height: 18px;
          margin-left: 0.5rem;
          cursor: pointer;

          .bs-input-number-container {
            width: 4.125rem;
            height: 2rem;
            display: inline-block;
            .bs-input-number {
              width: 4.125rem;
              min-width: 4.125rem;
              height: 2rem;
            }
          }
        }
      }
    }

    .modal-footer {
      border-radius: 0.25rem;
      border-top-width: 0.125rem;
      border-top-color: $gray-16;
    }

    .btn-ghost {
      color: $gray-15;
      font-weight: $font-weight-medium;
    }
  }

  &--alert {
    margin-top: 1.5rem;

    p {
      align-items: flex-start !important;
      gap: 12px;

      i {
        margin: 2px 0 0 0 !important;
        font-size: 1rem;
      }

      div {
        width: 492px;
        span {
          font-size: 12px;
          font-weight: $font-weight-medium;
          line-height: 20px;
          color: $gray-15;
          margin: 0;
        }
      }
    }
  }
}

.pause-span{
  line-height: 20px;
}

.pause-link {
  color: $black-1 !important;
  text-decoration: underline;
  font-weight: $font-weight-medium;
}